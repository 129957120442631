import {computed, getCurrentInstance, inject} from "@vue/composition-api";
import {VueFlowEditorProvider} from "@/g6-canvas/editor/editor.ts";
import {suffixSize} from "@/g6-canvas/utils/utils";
import {designComponent} from "@/g6-canvas/composition";

const logo = ''

interface Menu {
    name: string,
}

interface MenuGroup {
    name: string
    expanded?: boolean
    menus?: Menu[]
}

export default designComponent({
    name: 'vue-flow-editor-menu',
    setup(props, context) {

        const {editorState, props: editorProps} = inject(VueFlowEditorProvider) as any

        const headerStyles = computed(() => ({
            height: suffixSize(editorState.props.toolbarHeight)
        }))

        return () => (
            <div class="vue-flow-editor-menu">
                <div class="vue-flow-editor-menu-header" style={headerStyles.value}>
                    {editorProps.editorTitle || <img src={logo} alt="vue-flow-editor"/>}
                </div>
                <div class="vue-flow-editor-menu-list">
                    <div class="vue-flow-editor-menu-list-content">
                        {!!context.slots.default && context.slots.default()}
                    </div>
                </div>
            </div>
        )
    },
})
