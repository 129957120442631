<template>
  <div class="">
    <el-table :data="tableData" border max-height="450px" style="width: 100%">
      <el-table-column type="index" :label="$t('lconfigMoudle.inenum')" align="center">
      </el-table-column>
      <el-table-column prop="fno" :label="$t('configMoudle.purchaseContract')"></el-table-column>
      <el-table-column prop="spname" :label="$t('configMoudle.supplierName')"> </el-table-column>
      <el-table-column prop="real_attach_name" :label="$t('configMoudle.fileName')"> </el-table-column>
      <el-table-column prop="attach_path" :label="$t('configMoudle.filePath') "> </el-table-column>
      <!-- <el-table-column prop="attach_type" label="附件类型"> </el-table-column> -->
      <el-table-column :label="$t('Approval.operation')" width="200" align="center">
        <template slot-scope="scope">
          <i
            class="el-icon-download"
            @click="handleEdit(scope.$index, scope.row)"
          ></i>
          <!-- <i
            class="el-icon-delete-solid"
            @click="handleDeletea(scope.$index, scope.row)"
          ></i> -->
        </template>
      </el-table-column>
    </el-table>
    <!-- <el-pagination
      style="margin-top: 10px"
      background
      layout="total, prev, pager, next, sizes"
      :total="total"
      :page-sizes="[20, 50, 100, 200]"
      :current-page.sync="pageNum"
      @size-change="sizeChange"
      @current-change="currentChange"
      @prev-click="currentChange"
      @next-click="currentChange"
    >
    </el-pagination> -->
  </div>
</template>
 
<script>
// import { xxx } from '@/xxx';
export default {
  name: "Y-puchase",
  mixins: [],
  props: {
    tableData: {
      type: Array,
    },
    // pageNum: {
    //   type: Number,
    // },
    // pageSize: {
    //   type: Number,
    // },
    // total: {
    //   type: Number,
    // },
  },
  components: {},
  filter: {},
  computed: {},
  data() {
    return {
      params: "",
    };
  },
  watch: {},
  // life cycle start
  created() {},
  mounted() {
    this.mount();
  },
  // life cycle end
  methods: {
    mount() {},
    sizeChange (val) {
      // this.$emit("sizeChange", val)
    },
    currentChange (val) {
      // this.$emit("currentChange", val)
    },
    handleEdit (index, row) {
      this.$emit("handleEdit", row)
    },
    handleDeletea (index, row) {
      // this.rowList = row
      // this.dialogVisible = true
    },
  },
  destroyed() {},
};
</script>
 
<style lang="scss">
</style> 