import './iconfont'

import canvas from './vue-flow-editor-canvas'
import editor from './vue-flow-editor'
import menu from './vue-flow-editor-menu'
import toolbar from './vue-flow-editor-toolbar'
import editMenu from './vue-flow-edit-menu.vue'
import editMenuGroup from './vue-flow-edit-menu-group.vue'
import preview from './vue-flow-editor-preview.vue'

export const EditorComponent = [
    canvas,
    editor,
    menu,
    toolbar,
    editMenu,
    editMenuGroup,
    preview,
]