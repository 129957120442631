<template>
  <el-pagination
    v-if="isPaged"
    @size-change="handleSizeChange"
    @current-change="handleCurrentChange"
    :small="small"
    :current-page.sync="currentPages"
    :page-sizes="pageSizes"
    :page-size="pageSize"
    layout="total, sizes, prev, pager, next, jumper"
    :total="total"
    :class="{ypage: isSmall, ymiddle: isMiddle, ylarge: isLarge}"
  >
  </el-pagination>
</template>

<script>
import { checkFont } from "@/utils/methods";
export default {
  name: "Y-page",
  props: {
    small: {
      type: Boolean,
      default: false
    },
    total: {
      type: [Number, String],
      default: 0,
    },
    pageSize: {
      type: [Number, String],
      default: 20,
    },
    pageSizes: {
      type: Array,
      default: () => [20, 50, 100, 200]
    },
    currentPage: {
      type: [Number, String],
      default: 1,
    },
    isPaged: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      currentPages: 1,
      fonts: null,
      isSmall: false,
      isMiddle: false,
      isLarge: false
    };
  },
  computed: {},
  created() {},
  mounted() {
    let font = checkFont()
    this.fonts = font
    if(font == '14px') {
      this.isSmall = true
      this.isMiddle = false
      this.isLarge = false
    }else if(font == '16px'){
      this.isSmall = false
      this.isMiddle = true
      this.isLarge = false
    }else {
      this.isSmall = false
      this.isMiddle = false
      this.isLarge = true
    }
    let all = document.getElementsByTagName('*'),
    reg = new RegExp("[\\u4E00-\\u9FFF]+","g");

    for(var i=0;i<all.length;i++) {
      if(reg.test(all[i].innerText)) {
        all[i].style.fontSize = font;
      }
    }
  },
  watch: {
    currentPage (newVal) {
      console.log("currentPage", newVal)
      this.currentPages = newVal
    }
  },
  methods: {
    handleSizeChange(val) {
      if (!val) return;
      this.$emit("handleSizeChange", val);
    },
    handleCurrentChange(val) {
      if (!val) return;
      this.$emit("handleCurrentChange", val);
    },
  },
  components: {},
};
</script>

<style scoped lang="scss">
.el-pagination {
  display: flex;
}
.ypage {
  /deep/.el-input__inner {
    font-size: 14px;    
  }
  /deep/ .number {
    font-size: 14px;
  }
}
.ymiddle {
  /deep/.el-input__inner {
    font-size: 16px;    
  }
  /deep/ .number {
    font-size: 16px;
  }
}
.ylarge {
  /deep/.el-input__inner {
    font-size: 18px;    
  }
  /deep/ .number {
    font-size: 18px;
  }
}
</style>
