<script lang="ts">
import request from "@/axios/index";

export default {
  name: "index",
  mounted() {
    request.get('/curcyratefeqget').then(res => {
      this.gridData = []
      for (var i = 0; i < res.length; i++) {
        const row = res[i]
        const {minutes, hours, daysOfMonth, months, daysOfWeek} = this.splitCron(res[i].schcron)
        row.minutes = minutes
        row.hours = hours
        row.day = daysOfMonth
        row.month = months
        row.week = daysOfWeek
        this.gridData.push(row)
      }
    })
  },
  data() {
    return {
      gridData: [
        {schkey: 'permonth', month: [], week: [], day: [], hour: 0, minutes: 0},
        {schkey: 'perweek', month: [], week: [], day: [], hour: 0, minutes: 0},
        {schkey: 'perday', month: [], week: [], day: [], hour: 0, minutes: 0},
      ],
      dialogTableVisible: false,

      // 遮罩层
      loading: false,
      // 选中数组
      ids: [],
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      // 显示搜索条件
      showSearch: true,
      // 总条数
      total: 0,
      // 定时任务表格数据
      jobList: [],
      // 弹出层标题
      title: "",
      // 是否显示弹出层
      open: true,
      // 是否显示详细弹出层
      openView: false,
      // 是否显示Cron表达式弹出层
      openCron: false,
      // 传入的表达式
      expression: "",
      // 表单参数
      form: {},
      // 表单校验
      rules: {
        jobName: [
          {required: true, message: "任务名称不能为空", trigger: "blur"}
        ],
        invokeTarget: [
          {required: true, message: "调用目标字符串不能为空", trigger: "blur"}
        ],
        cronExpression: [
          {required: true, message: "cron执行表达式不能为空", trigger: "blur"}
        ]
      },
    }
  },
  methods: {
    show() {
      this.dialogTableVisible = true
    },
    cronCreate(month = '*', week = '?', day = '*', hour = 0, minutes = 0) {
      return `0 ${minutes} ${hour} * ${month} ${week}`;
    },
    handleStatusSwitch(row) {
      if (row.status) {
        this.gridData = this.gridData.map(item => {
          if (item.id !== row.id) {
            item.status = false;
          }
          return item
        })
      }
    },
    splitCron(cron) {
      if (!cron) {
        return
      }

      // 拆分 Cron 表达式为不同的部分
      const parts = cron.split(' ');

      // 分钟
      const minutes = parts[1] === '*' || parts[1] === '?' ? '1' : parseInt(parts[1])

      // 小时
      const hours = parts[2] === '*' || parts[2] === '?' ? '1' : parseInt(parts[2]);

      // 日期
      const daysOfMonth = parts[3] === '*' || parts[3] === '?' ? '*' : parseInt(parts[3]);

      // 月份
      const months = '*';

      // 星期
      const daysOfWeek = '*';

      // 返回拆分后的变量
      return {
        minutes,
        hours,
        daysOfMonth,
        months,
        daysOfWeek
      };
    },
    submit() {
      for (var i = 0; i < this.gridData.length; i++) {
        const row = this.gridData[i]
        row.schcron = this.cronCreate('*', '?', '*', row.hours, row.minutes)
      }
      this.loading = true
      request.post('/curcyratefeqset', this.gridData).then(res => {
        this.loading = false
        this.dialogTableVisible = false
        this.$message.success(this.$t('homeSet.saveTip'))
      }).catch(() => this.loading = false)
    },
    actions(_fun) {
      const that = this
      this.$confirm(this.$t('configMoudle.settingSyncTip'), this.$t('configMoudle.tip'), {
        confirmButtonText: this.$t('configMoudle.confirm'),
        cancelButtonText: this.$t('configMoudle.cancel'),
        type: 'warning',
        beforeClose: function (action, instance, done) {
          if (action === 'confirm') {
            instance.confirmButtonLoading = true
            request.get('/curcyrateget')
                .then(() => {
                  instance.confirmButtonLoading = false
                  that.$message({ type: 'success', message: that.$t('configMoudle.success')});
                  _fun()
                  done()
                })
                .catch(() => {
                  instance.confirmButtonLoading = false
                  done()
                  that.$message({ type: 'error', message: that.$t('configMoudle.error') });
                })
          } else {
            done()
          }
        }
      }).then(() => {

      })
    }
  }
}
</script>

<template>
  <div>
    <el-dialog title="定时任务" :visible.sync="dialogTableVisible">
      <el-table :data="gridData" size="mini">
        <el-table-column align="center" property="schname" label="任务名称" width="150"></el-table-column>
        <el-table-column prop="state" align="center" width="200" label="启用">
          <template v-slot="{ row }">
            <div style="text-align: center">
              <el-switch
                  style="margin-left: 70px"
                  v-model="row.status"
                  size="mini"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  @change="handleStatusSwitch(row)"></el-switch>
            </div>
          </template>
        </el-table-column>
        <el-table-column prop="state" align="center" label="时">
          <template v-slot="{ row }">
            <el-select v-model="row.hours" size="mini">
              <el-option v-for="i in 24" :value="i - 1" :label="i - 1"></el-option>
            </el-select>
          </template>
        </el-table-column>
        <el-table-column prop="state" align="center" label="分">
          <template v-slot="{ row }">
            <el-select v-model="row.minutes" size="mini">
              <el-option v-for="i in 60" :value="i - 1" :label="i - 1"></el-option>
            </el-select>
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer" class="dialog-footer">
        <el-button :loading="loading" @click="dialogTableVisible = false">取 消</el-button>
        <el-button :loading="loading" type="primary" @click="submit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<style scoped lang="scss">

</style>