import en from '../lang/en'
import zh from '../lang/zh'
import th from '../lang/th'
import id from '../lang/id'
import ru from '../lang/ru'
import vie from '../lang/vie'
import enLocale from 'element-ui/lib/locale/lang/en'
import zhLocale from 'element-ui/lib/locale/lang/zh-CN'
import locale from 'element-ui/lib/locale'
const getSession = name => {
	if(sessionStorage.vue_admin_template_token) {
		if(sessionStorage.vue_admin_template_token!=='undefined' ) {
				

			return JSON.parse(sessionStorage.getItem(name));
		}else  {  
			return null; // Or any other default value  
		}  
	}else if( name=='LanguageName'){
		return JSON.parse(sessionStorage.getItem(name));

	}
};
let language = null

if(getSession('LanguageName') == 'en') {
	language = en
  locale.use(enLocale)
	
}else if(getSession('LanguageName') == 'th') {

	language = th
	locale.use(enLocale)

}else if(getSession('LanguageName') == 'id') {

	language = id
	locale.use(enLocale)

}else if(getSession('LanguageName') == 'ru') {

	language = ru
	locale.use(enLocale)

}else if(getSession('LanguageName') == 'vie') {

	language = vie
	locale.use(enLocale)

}else if(getSession('LanguageName') == 'zh-cn') {

	language = zh
  locale.use(zhLocale)

}else {
	let lang = navigator.language; //浏览器语言判断
  lang = lang.substr(0, 2);
	switch (lang) {
		case 'zh': //中文
		language = zh;
	sessionStorage.setItem('LanguageName',JSON.stringify('zh-cn'))

				break;
		case 'en': //英文
		language = en;
	sessionStorage.setItem('LanguageName',JSON.stringify(lang))

				break;
		case 'id': 
		language = id;
	sessionStorage.setItem('LanguageName',JSON.stringify(lang))

				break;
				case 'ru': 
		language = ru;
	sessionStorage.setItem('LanguageName',JSON.stringify(lang))

				break;
		case 'vie': 
		language = vie;
	sessionStorage.setItem('LanguageName',JSON.stringify(lang))

				break;
		default:
			language = zh;
	sessionStorage.setItem('LanguageName',JSON.stringify('zh-cn'))

			break;
	}
}
export default language