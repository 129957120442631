import axios from 'axios'
import Vue from 'vue';
import { getSession } from "@/utils/methods" 
let baseURL = process.env.VUE_APP_EMAIL_API
let isRefreshing = false,
    requests = []
if(baseURL == '/emailpro') {
    baseURL = window.emailBaseUrl //公司官网环境
    // baseURL = 'http://114.116.11.77:8380' //公司环境
}
Vue.prototype.$emailBaseUrl = baseURL
const server = axios.create({
    baseURL: baseURL,
    timeout: 300000,
    headers: {
        'X-Custom-Header': 'email',
        'language': 'zhHans',
        'token':  getSession('vue_admin_template_token')|| ''
    }
})

server.interceptors.request.use(
    config => {
        config.headers.token = getSession('vue_admin_template_token') || '';
        return config
    },
    err => {
		console.error('error://', err);
        return Promise.reject(err)
    }
)

server.interceptors.response.use(res=>{
    const { code } = res.data
    if(code == 401) {
        const config = res.config
        if(!isRefreshing){
            isRefreshing = true
            localStorage.removeItem('email_token')
            requests = []
        }else{
            return new Promise(resolve=>{
                requests.push(token=>{
                    resolve()
                })
            })
        }
    }
    return res
},
err => { return Promise.reject(err) }
)

export default server
