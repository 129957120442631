import { initLanguage } from "@/api/sysTem";

export default {
// 报表管理
  reportSet:{
    template: "người mẫu",
   username : "nhân viên",
   set: "Phân công",
   empty: "Dữ liệu trống",
   reportName: "Tên thống kê",
   isEnabled: "sử dụng hay không",
},
// 代码事件
codeEvent:{
  eventSet: "vị trí sự cố",
  placeSetName: "Vui lòng nhập tên sự kiện",
  eventName: "tên sự kiện",
  eventId: "Mã sự kiện",
  placeSet: "vui lòng nhập",
  fieldChange: "Thay đổi trường",
  field: "Cánh đồng",
  status: "tình trạng",
  eventContent: "Nội dung sự kiện",
  sqlConfig: "cấu hình sql",
  name: "tên",
  remark: "Nhận xét"

},


  // 新增的确认对话框 一个个改实在是太困难了

    $confirm:{
      message:{
      修改为已读吗: "Sửa đổi như đã đọc?",
      确定撤回该数据吗: "Bạn có chắc chắn muốn rút dữ liệu này không?",
      '是否要变更该合同？': "Bạn có muốn thay đổi hợp đồng này không?",
      '是否要取消变更该合同？': "Bạn có muốn hủy bỏ những thay đổi đối với hợp đồng này không?"
      },
      title:{
        "提示": "gợi ý",
      },
      cancelButtonText:{},
      confirmButtonText:{},

    },

  // 新增的提示弹框 绑定到vue 中文作为建明 修改太困难了
  $message:{
    请选择数据: "Vui lòng chọn dữ liệu",
    请选择人员进行数据转交: "Vui lòng chọn một người để chuyển dữ liệu!",
    部署成功: "Triển khai thành công",
    请先添加计划明细: "Vui lòng thêm chi tiết kế hoạch trước",
    该文件暂不支持预览: "Tệp này chưa hỗ trợ xem trước",
    已取消关闭: "Đã hủy đóng",
    成功清除缓存: "Đã xóa bộ nhớ đệm thành công",
    '模型名称重复，请使用新名称创建模型。': "Tên mẫu máy trùng lặp, vui lòng tạo mẫu máy với tên mới",
    '用户名或密码不能为空！': "Tên người dùng hoặc mật khẩu không được để trống!",
    "提交成功": "gửi thành công",
    "提交失败": "Gửi không thành công",
    "已变更": "đã thay đổi",
    "已取消变更": "Đã hủy thay đổi",
    "待采明细有采购不能重新生成": "Các chi tiết cần mua không thể được tạo lại nếu có giao dịch mua.",
  "生成待采明细成功": "Đã tạo thành công các chi tiết cần khai thác",
  "生成待采明细失败": "Không thể tạo chi tiết để khai thác",
  "此调取未包含目标模块的字段，请切换到正确的页签": "Cuộc gọi này không chứa các trường của mô-đun đích. Vui lòng chuyển sang tab chính xác.",
  "明细中供应商存在多个，请检查！": "Chi tiết có nhiều nhà cung cấp, vui lòng kiểm tra!",
  "认领金额不能大于收款金额，请重新填写！": "Số tiền yêu cầu không thể lớn hơn số tiền thanh toán, vui lòng điền lại!",
  "请选择要赋值的人员": "Hãy chọn người để gán giá trị",
  "请选择模板人员!": "Vui lòng chọn nhân sự mẫu",
  "赋值成功": "Nhiệm vụ thành công",
  "赋值失败": "Bài tập không thành công"
  },
    // 个性化
  individuation:{
    fieldName: "Tên cột dọc",
    visible: "Có hiển thị hay không",
    total: "Có nên cộng lại không",
    locktopic: "Có nên khóa không",
    width: "chiều rộng hiện tại",
    enable: "cho phép",
    disabled: "Vô hiệu hóa"
  },
    formSet: {
      languageSet: "chuyển đổi ngôn ngữ",
      hyperlink: "Siêu liên kết",

      stretch: "kéo dài",
      tile: "Ngói",
      half: "căng một nửa",
      third: "Chiếm một phần ba",
      leftJustify: "căn lề trái",
      rightJustify: "căn lề trái",
      center: "căn giữa",
      equal: "bình đẳng",
      less: "ít hơn",
      lessOrequal: "ít hơn hoặc bằng",
      greater: "nhiều hơn",
      greatorequal: "lớn hơn hoặc bằng",
      like: "全 thích",
      left: "左trái",
      right: "phải trái",
      between: "GIỮA",
      database: "cơ sở dữ liệu",
      expression: "biểu thức tùy chỉnh",
      year: "Năm",
      month: "mặt trăng",
      day: "Ngày",
      hour: "giờ",
      minute: "điểm",
      second: "Thứ hai",
      input: "Hộp nhập liệu",
      DateRange: "Hộp chọn phạm vi ngày",
      dateInput: "Hộp chọn ngày",
      selectOption: "thả cái hộp xuống",
      switch: "công tắc",
      textarea: "trương Văn bản",
      numberInput: "hộp số",
      richText: "văn bản phong phú",
      checkBox: "hộp kiểm thả xuống",
      people: "nhân viên",
      citySelect: "Hộp thả xuống thành phố",
      selectInput: "hộp nhập thả xuống",
      permiAll: "Tất cả các quyền dữ liệu",
       permiCustom: "Quyền dữ liệu tùy chỉnh",
       department: "Quyền dữ liệu của bộ phận này",
       deptBelow: "Quyền dữ liệu cho bộ phận này và những bộ phận sau",
       selfOnly: "Chỉ có quyền dữ liệu cá nhân"
    },
    // 版本管理
    version: {
      record: "nhật ký thay đổi",
      times: "Số lượng thay đổi",
      updateDate: "Thay đổi ngày",
      updatePerson: "Thay đổi người",
      mainTableData: "Dữ liệu thay đổi bảng chính",
      detailsOldData: "Dữ liệu gốc chi tiết",
      detailsData: "Dữ liệu thay đổi chi tiết",
      oldData: "dữ liệu thô",
      newData: "Dữ liệu sau khi thay đổi"
    },

    // 补充的提示语
    addTips: {
      approve: "Đang xem xét",
      reject: "từ chối",
      pass: "vượt qua",
      noUse: "Mô-đun chưa được bật",
      hasPigeonhole: "Đã lưu trữ",
      noPigeonhole: "Chưa được lưu trữ",
      all: "tất cả",
      cancel: "Hủy bỏ",
      confirm: "xác nhận",
      noSupportpreview: "Tệp này chưa hỗ trợ xem trước",
      fileServiceIsNotDeployed: "Dịch vụ xem trước tệp không được triển khai",
      isAssign: "Có nên chỉ định",
      programmeNameRequire: "Vui lòng điền tên kế hoạch",
      programmeCodeRequire: "Vui lòng điền số kế hoạch",
      addPerson: "Hãy chọn một người",
      number: "KHÔNG.",
      groupEmpty: "Tên nhóm không được để trống",
      approveEmpty: "Vui lòng thêm nút phê duyệt",
      nodeEmpty: "Dữ liệu nút không được để trống",
      tempNameRequire: "Vui lòng nhập tên mẫu",
      tableNameRequire: "Vui lòng nhập tên bảng",
      refreshDatabase: "Vui lòng đợi, thư viện đang được làm mới",
      nowusebbs: "Tên bảng phụ không thể lặp lại",
      tableNameNorepeat: "Tên bảng không được lặp lại",
      moduleNameNorepeat: "Tên mẫu không thể lặp lại",
      done: "Hoạt động thành công",
      doneFailed: "lỗi hệ thống",
      successZhengshi: "Chuyển đổi thành công thành khách hàng chính thức",
      selectZhengshi: "Vui lòng chọn khách hàng cần chuyển đổi sang chính thức",
      successGonghai: "Chuyển đổi thành công thành khách hàng vùng biển xa",
      selectGonghai: "Hãy lựa chọn khách hàng có nhu cầu chuyển đi biển khơi",
      selectGiveData: "Vui lòng chọn dữ liệu cần chuyển",
      selectClassIs: "Những gì bạn đã chọn là",
      ifContinue: "Xác nhận để tiếp tục?",
      addTopClass: "Vui lòng chọn danh mục chính trước",
      cantclaimed: "Không thể yêu cầu hóa đơn tiền nước hiện tại",
      treeBelow: "Vui lòng chọn cấp độ thấp nhất của cấu trúc cây để tiếp tục",
      goodCanNotDelete: "Nút này cũng có sản phẩm và không thể xóa được.",
      orderNo: "Đơn đặt hàng không tồn tại",
      addoccupy: "Hãy chọn chi tiết cần chiếm",
      onlyOneoccupy: "Chỉ có thể chọn một bản ghi",
      occupyNum: "Vui lòng nhập số lượng sử dụng",
      warehouseLocation: "Khu vực lưu trữ",
      productName: "tên sản phẩm",
      currentInventory: "Hàng tồn kho hiện tại",
      purchaseQuantity: "Số lượng mua",
      occupationQuantity: "Số lượng chiếm dụng",

      onlyOneTop: "Chỉ có thể chọn một bảng chính cấp cao nhất",
      comfirmDelet: "Bạn có chắc chắn muốn xóa nó?",
      selectRow: "Vui lòng chọn các hàng bạn muốn di chuyển",
      onlyOneRowMove: "Chỉ có thể chọn một hàng để di chuyển",
      noEffectRow: "Số dòng đích không hợp lệ, vui lòng nhập lại.",
      noMaxLine: "Không thể vượt quá số dòng tối đa",
      fetchNoMove: "Dữ liệu chi tiết đã được gọi và không thể xóa được",
      checkError: "Ngoại lệ chi tiết xác minh",
      nodeNoGood: "Hiện tại không có sản phẩm nào tại nút này",
      languageNoRepeat: "Ngôn ngữ đã được thêm vào, vui lòng không thêm lại!",
      printMessage: "Không thể thực hiện xem trước và in trong trình duyệt IE, vui lòng sử dụng các trình duyệt khác để mở.",
      tableNoData: "Không tìm thấy cài đặt quy trình của mô-đun này, vui lòng đặt chúng và thử lại!",
      subassembly: "Phụ kiện phụ:"
    },
    //登录
    loginPage: {
      login: "Đăng nhập",
      welCome: "Vui lòng đăng nhập",
      password: "mật khẩu",
      account: "tài khoản"
    },
    GlobalConfig: {
      duplicationCheck: "cài đặt",
      CustomerSwitch: "chuyển đổi khách hàng",
      SupplierSwitch: "chuyển đổi nhà cung cấp",
      GoodsSwitch: "Chuyển đổi sản phẩm",
      FeedbackConfiguration: "cấu hình cơ bản",
      projectName: "Tên dự án",
      projectContact: "Số hợp đồng dự án",
      version: "số phiên bản",
      systemCallbackAddress: "Địa chỉ gọi lại hệ thống",
      wincareAddress: "địa chỉ wincare",
      projectNamePlace: "Vui lòng điền tên dự án",
      projectContactPlace: "Vui lòng điền số hợp đồng dự án",
      wincareAddressPlace: "Vui lòng điền địa chỉ gọi lại của hệ thống"
    },
    // 消息中心
    messageCenter: {
      addNewMessage: "Thêm tin nhắn mới",
      editNewMessage: "Chỉnh sửa tin nhắn",
      messageTitle: "Tiêu đề tin nhắn",
      modelName: "tên mô-đun",
      messageContent: "Nội dung văn bản",
      remark: "Nhận xét",
      receiveBy: "Người nhận",
      sendBy: "nhà tài trợ",
      createTime: "thời gian sáng tạo",
      isRead: "Nó đã được đọc chưa?",

    },
    placeHolder: {
      expression: "Biểu thức công thức tính toán",

      selectLanguage: "Vui lòng chọn ngôn ngữ",
      forTrackPlaceHolder: "Vui lòng nhập mã đơn hàng",
      start: "tăng lên",
      end: "kết thúc",
      to: "ĐẾN",
      startDate: "ngày bắt đầu",
      endDate: "ngày cuối",
      selectDate: "lựa chọn ngày",
      startTime: "Thời gian bắt đầu",
      endTime: "Thời gian kết thúc",
      pleaseFill: "vui lòng nhập",
      pleaseSelect: "xin vui lòng chọn",
      Financialrecognition: "Vui lòng chọn xác nhận tài chính",
      onWeek: "tuần trước",
      onMonth: "tháng trước",
      threeMonth: "ba tháng qua",
      contractNumber: "Số hợp đồng xuất khẩu",
    },
    // 导航栏上方的操作栏
    user: {
      quit: "đăng xuất",
      noticeCenter: "Trung tâm tin nhắn",
      preWarning: "cảnh báo sớm",
      statistical: "Báo cáo thống kê",
      noticeAlert: "Thông báo tin nhắn",
      searchMenu: "Trình đơn tin nhắn"
    },
    // 首页设置
    Home: {
      ToBeapproved: "Tài liệu đang chờ phê duyệt",
      realTimeData: "Dữ liệu theo thời gian thực", // 实时数据
      recents: "lượt truy cập gần đây", // 最近访问

    },
    // 列表设置
    listOfSet: {
      batchExport: "Xuất hàng loạt",
      ponoFieldExist: "Trường PONO không tồn tại",
      attachmentManagement: "Quản lý tệp đính kèm nhập khẩu",
      enableOcr: "Nhận dạng OCR",
      add: "Mới",
      clearCatch: "xóa bộ nhớ đệm",
      loadField: "Tải trường",
      copyModule: "mô-đun sao chép",
      search: "tìm kiếm",
      reset: "cài lại",
      edit: "biên tập",
      delete: "xóa bỏ",
      colWidth: "chiều rộng cột",
      moduleName: "tên mô-đun",
      mainTable: "Bảng chính",
      foreignKey: "khóa ngoại",
      listSql: "danh sách sql",
      isImage: "hình ảnh",
      imageColumn: "Hình ảnh ở cột nào?",
      storedProcedure: "thủ tục lưu trữ biểu mẫu",
      isReadOnly: "Chỉ xem",
      isInlineEditable: "Chỉnh sửa nội tuyến",
      isUpdateKeyOnImport: "Nhập bản cập nhật",
      isCheckFno: "Xác minh rằng fno là duy nhất",
      parentModule: "Mô-đun cao cấp",
      isPage: "Phân trang",
      contactSql: "liên hệ với sql",
      listStoredProcedure: "liệt kê thủ tục lưu trữ",
      checkStoredProcedure: "Xác minh thủ tục lưu trữ",
      isShowList: "Hiển thị danh sách",
      tableImgShow: "Hiển thị hình ảnh bảng",
      formImgShow: "Hiển thị hình ảnh biểu mẫu",
      isImport: "nhập khẩu",
      isExport: "Xuất khẩu",
      isSendEmail: "gửi email",
      isUploadFile: "Tải Tệp đính kèm",
      isBatchModify: "Chỉnh sửa hàng loạt",
      isAboutTypography: "Sắp chữ trái và phải",
      isGlobalRetrieval: "tìm kiếm toàn cầu",
      tableFieldSet: "Cài đặt trường bảng",
      formFieldSet: "Cài đặt trường biểu mẫu",
      codeEventSet: "Cài đặt sự kiện mã",
      languageSet: "cài đặt ngôn ngữ",
      sheetSet: "cài đặt trang trang tính",
      isDbClkJump: "Nhấp đúp chuột để nhập chi tiết",
      isShowSubtotal: "Tổng phụ được nhóm",
      showTheApprovalTab: "Hiển thị tab phê duyệt",
      passProcName: "thông qua thủ tục lưu trữ",
      rejectProcName: "Từ chối thủ tục lưu trữ",
      pageSize: "Số lượng hiển thị mặc định trên mỗi trang",
      addSuccess: "Thêm thành công",
      editSuccss: "hoàn thành cập nhật",

      /* 字段设置 */
      fieldShowOrder: "Thứ tự hiển thị trường",
      databaseFieldName: "Tên trường cơ sở dữ liệu",
      showName: "Tên hiển thị giao diện người dùng",
      dataType: "loại dữ liệu",
      alignType: "Căn chỉnh",
      queryOperator: "toán tử truy vấn",
      isSelect: "Hỏi thăm",
      isLocked: "khóa",
      isGlobalQuery: "tìm kiếm toàn cầu",
      isSum: "tổng cộng",
      isShow: "dễ thấy",
      dataDict: "Từ điển dữ liệu",
      datePrecision: "ngày chính xác",
      language: "cài đặt ngôn ngữ",
      databaseFields: "Tên trường cơ sở dữ liệu",
      configFormula: "Trình chỉnh sửa phương trình",
      initialization: "Mô-đun khởi tạo",
      initLanguage: "ngôn ngữ khởi tạo",
      //编码设置
      save: "giữ",
      year: "Năm (2 chữ số)",
      years: "Năm (4 chữ số)",
      month: "tháng",
      date: "ngày",
      fixedContent1: "Nội dung cố định 1",
      fixedContent2: "Nội dung cố định 2",
      serialNumber: "số seri",
      loginId: "ID người đăng nhập",
      serialNoDigit: "Các chữ số nối tiếp",
      serialNoUt: "Đơn vị số lẻ",
      pattern: "Định dạng",
      formField1: "Trường biểu mẫu 1",
      formField2: "Trường biểu mẫu 2",
      formField3: "Trường biểu mẫu 3",
      formField4: "Trường biểu mẫu 4",
      formField5: "Trường biểu mẫu 5",
      smallSerialStart: "Số bắt đầu của nước chảy nhỏ",
      bigSerial: "Đại Lục Hạo",
      resetSuccess: "Cài lại",
      soon: "Chờ đợi",
      strip: "dải",

    },

    FormOfSet: {
      uploadDrag: "Kéo tệp vào đây hoặc",
      clickUpload: "Bấm để tải lên",
      uploadSize: "Kích thước tập tin không vượt quá",

      add: "Mới",
      fieldOrder: "Thứ tự hiển thị trường",
      name: "tên",
      showName: "tên hiển thị",
      defaultValue: "Thêm giá trị mặc định",
      expressionDecimalDigit: "chữ số dành riêng",
      dataType: "loại dữ liệu",
      editType: "Chỉnh sửa loại",
      formConfigId: "ID cấu hình biểu mẫu",
      isMustFill: "Yêu cầu",
      approveParam: "Gửi trường",
      approveChange: "Các trường thay đổi phê duyệt",
      combinedField: "Tổng trường",
      majorKey: "khóa chính",
      isShow: "dễ thấy",
      isReadOnly: "chỉ đọc",
      dataDict: "Từ điển dữ liệu",
      remark: "Nhận xét",
      sheet: "Trang thuộc về",
      expression: "biểu thức tùy chỉnh",
      clauseTemplete: "Mẫu điều khoản",
      widthType: "loại chiều rộng",
      matchingCondition: "Vui lòng chọn tiêu chí phù hợp",
      noFileSelected: "Không có tệp nào được chọn"
    },

    // 配置模块
    configMoudle: {
      filesManage: "Quản lý tệp đính kèm",
      tip: "gợi ý",
      ocr: "Nhận dạng OCR",
      tableExportName: "Dữ liệu dạng bảng",
      timedifference: "Thời gian khác biệt",
      nodeFinish: "Trường thời gian hoàn thành nút",

      nodeFlow: "Quá trình nút",
      waterKey: "xiaoliushuikey",
      topLeval: "Đứng đầu",

      operator: "toán tử lựa chọn",
      function: "Chọn chức năng",
      selectfieldName: "Chọn trường",

      clear: "Thông thoáng",
      fieldType: "Loại lĩnh vực",
      resultField: "trường kết quả",

      formulaEditor: "Trình chỉnh sửa phương trình",
      fieldDigit: "chữ số dành riêng",

      fieldLength: "độ dài trường",
      characteristic: "Thuộc tính trường",
      dbname: "Tên cơ sở dữ liệu",
      fieldName: "Tên trường",
      catalogue: "Mục lục",
      menu: "thực đơn",
      button: "cái nút",

      male: "nam giới",
      female: "nữ giới",
      normal: "Bình thường",
      diabled: "vô hiệu hóa",
      signOrNot: "Bạn sẽ ký chứ?",
      tableName: "Tên bảng",
      bulkEdit: "Chỉnh sửa hàng loạt",
      portDetails: "Chi tiết tài liệu",
      totalAll: "tổng cộng",
      noData: "Không có dữ liệu",
      multipleChoice: "Lựa chọn duy nhất",
      saveFirst: "Vui lòng lưu trước",
      uploadTypeTip: "Hình ảnh tải lên chỉ có thể ở định dạng JPG hoặc PNG!",

      uploadSuccess: "Tải lên thành công",
      uploadFail: "Tải lên thất bại",
      ForTrackRemark: "Lưu ý: Cần phải có mô-đun và số thứ tự chứa nó. Vui lòng nhấp vào Truy vấn sau khi hoàn thành việc nhập.",
      searchForTrack: "Truy vấn số theo dõi",

      addFile: "Thêm bản đính kèm",
      updateFile: "Thay đổi tệp đính kèm",

      moveLineNum: "Số dòng mục tiêu",
      destLineNum: "Số dòng đích",
      selctRowTip: "Vui lòng chọn hàng để chỉnh sửa",
      selctRowTipDelet: "Vui lòng chọn các hàng bạn muốn xóa",

      getTemplate: "Nhận mẫu",
      details: "Chi tiết",
      forModel: "Mô-đun thuộc về",
      forTrack: "Số thứ tự thuộc về",
      filesManage: "Quản lý tệp đính kèm",
      serialNumber: "số seri",

      filesLoading: "Đang tải tệp đính kèm",
      noConfigTotal: "Bạn chưa định cấu hình tổng phụ",
      noGroup: "Vui lòng chọn tiêu chí phân nhóm",
      noTotalField: "Vui lòng chọn một trường tổng phụ",
      Financialrecognition: "xác nhận tài chính",
      FinancialCancle: "Hủy xác nhận tài chính",

      noRepeat: "không thể lặp lại",
      confirmRecall: "Có nên nhớ lại dữ liệu hiện tại hay không",
      recallSucces: "Rút tiền thành công",
      CategoryNum: "Số danh mục",
      shareSuccess: "Chia sẻ thành công",
      imageUpload: "Tải lên nhiều hình ảnh",
      selectFillter: "Nhập từ khóa để lọc",
      inventoryNumber: "Số thứ tự thả hàng",
      selectYes: "Đúng",
      selectNo: "KHÔNG",
      confirmDelete: "Xóa dữ liệu hiện tại?",
      transferOrder: "lệnh chuyển nhượng",
      highSeasCustomers: "Khách hàng biển khơi",
      customerMemorabilia: "Sự kiện khách hàng",
      formalCustomers: "Khách hàng chính thức",
      sendEmail: "gửi email",
      excelCopy: "sao chép Excel",
      claim: "khẳng định",
      add: "Mới",
      copyTokey: "Sao chép vào bảng",
      copyToform: "sao chép vào biểu mẫu",
      edit: "biên tập",
      delete: "xóa bỏ",
      bulkEdit: "Chỉnh sửa hàng loạt",
      content: "Vui lòng nhập nội dung",
      selectDate: "Chọn ngày",
      select: "xin vui lòng chọn",
      commodityTypeAdmin: "Quản lý danh mục sản phẩm",
      parentType: "Gia phả",
      TypeName: "tên phân loại",
      clickUpload: "Bấm để tải lên",
      fileView: "Xem trước tệp",
      Viewflie: "Xem trước",
      save: "giữ",
      printFormat: "Định dạng in",
      headSeal: "tem tiêu đề thư",
      cancel: "Hủy bỏ",
      print: "In",
      more: "Hơn",
      share: "đã chia sẻ",
      placeOnFile: "Lưu trữ",
      personnel: "Lựa chọn nhân sự",
      personName: "nhân viên",
      classChange: "Thay đổi danh mục",
      uploadPicture: "tải hình ảnh lên",
      billsPrint: "In tài liệu",
      uploadPictures: "Tải lên hàng loạt hình ảnh",

      globalQuery: "tìm kiếm toàn cầu",
      modelName: "Tên mẫu",
      search: "Tìm thấy",
      img: "hình ảnh",
      tip1: "Vui lòng nhập tên danh mục",
      tip2: "Đã sửa đổi thành công",
      tip3: "Thêm thành công",
      tip4: "Vui lòng chọn một",
      tip5: "Bạn có chắc chắn muốn xóa danh mục hiện tại và các danh mục phụ không?",
      tip: "gợi ý",
      confirm: "Chắc chắn",
      cancel: "Hủy bỏ",
      tip6: "đã xóa thành công",
      refresh: "làm cho khỏe lại",
      close: "Khép kín",
      closeLeft: "đóng bên trái",
      closeRight: "đóng bên phải",
      closeOther: "Đóng khác",
      closeAll: "Đóng tất cả",


      view: "Kiểm tra",
      versionView: "Chế độ xem phiên bản",
      tip7: "Chỉ có thể tải lên định dạng Excel",
      tip8: "Bạn không phải là người tạo ra tài liệu này",
      tip9: "Tài liệu đang được xem xét hoặc đã được phê duyệt",
      tip10: "Bạn không có hàng nào được chọn",
      tip11: "Vui lòng không kiểm tra các hàng đã được phê duyệt hoặc đang trong quá trình phê duyệt.",
      tip12: "Vui lòng chọn một hàng trước khi sửa đổi nó",
      tip13: "Vui lòng chọn dòng trước khi in",
      tip14: "Vui lòng chọn định dạng đầu ra báo cáo!",
      tip15: "Vui lòng chọn tên mô-đun!",
      tip16: "In thành công",
      tip17: "tải về thành công",

      approvalHistory: "Lịch sử phê duyệt",
      purchaseContract: "Số hợp đồng mua bán",
      supplierName: "Tên đệm",
      fileName: "tên phụ kiện",
      filePath: "Đường dẫn đính kèm",
      fileType: "Loại tệp đính kèm",

      compilingAndbrushingLibraries: "Biên dịch và chải thư viện",
      moduleName: "tên mô-đun",
      mainTable: "Tên bảng chính",
      subTableName: "Tên bảng phụ",
      subTable: "Tên bảng phụ",
      aboutMainTable: "Bảng chính tương ứng",
      createBy: "người sáng lập",


      // 详情
      fetch: "Truy xuất",
      approval: "Sự chấp thuận",
      reject: "Phê duyệt bị từ chối",
      approvalOption: "Ý kiến phê duyệt",
      sendEmail: "gửi email",
      Examination: "Sự chấp thuận",
      ExaminationSave: "Phê duyệt và lưu",

      AnnextopurchaseContract: "Đính kèm hợp đồng mua bán",
      Appendixtoexportcontract: "Xuất đính kèm hợp đồng mua bán",
      uploadFile: "Tải Tệp đính kèm",
      report: "Báo cáo",
      includeFetchSql: "Chứa đã truy xuất",
      includeSqlFinish: "Chứa dữ liệu được truy xuất",
      viewLargeImg: "Xem hình ảnh lớn hơn",
      save: "giữ",
      status: "tình trạng",
      copy: "sao chép",
      topcopy: "Đi lên",
      bottomcopy: "đi xuống",
      moveRow: "Di chuyển số ngân hàng",
      update: "giữ",
      submit: "nộp",
      recall: "rút",
      list: "danh sách",
      copyInvoice: "Sao chép tài liệu",
      // tip17: "Hãy chọn nội dung và nhấn OK",
      tip18: "Trang đã được làm mới, vui lòng vào lại trang này và thực hiện lại thao tác này!",
      subTotalSet: "Cấu hình tổng phụ",
      openSubtotal: "Bật tổng phụ",
      closeSubtotal: "Đóng tổng phụ",
      groupCondition: "Điều kiện nhóm",
      subtotalField: "Trường tổng phụ",
      notSelected: "Không được chọn",
      selected: "đã chọn",
      goLeft: "Qua bên trái",
      goRight: "rẽ phải",
      noFno: "Không tìm thấy số hợp đồng này và không thể chuyển hướng",

      // Y-common
      linenum: "Số dòng",
      batchDelete: "xóa hàng loạt",
      replaceLineNumber: "Số dòng đích",
      tip19: "Số dòng bạn đã nhập là bất hợp pháp",
      tip20: "Đang trong quá trình phê duyệt...",
      tip21: "Vui lòng chọn dữ liệu bạn muốn xóa",
      tip22: "Chỉ có thể chọn một dòng để ngắt dòng",
      tip23: "Vui lòng chọn dữ liệu bạn muốn chia sẻ",
      tip24: "Vui lòng chọn dữ liệu để lưu trữ hoặc hủy lưu trữ",
      tip25: "Vui lòng chọn người để chia sẻ",
      tip26: "Có nên hủy bản lưu trữ hay không",
      tip27: "Hủy lưu trữ thành công",
      tip28: "Đã lưu trữ thành công",
      tipQuit: "Bạn có chắc bạn muốn thoát?",
      sureToSave: "Nếu bạn đã lưu dữ liệu, hãy nhấp vào OK!",
      cancelFilter: "Hủy bộ lọc",

      SalesContract: "Hợp đồng mua bán xuất khẩu",
      PurchaseContract: "hợp đồng mua hàng",
      alteration: "thay đổi",
      cancelAlteration: "Hủy thay đổi",

    },


    // 列表设置-----语言设置
    LanguageSet: {
      contentRequire: "Ngôn ngữ không thể trống!",
      contentRequire: "Nội dung không thể trống",
      add: "Mới",
      edit: "Ôn lại",
      language: "ngôn ngữ",
      content: "nội dung",
      operation: "vận hành",
      simplifiedChinese: "Tiếng Trung giản thể",
      confirm: "Chắc chắn",
      cancel: "Hủy bỏ"
    },

    //列表设置-- sheet页设置
    SheetSetting: {
      name: "tên",
      AccordingToTheOrder: "Hiển thị sắp xếp",
      add: "Mới"
    },

    // 调取设置
    FetchSet: {
      sequence: "đặt hàng",
      add: "Mới",
      name: "tên",
      targetModuleId: "ID mô-đun mục tiêu",
      parentModuleId: "ID mô-đun cao cấp",
      targetField: "Trường mục tiêu",
      colWidth: "Chiều rộng cột trường",
      isPaged: "Phân trang",
      fetchSql: "tuyên bố cuộc gọi",
      isShowImage: "hình ảnh hiển thị",
      showColumns: "Nó được hiển thị ở cột nào?",
      isRetrieval: "cho phép",
      containsCalledSQL: "Chứa Sql đã tải",
      edit: "biên tập",
      delete: "xóa bỏ",
      languageSetting: "cài đặt ngôn ngữ",
      tableFiledSetting: "Cài đặt trường bảng",
      copySetting: "sao chép",
      fetchWarinning: "Vui lòng điền vào báo cáo cuộc gọi!",
      cancel: "Hủy bỏ",
      confirm: "Chắc chắn"
    },

    // 调取设置 ---- 调取字段设置
    FetchKeySet: {
      add: "Mới",
      fieldShowOrder: "Thứ tự hiển thị trường",
      databaseFieldName: "Tên trường cơ sở dữ liệu",
      showName: "Tên hiển thị giao diện người dùng",
      dataType: "loại dữ liệu",
      alignType: "Căn chỉnh",
      isSelect: "Hỏi thăm",
      queryOperator: "toán tử truy vấn",
      isSortable: "loại",
      isFetched: "Truy xuất",
      isLocked: "khóa",
      targetField: "Trường mục tiêu",
      isShow: "dễ thấy",
      datePrecision: "ngày chính xác",
    },

    // 首页设置
    homeSet: {
      homeSet: "Cài đặt trang chủ",
      roleNamePlaceHolder: "Vui lòng nhập tên vai trò",
      warningSet: "Cài đặt cảnh báo",
      reportSet: "Cài đặt báo cáo",
      earlyWarning: "cảnh báo sớm",
      title: "tiêu đề",
      router: "lộ trình",
      sql: "sql",
      languageSetting: "cài đặt ngôn ngữ",
      save: "giữ",
      select: "xin vui lòng chọn",
      inputPlaceHolder: "Vui lòng nhập nội dung",
      report: "Báo cáo",
      save: "giữ",
      add: "Mới",
      edit: "biên tập",
      submitTip: "Dữ liệu gửi không được để trống",
      saveTip: "đã lưu thành công",
      completeTip: "Vui lòng hoàn thành dữ liệu và lưu nó trước khi cài đặt ngôn ngữ",
      saveFailed: "Lưu không thành công",
      qrSetting: "cài đặt mã QR",
      qrUpload: "Tải lên mã QR APP",
      qrEdit: "Chỉnh sửa thông tin mã QR",
      qrPlaceholder: "Vui lòng nhập thông tin bạn muốn chỉnh sửa",
      qrTips: "Vui lòng tải ảnh lên trước",
      basicInfor: "thông tin cơ bản",
      fontConfig: "Cài đặt phông chữ",
      passWordConfig: "đổi mật khẩu",
      languageConfig: "cài đặt ngôn ngữ",
      fontSize: "Cài đặt kích thước trang phông chữ",
      fontFamily: "Cài đặt loại phông chữ trang",
      oldPassword: "Mật khẩu cũ",
      newPassword: "mật khẩu mới",
      languageSet: "cài đặt ngôn ngữ",
      defaultFont: "mặc định",
      smallFont: "Kích thước trung bình",
      largeFont: "lớn",
      defultFont: "mặc định",
      song: "Nhà Tống",
      kai: "kịch bản thông thường",
      yuan: "thân tròn",
      shijian: "Thơ giản thể",
      logoSetting: "Tải lên hình ảnh logo",
      companySetting: "tên",
      loginBackgroundSetting: "Hình nền đăng nhập",
    },

    // 报表权限设置
    ReportSet: {
      jimuReportPermissionSetting: "Cài đặt quyền báo cáo khối xây dựng",
      roleNamePlaceHolder: "Vui lòng nhập tên vai trò",
      save: "giữ",
      code: "số seri",
      reportName: "Tên báo cáo",
      createTime: "thời gian sáng tạo",
      warningTip: "Vui lòng chọn ít nhất một",
      saveTip: "đã lưu thành công",
      add: "Mới",
      edit: "biên tập",
    },

    // 字典设置
    DictSet: {
      searchButton: "tìm kiếm",
      resetButton: "cài lại",
      add: "Mới",
      edit: "biên tập",
      delete: "xóa bỏ",
      dictName: "Tên từ điển",
      isForbidden: "Vô hiệu hóa",
      enabled: "Bình thường",
      disAbled: "Vô hiệu hóa",
      remark: "Nhận xét",
      dataItemSetting: "Cài đặt mục dữ liệu",
      name: "tên",
      alias: "Bí danh",
      sortOrder: "Đặt hàng",
      desc: "mô tả",
      dataPermission: "quyền dữ liệu",
    },

    // 菜单管理
    menuSet: {
      shangpinku: "Truy xuất thư viện sản phẩm",
      kucun: "Truy xuất hàng tồn kho",
      yushou: "Phân bổ số tiền thu trước",
      permisVies: "Xem quyền",
      menuName: "Tên thực đơn",
      search: "tìm kiếm",
      reset: "cài lại",
      add: "Mới",
      edit: "biên tập",
      delete: "xóa bỏ",
      icon: "biểu tượng",
      type: "kiểu",
      orderNum: "thứ tự hiển thị",
      permsCode: "ID quyền",
      remark: "Nhận xét",
      routingPath: "địa chỉ định tuyến",
      menuStatus: "Trạng thái thực đơn",
      addMenu: "Thực đơn mới",
      menuType: "Loại thực đơn",
      menuIcon: "Biểu tượng menu (đơn sắc)",
      menuIconColor: "Biểu tượng menu nhiều màu",
      menuVisable: "Menu có thể nhìn thấy",
      menuOrder: "Thực đơn gọi món",
      parentCatalogue: "Thư mục gốc",
      associatedModule: "Các mô-đun liên kết",
      buttonName: "Tên nút",
      language: "cài đặt ngôn ngữ",
      action: "vận hành",
      associateModule: "Các mô-đun liên kết"
    },

    // 用户管理
    userAdmin: {
      deptPlace: "Vui lòng nhập tên bộ phận",
      userName: "tên tài khoản",
      password: "mật khẩu",
      loginAccount: "Đăng nhập tài khoản",
      phoneNumber: "số điện thoại",
      status: "tình trạng",
      createTime: "thời gian sáng tạo",
      search: "tìm kiếm",
      reset: "cài lại",
      add: "Mới",
      passOn: "chuyển khoản",
      passOnSuccess: "Chuyển thành công",
      passOnPerson: "Người mục tiêu chuyển lệnh",
      passOnMoudle: "Mô-đun chuyển lệnh",

      deptName: "Tên bộ phận",
      nickName: "Biệt danh của người dùng",
      englishName: "tên tiêng Anh",
      phone: "số điện thoại",
      email: "Email người dùng",
      roleName: "Vai trò",
      sex: "giới tính",
      postId: "Id vị trí",
      avatar: "hình đại diện",
      deptId: "Id khoa",
      passoword: "mật khẩu",
      remark: "Nhận xét",
      dept: "phòng",
      edit: "biên tập",
      delete: "xóa bỏ",
      tip1: "Vui lòng chọn một để chỉnh sửa",
      loadingtip: "Tải vui lòng đợi...",
      tip2: "Vui lòng chọn một để xóa",
      tip3: "Xác nhận xóa",
      tip: "gợi ý",
      confirm: "Chắc chắn",
      cancel: "Hủy bỏ",
      deleteSuccess: "đã xóa thành công!",
      editSuccess: "Đã sửa đổi thành công",
      noEmpty: "Không thể trống",
      limitOfAuthority: "Quyền",
      synchronousAssignmentGroup: "Nhóm phân công đồng bộ",
    },
    // 用户管理 --- 编辑用户
    editUserAdmin: {
      save: "giữ",
      sync: "Làm cho đồng bộ",
      add: "Mới",
      userInfo: "thông tin người dùng",
      nickName: "Biệt danh của người dùng",
      englishName: "tên tiêng Anh",
      password: "mật khẩu",
      phoneNumber: "số điện thoại",
      remark: "Nhận xét",
      loginAccount: "Đăng nhập tài khoản",
      userEmail: "Email người dùng",
      postId: "Id vị trí",
      dept: "phòng",
      roleName: "Vai trò",
      superiorLeaders: "Bề Trên",
      sex: "giới tính",
      status: "tình trạng",
      emailConfig: "Cấu hình email",
      dataPermission: "quyền dữ liệu",
      modelName: "tên mô-đun",
      groupName: "Tên nhóm",
      groupCode: "mã nhóm",
      edit: "biên tập",
      delete: "xóa bỏ",
      loadingtip: "Tải vui lòng đợi...",
      saveSuccess: "đã lưu thành công",
      emailUserName: "Tên người gửi",
      emailPassword: "email Mật khẩu",
      smtpHost: "Địa chỉ SMTP",
      smtpPort: "Cổng SMTP",
      signature: "dấu hiệu",
      ownPhone: "Số di động riêng",
      qq: "QQ",
      weChat: "WeChat",
      platformName: "Tên ga tàu",
      idcard: "số ID",
      isEmailManage: "Đây có phải là quản trị viên hộp thư không?",
      noPicture: "Chưa có hình ảnh nào"

    },



    // 在线用户
    userOnline: {
      userName: "tên tài khoản",
      name: "Tên tiếng Trung",
      search: "tìm kiếm",
      reset: "cài lại",
      kickOut: "đuổi ra ngoài",
      email: "Thư",
      phone: "Điện thoại",
      sex: "giới tính",
      loginConputerIp: "Đăng nhập IP máy tính",
      loginTime: "Thời gian đăng nhập",
      nowTime: "Thời lượng đăng nhập",
      status: "tình trạng",
      remark: "Nhận xét",
      tip1: "Bạn có chắc chắn muốn đuổi người dùng này ra ngoài không?",
      tip: "gợi ý",
      confirm: "Chắc chắn",
      cancel: "Hủy bỏ",
      tip2: "Vui lòng chọn trước khi đá!",
      tip3: "Người dùng bị đuổi ra"
    },


    // 角色管理
    roleAdmin: {
      addRole: "Tăng quyền",
      cancleRole: "Hủy quyền",
      tip1: "Bạn có chắc chắn muốn xóa vai trò đã chọn không?",
      menuAllot: "Phân công thực đơn",
      roleName: "Tên vai trò",
      status: "tình trạng",
      search: "tìm kiếm",
      reset: "cài lại",
      add: "Mới",
      save: "giữ",
      showSort: "thứ tự hiển thị",
      roleStatus: "trạng thái nhân vật",
      permissionChar: "ký tự cho phép",
      permissionScope: "Phạm vi thẩm quyền",
      dataPermission: "quyền dữ liệu",
      dataScope: "phạm vi dữ liệu",
      remark: "Nhận xét",
      edit: "biên tập",
      delete: "xóa bỏ",
      tip2: "Không thể trống",
      tip3: "Vui lòng chọn trước khi xóa",
      tip4: "Vui lòng chọn menu bạn muốn sửa đổi bài tập",
      tip5: "đã lưu thành công"
    },

    // 部门管理
    deptAdmin: {
      deptName: "Tên bộ phận",
      status: "tình trạng",
      search: "tìm kiếm",
      reset: "cài lại",
      add: "Mới",
      deptCode: "Mã khoa",
      departmentHead: "trưởng khoa",
      contactWay: "Thông tin liên lạc",
      showSort: "thứ tự hiển thị",
      createTime: "thời gian sáng tạo",
      addDept: "Thêm bộ phận mới",
      superiorDepartment: "Văn phòng cao hơn",
      edit: "biên tập",
      delete: "xóa bỏ",
      tip1: "Bạn có chắc chắn xóa khoa đã chọn không?",
      tip: "gợi ý",
      confirm: "Chắc chắn",
      cancel: "Hủy bỏ",
      tip2: "Vui lòng chọn trước khi xóa!",
      tip3: "đã xóa thành công"
    },

    // 部门管理
    deptAdmin: {
      deptName: "Tên bộ phận",
      status: "tình trạng",
      search: "tìm kiếm",
      reset: "cài lại",
      remark: "Nhận xét",
      add: "Mới",
      deptCode: "Mã khoa",
      departmentHead: "trưởng khoa",
      contactWay: "Thông tin liên hệ (số điện thoại di động)",
      showSort: "thứ tự hiển thị",
      createTime: "thời gian sáng tạo",
      addDept: "Thêm bộ phận mới",
      superiorDepartment: "Văn phòng cao hơn",
      edit: "biên tập",
      delete: "xóa bỏ",
      tip1: "Bạn có chắc chắn xóa khoa đã chọn không?",
      tip: "gợi ý",
      confirm: "Chắc chắn",
      cancel: "Hủy bỏ",
      tip2: "Vui lòng chọn trước khi xóa!",
      tip3: "đã xóa thành công"
    },

    //打印管理
    printAdmin: {
      add: "Mới",
      modelNumber: "Số sê-ri mô-đun",
      modelCode: "mã mô-đun",
      modelId: "Id mô-đun",
      mainTableName: "Tên bảng chính",
      modelName: "tên mô-đun",
      reportName: "Tên báo cáo",
      idField: "trường id",
      remark: "Nhận xét",
      edit: "biên tập",
      delete: "xóa bỏ",
      tip1: "Bạn có chắc chắn xóa mô-đun đã chọn không?",
      tip: "gợi ý",
      confirm: "Chắc chắn",
      cancel: "Hủy bỏ",
      tip2: "Vui lòng chọn trước khi xóa",
      tip3: "đã xóa thành công",
      classify: "Phân loại",
      printer: "máy in",
      closeConfirm: "Bạn đã lưu trước khi đóng chưa?"
    },
    // 系统日志
    Systemlog: {
      tip: "gợi ý",
      requestParams: "Thông số yêu cầu",
      returnParams: "Trả về tham số",
      module: "mô-đun",
      status: "tình trạng",
      message: "thông tin",
      requestMethodName: "Tên phương thức yêu cầu",
      requestIp: "Yêu cầu IP",
      requestWay: "Phương thức yêu cầu",
      bussinessType: "Loại hình kinh doanh",
      cancel: "Hủy bỏ",
      confirm: "Chắc chắn",
      search: "tìm kiếm",
      reset: "cài lại",
      add: "Mới",
      delete: "xóa bỏ",
      tip1: "Bạn có chắc chắn xóa các tham số đã kiểm tra không?",
      tip2: "Vui lòng chọn trước khi xóa",
      tip3: "xóaThành công"
    },

    // 模板类型
    termsTemplete: {
      add: "Mới",
      templeteName: "Tên mẫu",
      templeteContent: "Nội dung mẫu",
      content: "Vui lòng nhập nội dung",
      templeteType: "loại mẫu",
      createBy: "người sáng lập",
      createTime: "thời gian sáng tạo",
      updateBy: "người cập nhật",
      updateTime: "Cập nhật thời gian",
      selectDate: "Chọn ngày",
      cancel: "Hủy bỏ",
      confirm: "Chắc chắn",
      search: "tìm kiếm",
      reset: "cài lại",
      delete: "xóa bỏ",
    },

    // 预警设置
    warning: {
      content: "Vui lòng nhập nội dung",
      select: "xin vui lòng chọn",
      warningTitle: "Tiêu đề cảnh báo",
      isPage: "Phân trang",
      warningSql: "Cảnh báo sql",
      CustomMenuName: "Tên thực đơn tùy chỉnh",
      remark: "Nhận xét",
      showOrder: "thứ tự hiển thị",
      isShow: "dễ thấy",
      cancel: "Khép kín",
      confirm: "Chắc chắn",
      add: "Mới",
      edit: "biên tập",
      batchDeletes: "xóa hàng loạt",
      delete: "xóa bỏ",
      languageSet: "cài đặt ngôn ngữ",
      warningFieldSet: "Cài đặt trường cảnh báo",
      tip1: "Vui lòng chọn dữ liệu bạn muốn xóa",
      tip2: "thêmThành công",
      warningName: "Tên cảnh báo",
      emptyContent: "Vui lòng nhấp vào tên báo cáo ở bên trái để chọn báo cáo",
    },

    // 预警设置---预警字段设置
    warnFieldSet: {
      databaseName: "Tên trường cơ sở dữ liệu",
      search: "Tìm thấy",
      fieldShowOrder: "Thứ tự hiển thị trường",
      datebaseField: "Tên trường cơ sở dữ liệu",
      showName: "Tên hiển thị giao diện người dùng",
      dataType: "loại dữ liệu",
      select: "xin vui lòng chọn",
      alignType: "Căn chỉnh",
      isSelect: "Hỏi thăm",
      QueryOperator: "toán tử truy vấn",
      isLock: "khóa",
      datePrecision: "ngày chính xác",
      isShow: "dễ thấy",
      cancel: "Hủy bỏ",
      confirm: "Chắc chắn",
      add: "Mới",
      edit: "biên tập",
      languageSet: "cài đặt ngôn ngữ",
      tip1: "Vui lòng chọn dữ liệu bạn muốn xóa",
      tip2: "Thêm thành công",
    },


    // 流程模型
    flowModel: {
      name: "tên",
      search: "tìm kiếm",
      reset: "cài lại",
      add: "Mới",
      flowKey: "Khóa quy trình",
      version: "Phiên bản",
      createTime: "thời gian sáng tạo",
      updateTime: "Cập nhật thời gian",
      metadata: "metadata",
      edit: "biên tập",
      delete: "xóa bỏ",
      upload: "triển khai",
      download: "Xuất khẩu",
      downLoad: "Tải xuống",
      addFlowModel: "Thêm mô hình quy trình",
      category: "Phân loại",
      desc: "mô tả",
    },

    // 流程定义
    flowDefinition: {
      operation: "vận hành",
      moduleId: "id mô-đun",
      moduleName: "tên mô-đun",
      programmeId: "Id lược đồ",
      programmeName: "Tên lược đồ",
      processDefinitionKey: "Khóa phiên bản xử lý",
      remark: "Nhận xét",
      tip4: "Bạn có chắc chắn xóa mô-đun đã chọn không?",
      tip5: "Vui lòng chọn trước khi xóa",
      tip6: "đã lưu thành công",
      save: "giữ",
      active: "kích hoạt",
      hangUp: "treo",
      transferModel: "Chuyển mô hình",
      delete: "xóa bỏ",
      upload: "triển khai",
      category: "Phân loại",
      select: "xin vui lòng chọn",
      selectFile: "Chọn một tài liệu",
      clickUpload: "Bấm để tải lên",
      cancel: "Hủy bỏ",
      confirm: "Chắc chắn",
      flowChart: "sơ đồ",
      flowKey: "Khóa quy trình",
      flowName: "Tên quy trình",
      search: "tìm kiếm",
      reset: "cài lại",
      flowId: "Xử lý ID",
      version: "Phiên bản",
      flowDesc: "Miêu tả quá trình",
      uploadTime: "Thời gian triển khai",
      flowDefinition: "quá trình định nghĩa",
      deploymentProcessDefinition: "Định nghĩa quy trình triển khai",
      flowDefinitionStatus: "trạng thái xác định quy trình",
      edit: "biên tập",
      delete: "xóa bỏ",
      actived: "kích hoạt",
      tip1: "Bạn có chắc chắn xóa quá trình này?",
      tip: "gợi ý",
      confirm: "Chắc chắn",
      cancel: "Hủy bỏ",
      tip2: "đã xóa thành công",
      tip3: "Vui lòng chọn danh mục trước khi gửi",
      add: "Mới",
      selectModule: "Đầu tiên chọn tên mô-đun",
      selectProgramme: "Chọn tên gói trước"

    },

    // 审批方案
    programmeSet: {
      name: "tên",
      search: "tìm kiếm",
      reset: "cài lại",
      jointTrial: "Liệu sẽ có một phiên tòa",
      add: "Mới",
      groups: "Nhóm phê duyệt",
      Appoint: "Chỉ định người phê duyệt",
      nodeSetTip: "Vui lòng chọn nút phê duyệt",
      nodeSet: "Cài đặt nút phê duyệt",
      programmeSetAdd: "Thêm kế hoạch mới",
      programmeSetEdit: "Chỉnh sửa kế hoạch",
      programmeSetName: "Tên lược đồ",
      programmeSetCode: "Số gói",
      approvalNode: "Nút phê duyệt",
      version: "Phiên bản",
      createTime: "thời gian sáng tạo",
      updateTime: "Cập nhật thời gian",
      metadata: "metadata",
      edit: "biên tập",
      delete: "xóa bỏ",
      upload: "triển khai",
      download: "Xuất khẩu",
      addFlowModel: "Thêm vào",
      category: "Phân loại",
      desc: "mô tả",
    },


    // 审批
    Approval: {
      normal: "Bình thường",
      remove: "xóa bỏ",
      auditor: "người đánh giá",
      backTo: "Nút trả về",
      tipNode: "Nếu không được chọn, nó sẽ được trả về cho người gửi theo mặc định!",
      operation: "vận hành",
      agree: "đồng ý",
      reject: "từ chối",
      history: "lịch sử",
      schedule: "lịch trình",
      view: "Kiểm tra",
      confirm: "Chắc chắn",
      cancel: "Hủy bỏ",
      approvalOption: "Ý kiến phê duyệt",
      approvalProcess: "Quy trình phê duyệt",
      approvalHistory: "Lịch sử phê duyệt",
      approveParam: "Thông số phê duyệt",
      scheduleChart: "Biểu đồ tiến độ",
      initiator: "nhà tài trợ",
      moduleName: "tên mô-đun",
      search: "tìm kiếm",
      reset: "cài lại",
      refresh: "làm cho khỏe lại",
      approvalAgree: "Tán thành",
      approvalReject: "Phê duyệt bị từ chối",
      approvalTime: "Thời gian xử lý",
      // assignee: "phân công",
      approvalParam: "Thông số phê duyệt",
      taskName: "tên nhiệm vụ",
      assignee: "Người phê duyệt",
      startTime: "Thời gian bắt đầu",
      endTime: "Thời gian kết thúc",
      durationTime: "mất thời gian",
      refreshSuccess: "Làm mới thành công",
      refressFail: "Làm mới không thành công",
      tip1: "Vui lòng đánh dấu vào ô trước khi đồng ý!",
      tip2: "Vui lòng kiểm tra nó trước khi tiến hành thao tác từ chối!",
      viewInvoices: "Kiểm tra",
      print: "In",
      approvalStatus: "tình trạng chính",
      tip44: "Hãy điền vào các ý kiến phê duyệt",
      initiationDate: "Ngày ra mắt",
      fno: "số văn bản",
      save: "giữ",
      status: "tình trạng",
      billStatus: "tình trạng",
    },

    // 订单进度管理
    Order: {
      orderProgressTable: "lịch trình đặt hàng",
      progress: "lịch trình",
      operation: "vận hành",
      viewDetail: "kiểm tra chi tiết",
      orderProgressChart: "Biểu đồ tiến độ đặt hàng",
      contractNumber: "Hợp đồng số",
      contractDate: "thời hạn hợp đồng",
      customerName: "tên khách hàng",
      search: "tìm kiếm",
      reset: "cài lại",
      refresh: "làm cho khỏe lại",
      deliveryDate: "ngày giao hàng",
      destinationPort: "Điểm đến",
      shipmentPort: "cảng gửi hàng",
      createBy: "người giám sát",
      progress: "lịch trình",
      addNode: "Thêm nút mới",
      confirmNode: "Xác nhận nút",
      linkName: "Tên liên kết",
      computeMode: "Phép tính",
      basisNode: "Theo nút",
      Days: "ngày cần thiết",
      createDate: "Ngày thành lập",
      chargePersion: "Chịu trách nhiệm",
      status: "tình trạng",
      edit: "biên tập",
      delete: "xóa bỏ",
      selectNode: "Chọn nút",
      confirm: "xác nhận",
      cancel: "Hủy bỏ",
      sendSuccess: "Gửi thành công",
      addNodes: "Thêm thư viện nút",
      nodeName: "Tên nút",
      nodeEndCondition: "điều kiện kết thúc nút",
      nodeEndConditionSql: "Câu lệnh điều kiện kết thúc nút",
      nodesName: "Tên thư viện nút",
      createByName: "Tên người sáng tạo",
      createTime: "thời gian sáng tạo",
      tip1: "Bạn có chắc chắn xóa nút này không?",
      tip: "gợi ý",
      saveSuccess: "đã lưu thành công",
    },

    /* 左侧菜单 */
    Menu: {
      messageCenter: "Trung tâm tin nhắn",
      nodeSettings: "Cài đặt nút",
      businessFlowTracking: "Theo dõi luồng kinh doanh",
      SystemAdmin: "Quản lý hệ thống",
      ListSet: "Cài đặt danh sách",
      FetchSet: "Truy xuất cài đặt",
      HomeSet: "Cài đặt trang chủ",
      home: "trang đầu",
      ReportPermissSet: "Cài đặt quyền báo cáo",
      dataDict: "Từ điển dữ liệu",
      category: "Từ điển phân loại",
      menuAdmin: "Quản lý thực đơn",
      userAdmin: "Quản lý người dùng",
      userOnline: "người dùng trực tuyến",
      roleAdmin: "quản lý vai trò",
      deptAdmin: "Quản lý bộ phận",
      printAdmin: "Quản lý in",
      systemLog: "Nhật ký hệ thống",
      termsTemplete: "Mẫu điều khoản",
      warningSet: "Cài đặt cảnh báo",
      fieldSet: "Cài đặt trường",
      dataDictSet: "Cài đặt mục từ điển dữ liệu",
      fetchFieldSet: "Truy xuất cài đặt trường",
      sheetSet: "cài đặt trang trang tính",
      formSet: "Cài đặt biểu mẫu",
      codeSet: "cài đặt mã hóa",
      editUser: "Người dùng biên tập",
      languageSet: "cài đặt ngôn ngữ",
      versionView: "Chế độ xem phiên bản",
      persionCenter: "Trung tâm cá nhân",
      warnPermissSet: "Cài đặt quyền cảnh báo",
      warning: "cảnh báo sớm",
      warningFieldSet: "Cài đặt trường cảnh báo",
      businessModel: "mô hình kinh doanh",
      systemSet: "Cài đặt hệ thống",
      logoSet: "Cài đặt biểu trưng",
      loginSet: "Cài đặt nền đăng nhập",

      // 流程菜单
      flowMenu: "Trình đơn quy trình",
      flowDefinition: "quá trình định nghĩa",
      flowConfig: "Cấu hình quy trình",
      flowModel: "mô hình quá trình",
      modelOnlineDesign: "Thiết kế quy trình",
      addFlowConfig: "Thêm cấu hình quy trình",
      editFlowConfig: "Chỉnh sửa cấu hình quy trình",
      programmeSet: "Cài đặt giải pháp",
      programmeSetAdd: "Thêm kế hoạch mới",
      programmeSetEdit: "Chỉnh sửa kế hoạch",

      // 审批管理
      approvalAdmin: "Quản lý phê duyệt",
      peddingApproval: "Chưa giải quyết",
      approval: "Tán thành",
      Initiated: "Bắt đầu",
      CCRecord: "bản ghi CC",

      // 订单进度管理
      orderProgressAdmin: "Quản lý tiến độ đơn hàng",
      orderProgressList: "Danh sách tiến độ đặt hàng",
      orderNodes: "Thư viện nút đặt hàng",
      orderProgressDetail: "Chi tiết tiến độ đặt hàng",
      statisticsReport: "Báo cáo thống kê",

      // 代码事件配置
      codeEventSet: "Cấu hình sự kiện mã",

    },
    // 嵌套BI系统
    Bisystem: {
      BiName: "D"
    },
    StatisticalManagement: {
      StatisticalManagement: "Quản lý thống kê",
    },
    // 邮件模块
    Emails: {
      edit: "biên tập",
      sign: "Cài đặt chữ ký",
      folders: "Cài đặt thư mục",
      eName: "Hệ thống thư",
      write: "viết email",
      contact: "Sổ địa chỉ",
      inbox: "hộp thư đến",
      outbox: "Hộp thư đi",
      drafts: "hộp nháp",
      trash: "thùng rác",
      folders: "thư mục",
      searchFolder: "Tìm kiếm thư mục",
      label: "tem thư",
      settings: "Cài đặt email",
      please: "làm ơn đăng nhập",
      login: "Đăng nhập",
      loginsuccess: "đăng nhập thành công",
      loginfailed: "Đăng nhập thất bại",
      selectAccount: "Chọn tài khoản",
      signContent: "Nội dung chữ ký",

      account: "tài khoản",
      password: "mật khẩu",
      notnull: "Tài khoản hoặc mật khẩu không được để trống",
      cancel: "Hủy bỏ",
      go: "Chắc chắn",
      register: "đăng ký",
      regSuccess: "đăng ký thành công",
      khname: "Tên công ty",
      copy: "CC",
      secret: "Bcc",
      addcopy: "thêm cc",
      addsecret: "Thêm BCC",
      delcopy: "Xóa CC",
      delsecret: "Xóa BCC",
      addressee: "người nhận",
      sendName: "Tên người gửi",

      copyName: "CC",
      linkTips: "Nếu chưa có liên hệ vui lòng vào sổ địa chỉ để thêm hoặc nhập trực tiếp.",
      theme: "chủ đề",
      upload: "Tải Tệp đính kèm",
      clearFiles: "xóa danh sách",
      numsTips: "Số lượng tệp đính kèm hiện được chọn:",
      sizeTips: "Tổng kích thước của danh sách đính kèm là:",
      readReceipt: "Đọc công thức",
      Sender: "người gửi",
      pickSender: "Vui lòng chọn người gửi",
      send: "gửi",
      save: "lưu bản nháp",
      reset: "cài lại",
      addresseeEmpty: "Người nhận không được để trống",
      themeEmpty: "Chủ đề không thể trống",
      senderEmpty: "Người gửi không được để trống",
      senderNull: "Chưa có người gửi, vui lòng vào cài đặt email để thêm.",
      textEmpty: "Nội dung gửi không được để trống",
      sendSuccess: "Gửi thành công",
      sendFail: "Gửi thất bại",
      saveSuccess: "Đã lưu bản nháp thành công",
      saveFail: "Không lưu được bản nháp",
      refresh: "làm cho khỏe lại",
      themeKeyword: "Từ khóa chủ đề",
      contentKeyword: "Từ khóa nội dung",
      senderKeyword: "Chọn người gửi",
      getAll: "chọn tất cả",
      clearAll: "bỏ chọn tất cả",
      oneByone: "Số lượng lớn duy nhất đáng kể",
      generatedBy: "AI viết thư",
      languageSet: "Ngôn ngữ mục tiêu",
      transLate: "dịch",
      promotional: "Thư phát triển",
      exampleContent: "Ví dụ: Vui lòng tạo cho tôi một lá thư phát triển ngoại thương cho khách hàng trong ngành bán lẻ. Tên tôi là Zhang San, từ công ty xxx. Sản phẩm và dịch vụ chính của chúng tôi là phần mềm ngoại thương và thị trường mục tiêu là Đông Nam Á.",
      addMails: "Chèn vào email",

      readStatus: "trạng thái đọc",
      all: "tất cả",
      theLastDay: "ngày cuối",
      lastThreeDays: "ba ngày qua",
      lastSevenDays: "bảy ngày qua",
      lastThirtyDays: "30 ngày trước",
      lastNinetyDays: "90 ngày qua",
      lastOneHundredEightyDays: "180 ngày qua",
      unread: "chưa đọc",
      read: "Đã đọc",
      removed: "đã xóa",
      receive: "tiếp quản",
      delete: "xóa bỏ",
      emailNums: "Tổng số tin nhắn",
      emailUnread: "thư chưa đọc",
      receiving: "Đang nhận...",
      receiveFail: "Tiếp nhận không thành công",
      receiveSuccess: "Đã nhận thành công",
      emailDetails: "Bấm vào để xem chi tiết email",
      delTips: "Vui lòng chọn trước khi xóa",
      delSuccess: "đã xóa thành công",
      delFail: "không thể xóa",
      contactsList: "danh sách liên lạc",
      contactsManage: "Quản lý liên hệ",
      categoryManage: "Quản lý danh mục",
      categoryName: "tên phân loại",
      customerName: "tên khách hàng",
      name: "Tên",
      customerCode: "Mã khách hàng",
      email: "Thư",
      operation: "vận hành",
      categoryParent: "Tiêu đề phụ",
      phone: "Điện thoại",
      reply: "hồi đáp",
      replyAll: "Trả lời tất cả",
      date: "thời gian",
      saving: "giữ",
      pickCategory: "Chọn danh mục",
      edit: "Ôn lại",
      cateTips: "Vui lòng chọn một",
      chooseAnItem: "Chỉ có thể chọn một tùy chọn",
      writeCategoryName: "Vui lòng điền tên danh mục",
      nameMailTips: "Vui lòng điền đầy đủ tên và địa chỉ email của bạn",
      recoverySuccess: "Khôi phục thành công",
      recoveryFail: "Khôi phục không thành công",
      newFolder: "Thêm thư mục mới",
      editFolder: "Chỉnh sửa thư mục",

      addFolderTips: "Vui lòng nhập tên thư mục",
      folderName: "Tên thư mục",
      addSuccess: "Thêm thành công",
      addFail: "Không thể thêm",
      emailSetting: "Một",
      signSetting: "Cài đặt chữ ký",
      folderSetting: "Cài đặt thư mục",
      displayName: "tên hiển thị",
      senderName: "Tên người gửi",
      emailAddress: "địa chỉ email",
      required: "Vui lòng điền",
      addSetting: "Thêm cài đặt mới",
      requiredSelect: "xin vui lòng chọn",
      selectMoveWarning: "Vui lòng chọn tin nhắn để di chuyển",
      moveSuccess: "Đã di chuyển thành công",
      maveFailed: "Di chuyển không thành công",
      sendingServer: "máy chủ gửi",
      Authorization: "Mật khẩu ủy quyền",
      sendserverType: "Loại máy chủ (nhận)",
      serverAddress: "địa chỉ máy chủ",
      sendserverAddress: "Địa chỉ máy chủ (gửi)",
      isDefault: "Đây có phải là mặc định không",
      enable: "Có bật hay không",
      port: "Hải cảng",
      sendTime: "Gửi thời gian",
      originalMail: "Tin nhắn gốc",
      receiveAll: "Tính phí tất cả các tài khoản",
      receiveMailPick: "Vui lòng chọn tài khoản để tính phí",
      receiveMailTime: "Vui lòng chọn khoảng thời gian bạn muốn tính phí",
      receiveEmail: "Tùy chọn tính phí",
      emailNums: "Số lượng tin nhắn",
      relatedModules: "Các mô-đun liên quan",
      moduleName: "tên mô-đun",
      fno: "tài liệu số",
      selectReportFile: "Chọn tệp đính kèm báo cáo",
      uploaded: "Tệp đính kèm đã tải lên",
      uploadSuccess: "Báo cáo tệp đính kèm được tải lên thành công",
      uploadFailed: "Không thể tải lên tệp đính kèm báo cáo",
      selectModelName: "Vui lòng chọn tên mô-đun",
      selectPrintFormat: "Vui lòng chọn định dạng in",
      readTime: "thời gian đọc",
      readAddress: "khu vực",
      readCount: "số lần đọc",
      readCount: "số lần đọc",
      markAllAsRead: "đánh dấu tất cả như đã đọc",
      markSelectedAsRead: "Đánh dấu là đã đọc",
      markAllAsReadTip: "Bạn có chắc chắn muốn đánh dấu tất cả thư là đã đọc không?",
      markSelectedAsReadtip: "Bạn có chắc chắn muốn đánh dấu email đã chọn là đã đọc không?",
      markedSuccess: "Đánh dấu thành công",
      markedFail: "Đánh dấu không thành công",
      moveto: "Chuyển tới",
      emailForward: "Phía trước",
      emailTemplate: "Mẫu email",
      addTemplate: "Thêm mẫu",
      EditTemplate: "Chỉnh sửa mẫu",

      restore: "Bạn có chắc chắn muốn khôi phục tin nhắn này không?",
      emailSet: "Cấu hình email",
      noAccount: "Bạn chưa cấu hình tài khoản email",
      goToSet: "Đi tới cài đặt",
      singleForwarding: "Chỉ hỗ trợ chuyển tiếp một mục duy nhất",
      attachments: "ruột thừa",
      download: "Tải xuống",
      cancel: "Khép kín",
      selectTheMailTemplate: "Chọn mẫu email",
      name: "tên",
      defaultExpression: "Biểu tượng cảm xúc mặc định",
      receivingAccount: "Vui lòng chọn tài khoản nhận",
      pleaseSelectAnAccount: "Vui lòng chọn một tài khoản",
      pleaseEnterYourSignature: "Vui lòng nhập nội dung chữ ký",
      pleaseFillInTheName: "Vui lòng điền tên",
      pleaseFillInTheContent: "Vui lòng điền nội dung",
      targetLanguage: "Vui lòng chọn ngôn ngữ đích",
      english: "Tiếng Anh",
      french: "người Pháp",
      spanish: "người Tây Ban Nha",
      japanese: "tiếng Nhật",
      korean: "Hàn Quốc",
      burmese: "Miến Điện",
      thai: "tiếng Thái",
      russian: "tiếng Nga",
      servicesProvidedByOpenai: "Được phục vụ bởi openai",
    },
    Marketing: {
      explosibility: "Tổng số tiền có thể phát hành",
      alreadySent: "Tổng số tiền phát hành",
      remaining: "Phần còn lại có thể được gửi",
      screening: "lọc",
      reset: "cài lại",
      sendEmail: "gửi email",
      pleaseEnterSendEmail: "Vui lòng nhập để gửi email",
      missionDataOverview: "Tổng quan về dữ liệu nhiệm vụ",
      marketingNumber: "Số tiếp thị",
      sendingNumber: "Số người gửi",
      deliveryNumber: "Số người được phục vụ",
      unpackNumber: "Số người mở",
      recoverNumber: "Số lượng câu trả lời",
      taskName: "tên nhiệm vụ",
      add: "Mới",
      remove: "xóa bỏ",
      selected: "đã chọn",
      PCS: "cá nhân",
      underWay: "trong tiến trình",
      complete: "Hoàn thành",
      creationTime: "thời gian sáng tạo",
      startSending: "Bắt đầu gửi",
      sendComplete: "Gửi đã hoàn tất",
      lastUpdated: "cập nhật mới nhất",
      taskDeletion: "Hãy chọn nhiệm vụ cần xóa!",
      isDeleteCheck: "Xóa dữ liệu đã chọn?",
      tip: "gợi ý",
      senderSettings: "Cài đặt vận chuyển",
      contentEditing: "chỉnh sửa nội dung",
      sendSetting: "Gửi cài đặt",
      taskType: "Loại nhiệm vụ",
      developmentLetter: "Thư phát triển",
      companyIntroduction: "Hồ sơ công ty",
      productIntroduction: "Mô tả Sản phẩm",
      longTermConnection: "liên hệ lâu dài",
      seasonsGreetings: "Lời chúc kỳ nghỉ",
      other: "khác",
      senderNickname: "Biệt hiệu của người gửi",
      sendingAddress: "địa chỉ gửi",
      sendingType: "Kiểu gửi",
      none: "không có",
      sendImmediately: "gửi ngay lập tức",
      automatedMarketing: "Tiếp thị tự động",
      replyEmail: "Trả lời thư",
      correctEmail: "email chính xác",
      type: "kiểu",
      addressee: "người nhận",
      replyStopSending: "Trả lời ngừng gửi",
      readStoppedSending: "Đọc dừng gửi",
      pleaseEnter: "vui lòng nhập",
      pleaseSelect: "xin vui lòng chọn",
      select: "chọn",
      optional: "Không bắt buộc",
      confirm: "xác nhận",
      cancel: "Hủy bỏ",
      lastStep: "Trước",
      nextStep: "Bước tiếp theo",
      immediatelyEnable: "Kích hoạt ngay",
      emailSubject: "Chủ đề email",
      outgoingInterval: "khoảng thời gian gửi",
      subjectName: "Tên chủ đề",
      theme: "chủ đề",
      controls: "vận hành",
      editor: "biên tập",
      successfulOperation: "Hoạt động thành công",
      successfulReplication: "Đã sao chép thành công",
      operationFailure: "lỗi hệ thống",
      confirmToDelete: "xác nhận xóa?",
      mailSubjectName: "Tên chủ đề email",
      insertVariable: "chèn biến",
      emailContent: "nội dung của email",
      uploadAttachment: "Tải Tệp đính kèm",
      commodityList: "Danh sách sản phẩm",
      templateSelection: "Lựa chọn mẫu",
      AILetterWriting: "chữ AI",
      AIWrite: "AI viết thư",
      variable: "Biến đổi",
      toggle: "công tắc",
      for: "vì",
      language: "ngôn ngữ",
      english: "Tiếng Anh",
      french: "người Pháp",
      spanish: "người Tây Ban Nha",
      japanese: "tiếng Nhật",
      korean: "Hàn Quốc",
      burmese: "Miến Điện",
      thai: "tiếng Thái",
      russian: "tiếng Nga",
      day: "bầu trời",
      remark: "Nhận xét",
      isUnsubscribe: "Có bao gồm phần kết thúc hủy đăng ký hay không",
      companyName: "Tên công ty",
      languageUsed: "Ngôn ngữ đầu ra của email",
      productName: "tên sản phẩm",
      toneOfMail: "Âm báo email",
      business: "việc kinh doanh",
      moreFormal: "trang trọng hơn",
      politeness: "lịch sự",
      formal: "chính thức",
      festivalName: "tên ngày lễ",
      wordLimit: "Giới hạn từ",
      unlimitedWordCount: "Không giới hạn từ",
      intraWord: "Trong lời nói",
      generalDescription: "Mô tả chung",
      send: "gửi",
      insertIntoAMessage: "chèn vào tin nhắn",
      copyContent: "Sao chép nội dung",
      examples: "Ví dụ: Vui lòng tạo cho tôi một lá thư phát triển ngoại thương cho khách hàng trong ngành bán lẻ. Tên tôi là Zhang San, từ công ty xxx. Sản phẩm và dịch vụ chính của chúng tôi là phần mềm ngoại thương và thị trường mục tiêu là Đông Nam Á.",
      product: "hàng hóa",
      templateName: "Tên mẫu",
      hostedDataOverview: "Tổng quan về dữ liệu được lưu trữ",
      mailUserGrouping: "Nhóm người dùng thư",
      addGroup: "Tạo nhóm mới",
      deleteGroup: "Xóa nhóm",
      serialNumber: "số seri",
      userGroup: "nhóm người dùng",
      creationDate: "Ngày thành lập",
      instructions: "Minh họa",
      name: "tên",
      mailbox: "Thư",
      groupName: "Tên nhóm",
      userMailbox: "Email người dùng",
      userGroupName: "Tên nhóm người dùng",
      refresh: "làm cho khỏe lại",
      deletingUserGroup: "Vui lòng chọn nhóm người dùng sẽ bị xóa!",
      isCheckDeleteUserGroup: "Bạn có chắc chắn muốn xóa nhóm người dùng đã chọn không?",
      successfullyDeleted: "đã xóa thành công!",
      saveSuccessfully: "Đã lưu thành công !",
      success: "thành công",
      search: "tìm kiếm",
      personalTemplate: "Mẫu cá nhân",
      mailTemplateName: "Tên mẫu email",
      privatelyOwned: "riêng tư",
      publicity: "công cộng",
      isPublicTemplate: "Có nên công khai mẫu hay không",
      templateTopic: "Chủ đề mẫu",
      templateContent: "Nội dung mẫu",
      templateRemarks: "Nhận xét mẫu",
      saveTemplate: "Lưu mẫu",
      NOTranslationPicture: "Nội dung có hình ảnh không thể dịch được!",
      marketingTask: "Nhiệm vụ tiếp thị",
      setbasicInformation: "Đặt thông tin cơ bản",
      submitScheme: "Gửi và xác nhận kế hoạch",
      trusteeshipScheme: "Các kế hoạch yêu cầu lưu trữ",
      view: "Kiểm tra",
      edit: "Ôn lại",
      addCustomScheme: "Tạo một kế hoạch tùy chỉnh mới",
      outgoingMailbox: "Gửi email",
      replyEmail: "Trả lời email",
      recipientEmailAddress: "Địa chỉ email người nhận",
      industry: "Ngành công nghiệp",
      textile: "dệt may",
      homeFurnishings: "Trang chủ",
      mailLanguage: "Ngôn ngữ email",
      confirmationMarketingLetter: "Vui lòng xác nhận thư tiếp thị",
      tip1001: "Thực hiện phát triển khách hàng thông qua nhiều vòng tiếp thị, trong đó mỗi vòng tập trung vào các trọng tâm khác nhau và tự động gửi thư vào thời gian đã lên lịch tới những người nhận không trả lời.",
      startCircularMarketing: "Bắt đầu tiếp thị tuần hoàn",
      change: "Thay đổi nó",
      addScheme: "Tạo một kế hoạch mới",
      schemeName: "Tên lược đồ",
      templateLibrary: "Thư viện mẫu",
      generateTemplate: "Tạo mẫu",
      intervalTime: "Khoảng thời gian",
      triggerCondition: "Điều kiện kích hoạt",
      NoReplyYet: "Chưa được trả lời",
      unread: "chưa đọc",
      translation: "Dịch sang ngôn ngữ đích",
      description: "mô tả",
      save: "giữ",
      isGenerateRemplate: "Tạo mẫu?",
      addSuccessfully: "Thêm thành công",
      generateNewScheme: "Kế hoạch mới có được tạo sau khi nội dung sửa đổi được kích hoạt không?",
      mailTemplate: "Mẫu email",
      addTemplate: "Thêm mẫu",
      updateTime: "Cập nhật thời gian",
      personalTemplate: "Mẫu cá nhân",
      commonTemplate: "mẫu công cộng",
    }
  }
