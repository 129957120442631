<template>
  <div>
    <el-date-picker
      v-model="value"
      :type="type"
      :value-format="format"
      :picker-options="options"
      :range-separator="$t('placeHolder.to')"
      :start-placeholder="this.placeholderspan + $t('placeHolder.start')"
      :end-placeholder="this.placeholderspan + $t('placeHolder.end')"
      size="mini"
      v-on="$listeners"
      @change="datechange"
      style="height: 100%;"
    >
    </el-date-picker>
  </div>
</template>

<script>
import { switchTime } from "@/utils/methods";
export default {
  name: "Y-date",
  props: {
    placeholder: String,
    format: String,
    type: {
      type: String,
      default: "daterange",
    },
    value: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      value: '',
      placeholderspan: ""
    };
  },
  created() {
    if (this.placeholder === "" || this.placeholder === null || this.placeholder === undefined) {
      this.placeholderspan =this.$t('homeSet.inputPlaceHolder')
    } else {
      this.placeholderspan = this.placeholder
    }
  },
  watch: { },
  computed: {
    options() {
      return {
        onPick: ({ maxDate, minDate }) => {
           if (maxDate && minDate) {
            if (this.type === "daterange") {
              const _maxDate = switchTime(new Date(maxDate).getTime());
              const _minDate = switchTime(new Date(minDate).getTime());
              this.$emit("update:value", [_minDate, _maxDate]);
            }
          }
        },
        handleClear: () => {
          this.$emit("update:value", []);
        },
      };
    },
  },
  methods: {
    datechange (val) {
      this.$emit("update:value", val)
    }
  },
  mounted(){
    this.$emit('mounted')
  }
};
</script>

<style scoped lang='scss'>
/deep/.el-range-separator {
  display: flex;
  align-items: center;
}
</style>
