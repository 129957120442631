var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "vue-flow-edit-menu-group",
      class: { "vue-flow-edit-menu-group-expanded": _vm.p_value }
    },
    [
      _c(
        "div",
        {
          staticClass: "vue-flow-edit-menu-group-title",
          on: { click: _vm.onClickTitle }
        },
        [
          _vm._t("content", [_c("span", [_vm._v(_vm._s(_vm.label))])], {
            model: { label: _vm.label }
          }),
          !_vm.hideArrow
            ? _c("i", { staticClass: "el-icon-arrow-down" })
            : _vm._e()
        ],
        2
      ),
      _c("el-collapse-transition", [
        _vm.p_value
          ? _c(
              "div",
              {
                staticClass: "vue-flow-edit-menu-group-content",
                staticStyle: { "transition-duration": "200ms" }
              },
              [_vm._t("default")],
              2
            )
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }