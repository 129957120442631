<template>
  <div class="tags-bar">
    <div slot="left-btn" class="btns_nav prev-btn" @click="scrollPrev">
      <i class="el-icon-arrow-left"></i>
    </div>
    <div class="scrollTag" ref="scrollTag">
      <div class="tags" ref="tags" :style="navStyle">
        <router-link
            v-for="(tag, index) in tags"
            :key="tag.path + (tag.query ? tag.query.id : '') + index"
            :class="{ active: isActiveTag(tag) }"
            class="tags-item"
            :to="{ path: tag.path, query: tag.query, fullPath: tag.fullPath }">
          <span class="tag-item">
            <span class="tag-title" @click="handleTag(tag)">{{ tag.title }}</span>
          <span v-if="tag.path !== noClose" class="el-icon-close" @click.prevent.stop="tagsDel(tag)"></span>
          </span>
        </router-link>
      </div>
    </div>
    <div slot="rigth-btn" class="btns_nav next-btn" @click="scrollNext">
      <i class="el-icon-arrow-right"></i>
    </div>
  </div>
</template>

<script>
import {getSession} from "@/utils/methods";

export default {
  name: "Y-tag",
  props: {
    tags: {
      type: Array,
      default: () => [],
    },
    noClose: String,
  },
  data() {
    return {
      offset: 0,
      scrollable: true,
    };
  },
  computed: {
    navStyle() {
      return {transform: `translate(-${this.offset + "px"},0px)`};
    },
  },
  created() {
  },
  watch: {
    tags() {
      this.$nextTick((_) => this.update());
      this.$nextTick(_ => {
        if (getSession("users")) {
          let data = getSession("users"), fonts, all = document.getElementsByTagName('*'),
              reg = new RegExp("[\\u4E00-\\u9FFF]+", "g");
          if (data.fontSize == 'default') {
            fonts = '14px'
          } else if (data.fontSize == 'middle') {
            fonts = '16px'
          } else {
            fonts = '18px'
          }
          for (let i = 0; i < all.length; i++) {
            if (reg.test(all[i].innerText)) {
              all[i].style.fontSize = fonts;
            }
          }
        }
      })
    },
    $route() {
      this.$nextTick((_) => this.scrollToActiveTag());
    }
  },
  methods: {
    isActiveTag(tag) {
      return this.$route.path === tag.path &&
          (!this.$route.query.id || this.$route.query.id === tag.query.id);
    },
    update() {
      if (!this.$refs.tags) return;
      const navSize = this.$refs.tags.offsetWidth;
      const containerSize = this.$refs.scrollTag.offsetWidth;
      const currentOffset = this.offset;
      if (containerSize < navSize) {
        if (navSize - currentOffset < containerSize) {
          this.offset = navSize - containerSize;
        }
      } else {
        if (currentOffset > 0) {
          this.offset = 0;
        }
      }
    },
    scrollToActiveTag() {
      const tags = this.$refs.tags;
      const activeTag = this.$el.querySelector(".active");
      if (!activeTag) return;
      const scrollTag = this.$refs.scrollTag;
      const activeTagBounding = activeTag.getBoundingClientRect();
      const scrollTagBounding = scrollTag.getBoundingClientRect();

      const curOffset = this.offset;

      let newOffset = curOffset;
      if (activeTagBounding.right > scrollTagBounding.right) {
        newOffset =
            newOffset + (activeTagBounding.right - scrollTagBounding.right);
      }
      if (activeTagBounding.left < scrollTagBounding.left) {
        newOffset =
            curOffset - (scrollTagBounding.left - activeTagBounding.left);
      }
      newOffset = Math.max(newOffset, 0);
      this.offset = newOffset;
    },
    tagsDel(tag) {
      this.$emit("tagsDel", tag);
    },
    handleTag(tag) {
      this.$emit("handleTag", tag);
    },
    scrollPrev() {
      const containerSize = this.$refs.scrollTag.offsetWidth;
      const curOffset = this.offset;
      if (!curOffset) return;
      this.offset = curOffset > containerSize ? curOffset - containerSize : 0;
      this.offset = 0;
    },
    scrollNext() {
      const navSize = this.$refs.tags.offsetWidth;
      const containerSize = this.$refs.scrollTag.offsetWidth;
      const currentOffset = this.offset;
      if (navSize - currentOffset <= containerSize) return;

      this.offset = navSize - currentOffset > containerSize * 2
          ? currentOffset + containerSize
          : navSize - containerSize;
    },
  },
  mounted() {
    setTimeout(() => {
      this.scrollToActiveTag();
    }, 0);
  }
};
</script>

<style scoped lang="scss">
.tags-bar {
  width: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
  background-color: var(--barColor);

  .scrollTag {
    flex: 1;
    overflow: hidden;
    .tags {
      display: inline-block;
      white-space: nowrap;
      transition: all 0.3s ease;
      .tags-item {
        display: inline-block;
        font-size: 14px;
        padding: 8px 0;
        color: var(--barColor-font);
        border-radius: 5px 5px 0 0;
        position: relative;
        .tag-item {
          display: inline-block;
          padding: 0 14px;
          //border-right: 1px solid #e9e9eb;
          box-sizing: border-box;
          &:last-child {
            border-right: none;
          }
        }
        .el-icon-close {
          margin-left: 6px;
          color: var(--barColor-font);
        }
        .el-icon-close {
          color: var(--barColor-font);
          width: 14px;
          height: 14px;
          border-radius: 50%;
          text-align: center;
          line-height: 14px;
          &:hover {
            color: var(--barColor-dark-2);
            background: var(--barColor-font-active);
          }
        }
        &:hover {
          color: var(--barColor-font-active);
          background: var(--barColor-dark-2);
        }
      }
      .active {
        color: var(--barColor-font-active);
        background: var(--barColor-dark-1);
      }
    }
  }
  .btns_nav {
    text-align: center;
    color: var(--barColor-font);
    overflow: hidden;
    font-size: 20px;
    .el-icon-arrow-left {
      padding: 0 10px;
    }
    .el-icon-arrow-right {
      padding: 0 10px;
      border-right: 1px solid var(--barColor-font);
    }
    &:hover {
      color: var(--barColor-font-active);
      cursor: pointer;
    }
  }
}
</style>
