<template>
    <svg class="vue-flow-editor-icon" aria-hidden="true" @click="e => $emit('click',e)">
        <use :xlink:href="'#'+icon"></use>
    </svg>
</template>

<script>
    export default {
        name: "vue-flow-editor-icon",
        props: {
            icon: {type: String, required: true},                                                       //图标名称
        },
    }
</script>

<style lang="scss" scoped>
    .vue-flow-editor-icon {
        color: inherit;
        font-size: inherit;
        box-sizing: border-box;
        outline: none;

        /*阿里巴巴icon设置*/
        width: 1em;
        height: 1em;
        vertical-align: -0.15em;
        fill: currentColor;
        overflow: hidden;
    }
</style>