<template>
  <treeselect
    :multiple="multiple"
    :value="input"
    :options="options && formatOptions(options)"
    :normalizer="normalizerFormat && normalizer"
    :style="style"
    :appendToBody="appendToBody"
    class="Y-treeSelect"
    v-bind="$attrs"
    @input="handleEmitInput"
    :placeholder="placeholder"
    @select="selectDepart"
  />
</template>

<script>
import Treeselect from "@riophae/vue-treeselect";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import { isFunction } from "@/utils/validate";

export default {
  name: "Y-treeSelect",
  props: {
    input: [Array, Number, String],
    options: [Array, Function],
    normalizerFormat: Object,
    size: String,
    appendToBody: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default:  '',
    },
    multiple: {
      type: Boolean,
      default:  false
    },
  },
  computed: {
    style() {
      let obj = {
        mini: "28",
        small: "32",
        medium: "36",
      };
      if (obj[this.size]) {
        return {
          "--height": `${obj[this.size]}px`,
        };
      }
      return {
        "--height": "40px",
      };
    },
  },
  created() {
  },
  methods: {
    normalizer(node) {
      let v = Object.entries(this.normalizerFormat);
      let obj = {};
      v.map((item) => {
        obj[item[0]] = node[item[1]];
      });
      if (!obj.children) {
        obj.children = undefined;
      }
      return obj;
    },
    formatOptions(options) {
      return isFunction(options) ? options() : options;
    },
    selectDepart(val) {
      this.$emit("selectDepart", val);
    },
    // 更新input绑定的值
    handleEmitInput(value) {
      console.log(value, 'ssssasdasdas')
      this.$emit('update:input', value)
    },
  },
  components: { Treeselect },
};
</script>
<style scoped lang="scss">
.Y-treeSelect {
  /deep/ .vue-treeselect__control,
  /deep/.vue-treeselect__value-container {
    .vue-treeselect__single-value {
      padding-left: 10px;
      line-height: 28px;
    }
    .vue-treeselect__input-container {
      padding: 0;
    }
  }
}
/deep/.vue-treeselect__menu-container {
  /deep/.vue-treeselect__menu {
    font-size: 14px !important;
    font-weight: 0;
  }
}
</style>
