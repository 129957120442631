<template>
  <div class="Examination">
      <div class="leftBox">
          <div class="topBox">
            <!-- v-show="disable" -->
              <el-button v-show="disable" type="primary" size="small" @click="save" style="margin-bottom: 10px;">{{$t('configMoudle.ExaminationSave')}}</el-button>
              <br/>
              <el-row type="flex" class="row-bg" justify="start">
                <div class="topsunbox">
                  <span>{{$t('programmeSet.approvalNode')}}: </span>
                  <el-select v-model="apprValue" filterable @change="changeNode" size="mini" :placeholder="$t('programmeSet.nodeSetTip')">
                      <el-option
                        v-for="(item, idnex) in apprValueOptions"
                        :key="idnex"
                        :label="item.label"
                        :value="item.value">
                      </el-option>
                  </el-select>
                </div>
                <div class="topsunbox">
                  <span>{{$t('programmeSet.Appoint')}}: </span>
                  <el-select v-model="approverValue" :disabled="selectdisabled" filterable @change="changeApprover" multiple size="mini" placeholder="请选择审批人">
                      <el-option
                      v-for="(item, idnex) in approverValueOptions"
                      :key="idnex"
                      :label="item.label"
                      :value="item.value">
                      </el-option>
                  </el-select>
                </div>
                <div class="topsunbox">
                    <span>{{$t('programmeSet.jointTrial')}}: </span>
                    <el-checkbox v-model="isExamination" :disabled="selectdisabled" @change="isExaminationcahnge"></el-checkbox>
                </div>
              </el-row>
          </div>
          <div class="bottomBox">
              <!-- <img @dblclick="imgdeclick" :src="imgid" alt=""> -->
              <el-image
                @dblclick="imgdeclick"
                :src="imgid" 
              >
                <div slot="error" class="image-slot">
                  <i class="el-icon-picture-outline"></i>
                </div>
              </el-image>
          </div>
      </div>
      <div class="rightBox">
          <div class="h1span">
              <h1> {{ $t('Approval.approvalHistory') }} </h1>
          </div>
          <div class="actionBox">
               <el-table :data="tableData" height="100%" max-height="100%" border style="width: 100%">
                    <el-table-column
                        v-for="(item ,index) in historyColumn"
                        :key="index"
                        align="center"
                        header-align="center"
                        :prop="item.property"
                        :label="item.label"
                        :width="item.width"
                        :min-width="item.minwidth ? item.minwidth : ''"
                    >
                      <template slot-scope="scope">
                        <span v-if="item.property == 'durationTime'">
                          {{(scope.row[item.property] / 3600000).toFixed(2)}}小时
                        </span>
                        <span v-else>{{scope.row[item.property]}}</span>
                      </template>
                    </el-table-column>
                </el-table>
          </div>
      </div>
      <el-dialog
        title="大图"
        :visible.sync="dialogVisible"
        width="80%"
        :before-close="handleClose">
        <img class="dialogImg" :src="imgid" alt="">
      </el-dialog>
  </div>
</template>

<script>
import {
  findUserList,
} from "_api/sysTem/userAdmin";
import {
  approvalHistory,
  selectNode,
  approvalSave,
} from "@/api/approval";
import {
  switchTime,
} from "@/utils/methods";
import clone from "clone"
export default {
  name: "Y-Examination",
  data() {
    return {
        approverValue: [],
        approverValueOptions: [],
        approverValueOptionsdiabled: [],
        apprValue: "",
        apprValueOptions: [],
        newapprValueOptions: [],
        isExamination: false,
        approverImg: "",
        tableData: [],
        submitData: {},
        newprocessid: [],
        newcopypeocessadd: [],
        selectdisabled: true,
        dialogVisible: false,
        historyColumn: [
            { label: this.$t('Order.nodeName'), property: "taskName", width: 100 },
            { label: this.$t('Approval.assignee'), property: "assignee", width: 80 },
            { label: this.$t('Approval.approvalOption'), property: "commentMessage", width: "", minwidth: 240 },
            { label: this.$t('Approval.startTime'), property: "startTime", width: 100 },
            { label: this.$t('Approval.endTime'), property: "endTime", width: 100 },
            { label: this.$t('Approval.durationTime'), property: "durationTime", width: 100 },
        ],
    };
  },
  props: {
      rowid: {
        type: String,
        default: ""
      },
      modulid: {
        type: String,
        default: ""
      },
      imgid: {
        type: String,
        default: ""
      },
      processid: {
        type: Array,
        default: []
      },
      copypeocessadd: {
        type: Array,
        default: []
      },
      disable:{
        type: Boolean,
        default: ''
      }
  },
  watch: {
    modulid: {
      handler (val) {
        this.init(1, 1000)
      },
      deep: true,
      immediate: true
    },
    processid: {
      handler (val) {
        this.apprValueOptions = val
      },
      deep: true,
      default: true,
    },
    copypeocessadd: {
      handler (val) {
        this.newapprValueOptions = val
      },
      deep: true,
      immediate: true,
    },
    disable: {
      handler (val) {},
      deep: true,
      immediate: true,
    }
  },
  created() {
    this.apprValueOptions = this.processid
    this.init(1, 1000)
  },
  mounted() {
  },
  methods: {
    imgdeclick () {
      this.dialogVisible = true
    },
    handleClose () {
      this.dialogVisible = false
    },
    // 是否会审
    isExaminationcahnge (val) {
      if (val) {
        this.submitData.isJointlySign = '1'
      } else {
        this.submitData.isJointlySign = '0'
      }
      this.$emit("examinationData", this.newapprValueOptions)
    },
    // 选择审批节点
    changeNode(val) {
      if (this.apprValue != "" && this.apprValue !== null && this.apprValue !== undefined) {
        this.selectdisabled = false
      } else {
        this.selectdisabled = true
      }
      for (let i = 0; i < this.newapprValueOptions.length; i ++) {
        if (this.newapprValueOptions[i].nodeName == val) {
          if (this.newapprValueOptions[i]?.userIdsAssign) {
            let ids = this.newapprValueOptions[i].userIdsAssign.split(",")
            let labels = this.newapprValueOptions[i].userNamesAssign.split(",")
            let datas = []
            for (let i = 0; i < ids.length; i ++) {
              let data = {
                label: labels[i],
                value: ids[i],
              }
              datas.push(data)
            }
            this.approverValueOptions = datas
          } else {
            this.approverValueOptions = this.approverValueOptionsdiabled
          }
          this.submitData = this.newapprValueOptions[i]
          var arr = []
          if (this.newapprValueOptions[i].userIds === "" || this.newapprValueOptions[i].userIds === null || this.newapprValueOptions[i].userIds === undefined) {
            arr = []
          } else {
            arr = this.newapprValueOptions[i].userIds.split(",");
          }
          this.approverValue = arr
          this.isExamination = this.newapprValueOptions[i].isExamination
          if (this.newapprValueOptions[i].isJointlySign == '1') {
            this.isExamination = true
          } else {
            this.isExamination = false
          }
        }
      }
      this.$emit("examinationData", this.newapprValueOptions)
    },
    // 选择审批人
    changeApprover(e){
      this.submitData.userIds = e.join(",")
      let namee = []
      for (let j = 0; j < e.length; j ++) {
        for (let i = 0; i < this.approverValueOptions.length; i ++) {
          if (e[j] == this.approverValueOptions[i].value) {
            namee.push(this.approverValueOptions[i].label)
          }
        }
      }
      this.submitData.userNames = namee.join(",")
      this.$emit("examinationData", this.newapprValueOptions)
    },
    async init (pageNum, pageSize) {
        this.tableData = []
        this.approverValueOptionsdiabled = []
        this.approverValue = []
        this.apprValue = ""
        await findUserList({ pageNum,  pageSize}).then(res => {
            const { result } = res;
            let data = []
            for (let i = 0; i < result.length; i ++) {
                let add = {
                    label: result[i].nickName,
                    value: result[i].id,
                }
                data.push(add)
            }
            this.approverValueOptionsdiabled = data
        })
        approvalHistory({ id: this.rowid, moduleId: this.modulid }).then(res => {
          for (let i = 0; i < res.length; i ++) {
            res[i].startTime = switchTime(res[i].startTime, "{y}-{m}-{d}");
            res[i].endTime = switchTime(res[i].endTime, "{y}-{m}-{d}");
          }
          this.tableData = res
        })
    },
    save(){
      approvalSave(this.newapprValueOptions).then((res)=>{
        if (res) {
          this.$message({
            type: "success",
            message: this.$t('homeSet.saveTip'),
          });
          this.$emit("placedSave")
          // this.init(1, 1000)
        }
      })
    },
  },
  
};
</script>

<style lang="scss" scoped>
.Examination {
  width: 100%;
  height: calc(100% - 120px);
  display: flex;
  .leftBox {
    width: 45%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .topBox {
        width: 100%;
        box-sizing: border-box;
        .topsunbox {
            margin-right: 15px;
            margin-bottom: 10px;
            display: flex;
            flex-wrap: nowrap;
            align-items: center;
            span {
                margin-right: 5px;
                display: block;
                width: 80px;
            }
        }
    }
    .row-bg {
      flex-wrap: wrap;
    }
    .bottomBox {
        width: 100%;
        height: 60%;
        padding: 10px;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }
    }
  }
  .dialogImg {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  /deep/ .el-select {
      width: 160px;
  }
  .rightBox {
    width: 55%;
    height: 100%;
    display: flex;
    flex-direction: column;
    .h1span {
        width: 100%;
        height: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .actionBox {
        flex: 1;
        height: 100%;
        padding: 10px;
        box-sizing: border-box;
    }
  }
}
/deep/ .el-table th.el-table__cell {
    background: rgb(221, 221, 221);
}
</style>