import actualSize from '@/g6-canvas/image/toolbar/actual-size.png'
import copy from '@/g6-canvas/image/toolbar/copy.png'
import deletePng from '@/g6-canvas/image/toolbar/delete.png'
import fitView from '@/g6-canvas/image/toolbar/fit-view.png'
import grid from '@/g6-canvas/image/toolbar/grid.png'
import preview from '@/g6-canvas/image/toolbar/pre-view.png'
import redo from '@/g6-canvas/image/toolbar/redo.png'
import undo from '@/g6-canvas/image/toolbar/undo.png'
import save from '@/g6-canvas/image/toolbar/save.png'
import zoomIn from '@/g6-canvas/image/toolbar/zoomin.png'
import zoomOut from '@/g6-canvas/image/toolbar/zoomin.png'
import image from '@/g6-canvas/image/toolbar/image.png'

export const TOOLBAR_IMAGE = {
    actualSize,
    copy,
    delete: deletePng,
    fitView,
    grid,
    preview,
    redo,
    undo,
    save,
    zoomIn,
    zoomOut,
    image,
}
