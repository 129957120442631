<template>
    <div class="vue-flow-edit-menu-group" :class="{'vue-flow-edit-menu-group-expanded':p_value}">
        <div class="vue-flow-edit-menu-group-title" @click="onClickTitle">
            <slot name="content" :model="{label}">
                <span>{{label}}</span>
            </slot>
            <i class="el-icon-arrow-down" v-if="!hideArrow"/>
        </div>
        <el-collapse-transition>
            <div class="vue-flow-edit-menu-group-content" v-if="p_value" style="transition-duration: 200ms">
                <slot></slot>
            </div>
        </el-collapse-transition>
    </div>
</template>

<script>
    export default {
        name: "vue-flow-edit-menu-group",
        props: {
            value: {type: Boolean},
            label: {type: String},

            hideArrow: {type: Boolean},                         // 是否隐藏箭头
        },
        watch: {
            value(val) {
                this.p_value = val
            },
        },
        data() {
            return {
                p_value: this.value,
            }
        },
        methods: {
            onClickTitle() {
                this.p_value = !this.p_value
                this.$emit('input', this.p_value)
            },
        },
    }
</script>

<style lang="scss">
</style>