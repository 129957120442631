var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "vue-flow-edit-menu",
      attrs: { draggable: "true" },
      on: { dragstart: _vm.onDragstart, dragend: _vm.onDragend }
    },
    [
      _vm._t("content", [_c("span", [_vm._v(_vm._s(_vm.model.label))])], {
        model: _vm.model
      }),
      !_vm.hideArrow
        ? _c("i", { staticClass: "el-icon-arrow-right" })
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }