import request from "@/axios/index";
// 查询部门表
export function selectDept(params) {
  return request({
    url: "/sys/dept",
    method: "get",
    params
  })
}
//新增部门表
export  function addDept(data) {
  return request({
    url: '/sys/dept',
    method: 'post',
    data
  })
}

// 部门详情
export  function detailDept(id) {
  return request({
    url: `/sys/dept/${id}`,
    method: 'get'
  })
}

// 修改部门表
export  function editDept(data) {
  return request({
    url: `/sys/dept/${data.id}`,
    method: 'put',
    data
  })
}

// 删除部门表
export  function deleteDept(id) {
  return request({
    url: `/sys/dept/${id}`,
    method: 'delete'
  })
}

// 批量删除部门表
export function delAllDept(data) {
  return request({
    url: '/sys/dept',
    method: 'delete',
    data
  })
}
