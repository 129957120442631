import {computed, inject} from "@vue/composition-api";
import {suffixSize} from "@/g6-canvas/utils/utils";
import {VueFlowEditorProvider} from "@/g6-canvas/editor/editor.ts";
import {TOOLBAR_IMAGE} from '../image'
import {designComponent} from "@/g6-canvas/composition";

interface ToolbarButton {
    key?: string
    tip?: string
    label?: string
    icon?: string
    handler?: () => void
    isEnable?: () => boolean
}


export default designComponent({
    name: 'vue-flow-editor-toolbar',
    props: {
        height: {type: [String, Number]},
        toolbarButtonHandler: {type: Function},
    },
    setup(props, context) {

        const styles = computed(() => ({
            height: suffixSize(props.height)
        }))

        const {editorState, commander, openPreview} = inject(VueFlowEditorProvider) as any

        const toolbarButtons: ToolbarButton[] = [
            // {
            //     key: 'gridSwitcher',
            //     tip: '开启/关闭网格',
            //     label: '网格',
            //     icon: 'grid',
            //     handler: () => {
            //         commander.commands.switchGrid()
            //     },
            //     isEnable: () => commander.isEnable.value.switchGrid,
            // },
            // {
            //     key: 'miniMapSwitcher',
            //     tip: '开启/关闭缩略图',
            //     label: '缩略图',
            //     icon: 'image',
            //     handler: () => {
            //         commander.commands.switchMiniMap()
            //     },
            //     isEnable: () => commander.isEnable.value.switchMiniMap,
            // },
            {
                key: 'fitSize',
                tip: '适应画布',
                icon: 'fitView',
                handler: () => {
                    commander.commands.fitView()
                },
                isEnable: () => commander.isEnable.value.fitView,
            },
            {
                key: 'actualSize',
                tip: '实际尺寸',
                icon: 'actualSize',
                handler: () => {
                    commander.commands.actualView()
                },
                isEnable: () => commander.isEnable.value.actualView,
            },
            /*{
                key: 'refresh',
                tip: '刷新',
                icon: 'el-icon-refresh-right',
                handler: () => {
                    editorState.refreshCanvas()
                },
            },*/

            {
                key: 'zoomIn',
                tip: '放大 CTRL+=',
                label: '放大',
                icon: 'zoomIn',
                handler: () => {
                    commander.commands.zoomIn()
                },
                isEnable: () => commander.isEnable.value.zoomIn,
            },
            {
                key: 'zoomOut',
                tip: '缩小 CTRL+-',
                label: '缩小',
                icon: 'zoomOut',
                handler: () => {
                    commander.commands.zoomOut()
                },
                isEnable: () => commander.isEnable.value.zoomOut,
            },
            {
                key: 'preview',
                tip: '预览',
                icon: 'preview',
                handler: () => {
                    openPreview()
                }
            },
            {
                key: 'delete',
                tip: '删除 DELETE , CTRL+D',
                label: '删除',
                icon: 'delete',
                handler: () => {
                    commander.commands.delete()
                },
                isEnable: () => commander.isEnable.value.delete,
            },
            /*{
                key: 'print',
                tip: '控制台打印数据',
                icon: 'el-icon-data-analysis',
                handler: () => {
                    let {nodes, edges} = editorState.graph.save()
                    nodes = nodes.map(({data, id, label, shape, size, x, y}) => ({data, id, label, shape, size, x, y}))
                    edges = edges.map(({shape, source, sourceAnchor, target, targetAnchor, label}) => ({shape, source, sourceAnchor, target, targetAnchor, label}))
                    console.log(JSON.stringify({nodes, edges}))
                },
            },*/
        ]

        const targetToolbarButtons = computed(() => {
            const buttons = [...toolbarButtons]

            if (!editorState.props.disabledUndo) {
                buttons.splice(8, 0, ...[
                    {
                        key: 'undo',
                        tip: '撤销 CTRL+Z',
                        label: '撤销',
                        icon: 'undo',
                        handler: () => {
                            commander.commands.undo()
                        },
                        isEnable: () => commander.isEnable.value.undo,
                    },
                    {
                        key: 'redo',
                        tip: '重做 CTRL+SHIFT+Z',
                        label: '重做',
                        icon: 'redo',
                        handler: () => {
                            commander.commands.redo()
                        },
                        isEnable: () => commander.isEnable.value.redo,
                    },
                ])
            }

            return !!props.toolbarButtonHandler ? props.toolbarButtonHandler(buttons) : buttons
        })

        return () => (
            <div class="vue-flow-editor-toolbar" style={styles.value}>

                {targetToolbarButtons.value.map(item => (
                    <el-tooltip content={item.tip} key={item.key}>
                        <div class={[
                            'vue-flow-editor-toolbar-item',
                            {
                                'vue-flow-editor-toolbar-item-disabled': !!item.isEnable && !item.isEnable()
                            }]}
                             onClick={() => ((!item.isEnable || item.isEnable()) && item.handler())}>
                            <img src={TOOLBAR_IMAGE[item.icon]}/>
                            <span>{item.label || item.tip}</span>
                        </div>
                    </el-tooltip>
                ))}
                {context.slots.default && context.slots.default()}
            </div>
        )
    },
})
