export default {
  // 代码事件
  codeEvent: {
    eventSet:"Set event",
    placeSetName: 'Please enter an event name',
    eventName:"EventName",
    eventId:"Event ID",
    placeSet:"Place set ",
    fieldChange:"FieldChange",
    field:"Field",
    status:'Status',
    eventContent:'EventContent',
    sqlConfig:"SqlConfig",
    name:'Name',
    remark:'Remark'
  },

  // 表单配置
  formSet: {
    guageSet: 'Switch language',
    languageSet:"Language",
    stretch:'Stretch',
    tile:'Tile',
    half:'Stretch half',
    third:'Take up a third',
    leftJustify: 'Jusifycontent-left',
    rightJustify: 'Jusifycontent-right',
    center: "Jusifycontent-cener",
    equal: 'Equal',
    DateRange: 'DateRange',
    less: 'Less',
    lessOrequal: 'LessOrequal',
    greater: 'Greater',
    greatorequal: "Great or equal",
    like: 'Like',
    left: 'Left',
    right: 'Right',
    between: 'Between',
    database:'DataBase',
    expression:'Custom expression',
    year:'Year',
    day:'Day',
    hour:'Hour',
    minute:'Minute',
    second:'Second',
    input:'Input box',
    dateInput:'Date input',
    selectOption:'Select option',
    switch:'Switch',
    textarea:'Text area',
    numberInput:'Number input',
    richText:'Rich text',
    checkBox: 'Check box',
    hyperlink: 'Hyperlink',
    years: 'Years',
    month: 'Month',
    cascadeSelection: 'CascadeSelection',
    longRangeSelection: 'LongRangeSelection',
    people: 'People' ,
    citySelect:'City select' ,
    selectInput:'Select input',
    permiAll: 'All',
    permiCustom: 'User define',
    department: 'Department',
    deptBelow: "Department below",
    selfOnly:'Self only'
  },

  // 版本
  version: {
    record:'Update record',
    times:"Update times",
    updateDate:"Update date",
    updatePerson:"Update by",
    mainTableData:'Main datas',
    detailsOldData:'Detail raw data',
    detailsData:'Detail update data',
    oldData:"Raw data",
    newData:"Update data"
  },

  $confirm: {
    message:{
      修改为已读吗:'Confirm change to read?',
      确定撤回该数据吗:'Make sure to withdraw this data',
      '是否要变更该合同？':"Do you want to change the contract?",
      '是否要取消变更该合同？':"Do you want to cancel and change the contract?"
    },
    title:{
      "提示":"Tip",
    },
    cancelButtonText:{},
    confirmButtonText:{},
  },

  // 新增的提示弹框 绑定到vue 中文作为建明 修改太困难了
  $message: {
    '请选择人员进行数据转交': 'Please select the personnel for data transfer!',
    '请先添加计划明细': "Please add the plan details",
    '该文件暂不支持预览': 'This file does not support preview',
    '已取消关闭': 'Cancel',
    '请选择数据': "Please select data",
    '成功清除缓存': 'Clear cache Successfully',
    '部署成功': 'Deploy successfully',
    '模型名称重复，请使用新名称创建模型。':'Model name has already exist!',
    '用户名或密码不能为空！':' username or password cannot be empty!',
    "提交成功":"Done",
    "提交失败":"Failed",
    "已变更":'Changed success',
    "已取消变更":'Cancel changed',
    "待采明细有采购不能重新生成":'Purchase details cannot be re-generated',
    "生成待采明细成功":"Successful",
    "生成待采明细失败":'Failed',
    "此调取未包含目标模块的字段，请切换到正确的页签":'This fetch does not contain the target module field, please switch to the correct TAB',
    "明细中供应商存在多个，请检查！":"There are multiple suppliers in the details",
    "认领金额不能大于收款金额，请重新填写！":'The claimed amount cannot be greater than the received amount',
    "请选择要赋值的人员":"Please select the person name ",
    "请选择模板人员!":"Pleease select the teplate name",
    "赋值成功":"Done!",
    "赋值失败":'Failed'
  },

  // 个性化
  individuation: {
    fieldName:"FieldName",
    visible:"Visible",
    total:'Total',
    locktopic:"Locktopic",
    width:"Current width",
    enable:"Enable",
    disabled:'Disabled'
  },

  //登录
  loginPage: {
    login: "Sign in",
    welCome: 'Welcome HXODTS',
    password: "Password",
    account: 'Account'
  },

  // 消息中心
  messageCenter: {
    addNewMessage: 'Add new message',
    editNewMessage: 'Edit message',
    messageTitle: 'Title',
    modelName:'Model name',
    messageContent: 'Content',
    remark: 'Remark',
    receiveBy: 'Receive by',
    sendBy: 'Send by',
    createTime: 'Create time',
    isRead: 'Has read',
  },

  placeHolder: {
    expression:'Calculation formula expression',
    selectLanguage:'Select language',
    forTrackPlaceHolder:'Please fill the order number',
    start:" start",
    end:" end" ,
    to: 'To',
    startDate: 'Start date',
    endDate: 'End date',
    selectDate:'Select date',
    Financialrecognition:'Financial recognition',
    contractNumber:'Contract number',
    startTime: "Start time",
    endTime: 'End time',
    pleaseFill: "Please fill in",
    pleaseSelect: "Please select",
    onWeek:"Last one week",
    onMonth:'Last one month',
    threeMonth:'Last three months'
  },

  // 导航栏上方的操作栏
  user: {
    quit: 'Quit',
    noticeCenter: "Message center",
    preWarning: 'Early warning',
    statistical: "Report",
    noticeAlert: "Notification",
    searchMenu: 'Seach here',
    businessProcess: 'Business process',
    accountSetting: 'Account Settings',
    about: 'About'
  },

  // 首页设置
  Home: {
    ToBeapproved: 'Documents to be approved',// 待审批数据
    realTimeData: 'Realtime Data', // 实时数据
    recents: 'Recents', // 最近访问
    commUsedFun: 'Commonly used function modules',
    messageCenter: 'Message Center',
    more: 'More',
    schedule: 'Schedule',
    scheduleTip: 'No arrangements are available',
    menuCollapse: 'Collapse the menu'
  },

  /* 左侧菜单 */
  Menu: {
    HomeEditor:'Home Editor',
    messageCenter: 'MessageCenter',
    nodeSettings: 'NodeSettings',
    businessFlowTracking: 'BusinessFlowTracking',
    SystemAdmin: 'SystemAdmin',
    home: 'Home',
    ListSet: 'ListSet',
    FetchSet: 'FetchSet',
    HomeSet: 'HomeSet',
    ReportPermissSet: 'ReportPermissSet',
    dataDict: 'DataDict',
    category: 'Category',
    menuAdmin: 'MenuAdmin',
    userAdmin: 'UserAdmin',
    userOnline: 'UserOnline',
    roleAdmin: 'RoleAdmin',
    deptAdmin: 'DeptAdmin',
    printAdmin: 'PrintAdmin',
    systemLog: 'SystemLog',
    termsTemplete: 'TermsTemplete',
    warningSet: 'WarningSet',
    fieldSet: 'FieldSet',
    dataDictSet: 'DataDictSet',
    fetchFieldSet: 'FetchFieldSet',
    sheetSet: 'SheetSet',
    formSet: 'FormSet',
    codeSet: 'CodeSet',
    editUser: 'EditUser',
    languageSet: 'LanguageSet',
    versionView: 'VersionView',
    persionCenter: 'PersionCenter',
    warnPermissSet: 'WarnPermissSet',
    warning: 'Warning',
    warningFieldSet: 'WarningFieldSet',
    businessModel: 'BusinessModel',
    systemSet: 'SystemSettings',
    logoSet: 'LogoSet',
    loginSet: 'LoginBackgroundSet',
    // 流程菜单
    flowMenu: 'FlowMenu',
    monitor: 'Monitor',
    flowDefinition: 'FlowDefinition',
    flowConfig: 'FlowConfig',
    flowModel: 'FlowModel',
    modelOnlineDesign: 'ModelOnlineDesign',
    addFlowConfig: 'AddFlowConfig',
    editFlowConfig: 'EditFlowConfig',
    programmeSet: 'Program settings',
    programmeSetAdd: 'Add programme',
    programmeSetEdit: 'Edit programme',
    // 审批管理
    approvalAdmin: 'ApprovalAdmin',
    peddingApproval: 'PeddingApproval',
    approval: 'Approval',
    Initiated: 'Initiated',
    CCRecord: 'CC record',
    // 订单进度管理
    orderProgressAdmin: 'OrderProgressAdmin',
    orderProgressList: 'OrderProgressList',
    orderNodes: 'OrderNodes',
    orderProgressDetail: 'OrderProgressDetail',
    statisticsReport: 'StatisticalReport',
    // 代码事件配置
    codeEventSet: 'CodeEventSet',
  },

  // 列表设置
  listOfSet: {
    operation: 'Operation',
    refresh: 'Refresh',
    ponoFieldExist: 'The pono field does not exist',
    attachmentManagement: 'Import accessory management',
    add: 'Add',
    clearCatch: 'ClearCatch',
    loadField: 'Load field',
    copyModule: 'Copy module',
    search: "Search",
    reset: "Reset",
    edit: 'Edit',
    delete: 'Delete',
    colWidth: 'ColWidth',
    moduleName: 'ModuleName',
    mainTable: 'MainTable',
    foreignKey: 'ForeignKey',
    viewSwitch: 'ViewSwitch',
    approvalHistorySwitch: 'ApprovalHistorySwitch',
    isAgreeRequired: 'Approval or not required',
    listSql: 'ListSql',
    isImage: 'IsImage',
    imageColumn: 'ImageColumn',
    storedProcedure: 'StoredProcedure',
    isReadOnly: 'IsReadOnly',
    isInlineEditable: 'IsInlineEditable',
    isUpdateKeyOnImport: 'IsUpdateKeyOnImport',
    isCheckFno: 'NoCheckFnoUnique',
    enableOcr: 'Enable ocr',
    parentModule: 'ParentModule',
    isPage: 'IsPage',
    contactSql: 'ContactSql',
    listStoredProcedure: 'ListStoredProcedure',
    checkStoredProcedure: 'CheckStoredProcedure',
    isShowList: 'IsShowList',
    tableImgShow: 'TableImgShow',
    formImgShow: 'FormImgShow',
    isImport: 'IsImport',
    isExport: 'IsExport',
    batchExport: 'BatchExport',
    isSendEmail: 'IsSendEmail',
    isUploadFile: 'IsUploadFile',
    isBatchModify: 'IsBatchModify',
    isAboutTypography: 'IsAboutTypography',
    isGlobalRetrieval: 'IsGlobalRetrieval',
    tableFieldSet: 'TableFieldSet',
    formFieldSet: 'FormFieldSet',
    codeEventSet: 'CodeEventSet',
    languageSet: 'LanguageSet',
    sheetSet: 'SheetSet',
    isDbClkJump: 'IsDbClkJump',
    isShowSubtotal: 'Subtotal',
    showTheApprovalTab: 'ShowTheApprovalTab',
    passProcName: 'PassProcName',
    rejectProcName: 'RejectProcName',
    pageSize: 'PageSize',
    addSuccess: 'Successfully added',
    editSuccss: 'Successfully updated',
    configFormula: 'Formula editor',
    initialization: 'Initialization',
    initLanguage: 'Initialize Language',
    batchSetField: 'Batch set field',
    /* 字段设置 */
    fieldShowOrder: 'FieldShowOrder',
    databaseFieldName: 'DatabaseFieldName',
    showName: 'ShowName',
    dataType: 'DataType',
    alignType: "AlignType",
    queryOperator: "QueryOperator",
    isSelect: 'IsSelect',
    isLocked: "IsLocked",
    isGlobalQuery: 'IsGlobalQuery',
    isSum: 'IsSum',
    isShow: 'IsShow',
    dataDict: 'DataDict',
    datePrecision: "DatePrecision",
    language: 'Language',
    databaseFields: 'DatabaseFields',
    //编码设置
    save: 'Save',
    year: 'Year(2)',
    years: 'Year(4)',
    month: 'Month',
    date: 'Date',
    fixedContent1: 'FixedContent1',
    fixedContent2: 'FixedContent2',
    serialNumber: 'SerialNumber',
    loginId: 'LoginId',
    serialNoDigit: 'SerialNoDigit',
    serialNoUt: 'SerialNoUt',
    pattern: 'Pattern',
    formField1: 'FormField1',
    formField2: 'FormField2',
    formField3: 'FormField3',
    formField4: 'FormField4',
    formField5: 'FormField5',
    smallSerialStart: 'SmallSerialStart',
    bigSerial: 'Big Serial',
    resetSuccess: 'Reset succeeded',
    soon: 'And ',
    strip: 'Others',
    filePermission: 'FilePermission'

  },

  FormOfSet: {
    uploadDrag:" Drag the file here, or",
    clickUpload:'Click here',
    uploadSize:'The file size does not exceed',
    add: 'Add',
    fieldOrder: 'FieldOrder',
    name: 'Name',
    showName: 'ShowName',
    defaultValue: 'DefaultValue',
    expressionDecimalDigit: 'ExpressionDecimalDigit',
    dictLevel: 'DictLevel',
    dictRelationParentId: 'DictRelationParentId',
    dataType: 'DataType',
    editType: 'EditType',
    formConfigId: 'FormConfigId',
    isMustFill: 'IsMustFill',
    isMustFillAgree: 'IsMustSubmitFill',
    approveParam: 'ApproveParam',
    approveChange: 'ApproveChangeFile',
    combinedField: 'CombinedField',
    majorKey: 'MajorKey',
    isShow: 'IsShow',
    isReadOnly: 'IsReadOnly',
    dataDict: 'DataDict',
    remark: 'Remark',
    sheet: 'Sheet',
    expression: 'Expression',
    clauseTemplete: 'ClauseTemplete',
    widthType: 'WidthType',
    matchingCondition: 'Please select matching criteria',
    noFileSelected: 'No file selected'
  },

  // 列表设置-----语言设置
  LanguageSet: {
    contentRequire:"Language can not be empty",
    contentRequire:"Content can not be empty",
    add: "Add",
    edit: 'Edit',
    language: 'Language',
    content: 'Content',
    operation: 'Operation',
    simplifiedChinese: "SimplifiedChinese",
    confirm: "Confirm",
    cancel: "Cancel"
  },

  // 列表设置--- sheet页设置
  SheetSetting: {
    name: 'Name',
    AccordingToTheOrder: 'AccordingToTheOrder',
    add: 'Add',
    show: 'OnShow',
    hasFold: 'Collapse by default',
    hasTabs: 'SubTable tabs'
  },

  // 调取设置
  FetchSet: {
    sequence: 'Sequence',
    add: 'Add',
    name: 'Name',
    targetModuleId: 'TargetModuleId',
    parentModuleId: 'ParentModuleId',
    targetField: 'TargetField',
    colWidth: 'Field column width',
    isPaged: 'IsPaged',
    fetchSql: 'FetchSql',
    isShowImage: 'IsShowImage',
    showColumns: 'ShowColumns',
    isRetrieval: 'IsRetrieval',
    containsCalledSQL: 'ContainsCalledSQL',
    edit: 'Edit',
    delete: 'Delete',
    languageSetting: 'LanguageSetting',
    tableFiledSetting: 'TableFiledSetting',
    copySetting: 'CopySetting',
    fetchWarinning: 'Please fill in the retrieval statement',
    cancel: 'Cancel',
    confirm: 'Confirm',
    spidTips: 'No data yet!',
    fourTips: 'No data yet!',
    generateOrder: 'Generate order',
    sidebar: 'Sidebar categorization'

  },

  // 调取设置 ---- 调取字段设置
  FetchKeySet: {
    add: 'Add',
    syncField: 'SyncField',
    fieldShowOrder: 'FieldShowOrder',
    databaseFieldName: 'DatabaseFieldName',
    showName: 'ShowName',
    dataType: 'DataType',
    alignType: "AlignType",
    isSelect: 'IsSelect',
    queryOperator: "QueryOperator",
    isSortable: 'IsSortable',
    isFetched: "IsFetched",
    isLocked: "IsLocked",
    targetField: "TargetField",
    isShow: "IsShow",
    datePrecision: "DatePrecision",
  },

  reportSet: {
    jimuReportPermissionSetting: 'JimuReportPermissionSetting',
    roleNamePlaceHolder: 'Enter a role name',
    save: 'Save',
    code: 'Code',
    reportName: 'ReportName',
    createTime: 'CreateTime',
    warningTip: 'Please select at least one option',
    saveTip: 'Save Success',
    add: 'Add',
    edit: 'Edit',
    set: 'Set'
  },

  // 报表权限设置
  ReportSet: {
    jimuReportPermissionSetting: 'JimuReportPermissionSetting',
    roleNamePlaceHolder: 'Enter a role name',
    save: 'Save',
    code: 'Code',
    reportName: 'ReportName',
    createTime: 'CreateTime',
    warningTip: 'Please select at least one option',
    saveTip: 'Save Success',
    add: 'Add',
    edit: 'Edit',
    template: 'Template',
    username: 'Username',
    set: 'Set',
    empty: 'No data!',
    isEnabled: 'IsEnabled',
  },

  // 字典设置
  DictSet: {
    template: 'Download Template',
    searchButton: 'Search',
    resetButton: 'Reset',
    batchUpload: 'Import',
    add: 'Add',
    edit: 'Edit',
    delete: 'Delete',
    dictName: 'DictName',
    isForbidden: 'IsForbidden',
    enabled: 'Enabled',
    enable: 'Enable',
    disAbled:"Disabled",
    remark: 'Remark',
    dataItemSetting: 'DataItemSetting',
    name: 'Name',
    alias: 'Alias',
    sortOrder: 'SortOrder',
    desc: 'Desc',
    dataPermission: 'DataPermission',
    importSuccess: 'Success',
    importError: 'Fail',
  },

  // 菜单管理
  menuSet: {
    shangpinku:'Fetch commodity store',
    kucun:'Fetch inventory',
    yushou:'Advances received',
    permisVies:"Permissions",
    menuName: 'MenuName',
    search: 'Search',
    reset: 'Reset',
    add: 'Add',
    edit: 'Edit',
    delete: 'Delete',
    icon: 'Icon',
    type: 'Type',
    orderNum: 'OrderNum',
    permsCode: 'PermsCode',
    remark: 'Remark',
    routingPath: 'RoutingPath',
    menuStatus: 'MenuStatus',
    addMenu: 'AddMenu',
    menuType: 'MenuType',
    menuIcon: 'MenuIcon',
    menuIconColor: 'MenuIconColor(multicolor)',
    menuVisable: 'MenuVisible',
    menuOrder: 'MenuOrder',
    parentCatalogue: 'ParentCatalogue',
    associateModule: 'AssociateModule',
    buttonName: 'ButtonName',
    language: 'Language',
    deleteTip: 'Confirm deletion?',
    tip: 'Tip',
    confirm: 'Confirm',
    cancel: 'Cancel',
    firstSelectDelete: 'Please select before deleting',
    deleteSuccess: 'Delete Success',
    action: 'Operation',
    associateModule: 'AssociateModule',
    
    onExpanded: 'OnExpanded',
    onUnExpanded: 'OnUnExpanded'
  },

  // 用户管理
  userAdmin: {
    deptPlace: 'Please enter a department name',
    userName: 'UserName',
    password: 'Password',
    loginAccount: 'LoginAccount',
    phoneNumber: 'PhoneNumber',
    status: 'Status',
    createTime: 'CreateTime',
    search: 'Search',
    reset: 'Reset',
    add: 'Add',
    passOn: 'PassOn',
    passOnSuccess: 'Successful transfer',
    passOnPerson: 'Target personnel',
    passOnMoudle:'Transfer module',
    deptName: 'DeptName',
    nickName: 'NickName',
    englishName: 'EnglishName',
    phone: 'Mobile phone number',
    email: 'Email',
    roleName: 'RoleName',
    sex: 'Sex',
    postId: 'PostId',
    avatar: 'Avatar',
    deptId: 'DeptId',
    passoword: 'Passoword',
    remark: 'Remark',
    dept: 'Dept',
    edit: 'Edit',
    delete: 'Delete',
    tip1: 'Select one to modify',
    loadingtip: 'Loading, please wait...',
    tip2: 'Select an item to delete',
    tip3: 'Confirm deletion?',
    tip: 'Tip',
    confirm: 'Confirm',
    cancel: 'Cancel',
    deleteSuccess: 'DeleteSuccess!',
    editSuccess: 'EditSuccess!',
    noEmpty: ' can not be empty',
    limitOfAuthority: 'Limit of authority',
    synchronousAssignmentGroup: 'Synchronous assignment group',
  },

  // 用户管理 --- 编辑用户
  editUserAdmin: {
    save: 'Save',
    sync: 'Sync',
    add: 'Add',
    userInfo: 'UserInfo',
    nickName: 'NickName',
    englishName: 'EnglishName',
    password: 'Password',
    phoneNumber: 'PhoneNumber',
    remark: 'Remark',
    loginAccount: 'LoginAccount',
    userEmail: 'UserEmail',
    postId: 'PostId',
    dept: 'Dept',
    roleName: 'Role',
    superiorLeaders: 'SuperiorLeaders',
    sex: 'Sex',
    status: 'Status',
    emailConfig: 'EmailConfig',
    dataPermission: 'DataPermission',
    modelName: 'ModelName',
    groupName: 'GroupName',
    groupCode: 'GroupCode',
    edit: 'Edit',
    delete: 'Delete',
    loadingtip: 'Loading, please wait...',
    saveSuccess: 'SaveSuccess',
    emailUserName: 'AddresserName',
    userEmail: 'AddresserEmail',
    emailPassword: 'EmailPassword',
    smtpHost: 'SMTPAddress',
    smtpPort: 'SMTPPort',
    signature: 'Signature',
    ownPhone: 'Private mobile number',
    qq: 'QQ',
    weChat: 'WeChat',
    platformName: 'Platform Name',
    idcard: 'Idcard',
    isEmailManage: 'Whether an email administrator',
    noPicture: 'No photo',
  },

  // 在线用户
  userOnline: {
    userName: 'UserName',
    name: 'Name',
    search: 'Search',
    reset: 'Reset',
    kickOut: 'KickOut',
    email: 'Email',
    phone: 'Phone',
    sex: 'Sex',
    loginConputerIp: 'LoginConputerIp',
    loginTime: 'LoginTime',
    nowTime: 'NowTime',
    status: 'Status',
    remark: 'Remark',
    tip1: 'Confirm to kick out the user?',
    tip: 'Tip',
    confirm: 'Confirm',
    cancel: 'Cancel',
    tip2: 'Please select before kicking out!',
    tip3: 'User kicked out'
  },

  // 角色管理
  roleAdmin: {
    selectedOnly: 'Please select a piece of data',
    copy: 'Copy',
    addRole:'Role add',
    cancleRole:"Role cancle",
    tip1: 'Are you sure you want to delete the selected role?',
    menuAllot: 'MenuAllot',
    roleName: 'RoleName',
    status: 'Status',
    search: 'Search',
    reset: 'Reset',
    add: 'Add',
    save: 'Save',
    showSort: 'ShowSort',
    roleStatus: 'RoleStatus',
    permissionChar: 'PermissionChar',
    permissionScope: 'PermissionScope',
    dataPermission: 'Data permission',
    dataScope: 'DataScope',
    remark: 'Remark',
    edit: 'Edit',
    delete: 'Delete',
    tip2: 'NoEmpty!',
    tip3: 'Please select before deleting',
    tip4: 'Please select the menu to modify the assignment',
    tip5: 'SaveSuccess',
    requiredName: 'Required name'
  },

  // 部门管理
  deptAdmin: {
    deptName: 'DeptName',
    status: 'Status',
    search: 'Search',
    reset: 'Reset',
    remark: 'Remark',
    add: 'Add',
    deptCode: 'DeptCode',
    departmentHead: 'DepartmentHead',
    contactWay: 'ContactWay(phone)',
    showSort: 'ShowSort',
    createTime: 'CreateTime',
    addDept: 'AddDept',
    superiorDepartment: 'SuperiorDepartment',
    edit: 'Edit',
    delete: 'Delete',
    tip1: 'Are you sure to delete the checked departments?',
    tip: 'Tip',
    confirm: 'Confirm',
    cancel: 'Cancel',
    tip2: 'Please select before deleting!',
    tip3: 'DeleteSuccess'
  },

  //打印管理
  printAdmin: {
    add: 'Add',
    line: 'Line',
    modelNumber: 'ModuleNumber',
    modelCode: 'ModuleCode',
    modelId: 'ModuleId',
    modelName: 'ModuleName',
    mainTableName: 'MainTableName',
    reportName: 'ReportName',
    classify: 'Classify',
    idField: 'IdField',
    remark: 'Remark',
    edit: 'Edit',
    delete: 'Delete',
    tip1: 'Are you sure to delete the checked modules?',
    tip: 'Tip',
    confirm: 'Confirm',
    cancel: 'Cancel',
    tip2: 'Please select before deleting',
    tip3: 'DeleteSuccess',
    total: 'NumberOfDownload',
    printer: 'Report Designer',
    closeConfirm: 'Was it saved before closing?'
  },

  // 系统日志
  Systemlog: {
    tip: 'Tip',
    requestParams: 'RequestParams',
    returnParams: 'ReturnParams',
    module: 'Module',
    status: 'Status',
    message: 'Message',
    remark: 'Remark',
    createByName: 'Username',
    createTime: 'Time',
    execTime: 'Seconds',
    requestMethodName: 'RequestMethodName',
    requestIp: 'RequestIp',
    requestWay: 'RequestWay',
    bussinessType: 'BussinessType',
    cancel: 'Cancel',
    confirm: 'Confirm',
    search: 'Search',
    reset: 'Reset',
    add: 'Add',
    delete: 'Delete',
    tip1: 'Are you sure to delete the checked parameters?',
    tip2: 'Please select before deleting',
    tip3: 'DeleteSuccess'
  },

  // 模板类型
  termsTemplete: {
    add: 'Add',
    templeteName: 'TempleteName',
    templeteContent: 'TempleteContent',
    content: 'Content',
    templeteType: 'TempleteType',
    createBy: 'CreateBy',
    createTime: 'CreateTime',
    updateBy: 'UpdateBy',
    updateTime: 'UpdateTime',
    selectDate: 'SelectDate',
    cancel: 'Cancel',
    confirm: 'Confirm',
    search: 'Search',
    reset: 'Reset',
    delete: 'Delete',
  },

  //预警设置
  WArning: {
    content: 'Content',
    select: 'Select',
    warningTitle: 'WarningTitle',
    isPage: 'IsPage',
    warningSql: 'WarningSql',
    CustomMenuName: 'CustomMenuName',
    remark: 'Remark',
    showOrder: 'ShowOrder',
    isShow: 'IsShow',
    cancel: 'Cancel',
    confirm: 'Confirm',
    add: 'Add',
    edit: 'Edit',
    batchDeletes: 'BatchDeletes',
    delete: 'Delete',
    languageSet: 'LanguageSet',
    warningFieldSet: 'WarningFieldSet',
    tip1: 'Please select the data to delete',
    tip2: 'AddSuccess',
    warningName: 'Warning name',
    emptyContent: 'Click the report name on the left to select the report',
  },

  // 预警设置---预警字段设置
  warnFieldSet: {
    databaseName: 'DatabaseName',
    search: 'Search',
    fieldShowOrder: 'FieldShowOrder',
    datebaseField: 'DatebaseField',
    showName: 'ShowName',
    dataType: 'DataType',
    select: 'Select',
    alignType: 'AlignType',
    isSelect: 'IsSelect',
    QueryOperator: 'QueryOperator',
    isLock: 'IsLock',
    datePrecision: 'DatePrecision',
    isShow: 'IsShow',
    cancel: 'Cancel',
    confirm: 'Confirm',
    add: 'Add',
    edit: 'Edit',
    languageSet: 'LanguageSet',
    tip1: 'Please select the data to delete',
    tip2: 'Add Success',
  },

  // 流程模型
  flowModel: {
    name: 'Name',
    search: 'Search',
    reset: 'Reset',
    add: 'Add',
    flowKey: 'FlowKey',
    version: 'Version',
    createTime: 'CreateTime',
    updateTime: 'UpdateTime',
    metadata: 'Metadata',
    edit: 'Edit',
    delete: 'Delete',
    upload: 'Upload',
    download: 'Download',
    downLoad: 'DownLoad',
    addFlowModel: 'AddFlowModel',
    category: 'Category',
    desc: 'Desc',
  },

  // 流程定义
  flowDefinition: {
    operation: 'Operation',
    moduleId: 'ModuleId',
    moduleName: 'ModuleName',
    programmeId: 'ProgrammeId',
    programmeName: 'ProgrammeName',
    processDefinitionKey: 'ProcessDefinitionKey',
    remark: 'Remark',
    tip4: 'Are you sure to delete the checked modules?',
    tip5: 'Please select before deleting',
    tip6: 'SaveSuccess',
    save: 'Save',
    active: 'Active',
    hangUp: 'HangUp',
    transferModel: 'TransferModel',
    delete: 'Delete',
    upload: 'Upload',
    category: 'Category',
    select: 'Select',
    selectFile: 'SelectFile',
    clickUpload: 'ClickUpload',
    cancel: 'Cancel',
    confirm: 'Confirm',
    flowChart: 'FlowChart',
    flowKey: 'FlowKey',
    flowName: 'FlowName',
    search: 'Search',
    reset: 'Reset',
    flowId: 'FlowId',
    version: 'Version',
    flowDesc: 'FlowDesc',
    uploadTime: 'UploadTime',
    flowDefinition: 'FlowDefinition',
    deploymentProcessDefinition: 'DeploymentProcessDefinition',
    flowDefinitionStatus: 'FlowDefinitionStatus',
    edit: 'Edit',
    delete: 'Delete',
    actived: 'Actived',
    tip1: 'Are you sure you want to delete this process?',
    tip: 'Tip',
    confirm: 'Confirm',
    cancel: 'Cancel',
    tip2: 'Delete Success',
    tip3: 'Please select category and submit again',
    selectModule:'Select module name',
    selectProgramme:'Select programme name',
    add:'Add'
  },

  // 审批方案
  programmeSet: {
    name: 'Name',
    search: 'Search',
    reset: 'Reset',
    add: 'Add',
    nodeSetTip:'Please select the approval node',
    groups:'Approver Groups',
    Appoint:'Appoint the approver',
    jointTrial :"Is jointTrial ",
    nodeSet:'Approval node setting',
    programmeSetAdd:'Add  programme',
    programmeSetEdit: 'Edit programme',
    programmeSetName: 'Programme name',
    programmeSetCode: 'Programme number',
    approvalNode: 'Approval node',
    version: 'Version',
    createTime: 'Create time',
    updateTime: 'Update time',
    metadata: 'Meta data',
    edit: 'Edit',
    delete: 'Delete',
    upload: 'Deploy',
    download: 'Export',
    addFlowModel: 'Add flow model',
    category: 'Classify',
    desc: 'Describe',
  },

  // 审批
  Approval: {
    auditor:'Auditor',
    normal: 'Normal',
    remove: 'Remove',
    backTo: 'Back to node',
    tipNode: 'It will be returned to the submitter by default if you do not select ',
    operation: 'Operation',
    agree: 'Agree',
    reject: 'Reject',
    approveParam: 'ApproveParam',
    history: 'History',
    schedule: 'Schedule',
    view: 'View',
    confirm: 'Confirm',
    cancel: 'Cancel',
    approvalOption: 'ApprovalOption',
    approvalProcess: 'ApprovalProcess',
    approvalHistory: 'ApprovalHistory',
    scheduleChart: 'ScheduleChart',
    initiator: 'Initiator',
    moduleName: 'ModuleName',
    search: 'Search',
    reset: 'Reset',
    refresh: 'Refresh',
    approvalAgree: 'ApprovalAgree',
    approvalReject: 'ApprovalRejct',
    approvalTime: 'ApprovalTime',
    approvalParam: 'ApprovalParam',
    taskName: 'TaskName',
    assignee: 'Approver',
    billStatus: 'Status',
    startTime: 'StartTime',
    endTime: 'EndTime',
    durationTime: 'DurationTime',
    refreshSuccess: 'RefreshSuccess',
    refressFail: 'RefressFail',
    tip1: 'Please check this box before agreeing to the operation!',
    tip2: 'Please check and reject the operation!',
    viewInvoices: 'ViewInvoices',
    print: 'Print',
    approvalStatus: 'ApprovalStatus',
    tip44: 'Please fill in the approval opinion',
    initiationDate: 'Initiation date',
    save: 'Save',
    fno: 'Business number',
    status: 'Status'
  },

  // 订单进度管理
  Order: {
    orderProgressTable: 'OrderProgressTable',
    progress: 'Progress',
    operation: 'Operation',
    viewDetail: 'ViewDetail',
    orderProgressChart: 'OrderProgressChart',
    contractNumber: 'ContractNumber',
    contractDate: 'ContractDate',
    customerName: 'CustomerName',
    search: 'Search',
    reset: 'Reset',
    refresh: 'Refresh',
    deliveryDate: 'DeliveryDate',
    destinationPort: 'DestinationPort',
    shipmentPort: 'ShipmentPort',
    createBy: 'CreateBy',
    progress: 'Progress',
    addNode: 'AddNode',
    confirmNode: 'ConfirmNode',
    linkName: 'LinkName',
    computeMode: 'ComputeMode',
    basisNode: 'BasisNode',
    Days: 'Days',
    createDate: 'CreateDate',
    chargePersion: 'ChargePersion',
    status: 'Status',
    edit: 'Edit',
    delete: 'Delete',
    selectNode: 'SelectNode',
    confirm: 'Confirm',
    cancel: 'Cancel',
    sendSuccess: 'SendSuccess',
    addNodes: 'AddNodes',
    nodeName: 'NodeName',
    nodeEndCondition: 'NodeEndCondition',
    nodeEndConditionSql: 'NodeEndConditionSql',
    nodesName: 'NodesName',
    createByName: 'CreateByName',
    createTime: 'CreateTime',
    tip1: 'Are you sure you want to delete this node?',
    tip: 'Tip',
    saveSuccess: 'SaveSuccess',
  },

  // 嵌套BI系统
  Bisystem: {
    BiName: "Statistics"
  },

  StatisticalManagement: {
    StatisticalManagement: "Statistical Management",
  },

  // email
  Emails: {
    edit: 'Edit',
    sign: 'Signature',
    folders: 'Folders',
    eName: 'EmailSystem',
    write: 'WriteEmail',
    contact: 'Contacts',
    inbox: 'Inbox',
    outbox: 'Outbox',
    drafts: 'Drafts',
    trash: 'Spam',
    folders: 'MyFolders',
    searchFolder: "Search folders",
    label: 'Email Label',
    settings: 'Email Settings',
    please: 'Please Login',
    login: 'Login',
    loginsuccess: 'Login Success',
    loginfailed: 'Login Failed',
    account: 'Account',
    selectAccount: 'Account',
    signContent: 'Content',
    password: 'Password',
    notnull: 'Account or password cannot be blank',
    cancel: 'Cancel',
    go: 'Go on',
    register: 'Register',
    regSuccess: 'Register Success',
    khname: 'Unit Name',
    copy: 'Copy Send',
    secret: 'Secret Send',
    addcopy: 'Add Copy Send',
    addsecret: 'Add Secret Send',
    delcopy: 'Delete Copy Send',
    delsecret: 'Delete Secret Send',
    addressee: 'Addressee',
    copyName: 'CC',
    linkTips: 'Liaison not found, please to add liaison at Contacts',
    theme: 'Theme',
    upload: 'Upload attachments',
    clearFiles: 'Clear list',
    numsTips: 'Number of attachments currently selected: ',
    uploadTips: 'Total list size: ',
    sizeTips: 'The total size of the attachment list is:',
    readReceipt: 'Read Receipt',
    Sender: 'Sender',
    pickSender: 'Select a sender',
    send: 'Send',
    save: 'Save',
    reset: 'Reset',
    taskAddBut: 'Add',
    entrustAddBut: 'Add',
    taskRemoveBut: 'Remove',
    entrustRemoveBut: 'Remove',
    addresseeEmpty: 'Addressee cannot be empty',
    themeEmpty: 'Theme cannot be empty',
    senderEmpty: 'Sender cannot be empty',
    senderNull: 'No sender yet, please go to Email Settings to add',
    textEmpty: 'Sending content cannot be empty',
    sendSuccess: 'Send successful',
    sendFail: 'Send failed',
    saveSuccess: 'Save successful',
    saveFail: 'Save failed',
    refresh: 'Refresh',
    themeKeyword: "Theme'S keyword",
    contentKeyword: "Content'S keyword",
    senderKeyword: 'Select sender',
    getAll: 'Select all',
    clearAll: 'Clear all',
    oneByone: 'Send a group message 1 by 1',
    generatedBy: "Generated by Artificial intelligence",
    languageSet: 'Language',
    transLate: 'TransLate',
    promotional: 'Promotional letter',
    exampleContent: 'Example:Please generate a foreign trade development letter for retail industry customers for me. My name is Zhang SAN, from xxx company. Our main products and services are foreign trade software, and the target market is Southeast Asia.',
    readStatus: 'Reading status',
    addMails: "Insert",
    all: 'All',
    theLastDay: 'TheLastDay',
    lastThreeDays: 'Last Three Days',
    lastSevenDays: 'Last Seven Days',
    lastThirtyDays: 'Last Thirty Days',
    lastNinetyDays: 'Last Ninety Days',
    lastOneHundredEightyDays: 'Last One Hundred Eighty Days',
    unread: 'Unread',
    read: 'Read',
    removed:"Removed",
    receive: 'Receive',
    delete: 'Delete',
    emailNums: 'Total email',
    emailUnread: 'Unread email',
    receiving: 'Receiving...',
    receiveFail: 'Receiving Failed',
    receiveSuccess: 'Receiving Success',
    emailDetails: 'Click to view email details',
    delTips: 'Please select before deleting',
    delSuccess: 'Deleted successfully',
    delFail: 'Deleted failed',
    contactsList: 'Contacts List',
    contactsManage: 'Contacts Manage',
    categoryManage: 'Category Manage',
    categoryName: 'Category Name',
    customerName: 'Customer Name',
    name: 'Name',
    customerCode: 'Customer Code',
    email: 'Email',
    operation: 'Operation',
    categoryParent: 'Category Parent',
    phone: 'Phone',
    reply: 'Reply',
    replyAll: 'Reply All',
    date: 'Date',
    saving: 'Save',
    pickCategory: 'Choose category',
    edit: 'Edit',
    cateTips: 'Please select one correctly',
    chooseAnItem: 'Only one option can be selected',
    writeCategoryName: 'Fill in the category name',
    nameMailTips: 'Please complete your name and email address',
    recoverySuccess: 'Recovery successful',
    recoveryFail: 'Recovery Fail',
    newFolder: 'New folder',
    editFolder: 'Edit folder',
    addFolderTips: 'Please enter a folder name',
    folderName: ' folder name',
    addSuccess: 'Successfully added',
    addFail: 'Failed to add',
    emailSetting: 'Mailbox settings',
    signSetting: 'Signature settings',
    folderSetting: 'Folder settings',
    displayName: 'Display Name',
    senderName: 'Sender Name',
    copyName: 'CopyName',
    emailAddress: 'E-mail address',
    addSetting: 'Add',
    sendName: 'Sender name',
    required: 'Please fill in the ',
    requiredSelect: 'Please select the ',
    selectMoveWarning: 'Please select the message you want to move',
    moveSuccess: "Success",
    maveFailed: 'Failed',
    sendingServer: 'Sending server',
    Authorization: 'Password',
    sendserverType: "Server type(receive)",
    serverAddress: "Server address",
    sendserverAddress: "Server address(send)",
    isDefault: 'IsDefault',
    enable: 'Enable',
    port: 'Port',
    sendTime: 'Sending time',
    originalMail: 'Original mail',
    receiveAll: "Receive all accounts'Email",
    receiveMailPick: 'Please select the account to be charged',
    receiveMailTime: 'Please select the time period to be charged',
    receiveEmail: 'Mail receiving',
    emailNums: 'Number of emails',
    relatedModules: 'Related modules',
    moduleName: 'ModuleName',
    fno: 'Fno',
    selectReportFile: 'SelectReportFile',
    uploaded: 'Uploaded attachments',
    uploadSuccess: 'Upload successful',
    uploadFailed: 'Upload failed',
    selectModelName: 'Please select module name',
    selectPrintFormat: 'Please select a print format',
    readTime: 'Read Time',
    readAddress: 'Address',
    readCount: 'Read Count',
    markAllAsRead: 'Mark all as read"',
    markSelectedAsRead: 'Mark selected as read',
    markAllAsReadTip: 'Are you sure you want to mark all emails as read',
    markSelectedAsReadtip: 'Are you sure you want to mark the selected emails as read',
    markedSuccess: 'Marked successfully',
    markedFail: 'Marking failed',
    moveto: 'Move to',
    emailForward: 'Forward',
    emailTemplate: 'Template',
    addTemplate: 'Add Template',
    EditTemplate: 'Edit Template',
    restore: 'Are you sure you want to recover this email',
    emailSet: 'MailboxConfiguration',
    noAccount: 'You have not configured an email account',
    goToSet: 'Go to settings',
    eMarketingName:'Email marketing',
    eMarketingTemplate: "Template",
    eMarketingTask: "Task",
    eMarketingEntrust: 'Entrust marketing',
    eMarketingStatistics: 'Statistics',
    singleForwarding: 'Only single forwarding is supported',
    attachments: 'Attachments',
    download: 'Download',
    cancel: 'Cancel',
    selectTheMailTemplate: 'Select the mail template',
    name: 'Name',
    defaultExpression: 'Default expression',
    receivingAccount: 'Please select a receiving account',
    pleaseSelectAnAccount: 'Please select an account',
    pleaseEnterYourSignature: 'Please enter your signature',
    pleaseFillInTheName: 'Please Fill In TheName',
    pleaseFillInTheContent: 'Please Fill In The Content',
    targetLanguage: 'Please select the target language',
    english: 'English',
    french: 'French',
    spanish: 'Spanish',
    japanese: 'Japanese',
    korean: 'Korean',
    burmese: 'Burmese',
    thai: 'Thai',
    russian: 'Russian',
    servicesProvidedByOpenai: 'Services provided by OpenAI',
  },

  // 补充tips
  addTips: {
    approve:'Approving',
    reject:'Reject',
    pass:'Passed',
    noUse:'This module is disabled',
    hasPigeonhole:'Filed',
    noPigeonhole:'Not filed',
    all:'All',
    cancel:'Cancel',
    confirm:'Confirm',
    noSupportpreview:'The file does not support preview',
    fileServiceIsNotDeployed: 'The file preview service is not deployed',
    isAssign:"Assign",
    programmeNameRequire:'Please fill programme name',
    programmeCodeRequire:"Please fill programme code",
    addPerson:'Please select personnel',
    number:'Number',
    groupEmpty:"Group name cannot be empty",
    approveEmpty:'Please add an approval node',
    nodeEmpty:"Node data cannot be empty",
    tempNameRequire:'Template name must be require',
    refreshDatabase:'Loading',
    tableNameRequire:'Tablename must be require',
    nowusebbs:'Child table names cannot be repeated',
    tableNameNorepeat:"Table names must be unique",
    moduleNameNorepeat:'The template name must be unique',
    done:'Done!',
    doneFailed:'Failed!',
    successZhengshi:' converted into a full customer success',
    selectZhengshi:'Please select the client ',
    successGonghai:'Converted to high seas customer success',
    selectGonghai:'Please select the client ',
    selectClassIs:'The choice is',
    ifContinue:'Are you sure to continue?',
    selectGiveData:'Please select the data you want to transfer',
    addTopClass:"Please select a category first",
    cantclaimed:'Select the lowest level of the tree structure',
    treeBelow:'Select the lowest level of the tree structure',
    orderNo:"Purchase order does not exist",
    goodCanNotDelete:'Cannot remove',
    addoccupy:'Please select a detail that you would like to occupy',
    onlyOneoccupy:'Only one record can be selected',
    occupyNum: 'Please enter the occupied quantity',
    warehouseLocation: 'Warehouse location',
    productName: 'Product Name',
    currentInventory: 'Current inventory',
    purchaseQuantity: 'Purchase quantity',
    occupationQuantity: 'Occupation quantity',
    onlyOneTop:'Only one top-level primary table can be selected',
    comfirmDelet:"Make sure to remove?",
    selectRow:"Please select the line you want to move",
    onlyOneRowMove:"Only one row can be selected to move",
    noEffectRow:'The destination line number is invalid',
    noMaxLine:'Exceed the maximum line number',
    fetchNoMove:'Remove failed , the detail data has been called ',
    checkError:'Check  exception',
    nodeNoGood:'There is no product ',
    languageNoRepeat:' language has already exsit ',
    printMessage: 'You cannot preview the print in Internet Explorer. Please use another browser to open it',
    tableNoData: 'Please set them and try again!',
    subassembly: 'Subassembly:',

    itemno: 'Product number',
    sdesc: 'Chinese product name',
    zyqty: 'Occupied quantity',
    poqty: 'Unoccupied quantity'

  },

  GlobalConfig: {
    duplicationCheck: 'Duplication check',
    CustomerSwitch: 'Customer switch',
    CustomerSwitchDesc: 'Customer duplicate checking function, duplicate checking rules: customer number (fno), customer abbreviation (cpal), customer name (cpny), contact number (tel), company website (http)',
    SupplierSwitch: 'Supplier switch',
    SupplierSwitchDesc: 'Supplier duplicate checking function, duplicate checking rules: supplier number (fno), supplier abbreviation (cpal), supplier name (cpny), telephone number (tel), company website (http)',
    GoodsSwitch: 'Goods switch',
    GoodsSwitchDesc: 'Product duplicate checking function, product number (itemno)',
    FeedbackConfiguration: 'Feedback configuration',
    projectName: 'Project Name',
    projectContact: 'Project Contact Number',
    version: 'Version',
    systemCallbackAddress: 'System Callback Address',
    wincareAddress: 'Wincare Address',
    projectNamePlace: 'Please fill in the project name',
    projectContactPlace: 'Please fill in the project contract number',
    wincareAddressPlace: 'Please fill in the wincare address'
  },

  // 配置模块
  configMoudle: {
    splitLine: 'Data splitting',
    splitLineNum: 'Quantity',
    splitAdd: 'Add',
    splitOperation: 'Operation',
    splitQtyError: 'The sum of the split quantity must be equal to the total quantity',
    tableExportName: 'Table data',
    filesManage: 'Files Manage',
    ocr: 'Ocr recognition',
    timedifference:'Time difference',
    resetTheSelection: 'Reset the selection',
    nodeFinish:'Node completion time',
    analyticResult: 'Analytic result',
    nodeFlow:'Node flow procedure',
    associatedFormField: 'Associated form field',
    waterKey:'Key',
    associateSubtableFields: 'Associate subtable fields',
    topLeval:'Top level',
    function:"Select function",
    operator:'Select operator',
    selectfieldName:'Select field',
    clear:'Clear All',
    hierarchyTree: 'Hierarchy tree',
    fieldType:'Field type',
    field: 'Field',
    resultField:'Result field',
    formulaEditor:'Formula editor',
    fieldDigit:'Field digit',
    fieldLength:'Field length',
    characteristic:'Field characteristic',
    dbname:'Database name',
    fieldName:'Field name',
    male:'Male',
    female:'Female',
    normal:'Enable',
    diabled:'Disable',
    signOrNot:' sign or not',
    catalogue:'Catalogue',
    menu:'Menu',
    button:'Button',
    tableName:'Table name',
    subTableName: 'Child table name',
    subTable: 'Child table ',
    aboutMainTable: 'Main table name',
    bulkEdit:'Bulkbewerking',
    portDetails:'Bills details',
    headSeal: 'Letterhead stamp',
    totalAll:'Sum',
    noData:'No Data',
    multipleChoice:'Multiple choice',
    saveFirst:'Please save first',
    uploadTypeTip:" images only in JPG or PNG format!",
    ForTrackRemark:'Attention：The module and the tracking number can not be empty. Please complete the input before submitting',
    uploadSuccess:'Successfully upload',
    uploadFail:'Successfully upload',
    searchForTrack:'Query order number',
    addFile:'Add files',
    updateFile:'Change files',
    moveLineNum:'Moving number',
    destLineNum:'Destination number',
    selctRowTip:' Please select the data you want to edit',
    selctRowTipDelet:' Please select the data you want to remove',
    noTotalField:'Please select the subtotal field',
    getTemplate:'Get template',
    details:'Details',
    forModel:'Module',
    forTrack:'Tracking number',
    serialNumber: 'Number',
    thumbnail: 'Thumbnail',
    filesLoading:'Files loading',
    filesManage:'Management of files',
    noConfigTotal:'You have not configured a subtotal',
    noGroup:'Please select a grouping condition',
    Financialrecognition:'Finance confirm',
    FinancialCancle:' cancel finance confirm',
    noRepeat:'Can not be repeated',
    shareSuccess:'Shared success',
    confirmRecall:'Make sure to withdraw the current data',
    recallSucces: 'Success!',
    CategoryNum:' category number',
    imageUpload:"Multiple image upload",
    selectFillter:'Enter a keyword to filter',
    inventoryNumber:'Inventory number',
    confirmDelete: 'Make sure to remove?',
    selectYes:"Yes",
    selectNo:'No',
    transferOrder: 'Transfer Order',
    setting: 'Setting',
    actions: 'Actions',
    settingSyncTip: 'Whether it is synchronized or not？',
    success: 'Success',
    fail: 'Fail',
    selectTransfer: 'Please select the data to transfer',
    selectPrint: 'Please select the data to be printed',
    saveDefaultTemplate: 'Save default template',
    highSeasCustomers: 'High Seas Customers',
    customerMemorabilia: 'Customer Memorabilia',
    formalCustomers: 'Formal Customers',
    sendEmail: 'SendEmail',
    excelCopy: 'Excel copy',
    claim: 'Claim',
    add: 'Add',
    edit: 'Edit',
    delete: 'Delete',
    bulkEdit: 'BulkEdit',
    content: 'Please enter the content',
    selectDate: 'SelectDate',
    select: 'Select',
    commodityTypeAdmin: 'CommodityTypeAdmin',
    typeTitleAdd: 'Commodity type add',
    typeTitleEdit: 'Commodity type edit',
    copyTokey: 'Copy to table',
    copyToform: 'Copy to form',
    initializationSequence: 'Initialization sequence',
    parentType: 'ParentType',
    TypeName: 'TypeName',
    clickUpload: 'ClickUpload',
    fileView: 'FileView',
    Viewflie: 'Viewflie',
    save: 'Save',
    printFormat: 'PrintFormat',
    cancel: 'Cancel',
    print: 'Print',
    billsPrint:"BillsPrint",
    batchPrinting: 'BatchPrinting',
    more: "More",
    share: 'Share',
    placeOnFile: 'PlaceOnFile',
    toggleArchiving: 'Archive/Unarchive',
    bulkSubmission: 'BulkSubmission',
    personnel: 'Personnel selection',
    personName: 'Person name',
    classChange: 'Class change',
    uploadPictures: "Upload pictures",
    uploadPicture:"Upload picture",
    globalQuery: 'GlobalQuery',
    modelName: 'ModelName',
    search: 'Search',
    img: 'Img',
    tip: 'Tip',
    tip1: 'Please enter a category name',
    tip2: 'Modify successfully ',
    tip3: 'Successfully added',
    tip4: 'Please select one',
    tip5: 'Are you sure you want to delete the current category and subcategories',
    tip: 'Tip',
    confirm: 'Confirm',
    cancel: 'Cancel',
    tip6: 'Successfully delete',
    refresh: 'Refresh',
    close: 'Close',
    closeLeft: 'Close Left',
    closeRight: 'Close Right',
    closeOther: 'Close others',
    closeAll: 'Close All',
    view: 'View',
    versionView: 'VersionView',
    tip7: 'Only Excel format can be uploaded  ',
    tip8: 'You are not the originator of this document',
    tip9: 'The document is under approval or has been approved',
    tip10: 'You did not select a row',
    tip11: 'Do not check approved or pending rows',
    tip12: 'Select a line and then modify it',
    tip13: 'Please select one line before printing',
    tip14: 'Please select report output format!',
    tip15: 'Please select module name!',
    tip16: 'Print success',
    tip17: 'DownLoad success',
    approvalHistory: 'Approval history',
    purchaseContract: 'Purchase Contract',
    supplierName:'Supplier name',
    fileName:'Files names',
    filePath:'Files path',
    fileType:'Files type',
    compilingAndbrushingLibraries: 'Compiling and brushing libraries',
    moduleName: 'ModuleName',
    mainTable: 'MainTable',
    createBy: 'CreateBy',
    // 详情
    fetch: 'Fetch',
    approval: 'Approval',
    reject: 'Reject',
    approvalOption: 'ApprovalOption',
    sendEmail: 'SendEmail',
    Examination: 'Examination',
    ExaminationSave: ' save examination',
    AnnextopurchaseContract: 'Annex to purchase Contract',
    Appendixtoexportcontract: 'Appendix to export contract',
    uploadFile: 'UploadFile',
    report: 'Report',
    includeFetchSql: 'Show exported data',
    includeSqlFinish: 'IncludeSqlFinish',
    viewLargeImg: 'ViewLargeImg',
    save: 'Save',
    status: 'Status',
    copy: 'Copy',
    topcopy: 'Top Copy',
    bottomcopy: 'Bottom Copy',
    moveRow: 'MoveRow',
    update: 'Update',
    submit: 'Submit',
    recall: 'Recall',
    list: 'List',
    copyInvoice: 'CopyInvoice',
    tip17: 'Please select the content and click OK',
    tip18: 'The page has been refreshed. Please go to this page again and perform this operation!',
    subTotalSet: 'Subtotal config',
    openSubtotal: 'Open subtotal',
    closeSubtotal: 'Close Subtotal',
    groupCondition: 'Group Condition',
    subtotalField: 'Subtotal Field',
    notSelected: 'Not Selected',
    selected: 'Selected',
    goLeft: 'Left',
    goRight: 'Right',
    noFno: 'Cannot find this contract number, unable to jump',
    // Y-common
    linenum: 'Linenum',
    batchDelete: 'BatchDelete',
    replaceLineNumber: 'ReplaceLineNumber',
    tip19: 'The line number you entered is invalid',
    tip20: 'In approval state...',
    tip21: 'Please select the data to delete',
    tip22: 'Only one line can be selected for newline',
    tip23: 'Please select the data you want to share',
    tip24: 'Please select the data you want to archive or unarchive',
    tip25: 'Please select the person you want to share',
    tip26: 'Whether to cancel the archive？',
    tip27: "Canceling archive succeeded",
    tip28: "Archiving success",
    tipQuit: 'Make sure to quit?',
    unfiling: 'Unfiling',
    sureToSave: 'Please click the confirm button after checking that the data is saved!',
    cancelFilter: 'CancelFilter',
    SalesContract: 'Sales Contract ',
    PurchaseContract: 'Purchase contract',
    alteration: 'Alteration',
    cancelAlteration: 'CancelAlteration'
  },

  // 首页设置
  homeSet: {
    homeSet: "HomeSet",
    roleNamePlaceHolder: 'RoleNamePlaceHolder',
    warningSet: 'WarningSet',
    reportSet: 'ReportSet',
    earlyWarning: 'EarlyWarning',
    title: 'Title',
    router: 'Router',
    sql: 'Sql',
    languageSetting: 'LanguageSetting',
    save: 'Save',
    select: 'Please select',
    inputPlaceHolder: 'InputPlaceHolder',
    report: 'Report',
    save: 'Save',
    add: 'Add',
    edit: 'Edit',
    submitTip: 'The submitted data cannot be empty',
    saveTip: 'Save Success',
    completeTip: 'Please improve the data and save it before setting the language',
    saveFailed: 'Save Failed',
    qrSetting: 'QR code setting',
    qrUpload: 'App QR code upload',
    qrEdit: 'QR code information editing',
    qrPlaceholder: 'Please enter the information to edit',
    qrTips: 'Please upload the picture first',
    basicInfor: "Basic information",
    fontConfig: "Fonts",
    passWordConfig: 'Password',
    languageConfig: "Language",
    fontSize: "Font size Settings",
    fontFamily: 'Font familly Settings',
    oldPassword: 'Old password',
    newPassword: 'New password',
    languageSet: 'Language Settings',
    defaultFont: 'Default',
    smallFont: 'Small',
    largeFont: 'Large',
    defultFont: 'Acquiesce',
    song: 'Song',
    kai: "DFKai-SB",
    yuan: 'Yuan',
    shijian: 'Air',
    logoSetting: 'LogoSetting',
    companySetting: 'CompanyName',
    loginBackgroundSetting: 'LoginBackgroundSetting',
    top:"Top",
    side:"Side",
    themeConfig:"Theme config",
    navSet:'Nav config',
    navTheme:"Theme of Menu",
    navFontColor:"Menu font color",
    pageTheme:"Page theme",
    moreMenu:"More",
  },

  // 邮件营销
  Marketing: {
    explosibility: 'Total deliverable volume',
    alreadySent: 'Total quantity issued',
    remaining: 'Residual deliverability',
    screening: 'Screening',
    reset: 'Reset',
    sendEmail: 'Send Email',
    pleaseEnterSendEmail: 'Please Enter Send Email',
    missionDataOverview: 'Mission Data Overview',
    marketingNumber: 'Marketing Number',
    sendingNumber: 'Sending Number',
    deliveryNumber: 'Delivery Number',
    unpackNumber: 'Unpack Number',
    recoverNumber: 'Recover Number',
    taskName: 'Task Name',
    add: 'Add',
    remove: 'Remove',
    selected: 'Selected',
    PCS: 'PCS',
    underWay: 'Under Way',
    complete: 'Complete',
    creationTime: 'Creation Time',
    startSending: 'Start Sending',
    sendComplete: 'Send Complete',
    lastUpdated: 'Last Updated',
    taskDeletion: 'Select the task you want to delete !',
    isDeleteCheck: 'Whether to delete selected data ?',
    tip: 'Tip',
    senderSettings: 'Sender Settings',
    contentEditing: 'Content Editing',
    sendSetting: 'Send Setting',
    taskType: 'Task Type',
    developmentLetter: 'Development Letter',
    companyIntroduction: 'Company Introduction',
    productIntroduction: 'Product Introduction',
    longTermConnection: 'Long Term Connection',
    seasonsGreetings: 'Seasons Greetings',
    other: 'Other',
    senderNickname: 'Sender Nickname',
    sendingAddress: 'Sending Address',
    sendingType: 'Sending Type',
    none: 'None',
    sendImmediately: 'Send Immediately',
    automatedMarketing: 'Automated Marketing',
    replyEmail: 'Reply Email',
    correctEmail: 'Correct Email',
    type: 'Type',
    addressee: 'Addressee',
    replyStopSending: 'Reply Stop Sending',
    readStoppedSending: 'Read Stopped Sending',
    pleaseEnter: 'PleaseEnter',
    pleaseSelect: 'PleaseSelect',
    select: 'Select',
    optional: 'Optional',
    confirm: 'Confirm',
    cancel: 'Cancel',
    lastStep: 'Last Step',
    nextStep: 'Next Step',
    immediatelyEnable: 'Immediately Enable',
    emailSubject: 'Email Subject',
    outgoingInterval: 'Outgoing Interval',
    subjectName: 'Subject Name',
    theme: 'Theme',
    controls: 'Controls',
    editor: 'Editor',
    successfulOperation: 'Successful Operation',
    successfulReplication: 'Successful Replication',
    operationFailure: 'Operation Failure',
    confirmToDelete: 'Confirm To Delete',
    mailSubjectName: 'Mail Subject Name',
    insertVariable: 'Insert Variable',
    emailContent: 'Email Content',
    uploadAttachment: 'Upload Attachment',
    commodityList: 'Commodity List',
    templateSelection: 'Template Selection',
    AILetterWriting: 'AI Letter Writing',
    AIWrite: 'AI Write',
    variable: 'Variable',
    toggle: 'Toggle',
    for: 'For',
    language: 'Language',
    english: 'English',
    french: 'French',
    spanish: 'Spanish',
    japanese: 'Japanese',
    korean: 'Korean',
    burmese: 'Burmese',
    thai: 'Thai',
    russian: 'Russian',
    outgoingInterval: 'Outgoing Interval',
    day: 'Day',
    remark: 'Remark',
    isUnsubscribe: 'With or without an unsubscribe closing',
    companyName: 'Company Name',
    languageUsed: 'The language used to output the message',
    productName: 'Product Name',
    toneOfMail: 'Tone of mail',
    business: 'Business',
    moreFormal: 'More Formal',
    politeness: 'Pliteness',
    formal: 'Formal',
    festivalName: 'Festival Name',
    wordLimit: 'Word Limit',
    unlimitedWordCount: 'Unlimited Word Count',
    intraWord: 'Intra-word',
    generalDescription: 'General Description',
    send: 'Send',
    insertIntoAMessage: 'Insert into a message',
    copyContent: 'Copy Content',
    examples: 'Example: Please generate a foreign trade development letter for retail industry customers for me, my name is Zhang SAN, from xxx company, our main products and services are foreign trade software, the target market is Southeast Asia.',
    product: 'Product',
    templateName: 'Template Name',
    hostedDataOverview: 'Hosted Data Overview',
    mailUserGrouping: 'MailUser Grouping',
    addGroup: 'Add Group',
    deleteGroup: 'Delete Group',
    serialNumber: 'Serial Number',
    userGroup: 'User Group',
    creationDate: 'Creation Date',
    instructions: 'Instructions',
    name: 'Name',
    mailbox: 'Mailbox',
    groupName: 'Group Name',
    userMailbox: 'User Mailbox',
    userGroupName: 'User group name',
    refresh: 'Refresh',
    deletingUserGroup: 'Select the user group that you want to delete !',
    isCheckDeleteUserGroup: 'Are you sure to delete the selected user group ?',
    successfullyDeleted: 'Successfully deleted !',
    saveSuccessfully: 'Save successfully !',
    success: 'Success',
    search: 'Search',
    personalTemplate: 'Personal Template',
    mailTemplateName: 'Mail Template Name',
    privatelyOwned: 'Privately Owned',
    publicity: 'Publicity',
    isPublicTemplate: 'Is Public Template',
    templateTopic: 'Template Topic',
    templateContent: 'Template Content',
    templateRemarks: 'Template Remarks',
    saveTemplate: 'Save Template',
    NOTranslationPicture: 'Cannot translate content containing images !',
    marketingTask: 'Marketing Task',
    setbasicInformation: 'Set basic information',
    submitScheme: 'Submit and confirm the proposal',
    trusteeshipScheme: 'A solution that requires hosting',
    view: 'View',
    edit: 'Edit',
    addCustomScheme: 'Add Custom Scheme',
    outgoingMailbox: 'Outgoing Mailbox',
    replyEmail: 'Reply Email',
    recipientEmailAddress: 'Recipient Email Address',
    industry: 'Industry',
    textile: 'Textile',
    homeFurnishings: 'Home Furnishings',
    mailLanguage: 'Mail Language',
    confirmationMarketingLetter: 'Please confirm the marketing letter',
    tip1001: 'Customer development through multiple rounds of marketing, each with a different focus and an automatic, timed message to the recipient who did not respond',
    startCircularMarketing: 'Start Circular Marketing',
    change: 'Change',
    addScheme: 'Add Scheme',
    schemeName: 'Scheme Name',
    templateLibrary: 'Template Library',
    generateTemplate: 'Generate Template',
    intervalTime: 'Interval Time',
    triggerCondition: 'Trigger Condition',
    NoReplyYet: 'No Reply Yet',
    unread: 'Unread',
    translation: 'Translate into the target language',
    description: 'Description',
    save: 'Save',
    isGenerateRemplate: 'Whether to generate a template ?',
    addSuccessfully: 'Add Successfully',
    generateNewScheme: 'Modify content Will a new solution be generated after enabling it ?',
    mailTemplate: 'Mail Template',
    addTemplate: 'Add Template',
    updateTime: 'Update Time',
    personalTemplate: 'Personal Template',
    commonTemplate: 'Common Template',
  }
}
