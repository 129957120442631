var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "mail-zone" },
    [
      _c(
        "splitpanes",
        { staticClass: "default-theme" },
        [
          _c(
            "pane",
            { staticClass: "mail-list" },
            [
              _c("div", { staticClass: "tab-bar" }, [
                _c("span", [
                  _vm._v(
                    _vm._s(this.$t("Emails.emailNums")) +
                      "：" +
                      _vm._s(_vm.pageNums)
                  )
                ]),
                _vm.$route.path == "/Email/emailInbox"
                  ? _c("span", [
                      _vm._v(
                        _vm._s(this.$t("Emails.emailUnread")) +
                          "：" +
                          _vm._s(_vm.eunread)
                      )
                    ])
                  : _vm._e()
              ]),
              _c(
                "el-table",
                {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.tableLoading,
                      expression: "tableLoading"
                    }
                  ],
                  staticStyle: { width: "100%" },
                  attrs: { data: _vm.tableData, "tooltip-effect": "dark" },
                  on: { "selection-change": _vm.SelectChange }
                },
                [
                  _c("el-table-column", {
                    attrs: { type: "selection", width: "40" }
                  }),
                  _c("el-table-column", {
                    attrs: { label: "" },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function(scope) {
                          return [
                            _c(
                              "div",
                              {
                                staticClass: "mail-item",
                                style:
                                  scope.row.status === 0
                                    ? "font-weight:bold"
                                    : "",
                                on: {
                                  click: function($event) {
                                    return _vm.getContent(scope.row.id)
                                  }
                                }
                              },
                              [
                                _c("div", { staticClass: "mail-item-info" }, [
                                  _c(
                                    "span",
                                    { staticStyle: { "font-size": "14px" } },
                                    [_vm._v(_vm._s(scope.row.sendName))]
                                  ),
                                  _c(
                                    "span",
                                    { staticStyle: { "font-size": "14px" } },
                                    [_vm._v(_vm._s(scope.row.sendTime))]
                                  ),
                                  _c(
                                    "span",
                                    { staticStyle: { "font-size": "14px" } },
                                    [
                                      scope.row.accessoryList &&
                                      scope.row.accessoryList.length
                                        ? _c("i", {
                                            staticClass: "el-icon-paperclip"
                                          })
                                        : _vm._e(),
                                      _vm._v(_vm._s(scope.row.title) + " ")
                                    ]
                                  )
                                ]),
                                _c("div", { staticClass: "mail-item-date" }, [
                                  _c("span", [
                                    _vm.$route.path == "/Email/emailSpam"
                                      ? _c("i", {
                                          staticClass:
                                            "del el-icon-magic-stick",
                                          on: {
                                            click: function($event) {
                                              return _vm.restoreEmail(
                                                scope.row.id
                                              )
                                            }
                                          }
                                        })
                                      : _vm._e(),
                                    _vm.$route.path !== "/Email/emailMyFolders"
                                      ? _c("i", {
                                          staticClass: "del el-icon-delete",
                                          on: {
                                            click: function($event) {
                                              return _vm.delEmail(scope.row.id)
                                            }
                                          }
                                        })
                                      : _vm._e()
                                  ]),
                                  _c(
                                    "div",
                                    {
                                      staticStyle: {
                                        display: "flex",
                                        "justify-content": "end",
                                        "margin-top": "5px"
                                      }
                                    },
                                    [
                                      scope.row.isNew == 1
                                        ? _c("span", {
                                            staticClass: "circleRed"
                                          })
                                        : _vm._e()
                                    ]
                                  )
                                ])
                              ]
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "pagination" },
                [
                  _c("el-pagination", {
                    attrs: {
                      background: "",
                      layout: "prev, pager, next",
                      total: _vm.pageNums,
                      "hide-on-single-page": true
                    },
                    on: { "current-change": _vm.pageChange }
                  })
                ],
                1
              )
            ],
            1
          ),
          _c("pane", [
            _c("div", { staticClass: "mail-details" }, [
              _vm.showDetail
                ? _c("div", { staticClass: "txt" }, [
                    _c(
                      "div",
                      { staticClass: "info" },
                      [
                        _c("span", [_vm._v(_vm._s(_vm.emailDetail.title))]),
                        _c("span", [
                          _vm._v(
                            _vm._s(this.$t("Emails.Sender")) +
                              "：" +
                              _vm._s(_vm.emailDetail.sendName)
                          )
                        ]),
                        _c("span", [
                          _vm._v(
                            _vm._s(this.$t("Emails.date")) +
                              "：" +
                              _vm._s(_vm.emailDetail.sendTime)
                          )
                        ]),
                        _c("span", [
                          _vm._v(
                            _vm._s(this.$t("Emails.addressee")) +
                              "：" +
                              _vm._s(
                                _vm.emailDetail.receiver ||
                                  _vm.emailDetail.receiverName
                              )
                          )
                        ]),
                        _vm.emailDetail.copyName
                          ? _c("span", [
                              _vm._v(
                                _vm._s(this.$t("Emails.copyName")) +
                                  "：" +
                                  _vm._s(_vm.emailDetail.copyName)
                              )
                            ])
                          : _vm._e(),
                        _vm.isSent
                          ? [
                              _c("span", [
                                _vm._v(
                                  _vm._s(this.$t("Emails.readCount")) +
                                    "：" +
                                    _vm._s(_vm.emailDetail.readCount)
                                )
                              ]),
                              _c("span", [
                                _vm._v(
                                  _vm._s(this.$t("Emails.readTime")) +
                                    "：" +
                                    _vm._s(_vm.emailDetail.readTime)
                                )
                              ]),
                              _c("span", [
                                _vm._v(
                                  _vm._s(this.$t("Emails.readAddress")) + "："
                                ),
                                _c(
                                  "a",
                                  {
                                    attrs: {
                                      href:
                                        "https://qifu.baidu.com/?activeKey=SEARCH_IP&trace=apistore_ip_aladdin&activeId=SEARCH_IP_ADDRESS&ip=" +
                                        _vm.ipname,
                                      target: "_blank"
                                    }
                                  },
                                  [_vm._v(_vm._s(_vm.ipname))]
                                )
                              ])
                            ]
                          : _vm._e(),
                        _vm.emailDetail.accessoryList &&
                        _vm.emailDetail.accessoryList.length
                          ? _c("span", [
                              _vm._v(" 附件："),
                              _c("a", { staticStyle: { color: "blue" } }, [
                                _vm._v(
                                  _vm._s(_vm.emailDetail.accessoryList.length) +
                                    "个"
                                )
                              ])
                            ])
                          : _vm._e(),
                        _c(
                          "el-button",
                          {
                            staticClass: "replyBtn",
                            attrs: { plain: "", size: "mini" },
                            nativeOn: {
                              click: function($event) {
                                return (function(e) {
                                  return _vm.emailReply(e, 1)
                                })($event)
                              }
                            }
                          },
                          [_vm._v(_vm._s(this.$t("Emails.reply")))]
                        ),
                        _c(
                          "el-button",
                          {
                            staticClass: "replyBtn",
                            attrs: { plain: "", size: "mini" },
                            nativeOn: {
                              click: function($event) {
                                return (function(e) {
                                  return _vm.emailReply(e, 2)
                                })($event)
                              }
                            }
                          },
                          [_vm._v(_vm._s(this.$t("Emails.replyAll")))]
                        )
                      ],
                      2
                    ),
                    _c(
                      "div",
                      {
                        staticClass: "detail",
                        staticStyle: { "max-height": "540px" }
                      },
                      [
                        _vm.showContent
                          ? _c("EmailContent", {
                              attrs: { content: _vm.emailDetail.content }
                            })
                          : _vm._e()
                      ],
                      1
                    ),
                    _vm.emailDetail.accessoryList &&
                    _vm.emailDetail.accessoryList.length
                      ? _c(
                          "div",
                          {
                            staticClass: "attbg",
                            staticStyle: {
                              padding: "2px",
                              height: "auto",
                              background: "#dde7f9"
                            },
                            attrs: {
                              id: "attachment",
                              a: "",
                              b: "false",
                              "ui-type": "attCon"
                            }
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "txt_left",
                                staticStyle: { padding: "6px 10px 10px 8px" }
                              },
                              [
                                _c("div", { staticStyle: { height: "14px" } }, [
                                  _c(
                                    "b",
                                    { staticStyle: { "font-size": "14px" } },
                                    [_vm._v("附件")]
                                  ),
                                  _vm._v(" ( "),
                                  _c(
                                    "span",
                                    { attrs: { id: "attachmentCount" } },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.emailDetail.accessoryList.length
                                          ) +
                                          " "
                                      )
                                    ]
                                  ),
                                  _vm._v(" 个) ")
                                ])
                              ]
                            ),
                            _vm._l(_vm.emailDetail.accessoryList, function(
                              item,
                              index
                            ) {
                              return _c(
                                "div",
                                {
                                  key: index,
                                  staticStyle: {
                                    padding: "0 8px 6px 12px",
                                    background: "#fff",
                                    _height: "60px",
                                    "line-height": "140%"
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "att_bt attachitem" },
                                    [
                                      _c("div", { staticClass: "ico_big" }, [
                                        _c(
                                          "span",
                                          {
                                            attrs: {
                                              player:
                                                "/cgi-bin/download?mailid=ZL0018_aujN~KmM434unNgAJ6ZfYc3&filename=tcpServer.log&sid=UtBPTh_Cd_rZAkYk"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " + _vm._s(item.name) + " "
                                            )
                                          ]
                                        ),
                                        _c(
                                          "span",
                                          { staticClass: "graytext" },
                                          [
                                            _vm._v(
                                              " (" + _vm._s(item.size) + ") "
                                            )
                                          ]
                                        ),
                                        _c("div", { staticClass: "down_big" }, [
                                          _vm._v(" "),
                                          _c(
                                            "a",
                                            {
                                              attrs: {
                                                href:
                                                  _vm.baseURL +
                                                  "/mail_upload/static?id=" +
                                                  item.id,
                                                target: "_blank",
                                                unset: "true"
                                              }
                                            },
                                            [_vm._v("下载")]
                                          )
                                        ])
                                      ])
                                    ]
                                  )
                                ]
                              )
                            })
                          ],
                          2
                        )
                      : _vm._e()
                  ])
                : _c(
                    "div",
                    { staticStyle: { margin: "0 auto" } },
                    [
                      _c("el-empty", {
                        staticStyle: {
                          height: "380px",
                          width: "380px",
                          margin: "auto"
                        }
                      })
                    ],
                    1
                  )
            ])
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }