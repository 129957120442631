<template>
    <transition name="vue-flow-editor-preview-transition">
        <div class="vue-flow-editor-preview" v-if="p_value">
            <div class="vue-flow-editor-preview-body" :style="!ableClosed ? { height: '99%', width: '100%' } : {}"
                ref="body">
                <div class="vue-flow-editor-preview-target" ref="target"></div>
                <div v-if="ableClosed" class="vue-flow-editor-preview-close" @click="close">
                    <i class="el-icon-close" />
                </div>
            </div>
        </div>
    </transition>
</template>

<script>

import { G6 } from "@/g6-canvas/g6/g6.ts";
import { GraphStyle } from "../utils/styles";

export default {
    name: "vue-flow-editor-preview",
    props: {
        value: { type: Boolean },
        data: { type: Object },
        ableClosed: { default: true }
    },
    watch: {
        value(val) {
            this.openInit(val)
        },
        data(){
            this.openInit(true)
        }
    },
    data() {
        return {
            graph: null,
            p_value: this.value,
        }
    },
    methods: {
        openInit(val) {
            this.p_value = val
            if (!!val) {
                this.$nextTick(() => {
                    this.refresh()
                })
            }
        },
        refresh() {
            const container = this.$refs.target
            const { offsetHeight, offsetWidth } = this.$refs.body
            const _this = this
            if (!!this.graph) {
                this.graph.destroy()
            }
            this.graph = new G6.Graph({
                container,
                width: offsetWidth,
                height: offsetHeight,

                ...GraphStyle.default,
                mode: {
                    default: [
                        'drag-canvas',
                        'zoom-canvas',
                    ]
                },
            })
            console.log(this.data)
            this.graph.read(this.data)
            this.graph.fitView(20)
            this.graph.on('node:dblclick', function (e) {
                _this.$emit('dblclick-node', e)

            })
        },
        close() {
            this.p_value = false
            this.$emit('input', this.p_value)
        },
    },
    mounted() {

        if (!this.ableClosed) {
            this.openInit(true)
        }
    }
}
</script>

<style lang="scss"></style>