<template>
  <el-dialog 
    :title="title"
    :visible="dialog"
    :width="width"
    append-to-body
    :destroy-on-close="destoryOnClone"
    :close-on-click-modal="false"
    v-bind="$attrs"
    @close="$emit('update:dialog', false)">
    <slot></slot>
    <slot name="footer">
      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="$emit('update:dialog', false)">{{$t('configMoudle.cancel')}}</el-button>
        <el-button 
          size="mini"
          type="primary"
          @click="$emit('handleOk', $slots.default)">{{$t('configMoudle.confirm')}}</el-button>
      </span>
    </slot>
  </el-dialog>
</template>

<script>
export default {
  name: 'Y-dialogBox',
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '对话框',
    },
    width: {
      type: String,
      default: '40%',
    },
    destoryOnClone: {
      type: Boolean,
      default: true,
    },
  },
}
</script>
<style lang="scss" scoped>
</style>
 