export default {
// 报表管理
  reportSet: {
    template: "แม่แบบบุคลากร",
    username: "บุคลากร",
    set: "มอบหมาย",
    empty: "ไม่มีข้อมูล",
    reportName: "ชื่อรายงานสถิติ",
    isEnabled: "ใช้งานหรือไม่"
},
// 代码事件
codeEvent: {
    eventSet: "การกำหนดค่าเหตุการณ์",
    placeSetName: "กรุณาใส่ชื่อเหตุการณ์",
    eventName: "ชื่อเหตุการณ์",
    eventId: "ID เหตุการณ์",
    placeSet: "กรุณาใส่",
    fieldChange: "การเปลี่ยนแปลงฟิลด์",
    field: "ฟิลด์",
    status: "สถานะ",
    eventContent: "เนื้อหาเหตุการณ์",
    sqlConfig: "การกำหนดค่า SQL",
    name: "ชื่อ",
    remark: "หมายเหตุ"
},


  // 新增的确认对话框 一个个改实在是太困难了
  
   $confirm: {
    message: {
        修改为已读吗: 'ต้องการเปลี่ยนเป็นอ่านแล้วหรือไม่',
        确定撤回该数据吗: 'ยืนยันที่จะถอนข้อมูลนี้หรือไม่?',
        '是否要变更该合同？': "ต้องการเปลี่ยนแปลงสัญญานี้หรือไม่？",
        '是否要取消变更该合同？': 'ต้องการยกเลิกการเปลี่ยนแปลงสัญญานี้หรือไม่？'
    },
    title: {
        "提示": "คำแนะนำ"
    },
    cancelButtonText: {},
    confirmButtonText: {}
  },
  
  // 新增的提示弹框 绑定到vue 中文作为建明 修改太困难了
$message: {
    请选择数据: "กรุณาเลือกข้อมูล",
    请选择人员进行数据转交: "กรุณาเลือกบุคลากรเพื่อส่งต่อข้อมูล!",
    部署成功: "การปรับใช้งานสำเร็จ",
    请先添加计划明细: "กรุณาเพิ่มรายละเอียดแผนก่อน",
    该文件暂不支持预览: "ไฟล์นี้ไม่รองรับการดูตัวอย่างชั่วคราว",
    已取消关闭: "ยกเลิกการปิดแล้ว",
    成功清除缓存: "ล้างแคชสำเร็จ",
    '模型名称重复，请使用新名称创建模型。': "ชื่อโมเดลซ้ำ กรุณาใช้ชื่อใหม่สร้างโมเดล",
    '用户名或密码不能为空！': "ชื่อผู้ใช้หรือรหัสผ่านต้องไม่ว่างเปล่า!",
    "提交成功": "ส่งสำเร็จ",
    "提交失败": "ส่งล้มเหลว",
    "已变更": "เปลี่ยนแปลงแล้ว",
    "已取消变更": "ยกเลิกการเปลี่ยนแปลงแล้ว",
    "待采明细有采购不能重新生成": "รายละเอียดการซื้อรอการจัดซื้อไม่สามารถสร้างใหม่ได้",
    "生成待采明细成功": "สร้างรายละเอียดการซื้อสำเร็จ",
    "生成待采明细失败": "สร้างรายละเอียดการซื้อไม่สำเร็จ",
    "此调取未包含目标模块的字段，请切换到正确的页签": "การดึงข้อมูลนี้ไม่รวมฟิลด์ของโมดูลเป้าหมาย กรุณาเปลี่ยนไปที่แท็บที่ถูกต้อง",
    "明细中供应商存在多个，请检查！": "มีผู้จำหน่ายหลายรายในรายละเอียด กรุณาตรวจสอบ!",
    "认领金额不能大于收款金额，请重新填写！": "จำนวนเงินที่อ้างสิทธิ์ต้องไม่เกินจำนวนเงินที่ได้รับ กรุณากรอกใหม่!",
    "请选择要赋值的人员": "กรุณาเลือกบุคลากรที่จะมอบหมาย",
    "请选择模板人员!": "กรุณาเลือกบุคลากรแม่แบบ!",
    "赋值成功": "มอบหมายสำเร็จ",
    "赋值失败": "มอบหมายล้มเหลว"
},

    // 个性化
  individuation: {
    fieldName: "ชื่อคอลัมน์",
    visible: "แสดงหรือไม่",
    total: "รวมทั้งหมดหรือไม่",
    locktopic: "ล็อกหรือไม่",
    width: "ความกว้างปัจจุบัน",
    enable: "เปิดใช้งาน",
    disabled: "ปิดใช้งาน"
},
  formSet: {
      languageSet: "เปลี่ยนภาษา",
      hyperlink: "ไฮเปอร์ลิงก์",
      stretch: "ยืด",
      tile: "ปู",
      half: "ยืดครึ่งหนึ่ง",
      third: "ใช้หนึ่งในสาม",
      leftJustify: "ชิดซ้าย",
      rightJustify: "ชิดขวา",
      center: "จัดกึ่งกลาง",
      equal: "เท่ากับ",
      less: "น้อยกว่า",
      lessOrequal: "น้อยกว่าหรือเท่ากับ",
      greater: "มากกว่า",
      greatorequal: "มากกว่าหรือเท่ากับ",
      like: "ชอบทั้งหมด",
      left: "ซ้าย",
      right: "ขวา",
      between: "ระหว่าง",
      database: "ฐานข้อมูล",
      expression: "นิพจน์ที่กำหนดเอง",
      year: "ปี",
      month: "เดือน",
      day: "วัน",
      hour: "ชั่วโมง",
      minute: "นาที",
      second: "วินาที",
      input: "กล่องป้อนข้อมูล",
      DateRange: "กล่องเลือกช่วงวันที่",
      dateInput: "กล่องเลือกวันที่",
      selectOption: "กล่องเลือก",
      switch: "สวิตช์",
      textarea: "พื้นที่ข้อความ",
      numberInput: "กล่องตัวเลข",
      richText: "ข้อความรูปแบบ",
      checkBox: "กล่องเลือกหลายตัว",
      people: "บุคคล",
      citySelect: "กล่องเลือกเมือง",
      selectInput: "กล่องป้อนข้อมูลแบบเลือก",
      permiAll: "สิทธิ์ข้อมูลทั้งหมด",
      permiCustom: "สิทธิ์ข้อมูลที่กำหนดเอง",
      department: "สิทธิ์ข้อมูลของแผนกนี้",
      deptBelow: "สิทธิ์ข้อมูลของแผนกนี้และต่ำกว่า",
      selfOnly: "สิทธิ์ข้อมูลเฉพาะตนเอง"
  },
    // 版本管理
    version: {
    record: "บันทึกการเปลี่ยนแปลง",
    times: "จำนวนครั้งที่เปลี่ยนแปลง",
    updateDate: "วันที่เปลี่ยนแปลง",
    updatePerson: "ผู้เปลี่ยนแปลง",
    mainTableData: "ข้อมูลการเปลี่ยนแปลงของตารางหลัก",
    detailsOldData: "ข้อมูลเดิมของรายละเอียด",
    detailsData: "ข้อมูลการเปลี่ยนแปลงของรายละเอียด",
    oldData: "ข้อมูลเดิม",
    newData: "ข้อมูลหลังการเปลี่ยนแปลง"
},
  
    // 补充的提示语
   addTips: {
    approve: "กำลังอนุมัติ",
    reject: "ปฏิเสธ",
    pass: "ผ่าน",
    noUse: "โมดูลไม่ได้เปิดใช้งาน",
    hasPigeonhole: "เก็บเข้าที่เรียบร้อยแล้ว",
    noPigeonhole: "ยังไม่ได้เก็บเข้าที่",
    all: "ทั้งหมด",
    cancel: "ยกเลิก",
    confirm: "ยืนยัน",
    noSupportpreview: "ไฟล์นี้ไม่รองรับการดูตัวอย่างชั่วคราว",
    fileServiceIsNotDeployed: "บริการดูตัวอย่างไฟล์ยังไม่ได้ปรับใช้",
    isAssign: "ต้องการกำหนดหรือไม่",
    programmeNameRequire: "กรุณากรอกชื่อโครงการ",
    programmeCodeRequire: "กรุณากรอกรหัสโครงการ",
    addPerson: "กรุณาเลือกบุคลากร",
    number: "ที่",
    groupEmpty: "ชื่อกลุ่มต้องไม่ว่างเปล่า",
    approveEmpty: "กรุณาเพิ่มจุดอนุมัติ",
    nodeEmpty: "ข้อมูลจุดต้องไม่ว่างเปล่า",
    tempNameRequire: "กรุณากรอกชื่อแม่แบบ",
    tableNameRequire: "กรุณากรอกชื่อตาราง",
    refreshDatabase: "กรุณารอสักครู่ กำลังรีเฟรชฐานข้อมูล",
    nowusebbs: "ชื่อตารางย่อยต้องไม่ซ้ำกัน",
    tableNameNorepeat: "ชื่อตารางต้องไม่ซ้ำกัน",
    moduleNameNorepeat: "ชื่อแม่แบบต้องไม่ซ้ำกัน",
    done: "ดำเนินการสำเร็จ",
    doneFailed: "ดำเนินการล้มเหลว",
    successZhengshi: "เปลี่ยนเป็นลูกค้าอย่างเป็นทางการเรียบร้อยแล้ว",
    selectZhengshi: "กรุณาเลือกลูกค้าที่ต้องการเปลี่ยนเป็นลูกค้าอย่างเป็นทางการ",
    successGonghai: "เปลี่ยนเป็นลูกค้าทะเลเรียบร้อยแล้ว",
    selectGonghai: "กรุณาเลือกลูกค้าที่ต้องการเปลี่ยนเป็นลูกค้าทะเล",
    selectGiveData: "กรุณาเลือกข้อมูลที่ต้องการส่งต่อ",
    selectClassIs: "คุณเลือกคือ",
    ifContinue: "ยืนยันที่จะดำเนินการต่อหรือไม่",
    addTopClass: "กรุณาเลือกหมวดหมู่หลักก่อน",
    cantclaimed: "ไม่สามารถอ้างสิทธิ์ใบแจ้งหนี้นี้ได้",
    treeBelow: "กรุณาเลือกโครงสร้างต้นไม้ที่อยู่ล่างสุด",
    goodCanNotDelete: "ยังมีสินค้าในจุดนี้ ไม่สามารถลบได้",
    orderNo: "คำสั่งซื้อที่ไม่ได้มีอยู่",
    addoccupy: "กรุณาเลือกรายละเอียดที่ต้องการยึด",
    onlyOneoccupy: "สามารถเลือกได้เพียงรายการเดียว",
    occupyNum: "กรุณากรอกจำนวนที่ยึด",
    warehouseLocation: "ตำแหน่งในคลังสินค้า",
    productName: "ชื่อสินค้า",
    currentInventory: "สต็อกปัจจุบัน",
    purchaseQuantity: "จำนวนที่สั่งซื้อ",
    occupationQuantity: "จำนวนที่ยึด",
    onlyOneTop: "สามารถเลือกได้เพียงตารางหลักอันดับแรกเดียวเท่านั้น",
    comfirmDelet: "ยืนยันที่จะลบหรือไม่?",
    selectRow: "กรุณาเลือกรายการที่ต้องการย้าย",
    onlyOneRowMove: "สามารถเลือกได้เพียงรายการเดียวในการย้าย",
    noEffectRow: "หมายเลขแถวเป้าหมายไม่ถูกต้อง กรุณากรอกใหม่",
    noMaxLine: "ไม่สามารถเกินหมายเลขแถวสูงสุดได้",
    fetchNoMove: "ข้อมูลรายละเอียดถูกเรียกใช้งานแล้ว ไม่สามารถลบได้",
    checkError: "ตรวจสอบความถูกต้องของรายละเอียดผิดพลาด",
    nodeNoGood: "ยังไม่มีสินค้าในจุดนี้",
    languageNoRepeat: "ภาษาได้ถูกเพิ่มแล้ว กรุณาอย่าเพิ่มซ้ำ!",
    printMessage: "ไม่สามารถดูตัวอย่างการพิมพ์ในเบราว์เซอร์ IE ได้ กรุณาใช้เบราว์เซอร์อื่น",
    tableNoData: "ไม่พบการตั้งค่ากระบวนการสำหรับโมดูลนี้ กรุณาตั้งค่าและลองใหม่อีกครั้ง!",
    subassembly: "ส่วนประกอบย่อย:"
},
    //登录
    loginPage: {
    login: "เข้าสู่ระบบ",
    welCome: "ยินดีต้อนรับเข้าสู่ระบบ",
    password: "รหัสผ่าน",
    account: "บัญชี"
},
   GlobalConfig: {
    duplicationCheck: "ตั้งค่าการตรวจสอบความซ้ำกัน",
    CustomerSwitch: "สลับลูกค้า",
    SupplierSwitch: "สลับซัพพลายเออร์",
    GoodsSwitch: "สลับสินค้า",
    FeedbackConfiguration: "การกำหนดค่าพื้นฐาน",
    projectName: "ชื่อโครงการ",
    projectContact: "หมายเลขสัญญาโครงการ",
    version: "เลขรุ่น",
    systemCallbackAddress: "ที่อยู่การคืนโซ่ระบบ",
    wincareAddress: "ที่อยู่ Wincare",
    projectNamePlace: "กรุณากรอกชื่อโครงการ",
    projectContactPlace: "กรุณากรอกหมายเลขสัญญาโครงการ",
    wincareAddressPlace: "กรุณากรอกที่อยู่ระบบคืน Wincare"
},
    // 消息中心
   messageCenter: {
    addNewMessage: "เพิ่มข้อความใหม่",
    editNewMessage: "แก้ไขข้อความ",
    messageTitle: "หัวข้อข้อความ",
    modelName: "ชื่อโมดูล",
    messageContent: "เนื้อหาข้อความ",
    remark: "หมายเหตุ",
    receiveBy: "ผู้รับ",
    sendBy: "ผู้ส่ง",
    createTime: "เวลาที่สร้าง",
    isRead: "อ่านแล้วหรือยัง"
},
   placeHolder: {
    expression: "สูตรคำนวณ",
    selectLanguage: "กรุณาเลือกภาษา",
    forTrackPlaceHolder: "กรุณาใส่หมายเลขอ้างอิง",
    start: "เริ่มต้น",
    end: "สิ้นสุด",
    to: "ถึง",
    startDate: "วันที่เริ่มต้น",
    endDate: "วันที่สิ้นสุด",
    selectDate: "เลือกวันที่",
    startTime: "เวลาเริ่มต้น",
    endTime: "เวลาสิ้นสุด",
    pleaseFill: "กรุณากรอก",
    pleaseSelect: "กรุณาเลือก",
    Financialrecognition: "กรุณาเลือกการยืนยันทางการเงิน",
    onWeek: "เกือบหนึ่งสัปดาห์ที่ผ่านมา",
    onMonth: "เดือนที่ผ่านมา",
    threeMonth: "สามเดือนที่ผ่านมา",
    contractNumber: "หมายเลขสัญญาสำหรับการส่งออก"
},
    // 导航栏上方的操作栏
    user: {
    quit: 'ออกจากระบบ',
    noticeCenter: "ศูนย์ข้อความ",
    preWarning: 'การเตือนล่วงหน้า',
    statistical: "รายงานสถิติ",
    noticeAlert: "การแจ้งเตือนข้อความ",
    searchMenu: 'เมนูข้อความ'
},
    // 首页设置
    Home: {
    ToBeapproved: 'เอกสารรอการอนุมัติ',
    realTimeData: 'ข้อมูลแบบเรียลไทม์',
    recents: 'การเข้าชมล่าสุด'
  },
  listOfSet: {
    operation: 'การดำเนินการ',
    batchExport: 'ส่งออกเป็นชุด',
    ponoFieldExist: 'ไม่มีฟิลด์ PONO',
    attachmentManagement: 'การจัดการไฟล์แนบ',
    enableOcr: 'เปิดใช้งาน OCR',
    add: 'เพิ่ม',
    clearCatch: 'ล้างแคช',
    loadField: 'โหลดฟิลด์',
    copyModule: 'คัดลอกโมดูล',
    search: "ค้นหา",
    reset: "รีเซ็ต",
    edit: 'แก้ไข',
    delete: 'ลบ',
    colWidth: 'ความกว้างคอลัมน์',
    moduleName: 'ชื่อโมดูล',
    mainTable: 'ตารางหลัก',
    foreignKey: 'คีย์ต่างประเทศ',
    listSql: 'SQL รายการ',
    isImage: 'ภาพ',
    imageColumn: 'คอลัมน์ภาพ',
    storedProcedure: 'ประมวลผลเก็บเข้า',
    isReadOnly: 'เฉพาะการดู',
    isInlineEditable: 'แก้ไขในบรรทัด',
    isUpdateKeyOnImport: 'นำเข้าการอัปเดต',
    isCheckFno: 'ตรวจสอบ FNO ที่ไม่ซ้ำ',
    parentModule: 'โมดูลหลัก',
    isPage: 'หน้า',
    contactSql: 'SQL ผู้ติดต่อ',
    listStoredProcedure: 'การเก็บเข้ารายการ',
    checkStoredProcedure: 'ตรวจสอบการเก็บเข้าระบบ',
    isShowList: 'แสดงรายการ',
    tableImgShow: 'แสดงภาพตาราง',
    formImgShow: 'แสดงรูปแบบ',
    isImport: 'นำเข้า',
    isExport: 'ส่งออก',
    isSendEmail: 'ส่งอีเมล',
    isUploadFile: 'อัปโหลดไฟล์',
    isBatchModify: 'แก้ไขเป็นกลุ่ม',
    isAboutTypography: 'การจัดวางซ้ายขวา',
    isGlobalRetrieval: 'การค้นหาทั่วโลก',
    tableFieldSet: 'ตั้งค่าฟิลด์ตาราง',
    formFieldSet: 'ตั้งค่าฟิลด์แบบฟอร์ม',
    codeEventSet: 'ตั้งค่าเหตุการณ์โค้ด',
    languageSet: 'ตั้งค่าภาษา',
    sheetSet: 'ตั้งค่าหน้ากระดาษ',
    isDbClkJump: 'คลิกสองครั้งเพื่อเข้าสู่รายละเอียด',
    isShowSubtotal: 'แสดงรวมย่อย',
    showTheApprovalTab: 'แสดงแท็บการอนุมัติ',
    passProcName: 'ชื่อประมวลผลการผ่าน',
    rejectProcName: 'ชื่อประมวลผลการปฏิเสธ',
    pageSize: 'จำนวนที่แสดงในแต่ละหน้า',
    addSuccess: 'เพิ่มสำเร็จ',
    editSuccss: 'แก้ไขสำเร็จ',

    /* การตั้งค่าฟิลด์ */
    fieldShowOrder: 'ลำดับการแสดงฟิลด์',
    databaseFieldName: 'ชื่อฟิลด์ฐานข้อมูล',
    showName: 'ชื่อที่แสดงด้านหน้า',
    dataType: 'ประเภทข้อมูล',
    alignType: "การจัดวาง",
    queryOperator: "ตัวดำเนินการค้นหา",
    isSelect: 'เลือก',
    isLocked: "ล็อก",
    isGlobalQuery: 'การค้นหาทั่วโลก',
    isSum: 'รวม',
    isShow: 'แสดง',
    dataDict: 'พจนานุกรมข้อมูล',
    datePrecision: "ความละเอียดของวันที่",
    language: 'ตั้งค่าภาษา',
    databaseFields: 'ฟิลด์ฐานข้อมูล',
    configFormula: 'ตัวแก้ไขสูตร',
    initialization: 'เริ่มต้นโมดูล',
    initLanguage: 'เริ่มต้นภาษา',

    // การตั้งค่าการเข้ารหัส
    save: 'บันทึก',
    year: 'ปี (2 ตัวอักษร)',
    years: 'ปี (4 ตัวอักษร)',
    month: 'เดือน',
    date: 'วัน',
    fixedContent1: 'เนื้อหาคงที่ 1',
    fixedContent2: 'เนื้อหาคงที่ 2',
    serialNumber: 'เลขลำดับ',
    loginId: 'รหัสผู้ใช้เข้าสู่ระบบ',
    serialNoDigit: 'จำนวนหลักของเลขลำดับ',
    serialNoUt: 'หน่วยของหมายเลขอ้างอิง',
    pattern: 'รูปแบบ',
    formField1: 'ฟิลด์แบบฟอร์ม 1',
    formField2: 'ฟิลด์แบบฟอร์ม 2',
    formField3: 'ฟิลด์แบบฟอร์ม 3',
    formField4: 'ฟิลด์แบบฟอร์ม 4',
    formField5: 'ฟิลด์แบบฟอร์ม 5',
    smallSerialStart: 'เลขเริ่มต้นของลำดับเล็ก',
    bigSerial: 'เลขลำดับใหญ่',
    resetSuccess: 'รีเซ็ตเรียบร้อย',
    soon: 'เร็ว ๆ นี้',
    strip: 'แถว'
},
  
   FormOfSet: {
    uploadDrag: "ลากและวางไฟล์ที่นี่ หรือ",
    clickUpload: 'คลิกเพื่ออัปโหลด',
    uploadSize: 'ขนาดไฟล์ต้องไม่เกิน',

    add: 'เพิ่ม',
    fieldOrder: 'ลำดับการแสดงฟิลด์',
    name: 'ชื่อ',
    showName: 'ชื่อที่แสดง',
    defaultValue: 'ค่าเริ่มต้น',
    expressionDecimalDigit: 'จำนวนหลักทศนิยม',
    dataType: 'ประเภทข้อมูล',
    editType: 'ประเภทการแก้ไข',
    formConfigId: 'รหัสการกำหนดค่าฟอร์ม',
    isMustFill: 'จำเป็นต้องกรอก',
    approveParam: 'ฟิลด์การส่งอนุมัติ',
    approveChange: 'ฟิลด์การเปลี่ยนแปลงอนุมัติ',
    combinedField: 'ฟิลด์ที่รวม',
    majorKey: 'คีย์หลัก',
    isShow: 'แสดง',
    isReadOnly: 'อ่านเท่านั้น',
    dataDict: 'พจนานุกรมข้อมูล',
    remark: 'หมายเหตุ',
    sheet: 'หน้ากระดาษที่เกี่ยวข้อง',
    expression: 'สูตรคำนวณที่กำหนดเอง',
    clauseTemplete: 'แม่แบบข้อตกลง',
    widthType: 'ประเภทความกว้าง'
},
    // 配置模块
   configMoudle: {
  filesManage: 'การจัดการไฟล์แนบ',
  tip: 'เคล็ดลับ',
  ocr: 'การจดจำตัวหนังสือ OCR',
  tableExportName: 'ข้อมูลตาราง',
  timedifference: 'ช่วงเวลา',
  nodeFinish: 'ฟิลด์เวลาที่โหนดเสร็จสิ้น',
  
  nodeFlow: 'กระแสโหนด',
  waterKey: 'คีย์น้ำเล็ก',
  topLeval: 'ระดับบน',
  
  operator: 'เลือกตัวดำเนินการ',
  function: 'เลือกฟังก์ชัน',
  selectfieldName: 'เลือกชื่อฟิลด์',
  
  clear: 'เคลียร์',
  fieldType: 'ประเภทฟิลด์',
  resultField: 'ฟิลด์ผลลัพธ์',
  
  formulaEditor: 'ตัวแก้ไขสูตร',
  fieldDigit: 'จำนวนทศนิยม',
  
  fieldLength: 'ความยาวของฟิลด์',
  characteristic: 'ลักษณะเฉพาะ',
  dbname: 'ชื่อฐานข้อมูล',
  fieldName: 'ชื่อฟิลด์',
  catalogue: 'แคตตาล็อก',
  menu: 'เมนู',
  button: 'ปุ่ม',
  
  male: 'ชาย',
  female: 'หญิง',
  normal: 'ปกติ',
  diabled: 'ปิดการใช้งาน',
  signOrNot: 'ลงลายเซ็นหรือไม่',
  tableName: 'ชื่อตาราง',
  bulkEdit: 'แก้ไขเป็นชุด',
  portDetails: 'รายละเอียดพอร์ต',
  totalAll: 'รวมทั้งหมด',
  noData: 'ไม่มีข้อมูล',
  multipleChoice: 'เลือกเพียงหนึ่ง',
  saveFirst: 'บันทึกก่อน',
  uploadTypeTip: 'อัปโหลดรูปภาพได้เฉพาะรูป JPG หรือ PNG เท่านั้น!',
  
  uploadSuccess: 'อัปโหลดสำเร็จ',
  uploadFail: 'อัปโหลดล้มเหลว',
  ForTrackRemark: 'หมายเหตุ: โปรดระบุโมดูลและหมายเลขสั่งซื้อที่เกี่ยวข้อง หลังจากกรอกเสร็จโปรดคลิกค้นหา',
  searchForTrack: 'ค้นหาหมายเลขสั่งซื้อ',
  
  addFile: 'เพิ่มไฟล์แนบ',
  updateFile: 'อัปเดตไฟล์แนบ',
  
  moveLineNum: 'หมายเลขบรรทัดปลายทาง',
  destLineNum: 'หมายเลขบรรทัดปลายทาง',
  selctRowTip: 'กรุณาเลือกแถวที่ต้องการแก้ไข',
  selctRowTipDelet: 'กรุณาเลือกแถวที่ต้องการลบ',
  
  getTemplate: 'ดึงแม่แบบ',
  details: 'รายละเอียด',
  forModel: 'สำหรับโมเดล',
  forTrack: 'สำหรับหมายเลขสั่งซื้อ',
  filesManage: 'การจัดการไฟล์แนบ',
  serialNumber: 'ลำดับที่',
  
  filesLoading: 'กำลังโหลดไฟล์',
  noConfigTotal: 'คุณยังไม่ได้กำหนดการรวมยอด',
  noGroup: 'โปรดเลือกเงื่อนไขการจัดกลุ่ม',
  noTotalField: 'โปรดเลือกฟิลด์ยอดรวม',
  Financialrecognition: 'การยืนยันทางการเงิน',
  FinancialCancle: 'ยกเลิกการยืนยันทางการเงิน',
  
  noRepeat: 'ไม่สามารถทำซ้ำได้',
  confirmRecall: 'คุณต้องการถอนข้อมูลปัจจุบันหรือไม่',
  recallSucces: 'ถอนข้อมูลสำเร็จ',
  CategoryNum: 'หมายเลขหมวดหมู่ใหญ่',
  shareSuccess: 'แชร์สำเร็จ',
  imageUpload: 'อัปโหลดภาพ',
  selectFillter: 'กรอกคำหลักเพื่อกรอง',
  inventoryNumber: 'หมายเลขใบสั่งของ',
  selectYes: "ใช่",
  selectNo: 'ไม่ใช่',
  confirmDelete: 'คุณต้องการลบข้อมูลนี้หรือไม่?',
  transferOrder: 'โอนคำสั่ง',
  selectTransfer: 'กรุณาเลือกข้อมูลที่จะโอน',
  selectPrint: 'กรุณาเลือกข้อมูลที่ต้องการพิมพ์',
  saveDefaultTemplate: 'บันทึกแม่แบบเริ่มต้น',
  highSeasCustomers: 'ลูกค้าในทะเลหลวง',
  customerMemorabilia: 'เหตุการณ์สำคัญของลูกค้า',
  formalCustomers: 'ลูกค้าท่านอื่น',
  sendEmail: 'ส่งอีเมล',
  excelCopy: 'คัดลอก Excel',
  claim: 'เรียกร้อง',
  add: 'เพิ่ม',
  copyTokey: 'คัดลอกไปยังตาราง',
  copyToform: 'คัดลอกไปยังแบบฟอร์ม',
  edit: 'แก้ไข',
  delete: 'ลบ',
  bulkEdit: 'แก้ไขเป็นชุด',
  content: 'กรุณาใส่เนื้อหา',
  selectDate: 'เลือกวันที่',
  select: 'กรุณาเลือก',
  commodityTypeAdmin: 'การจัดการประเภทสินค้า',
  parentType: 'ประเภทหลัก',
  TypeName: 'ชื่อประเภท',
  clickUpload: 'คลิกเพื่ออัปโหลด',
  fileView: 'ดูไฟล์',
  Viewflie: 'ดู',
  save: 'บันทึก',
  printFormat: 'รูปแบบการพิมพ์',
  headSeal: 'ตราหัว',
  cancel: 'ยกเลิก',
  print: 'พิมพ์',
  more: "มากกว่า",
  share: 'แชร์',
  placeOnFile: 'วางไว้ในไฟล์',
  personnel: 'การเลือกบุคคล',
  personName: 'บุคคล',
  classChange: 'การเปลี่ยนแปลงชนิด',
  uploadPicture: "อัปโหลดรูปภาพ",
  billsPrint: "พิมพ์ใบเสร็จ",
  batchPrinting: 'พิมพ์เป็นชุด',
  uploadPictures: "อัปโหลดรูปภาพเป็นชุด",
  
  globalQuery: 'ค้นหาทั่',
   modelName: 'ชื่อแม่แบบ',
search: 'ค้นหา',
img: 'รูปภาพ',
tip1: 'กรุณากรอกชื่อประเภท',
tip2: 'แก้ไขสำเร็จ',
tip3: 'เพิ่มสำเร็จ',
tip4: 'โปรดเลือกอย่างใดอย่างหนึ่ง',
tip5: 'คุณแน่ใจหรือไม่ว่าต้องการลบประเภทนี้และประเภทย่อย?',
tip: 'เคล็ดลับ',
confirm: 'ยืนยัน',
cancel: 'ยกเลิก',
tip6: 'ลบสำเร็จ',
refresh: 'รีเฟรช',
close: 'ปิด',
closeLeft: 'ปิดด้านซ้าย',
closeRight: 'ปิดด้านขวา',
closeOther: 'ปิดอื่น ๆ',
closeAll: 'ปิดทั้งหมด',

view: 'ดู',
versionView: 'ดูเวอร์ชัน',
tip7: 'สามารถอัปโหลดไฟล์ Excel เท่านั้น',
tip8: 'คุณไม่ใช่ผู้สร้างเอกสารนี้',
tip9: 'เอกสารนี้กำลังอยู่ในขบวนการอนุมัติหรือได้รับการอนุมัติแล้ว',
tip10: 'คุณไม่ได้เลือกแถว',
tip11: 'โปรดอย่าเลือกแถวที่ได้รับการอนุมัติหรือกำลังอยู่ระหว่างการอนุมัติ',
tip12: 'โปรดเลือกแถวก่อนที่จะแก้ไข',
tip13: 'โปรดเลือกแถวก่อนที่จะพิมพ์',
tip14: 'โปรดเลือกรูปแบบการออกรายงาน!',
tip15: 'โปรดเลือกชื่อโมดูล!',
tip16: 'พิมพ์สำเร็จ',
tip17: 'ดาวน์โหลดสำเร็จ',

approvalHistory: 'ประวัติการอนุมัติ',
purchaseContract: 'หมายเลขสัญญาจ้างซื้อ',
supplierName: 'ชื่อผู้ผลิตหรือจัดจำหน่าย',
fileName: 'ชื่อไฟล์แนบ',
filePath: 'เส้นทางไฟล์แนบ',
fileType: 'ประเภทไฟล์แนบ',

compilingAndbrushingLibraries: 'คอมไพล์และแปรปรวนไลบรารี',
moduleName: 'ชื่อโมดูล',
mainTable: 'ชื่อตารางหลัก',
subTableName: 'ชื่อตารางย่อย',
subTable: 'ชื่อตารางย่อย',
aboutMainTable: 'เกี่ยวกับตารางหลัก',
createBy: 'สร้างโดย',

// รายละเอียด
fetch: 'เรียก',
approval: 'อนุมัติ',
reject: 'ปฏิเสธ',
approvalOption: 'ความคิดเห็นในการอนุมัติ',
sendEmail: 'ส่งอีเมล',
Examination: 'การตรวจสอบ',
ExaminationSave: 'บันทึกการตรวจสอบ',

AnnextopurchaseContract: 'ไฟล์แนบสัญญาจ้างซื้อ',
Appendixtoexportcontract: 'ไฟล์แนบสัญญาการส่งออก',
uploadFile: 'อัปโหลดไฟล์แนบ',
report: 'รายงาน',
includeFetchSql: 'รวมการเรียก',
includeSqlFinish: 'รวมข้อมูลที่เสร็จสมบูรณ์แล้ว',
viewLargeImg: 'ดูรูปภาพขนาดใหญ่',
save: 'บันทึก',
status: 'สถานะ',
copy: 'คัดลอก',
topcopy: 'ย้ายขึ้น',
bottomcopy: 'ย้ายลง',
moveRow: 'ย้ายหมายเลขแถว',
update: 'บันทึก',
submit: 'ส่ง',
recall: 'ถอนคำขอ',
list: 'รายการ',
copyInvoice: 'คัดลอกใบแจ้งหนี้',
// tip17: 'กรุณาเลือกเนื้อหาก่อนคลิกที่ตกลง',
tip18: 'หน้าเว็บได้รีเฟรชแล้ว โปรดเข้าสู่หน้านี้อีกครั้งเพื่อดำเนินการนี้!',
subTotalSet: 'ตั้งค่ายอดรวมย่อย',
openSubtotal: 'เปิดยอดรวมย่อย',
closeSubtotal: 'ปิดยอดรวมย่อย',
groupCondition: 'เงื่อนไขกลุ่ม',
subtotalField: 'ฟิลด์ยอดรวมย่อย',
notSelected: 'ยังไม่ได้เลือก',
selected: 'เลือกแล้ว',
goLeft: 'ไปทางซ้าย',
goRight: 'ไปทางขวา',
noFno: 'ไม่พบหมายเลขสัญญาจ้างซื้อนี้ ไม่สามารถข้ามไปได้',

// Y-common
linenum: 'หมายเลขบรรทัด',
batchDelete: 'ลบเป็นชุด',
replaceLineNumber: 'หมายเลขบรรทัดปลายทาง',
tip19: 'หมายเลขบรรทัดที่ป้อนไม่ถูกต้อง',
tip20: 'อยู่ในสถานะการอนุมัติ...',
tip21: 'โปรดเลือกข้อมูลที่ต้องการลบ',
tip22: 'สามารถเลือกได้เพียงหนึ่งรายการเพื่อเปลี่ยนแถว',
tip23: 'โปรดเลือกข้อมูลที่ต้องการแชร์',
tip24: 'โปรดเลือกข้อมูลที่ต้องการเก็บเอกสารหรือยกเลิกการเก็บเอกสาร',
tip25: 'โปรดเลือกบุคคลที่ต้องการแชร์',
tip26: 'คุณต้องการยกเลิกการเก็บเอกสารหรือไม่?',
tip27: 'ยกเลิกการเก็บเอกสารสำเร็จ',
tip28: 'เก็บเอกสารสำเร็จ',
tipQuit: 'คุณแน่ใจหรือไม่ที่จะออก?',
sureToSave: 'หากคุณบันทึกข้อมูลแล',
     cancelFilter: 'ยกเลิกการคัดกรอง',

SalesContract: 'สัญญาการขาย',
PurchaseContract: 'สัญญาจ้างซื้อ',
alteration: 'การเปลี่ยนแปลง',
cancelAlteration: 'ยกเลิกการเปลี่ยนแปลง',
      
    },
  
  
    // 列表设置-----语言设置
   LanguageSet: {
  languageRequired: "ภาษาต้องไม่เว้นว่าง!",
  contentRequired: "เนื้อหาต้องไม่เว้นว่าง",
  add: "เพิ่ม",
  edit: 'แก้ไข',
  language: 'ภาษา',
  content: 'เนื้อหา',
  operation: 'การดำเนินการ',
  simplifiedChinese: "จีนตัวย่อ",
  confirm: "ยืนยัน",
  cancel: "ยกเลิก"
},
  
    //列表设置-- sheet页设置
   SheetSetting: {
      name: 'ชื่อ',
      AccordingToTheOrder: 'แสดงตามลำดับ',
      add: 'เพิ่ม'
    },
  
    // 调取设置
    FetchSet: {
      sequence: 'ลำดับ',
      add: 'เพิ่ม',
      name: 'ชื่อ',
      targetModuleId: 'รหัสโมดูลเป้าหมาย',
      parentModuleId: 'รหัสโมดูลหลัก',
      targetField: 'ฟิลด์เป้าหมาย',
      colWidth: 'ความกว้างคอลัมน์',
      isPaged: 'แบ่งหน้า',
      fetchSql: 'คำสั่ง SQL สำหรับดึงข้อมูล',
      isShowImage: 'แสดงรูปภาพ',
      showColumns: 'แสดงในคอลัมน์ที่',
      isRetrieval: 'เปิดใช้งานการดึงข้อมูล',
      containsCalledSQL: 'ประกอบด้วย SQL ที่ถูกเรียกแล้ว',
      edit: 'แก้ไข',
      delete: 'ลบ',
      languageSetting: 'ตั้งค่าภาษา',
      tableFiledSetting: 'ตั้งค่าฟิลด์ในตาราง',
      copySetting: 'คัดลอก',
      fetchWarinning: 'กรุณากรอกคำสั่ง SQL สำหรับดึงข้อมูล!',
      cancel: 'ยกเลิก',
      confirm: 'ยืนยัน'
    },
  
    // 调取设置 ---- 调取字段设置
    FetchKeySet: {
      add: 'เพิ่ม',
      fieldShowOrder: 'ลำดับการแสดงฟิลด์',
      databaseFieldName: 'ชื่อฟิลด์ในฐานข้อมูล',
      showName: 'ชื่อที่แสดงบนหน้าจอ',
      dataType: 'ประเภทข้อมูล',
      alignType: "ประเภทการจัดวาง",
      isSelect: 'การค้นหา',
      queryOperator: "ตัวดำเนินการค้นหา",
      isSortable: 'การเรียงลำดับ',
      isFetched: "การดึงข้อมูล",
      isLocked: "ล็อก",
      targetField: "ฟิลด์เป้าหมาย",
      isShow: "แสดง",
      datePrecision: "ความแม่นยำของวันที่",
    },
  
    // 首页设置
   homeSet: {
  homeSet: "การตั้งค่าหน้าหลัก",
  roleNamePlaceHolder: 'โปรดใส่ชื่อบทบาท',
  warningSet: 'การตั้งค่าการเตือนล่วงหน้า',
  reportSet: 'การตั้งค่ารายงาน',
  earlyWarning: 'การเตือนล่วงหน้า',
  title: 'หัวข้อ',
  router: 'เส้นทาง',
  sql: 'SQL',
  languageSetting: 'การตั้งค่าภาษา',
  save: 'บันทึก',
  select: 'โปรดเลือก',
  inputPlaceHolder: 'โปรดใส่เนื้อหา',
  report: 'รายงาน',
  add: 'เพิ่ม',
  edit: 'แก้ไข',
  submitTip: 'ข้อมูลที่ต้องส่งห้ามเป็นค่าว่าง',
  saveTip: 'บันทึกสำเร็จ',
  completeTip: 'โปรดกรอกข้อมูลให้ครบถ้วนและบันทึกก่อนตั้งค่าภาษา',
  saveFailed: 'บันทึกไม่สำเร็จ',
  qrSetting: 'การตั้งค่ารหัส QR',
  qrUpload: 'อัปโหลดรหัส QR จากแอป',
  qrEdit: 'แก้ไขข้อมูลรหัส QR',
  qrPlaceholder: 'โปรดกรอกข้อมูลที่ต้องการแก้ไข',
  qrTips: 'โปรดอัปโหลดรูปภาพก่อน',
  basicInfor: "ข้อมูลพื้นฐาน",
  fontConfig: "การตั้งค่าแบบอักษร",
  passWordConfig: 'การตั้งค่ารหัสผ่าน',
  languageConfig: "การตั้งค่าภาษา",
  fontSize: "การตั้งค่าขนาดอักษร",
  fontFamily: 'การตั้งค่าประเภทแบบอักษร',
  oldPassword: 'รหัสผ่านเดิม',
  newPassword: 'รหัสผ่านใหม่',
  languageSet: 'การตั้งค่าภาษา',
  defaultFont: 'ค่าเริ่มต้น',
  smallFont: 'ขนาดเล็ก',
  largeFont: 'ขนาดใหญ่',
  defultFont: 'ค่าเริ่มต้น',
  song: '宋体',
  kai: "楷体",
  yuan: '圆体',
  shijian: '诗简体',
  logoSetting: 'การอัปโหลดภาพโลโก้',
  companySetting: 'ชื่อบริษัท',
  loginBackgroundSetting: 'ภาพพื้นหลังการเข้าสู่ระบบ',
},
  
    // 报表权限设置
   ReportSet: {
      jimuReportPermissionSetting: 'การตั้งค่าสิทธิ์รายงานจีมู',
      roleNamePlaceHolder: 'โปรดใส่ชื่อบทบาท',
      save: 'บันทึก',
      code: 'รหัส',
      reportName: 'ชื่อรายงาน',
      createTime: 'เวลาสร้าง',
      warningTip: 'โปรดเลือกอย่างน้อยหนึ่งรายการ',
      saveTip: 'บันทึกสำเร็จ',
      add: 'เพิ่ม',
      edit: 'แก้ไข',
    },
  
    // 字典设置
    DictSet: {
  searchButton: 'ค้นหา',
  resetButton: 'รีเซ็ต',
  add: 'เพิ่ม',
  edit: 'แก้ไข',
  delete: 'ลบ',
  dictName: 'ชื่อพจนานุกรม',
  isForbidden: 'ปิดใช้งาน',
  enabled: 'เปิดใช้งาน',
  disAbled: "ปิดใช้งาน",
  remark: 'หมายเหตุ',
  dataItemSetting: 'ตั้งค่ารายการข้อมูล',
  name: 'ชื่อ',
  alias: 'ชื่อเล่น',
  sortOrder: 'ลำดับการเรียง',
  desc: 'คำอธิบาย',
  dataPermission: 'สิทธิ์ข้อมูล',
},
  
    // 菜单管理
   menuSet: {
  shangpinku: 'เรียกดูคลังสินค้า',
  kucun: 'เรียกดูสินค้าคงคลัง',
  yushou: 'การแบ่งปันเงินมัดจำล่วงหน้า',
  permisVies: "มุมมองสิทธิ์",
  menuName: 'ชื่อเมนู',
  search: 'ค้นหา',
  reset: 'รีเซ็ต',
  add: 'เพิ่ม',
  edit: 'แก้ไข',
  delete: 'ลบ',
  icon: 'ไอคอน',
  type: 'ประเภท',
  orderNum: 'ลำดับการแสดง',
  permsCode: 'รหัสสิทธิ์',
  remark: 'หมายเหตุ',
  routingPath: 'เส้นทางการเดินทาง',
  menuStatus: 'สถานะเมนู',
  addMenu: 'เพิ่มเมนู',
  menuType: 'ประเภทเมนู',
  menuIcon: 'ไอคอนเมนู (สีเดียว)',
  menuIconColor: 'ไอคอนเมนู (หลากสี)',
  menuVisable: 'เมนูที่มองเห็นได้',
  menuOrder: 'ลำดับเมนู',
  parentCatalogue: 'ไดเรกทอรีหลัก',
  associatedModule: 'โมดูลที่เกี่ยวข้อง',
  buttonName: 'ชื่อปุ่ม',
  language: 'ตั้งค่าภาษา',
  action: 'การกระทำ'
},
  
    // 用户管理
   userAdmin: {
  deptPlace: 'กรุณากรอกชื่อแผนก',
  userName: 'ชื่อผู้ใช้',
  password: 'รหัสผ่าน',
  loginAccount: 'บัญชีเข้าสู่ระบบ',
  phoneNumber: 'หมายเลขโทรศัพท์',
  status: 'สถานะ',
  createTime: 'เวลาที่สร้าง',
  search: 'ค้นหา',
  reset: 'รีเซ็ต',
  add: 'เพิ่ม',
  passOn: 'โอน',
  passOnSuccess: 'โอนสำเร็จ',
  passOnPerson: 'ผู้รับโอน',
  passOnMoudle: 'โมดูลที่โอน',
  
  deptName: 'ชื่อแผนก',
  nickName: 'ชื่อเล่น',
  englishName: 'ชื่อภาษาอังกฤษ',
  phone: 'หมายเลขโทรศัพท์',
  email: 'อีเมลผู้ใช้',
  roleName: 'บทบาท',
  sex: 'เพศ',
  postId: 'รหัสตำแหน่ง',
  avatar: 'รูปโปรไฟล์',
  deptId: 'รหัสแผนก',
  password: 'รหัสผ่าน',
  remark: 'หมายเหตุ',
  dept: 'แผนก',
  edit: 'แก้ไข',
  delete: 'ลบ',
  tip1: 'กรุณาเลือกรายการเพื่อแก้ไข',
  loadingtip: 'กำลังโหลด โปรดรอ...',
  tip2: 'กรุณาเลือกรายการเพื่อลบ',
  tip3: 'ยืนยันการลบหรือไม่',
  tip: 'เรียน',
  confirm: 'ตกลง',
  cancel: 'ยกเลิก',
  deleteSuccess: 'ลบสำเร็จ!',
  editSuccess: 'แก้ไขสำเร็จ',
  noEmpty: 'ต้องไม่เป็นค่าว่าง',
  limitOfAuthority: 'สิทธิ์',
  synchronousAssignmentGroup: 'การจัดกลุ่มการมอบหมายซิงโครนัส',
},
    // 用户管理 --- 编辑用户
    editUserAdmin: {
  save: 'บันทึก',
  sync: 'ซิงค์',
  add: 'เพิ่ม',
  userInfo: 'ข้อมูลผู้ใช้',
  nickName: 'ชื่อเล่น',
  englishName: 'ชื่อภาษาอังกฤษ',
  password: 'รหัสผ่าน',
  phoneNumber: 'หมายเลขโทรศัพท์',
  remark: 'หมายเหตุ',
  loginAccount: 'บัญชีเข้าสู่ระบบ',
  userEmail: 'อีเมลผู้ใช้',
  postId: 'รหัสตำแหน่ง',
  dept: 'แผนก',
  roleName: 'บทบาท',
  superiorLeaders: 'ผู้นำหัวหน้า',
  sex: 'เพศ',
  status: 'สถานะ',
  emailConfig: 'การกำหนดค่าอีเมล',
  dataPermission: 'สิทธิ์การข้อมูล',
  modelName: 'ชื่อโมเดล',
  groupName: 'ชื่อกลุ่ม',
  groupCode: 'รหัสกลุ่ม',
  edit: 'แก้ไข',
  delete: 'ลบ',
  loadingtip: 'กำลังโหลด โปรดรอ...',
  saveSuccess: 'บันทึกสำเร็จ',
  emailUserName: 'ชื่อผู้ใช้อีเมล',
  emailPassword: 'รหัสผ่านอีเมล',
  smtpHost: 'โฮสต์ SMTP',
  smtpPort: 'พอร์ต SMTP',
  signature: 'ลายเซ็น',
  ownPhone: 'หมายเลขโทรศัพท์ส่วนตัว',
  qq: 'QQ',
  weChat: 'WeChat',
  platformName: 'ชื่อแพลตฟอร์ม',
  idcard: 'หมายเลขบัตรประชาชน',
  isEmailManage: 'เป็นผู้ดูแลอีเมลหรือไม่',
  noPicture: 'ไม่มีรูปภาพ'
},
  
  
  
    // 在线用户
   userOnline: {
  userName: 'ชื่อผู้ใช้',
  name: 'ชื่อภาษาจีน',
  search: 'ค้นหา',
  reset: 'รีเซ็ต',
  kickOut: 'เตะออก',
  email: 'อีเมล',
  phone: 'โทรศัพท์',
  sex: 'เพศ',
  loginConputerIp: 'IP เครื่องคอมพิวเตอร์ที่เข้าสู่ระบบ',
  loginTime: 'เวลาเข้าสู่ระบบ',
  nowTime: 'เวลาที่เข้าสู่ระบบ',
  status: 'สถานะ',
  remark: 'หมายเหตุ',
  tip1: 'ยืนยันที่จะเตะผู้ใช้นี้หรือไม่?',
  tip: 'เคล็ดลับ',
  confirm: 'ยืนยัน',
  cancel: 'ยกเลิก',
  tip2: 'กรุณาเลือกผู้ใช้ก่อนที่จะเตะ!',
  tip3: 'เตะผู้ใช้แล้ว'
},
  
  
    // 角色管理
    roleAdmin: {
  addRole: 'เพิ่มบทบาท',
  cancleRole: "ยกเลิกบทบาท",
  tip1: 'คุณแน่ใจหรือไม่ที่จะลบบทบาทที่เลือก?',
  menuAllot: 'การจัดสรรเมนู',
  roleName: 'ชื่อบทบาท',
  status: 'สถานะ',
  search: 'ค้นหา',
  reset: 'รีเซ็ต',
  add: 'เพิ่ม',
  save: 'บันทึก',
  showSort: 'ลำดับการแสดง',
  roleStatus: 'สถานะบทบาท',
  permissionChar: 'สิทธิ์ตัวละคร',
  permissionScope: 'ขอบเขตสิทธิ์',
  dataPermission: 'สิทธิ์ข้อมูล',
  dataScope: 'ขอบเขตข้อมูล',
  remark: 'หมายเหตุ',
  edit: 'แก้ไข',
  delete: 'ลบ',
  tip2: 'ต้องไม่ว่างเปล่า',
  tip3: 'กรุณาเลือกก่อนที่จะลบ',
  tip4: 'กรุณาเลือกเมนูที่ต้องการแก้ไขการจัดสรร',
  tip5: 'บันทึกสำเร็จ'
},
  
    // 部门管理
   deptAdmin: {
  deptName: 'ชื่อแผนก',
  status: 'สถานะ',
  search: 'ค้นหา',
  reset: 'รีเซ็ต',
  add: 'เพิ่ม',
  deptCode: 'รหัสแผนก',
  departmentHead: 'หัวหน้าแผนก',
  contactWay: 'ข้อมูลติดต่อ',
  showSort: 'ลำดับการแสดง',
  createTime: 'เวลาที่สร้าง',
  addDept: 'เพิ่มแผนก',
  superiorDepartment: 'แผนกหลัก',
  edit: 'แก้ไข',
  delete: 'ลบ',
  tip1: 'ยืนยันการลบแผนกที่เลือกหรือไม่?',
  tip: 'เรียน',
  confirm: 'ยืนยัน',
  cancel: 'ยกเลิก',
  tip2: 'กรุณาเลือกก่อนที่จะลบ!',
  tip3: 'ลบสำเร็จ'
},
  
    // 部门管理
   deptAdmin: {
  deptName: 'ชื่อแผนก',
  status: 'สถานะ',
  search: 'ค้นหา',
  reset: 'รีเซ็ต',
  remark: 'หมายเหตุ',
  add: 'เพิ่ม',
  deptCode: 'รหัสแผนก',
  departmentHead: 'หัวหน้าแผนก',
  contactWay: 'ข้อมูลติดต่อ (หมายเลขโทรศัพท์มือถือ)',
  showSort: 'ลำดับการแสดง',
  createTime: 'เวลาที่สร้าง',
  addDept: 'เพิ่มแผนก',
  superiorDepartment: 'แผนกหลัก',
  edit: 'แก้ไข',
  delete: 'ลบ',
  tip1: 'ยืนยันการลบแผนกที่เลือกหรือไม่?',
  tip: 'เรียน',
  confirm: 'ยืนยัน',
  cancel: 'ยกเลิก',
  tip2: 'กรุณาเลือกก่อนที่จะลบ!',
  tip3: 'ลบสำเร็จ'
},
  
    //打印管理
   printAdmin: {
  add: 'เพิ่ม',
  modelNumber: 'หมายเลขโมดูล',
  modelCode: 'รหัสโมดูล',
  modelId: 'รหัสโมดูล',
  mainTableName: 'ชื่อตารางหลัก',
  modelName: 'ชื่อโมดูล',
  reportName: 'ชื่อรายงาน',
  idField: 'ฟิลด์ ID',
  remark: 'หมายเหตุ',
  edit: 'แก้ไข',
  delete: 'ลบ',
  tip1: 'คุณต้องการลบโมดูลที่เลือกหรือไม่?',
  tip: 'เคล็ดลับ',
  confirm: 'ยืนยัน',
  cancel: 'ยกเลิก',
  tip2: 'กรุณาเลือกก่อนที่จะลบ!',
  tip3: 'ลบสำเร็จ',
},
    // 系统日志
    Systemlog: {
  tip: 'เคล็ดลับ',
  requestParams: 'พารามิเตอร์การร้องขอ',
  returnParams: 'พารามิเตอร์การคืนค่า',
  module: 'โมดูล',
  status: 'สถานะ',
  message: 'ข้อความ',
  requestMethodName: 'ชื่อเมธอดการร้องขอ',
  requestIp: 'IP การร้องขอ',
  requestWay: 'วิธีการร้องขอ',
  bussinessType: 'ประเภทธุรกิจ',
  cancel: 'ยกเลิก',
  confirm: 'ยืนยัน',
  search: 'ค้นหา',
  reset: 'รีเซ็ต',
  add: 'เพิ่ม',
  delete: 'ลบ',
  tip1: 'คุณต้องการลบพารามิเตอร์ที่เลือกหรือไม่?',
  tip2: 'กรุณาเลือกก่อนที่จะลบ!',
  tip3: 'ลบสำเร็จ',
},
  
    // 模板类型
    termsTemplete: {
  add: 'เพิ่ม',
  templeteName: 'ชื่อเทมเพลต',
  templeteContent: 'เนื้อหาเทมเพลต',
  content: 'โปรดป้อนเนื้อหา',
  templeteType: 'ประเภทเทมเพลต',
  createBy: 'สร้างโดย',
  createTime: 'เวลาสร้าง',
  updateBy: 'อัปเดตโดย',
  updateTime: 'เวลาอัปเดต',
  selectDate: 'เลือกวันที่',
  cancel: 'ยกเลิก',
  confirm: 'ยืนยัน',
  search: 'ค้นหา',
  reset: 'รีเซ็ต',
  delete: 'ลบ',
},
  
    // 预警设置
   warning: {
  content: 'โปรดป้อนเนื้อหา',
  select: 'โปรดเลือก',
  warningTitle: 'หัวข้อการเตือน',
  isPage: 'แบ่งหน้า',
  warningSql: 'SQL เตือน',
  CustomMenuName: 'ชื่อเมนูที่กำหนดเอง',
  remark: 'หมายเหตุ',
  showOrder: 'ลำดับการแสดง',
  isShow: 'แสดง',
  cancel: 'ยกเลิก',
  confirm: 'ยืนยัน',
  add: 'เพิ่ม',
  edit: 'แก้ไข',
  batchDeletes: 'ลบแบบกลุ่ม',
  delete: 'ลบ',
  languageSet: 'การตั้งค่าภาษา',
  warningFieldSet: 'การตั้งค่าฟิลด์การเตือน',
  tip1: 'โปรดเลือกข้อมูลที่ต้องการลบ',
  tip2: 'เพิ่มเรียบร้อยแล้ว',
  warningName: 'ชื่อการเตือน',
  emptyContent: 'กรุณาคลิกที่ชื่อรายงานทางด้านซ้ายเพื่อเลือกรายงาน',
},
  
    // 预警设置---预警字段设置
    warnFieldSet: {
  databaseName: 'ชื่อฟิลด์ในฐานข้อมูล',
  search: 'ค้นหา',
  fieldShowOrder: 'ลำดับการแสดงฟิลด์',
  datebaseField: 'ชื่อฟิลด์ในฐานข้อมูล',
  showName: 'ชื่อที่แสดงหน้าจอ',
  dataType: 'ประเภทข้อมูล',
  select: 'โปรดเลือก',
  alignType: 'การจัดวาง',
  isSelect: 'ค้นหา',
  QueryOperator: 'ตัวดำเนินการค้นหา',
  isLock: 'ล็อค',
  datePrecision: 'ความแม่นยำของวันที่',
  isShow: 'แสดง',
  cancel: 'ยกเลิก',
  confirm: 'ยืนยัน',
  add: 'เพิ่ม',
  edit: 'แก้ไข',
  languageSet: 'ตั้งค่าภาษา',
  tip1: 'โปรดเลือกข้อมูลที่ต้องการลบ',
  tip2: 'เพิ่มเรียบร้อยแล้ว',
},
  
  
    // 流程模型
   flowModel: {
  name: 'ชื่อ',
  search: 'ค้นหา',
  reset: 'รีเซ็ต',
  add: 'เพิ่ม',
  flowKey: 'คีย์กระแส',
  version: 'เวอร์ชัน',
  createTime: 'เวลาที่สร้าง',
  updateTime: 'เวลาที่อัปเดต',
  metadata: 'เมตาดาต้า',
  edit: 'แก้ไข',
  delete: 'ลบ',
  upload: 'อัปโหลด',
  download: 'ดาวน์โหลด',
  downLoad: 'ดาวน์โหลด',
  addFlowModel: 'เพิ่มโมเดลกระแส',
  category: 'หมวดหมู่',
  desc: 'คำอธิบาย',
},
  
    // 流程定义
   flowDefinition: {
  operation: 'การดำเนินการ',
  moduleId: 'รหัสโมดูล',
  moduleName: 'ชื่อโมดูล',
  programmeId: 'รหัสโปรแกรม',
  programmeName: 'ชื่อโปรแกรม',
  processDefinitionKey: 'คีย์การกำหนดกระบวนการ',
  remark: 'หมายเหตุ',
  tip4: 'คุณแน่ใจหรือไม่ที่จะลบโมดูลที่เลือก?',
  tip5: 'กรุณาเลือกก่อนที่จะลบ',
  tip6: 'บันทึกสำเร็จ',
  save: 'บันทึก',
  active: 'เปิดใช้งาน',
  hangUp: 'พัก',
  transferModel: 'โอนย้ายโมเดล',
  delete: 'ลบ',
  upload: 'อัปโหลด',
  category: 'หมวดหมู่',
  select: 'กรุณาเลือก',
  selectFile: 'เลือกไฟล์',
  clickUpload: 'คลิกที่นี่เพื่ออัปโหลด',
  cancel: 'ยกเลิก',
  confirm: 'ยืนยัน',
  flowChart: 'แผนภาพกระแสงาน',
  flowKey: 'คีย์กระแส',
  flowName: 'ชื่อกระแส',
  search: 'ค้นหา',
  reset: 'รีเซ็ต',
  flowId: 'รหัสกระแส',
  version: 'เวอร์ชัน',
  flowDesc: 'คำอธิบายกระแส',
  uploadTime: 'เวลาที่อัปโหลด',
  flowDefinition: 'คำนิยามกระแส',
  deploymentProcessDefinition: 'การตั้งค่าคำนิยามกระบวนการ',
  flowDefinitionStatus: 'สถานะคำนิยามกระแส',
  edit: 'แก้ไข',
  delete: 'ลบ',
  actived: 'เปิดใช้งานแล้ว',
  tip1: 'คุณแน่ใจหรือไม่ที่จะลบกระแสนี้?',
  tip: 'เรียบร้อย',
  confirm: 'ยืนยัน',
  cancel: 'ยกเลิก',
  tip2: 'ลบเรียบร้อย',
  tip3: 'กรุณาเลือกหมวดหมู่ก่อนยืนยัน',
  add: 'เพิ่ม',
  selectModule: 'กรุณาเลือกชื่อโมดูลก่อน',
  selectProgramme: 'กรุณาเลือกชื่อโปรแกรมก่อน'
},
  
    // 审批方案
   programmeSet: {
  name: 'ชื่อ',
  search: 'ค้นหา',
  reset: 'รีเซ็ต',
  jointTrial: 'การตรวจสอบร่วม',
  add: 'เพิ่ม',
  groups: 'กลุ่มการอนุมัติ',
  Appoint: 'กำหนดผู้อนุมัติ',
  nodeSetTip: 'โปรดเลือกโหนดการอนุมัติ',
  nodeSet: 'การตั้งค่าโหนดการอนุมัติ',
  programmeSetAdd: 'เพิ่มแผน',
  programmeSetEdit: 'แก้ไขแผน',
  programmeSetName: 'ชื่อแผน',
  programmeSetCode: 'รหัสแผน',
  approvalNode: 'โหนดการอนุมัติ',
  version: 'เวอร์ชัน',
  createTime: 'เวลาสร้าง',
  updateTime: 'เวลาปรับปรุง',
  metadata: 'เมตาดาต้า',
  edit: 'แก้ไข',
  delete: 'ลบ',
  upload: 'อัปโหลด',
  download: 'ดาวน์โหลด',
  addFlowModel: 'เพิ่มโมเดลกระแส',
  category: 'หมวดหมู่',
  desc: 'คำอธิบาย',
},
  
  
    // 审批
   Approval: {
  auditor: 'ผู้ตรวจสอบ',
  backTo: 'กลับไปยังโหนด',
  tipNode: 'ไม่เลือกคืนค่าผู้ส่งเรื่องโดยค่าเริ่มต้น!',
  operation: 'ดำเนินการ',
  agree: 'ยอมรับ',
  reject: 'ปฏิเสธ',
  history: 'ประวัติ',
  schedule: 'กำหนดการ',
  view: 'ดู',
  confirm: 'ยืนยัน',
  cancel: 'ยกเลิก',
  approvalOption: 'ความเห็นในการอนุมัติ',
  approvalProcess: 'กระบวนการอนุมัติ',
  approvalHistory: 'ประวัติการอนุมัติ',
  approveParam: 'พารามิเตอร์การอนุมัติ',
  scheduleChart: 'แผนภูมิกำหนดการ',
  initiator: 'ผู้เริ่มต้น',
  moduleName: 'ชื่อโมดูล',
  search: 'ค้นหา',
  reset: 'รีเซ็ต',
  refresh: 'รีเฟรช',
  approvalAgree: 'อนุมัติ',
  approvalReject: 'ปฏิเสธการอนุมัติ',
  approvalTime: 'เวลาการอนุมัติ',
  approvalParam: 'พารามิเตอร์การอนุมัติ',
  taskName: 'ชื่องาน',
  assignee: 'ผู้มอบหมาย',
  startTime: 'เวลาเริ่มต้น',
  endTime: 'เวลาสิ้นสุด',
  durationTime: 'ระยะเวลาที่ใช้',
  refreshSuccess: 'รีเฟรชสำเร็จ',
  refreshFail: 'รีเฟรชล้มเหลว',
  tip1: 'โปรดเลือกก่อนดำเนินการยอมรับ!',
  tip2: 'โปรดเลือกก่อนดำเนินการปฏิเสธ!',
  viewInvoices: 'ดู',
  print: 'พิมพ์',
  approvalStatus: 'สถานะการอนุมัติ',
  tip44: 'โปรดกรอกความเห็นในการอนุมัติ',
  initiationDate: 'วันที่เริ่มต้น',
  fno: 'หมายเลขเอกสาร',
  save: 'บันทึก'
},
  
    // 订单进度管理
   Order: {
  orderProgressTable: 'ตารางความคืบหน้าการสั่งซื้อ',
  progress: 'ความคืบหน้า',
  operation: 'ดำเนินการ',
  viewDetail: 'ดูรายละเอียด',
  orderProgressChart: 'แผนภูมิความคืบหน้าการสั่งซื้อ',
  contractNumber: 'หมายเลขสัญญา',
  contractDate: 'วันที่สัญญา',
  customerName: 'ชื่อลูกค้า',
  search: 'ค้นหา',
  reset: 'รีเซ็ต',
  refresh: 'รีเฟรช',
  deliveryDate: 'วันที่ส่งมอบ',
  destinationPort: 'ท่าเรือปลายทาง',
  shipmentPort: 'ท่าเรือการขนส่ง',
  createBy: 'ผู้สร้าง',
  addNode: 'เพิ่มโหนด',
  confirmNode: 'ยืนยันโหนด',
  linkName: 'ชื่อลิงก์',
  computeMode: 'โหมดการคำนวณ',
  basisNode: 'โหนดข้อมูล',
  Days: 'จำนวนวันที่จำเป็น',
  createDate: 'วันที่สร้าง',
  chargePersion: 'ผู้รับผิดชอบ',
  status: 'สถานะ',
  edit: 'แก้ไข',
  delete: 'ลบ',
  selectNode: 'เลือกโหนด',
  confirm: 'ยืนยัน',
  cancel: 'ยกเลิก',
  sendSuccess: 'ส่งสำเร็จ',
  addNodes: 'เพิ่มโหนดในคลัง',
  nodeName: 'ชื่อโหนด',
  nodeEndCondition: 'เงื่อนไขการสิ้นสุดโหนด',
  nodeEndConditionSql: 'คำสั่งเงื่อนไขการสิ้นสุดโหนด',
  nodesName: 'ชื่อคลังโหนด',
  createByName: 'ชื่อผู้สร้าง',
  createTime: 'เวลาที่สร้าง',
  tip1: 'ยืนยันการลบโหนดนี้หรือไม่?',
  tip: 'เคล็ดลับ',
  saveSuccess: 'บันทึกสำเร็จ'
},
  
    /* 左侧菜单 */
    Menu: {
     messageCenter: 'ศูนย์ข้อความ',
nodeSettings: 'การตั้งค่าโหนด',
businessFlowTracking: 'การติดตามการไหลของธุรกิจ',
SystemAdmin: 'ผู้ดูแลระบบ',
ListSet: 'การตั้งค่ารายการ',
FetchSet: 'การตั้งค่าการดึงข้อมูล',
HomeSet: 'การตั้งค่าหน้าหลัก',
home: 'หน้าหลัก',
ReportPermissSet: 'การตั้งค่าสิทธิ์รายงาน',
dataDict: 'พจนานุกรมข้อมูล',
category: 'หมวดหมู่',
menuAdmin: 'การจัดการเมนู',
userAdmin: 'การจัดการผู้ใช้',
userOnline: 'ผู้ใช้ออนไลน์',
roleAdmin: 'การจัดการบทบาท',
deptAdmin: 'การจัดการแผนก',
printAdmin: 'การจัดการการพิมพ์',
systemLog: 'บันทึกระบบ',
termsTemplete: 'เทมเพลตข้อกำหนด',
warningSet: 'การตั้งค่าการเตือน',
fieldSet: 'การตั้งค่าฟิลด์',
dataDictSet: 'การตั้งค่ารายการพจนานุกรมข้อมูล',
fetchFieldSet: 'การตั้งค่าการดึงฟิลด์',
sheetSet: 'การตั้งค่าหน้ากระดาษ',
formSet: 'การตั้งค่าแบบฟอร์ม',
codeSet: 'การตั้งค่ารหัส',
editUser: 'แก้ไขผู้ใช้',
languageSet: 'การตั้งค่าภาษา',
versionView: 'ดูเวอร์ชัน',
persionCenter: 'ศูนย์กลางบุคคล',
warnPermissSet: 'การตั้งค่าสิทธิ์การเตือน',
warning: 'การเตือน',
warningFieldSet: 'การตั้งค่าฟิลด์การเตือน',
businessModel: 'โมเดลธุรกิจ',
systemSet: 'การตั้งค่าระบบ',
logoSet: 'การตั้งค่าโลโก้',
loginSet: 'การตั้งค่าพื้นหลังการเข้าสู่ระบบ',

  
      // 流程菜单
     flowMenu: 'เมนูกระบวนการ',
flowDefinition: 'คำจำกัดความของกระบวนการ',
flowConfig: 'การกำหนดค่ากระบวนการ',
flowModel: 'แบบจำลองกระบวนการ',
modelOnlineDesign: 'การออกแบบออนไลน์ของแบบจำลอง',
addFlowConfig: 'เพิ่มการกำหนดค่ากระบวนการ',
editFlowConfig: 'แก้ไขการกำหนดค่ากระบวนการ',
programmeSet: 'การตั้งค่าโปรแกรม',
programmeSetAdd: 'เพิ่มการตั้งค่าโปรแกรม',
programmeSetEdit: 'แก้ไขการตั้งค่าโปรแกรม',

  
      // 审批管理
     approvalAdmin: 'การจัดการการอนุมัติ',
peddingApproval: 'รอการอนุมัติ',
approval: 'อนุมัติแล้ว',
Initiated: 'เริ่มต้นแล้ว',
CCRecord: 'บันทึกการส่งสำเนา',

  
      // 订单进度管理
     orderProgressAdmin: 'การจัดการความคืบหน้าใบสั่งซื้อ',
orderProgressList: 'รายการความคืบหน้าใบสั่งซื้อ',
orderNodes: 'คลังข้อมูลของโหนดใบสั่งซื้อ',
orderProgressDetail: 'รายละเอียดความคืบหน้าใบสั่งซื้อ',
statisticsReport: 'รายงานสถิติ',

  
      // 代码事件配置
      codeEventSet: 'การตั้งค่าเหตุการณ์โค้ด',
     
    },
    // 嵌套BI系统
    Bisystem: {
      BiName: "ค้นหาสถิติ"
    },
    StatisticalManagement: {
      StatisticalManagement: "การจัดการสถิติ"
    },
    // 邮件模块
    Emails: {
     edit: 'แก้ไข',
sign: 'ตั้งค่าลายเซ็น',
folders: 'ตั้งค่าโฟลเดอร์',
eName: 'ระบบอีเมล',
write: 'เขียนอีเมล',
contact: 'สมุดที่อยู่',
inbox: 'กล่องจดหมายเข้า',
outbox: 'กล่องจดหมายออก',
drafts: 'กล่องจดหมายร่าง',
trash: 'ถังขยะ',
searchFolder: 'ค้นหาโฟลเดอร์',
label: 'ป้ายชื่ออีเมล',
settings: 'ตั้งค่าอีเมล',
please: 'กรุณาเข้าสู่ระบบ',
login: 'เข้าสู่ระบบ',
loginsuccess: 'เข้าสู่ระบบสำเร็จ',
loginfailed: 'เข้าสู่ระบบล้มเหลว',
selectAccount: 'เลือกบัญชี',
signContent: 'เนื้อหาของลายเซ็น',
  
    account: 'บัญชีผู้ใช้',
password: 'รหัสผ่าน',
notnull: 'บัญชีหรือรหัสผ่านต้องไม่ว่างเปล่า',
cancel: 'ยกเลิก',
go: 'ตกลง',
register: 'ลงทะเบียน',
regSuccess: 'ลงทะเบียนสำเร็จ',
khname: 'ชื่อหน่วยงาน',
copy: 'สำเนา',
secret: 'ส่งลับ',
addcopy: 'เพิ่มสำเนา',
addsecret: 'เพิ่มส่งลับ',
delcopy: 'ลบสำเนา',
delsecret: 'ลบส่งลับ',
addressee: 'ผู้รับ',
sendName: 'ชื่อผู้ส่ง',
copyName: 'ผู้รับสำเนา',
linkTips: 'ไม่มีรายชื่อที่ติดต่อในที่ตั้งกรุณาไปที่ที่อยู่ในบัญชีโดยตรง',
theme: 'หัวข้อ',
upload: 'อัปโหลดเอกสารแนบ',
clearFiles: 'ล้างรายการ',
numsTips: 'จำนวนไฟล์แนบที่เลือก: ',
sizeTips: 'ขนาดรวมของไฟล์แนบ: ',
readReceipt: 'ส่งการอ่านแล้ว',
Sender: 'ผู้ส่ง',
pickSender: 'เลือกผู้ส่ง',
send: 'ส่ง',
save: 'บันทึกเป็นร่าง',
reset: 'รีเซ็ต',

     addresseeEmpty: 'กรุณากรอกชื่อผู้รับ',
themeEmpty: 'กรุณากรอกหัวข้อ',
senderEmpty: 'กรุณาเลือกผู้ส่ง',
senderNull: 'ยังไม่มีผู้ส่ง กรุณาไปที่การตั้งค่าอีเมลเพื่อเพิ่ม',
textEmpty: 'กรุณากรอกเนื้อหาของอีเมล',
sendSuccess: 'ส่งอีเมลสำเร็จ',
sendFail: 'ส่งอีเมลไม่สำเร็จ',
saveSuccess: 'บันทึกเป็นร่างสำเร็จ',
saveFail: 'บันทึกเป็นร่างไม่สำเร็จ',
refresh: 'รีเฟรช',
themeKeyword: 'คำสำคัญในหัวข้อ',
contentKeyword: 'คำสำคัญในเนื้อหา',
senderKeyword: 'เลือกผู้ส่ง',
getAll: 'เลือกทั้งหมด',
clearAll: 'ยกเลิกทั้งหมด',
oneByone: 'ส่งแบบหนึ่งต่อหนึ่ง',
generatedBy: 'สร้างโดย AI',
languageSet: 'ภาษาเป้าหมาย',
transLate: 'แปล',
promotional: 'จดหมายโปรโมชั่น',
exampleContent: 'ตัวอย่าง: กรุณาสร้างจดหมายเปิดตัวสำหรับลูกค้าในอุตสาหกรรมการค้าปลีก ชื่อของฉันคือ ซานา มาจาก บริษัท XXX ผลิตภัณฑ์และบริการหลักของเราคือซอฟต์แวร์การค้าส่งออก ตลาดเป้าหมายคือเอเชียตะวันออกเฉียงใต้',
addMails: 'แทรกในอีเมล',

     readStatus: 'สถานะการอ่าน',
all: 'ทั้งหมด',
theLastDay: 'เมื่อวานนี้',
lastThreeDays: 'สามวันที่ผ่านมา',
lastSevenDays: 'เจ็ดวันที่ผ่านมา',
lastThirtyDays: 'สามสิบวันที่ผ่านมา',
lastNinetyDays: 'เก้าสิบวันที่ผ่านมา',
lastOneHundredEightyDays: 'หนึ่งร้อยแปดสิบวันที่ผ่านมา',
unread: 'ยังไม่ได้อ่าน',
read: 'อ่านแล้ว',
removed: 'ลบแล้ว',
receive: 'รับ',
delete: 'ลบ',
emailNums: 'จำนวนอีเมล',
emailUnread: 'อีเมลที่ยังไม่ได้อ่าน',
receiving: 'กำลังรับ...',
receiveFail: 'การรับล้มเหลว',
receiveSuccess: 'การรับสำเร็จ',
emailDetails: 'คลิกเพื่อดูรายละเอียดอีเมล',
delTips: 'โปรดเลือกเพื่อลบ',
delSuccess: 'ลบสำเร็จ',
delFail: 'ลบไม่สำเร็จ',
contactsList: 'รายการผู้ติดต่อ',
contactsManage: 'จัดการผู้ติดต่อ',
categoryManage: 'จัดการหมวดหมู่',
categoryName: 'ชื่อหมวดหมู่',
customerName: 'ชื่อลูกค้า',
name: 'ชื่อ',
customerCode: 'รหัสลูกค้า',
email: 'อีเมล',
operation: 'ดำเนินการ',
categoryParent: 'หมวดหมู่หลัก',
phone: 'โทรศัพท์',
reply: 'ตอบกลับ',
replyAll: 'ตอบกลับทุกคน',
date: 'วันที่',
saving: 'กำลังบันทึก',
pickCategory: 'เลือกหมวดหมู่',
edit: 'แก้ไข',
cateTips: 'โปรดเลือกหมวดหมู่',
chooseAnItem: 'โปรดเลือกเพียงรายการเดียว',
writeCategoryName: 'โปรดกรอกชื่อหมวดหมู่',
nameMailTips: 'โปรดกรอกชื่อและอีเมลให้ครบถ้วน',
recoverySuccess: 'กู้คืนสำเร็จ',
recoveryFail: 'การกู้คืนล้มเหลว',
newFolder: 'สร้างโฟลเดอร์ใหม่',
editFolder: 'แก้ไขโฟลเดอร์',

  
    addFolderTips: 'โปรดใส่ชื่อโฟลเดอร์',
folderName: 'ชื่อโฟลเดอร์',
addSuccess: 'เพิ่มสำเร็จ',
addFail: 'เพิ่มล้มเหลว',
emailSetting: 'การตั้งค่าอีเมล',
signSetting: 'การตั้งค่าลายเซ็น',
folderSetting: 'การตั้งค่าโฟลเดอร์',
displayName: 'ชื่อที่แสดง',
senderName: 'ชื่อผู้ส่ง',
emailAddress: 'ที่อยู่อีเมล',
required: 'กรุณากรอก',
addSetting: 'เพิ่มการตั้งค่า',
requiredSelect: 'โปรดเลือก',
selectMoveWarning: 'โปรดเลือกอีเมลที่ต้องการย้าย',
moveSuccess: 'ย้ายสำเร็จ',
maveFailed: 'ย้ายล้มเหลว',
sendingServer: 'เซิร์ฟเวอร์ส่ง',
Authorization: 'รหัสผ่านการอนุญาต',
sendserverType: 'ประเภทเซิร์ฟเวอร์ (สำหรับรับ)',
serverAddress: 'ที่อยู่เซิร์ฟเวอร์',
sendserverAddress: 'ที่อยู่เซิร์ฟเวอร์ (สำหรับส่ง)',
isDefault: 'เป็นค่าเริ่มต้นหรือไม่',
enable: 'เปิดใช้งานหรือไม่',
port: 'พอร์ต',
sendTime: 'เวลาที่ส่ง',
originalMail: 'อีเมลต้นฉบับ',
receiveAll: 'รับทั้งหมด',
receiveMailPick: 'โปรดเลือกบัญชีที่ต้องการรับ',
receiveMailTime: 'โปรดเลือกช่วงเวลาที่ต้องการรับ',
receiveEmail: 'ตัวเลือกการรับอีเมล',
emailNums: 'จำนวนอีเมล',
relatedModules: 'โมดูลที่เกี่ยวข้อง',
moduleName: 'ชื่อโมดูล',
fno: 'หมายเลขเอกสาร',
selectReportFile: 'เลือกไฟล์รายงานแนบ',
uploaded: 'ไฟล์ที่อัปโหลดแล้ว',
uploadSuccess: 'อัปโหลดไฟล์รายงานแนบสำเร็จ',
uploadFailed: 'อัปโหลดไฟล์รายงานแนบล้มเหลว',
selectModelName: 'โปรดเลือกชื่อโมดูล',
selectPrintFormat: 'โปรดเลือกรูปแบบการพิมพ์',
readTime: 'เวลาที่อ่าน',
readAddress: 'สถานที่ที่อ่าน',
readCount: 'จำนวนครั้งที่อ่าน',
markAllAsRead: 'ทำเครื่องหมายทั้งหมดเป็นอ่านแล้ว',
markSelectedAsRead: 'ทำเครื่องหมายที่เลือกเป็นอ่านแล้ว',
markAllAsReadTip: 'คุณแน่ใจหรือไม่ที่จะทำเครื่องหมายทุกอีเมลว่าอ่านแล้ว',
markSelectedAsReadtip: 'คุณแน่ใจหรือไม่ที่จะทำเครื่องหมายอีเมลที่เลือกว่าอ่านแล้ว',
    markedSuccess: 'ทำเครื่องหมายสำเร็จ',
markedFail: 'ทำเครื่องหมายล้มเหลว',
moveto: 'ย้ายไปยัง',
emailForward: 'ส่งต่ออีเมล',
emailTemplate: 'แม่แบบอีเมล',
addTemplate: 'เพิ่มแม่แบบ',
EditTemplate: 'แก้ไขแม่แบบ',
restore: 'คุณแน่ใจหรือไม่ว่าต้องการกู้คืนอีเมลนี้',
emailSet: 'การตั้งค่าอีเมล',
noAccount: 'คุณยังไม่ได้กำหนดบัญชีอีเมล',
goToSet: 'ไปที่การตั้งค่า',
singleForwarding: 'รองรับการส่งต่อเพียงรายการเดียว',
attachments: 'ไฟล์แนบ',
download: 'ดาวน์โหลด',
cancel: 'ยกเลิก',
selectTheMailTemplate: 'เลือกแม่แบบอีเมล',
name: 'ชื่อ',
defaultExpression: 'สำเนาค่าเริ่มต้น',
receivingAccount: 'กรุณาเลือกบัญชีที่จะรับ',
pleaseSelectAnAccount: 'กรุณาเลือกบัญชี',
pleaseEnterYourSignature: 'กรุณาใส่เนื้อหาลายเซ็นของคุณ',
pleaseFillInTheName: 'กรุณากรอกชื่อ',
pleaseFillInTheContent: 'กรุณากรอกเนื้อหา',
targetLanguage: 'กรุณาเลือกภาษาเป้าหมาย',
english: 'อังกฤษ',
french: 'ฝรั่งเศส',
spanish: 'สเปน',
japanese: 'ญี่ปุ่น',
korean: 'เกาหลี',
burmese: 'พม่า',
thai: 'ไทย',
russian: 'รัสเซีย',
servicesProvidedByOpenai: 'บริการจาก OpenAI',

    },
    Marketing: {
    explosibility: 'ความสามารถในการส่ง',
alreadySent: 'จำนวนที่ส่งแล้ว',
remaining: 'จำนวนที่เหลือ',
screening: 'คัดเลือก',
reset: "รีเซ็ต",
sendEmail: 'ส่งอีเมล',
pleaseEnterSendEmail: 'กรุณากรอกอีเมลที่ต้องการส่ง',
missionDataOverview: 'ภาพรวมข้อมูลภารกิจ',
marketingNumber: 'จำนวนผู้ตลาด',
sendingNumber: 'จำนวนผู้ส่ง',
deliveryNumber: 'จำนวนผู้รับ',
unpackNumber: 'จำนวนผู้เปิด',
recoverNumber: 'จำนวนผู้ตอบกลับ',
taskName: 'ชื่อภารกิจ',
add: 'เพิ่ม',
remove: 'ลบ',
selected: 'เลือกแล้ว',
PCS: 'ชิ้น',
underWay: 'กำลังดำเนินการ',
complete: 'เสร็จสิ้น',
creationTime: 'เวลาสร้าง',
startSending: 'เริ่มส่ง',
sendComplete: 'ส่งเสร็จ',
lastUpdated: 'อัปเดตล่าสุด',
taskDeletion: 'กรุณาเลือกภารกิจที่ต้องการลบ!',
isDeleteCheck: 'คุณต้องการลบข้อมูลที่เลือกหรือไม่?',

     tip: 'เคล็ดลับ',
senderSettings: 'การตั้งค่าผู้ส่ง',
contentEditing: 'การแก้ไขเนื้อหา',
sendSetting: 'การตั้งค่าการส่ง',
taskType: 'ประเภทงาน',
developmentLetter: 'จดหมายเชิญพัฒนาธุรกิจ',
companyIntroduction: 'แนะนำบริษัท',
productIntroduction: 'แนะนำผลิตภัณฑ์',
longTermConnection: 'การเชื่อมโยงระยะยาว',
seasonsGreetings: 'การต้อนรับฤดูกาล',
other: 'อื่น ๆ',
senderNickname: 'ชื่อเล่นผู้ส่ง',
sendingAddress: 'ที่อยู่ส่ง',
sendingType: 'ประเภทการส่ง',
none: 'ไม่มี',
sendImmediately: 'ส่งทันที',
automatedMarketing: 'การตลาดอัตโนมัติ',
replyEmail: 'อีเมลตอบกลับ',
correctEmail: 'อีเมลที่ถูกต้อง',
type: 'ประเภท',
addressee: 'ผู้รับ',
replyStopSending: 'หยุดการส่งอีเมลตอบกลับ',
readStoppedSending: 'หยุดการส่งอีเมลหลังอ่าน',
pleaseEnter: 'กรุณากรอก',
pleaseSelect: 'กรุณาเลือก',
select: 'เลือก',
optional: 'ทางเลือก',

    confirm: 'ยืนยัน',
cancel: 'ยกเลิก',
lastStep: 'ขั้นตอนก่อนหน้า',
nextStep: 'ขั้นตอนต่อไป',
immediatelyEnable: 'เปิดใช้งานทันที',
emailSubject: 'หัวเรื่องอีเมล',
outgoingInterval: 'ระยะเวลาการส่ง',
subjectName: 'ชื่อหัวข้อ',
theme: 'ธีม',
controls: 'ควบคุม',
editor: 'แก้ไข',
successfulOperation: 'ดำเนินการสำเร็จ',
successfulReplication: 'ทำซ้ำสำเร็จ',
operationFailure: 'การดำเนินการล้มเหลว',
confirmToDelete: 'คุณแน่ใจหรือไม่ว่าต้องการลบ?',
mailSubjectName: 'ชื่อหัวข้ออีเมล',
insertVariable: 'แทรกตัวแปร',
emailContent: 'เนื้อหาอีเมล',
uploadAttachment: 'อัปโหลดไฟล์แนบ',
commodityList: 'รายการสินค้า',
templateSelection: 'เลือกเทมเพลต',
AILetterWriting: 'เขียนจดหมายโดย AI',
AIWrite: 'เขียนโดย AI',
variable: 'ตัวแปร',

     toggle: 'สลับ',
for: 'สำหรับ',
language: 'ภาษา',
english: 'อังกฤษ',
french: 'ฝรั่งเศส',
spanish: 'สเปน',
japanese: 'ญี่ปุ่น',
korean: 'เกาหลี',
burmese: 'พม่า',
thai: 'ไทย',
russian: 'รัสเซีย',
day: 'วัน',
remark: 'หมายเหตุ',
isUnsubscribe: 'รวมข้อความยกเลิกการสมัครสมาชิกหรือไม่',
companyName: 'ชื่อบริษัท',
languageUsed: 'ภาษาที่ใช้ในการส่งอีเมล',
productName: 'ชื่อผลิตภัณฑ์',
toneOfMail: 'ลักษณะของอีเมล',
business: 'ธุรกิจ',
moreFormal: 'เป็นทางการมากขึ้น',
politeness: 'สุภาพ',
formal: 'ทางการ',

     festivalName: 'ชื่อเทศกาล',
wordLimit: 'จำกัดคำ',
unlimitedWordCount: 'ไม่จำกัดจำนวนคำ',
intraWord: 'ต่ำกว่าคำ',
generalDescription: 'คำอธิบายทั่วไป',
send: 'ส่ง',
insertIntoAMessage: 'แทรกในข้อความ',
copyContent: 'คัดลอกเนื้อหา',
examples: 'ตัวอย่าง: โปรดสร้างจดหมายเวียนการพัฒนาทางการค้าสำหรับลูกค้าภาคการค้าส่ง ชื่อของฉันคือ John Doe มาจากบริษัท XXX ผลิตภัณฑ์และบริการหลักของเราคือซอฟต์แวร์การค้าส่งและตลาดเป้าหมายคือตะวันออกเฉียงใต้',
product: 'ผลิตภัณฑ์',
templateName: 'ชื่อเทมเพลต',
hostedDataOverview: 'ภาพรวมข้อมูลที่เก็บไว้',
mailUserGrouping: 'การจัดกลุ่มผู้ใช้อีเมล',
addGroup: 'เพิ่มกลุ่ม',
deleteGroup: 'ลบกลุ่ม',
serialNumber: 'หมายเลขซีเรียล',
userGroup: 'กลุ่มผู้ใช้',
creationDate: 'วันที่สร้าง',
instructions: 'คำแนะนำ',
name: 'ชื่อ',
mailbox: 'กล่องจดหมาย',
groupName: 'ชื่อกลุ่ม',

     userMailbox: 'กล่องจดหมายของผู้ใช้',
userGroupName: 'ชื่อกลุ่มผู้ใช้',
refresh: 'รีเฟรช',
deletingUserGroup: 'กรุณาเลือกกลุ่มผู้ใช้ที่ต้องการลบ !',
isCheckDeleteUserGroup: 'คุณแน่ใจหรือไม่ว่าต้องการลบกลุ่มผู้ใช้ที่เลือก ?',
successfullyDeleted: 'ลบเรียบร้อยแล้ว !',
saveSuccessfully: 'บันทึกสำเร็จ !',
success: 'สำเร็จ',
search: 'ค้นหา',
personalTemplate: 'เทมเพลตส่วนบุคคล',
mailTemplateName: 'ชื่อเทมเพลตอีเมล',
privatelyOwned: 'เป็นส่วนตัว',
publicity: 'เปิดเผย',
isPublicTemplate: 'เป็นเทมเพลตที่เผยแพร่หรือไม่',
templateTopic: 'หัวข้อเทมเพลต',
templateContent: 'เนื้อหาเทมเพลต',
templateRemarks: 'หมายเหตุเทมเพลต',
saveTemplate: 'บันทึกเทมเพลต',
NOTranslationPicture: 'ไม่สามารถแปลเนื้อหาที่มีรูปภาพได้ !',
marketingTask: 'งานการตลาด',
setbasicInformation: 'ตั้งค่าข้อมูลพื้นฐาน',
submitScheme: 'ส่งและยืนยันแผนการ',
trusteeshipScheme: 'แผนที่ต้องการให้ฝาก',
view: 'ดู',
edit: 'แก้ไข',

    addCustomScheme: 'สร้างแผนกำหนดเอง',
outgoingMailbox: 'กล่องจดหมายออก',
replyEmail: 'อีเมลตอบกลับ',
recipientEmailAddress: 'ที่อยู่อีเมลผู้รับ',
industry: 'อุตสาหกรรม',
textile: 'อุตสาหกรรมเสื้อผ้า',
homeFurnishings: 'อุตสาหกรรมของใช้ในบ้าน',
mailLanguage: 'ภาษาที่ใช้ในอีเมล',
confirmationMarketingLetter: 'โปรดยืนยันจดหมายการตลาด',
tip1001: 'ผ่านการตลาดหลายรอบเพื่อการพัฒนาลูกค้า ในแต่ละรอบจะมีการเน้นด้านต่าง ๆ และสำหรับผู้รับที่ยังไม่ตอบจะมีการส่งอีเมลอัตโนมัติตามเวลาที่กำหนด',
startCircularMarketing: 'เริ่มต้นการตลาดโค้งรอบ',
change: 'เปลี่ยน',
addScheme: 'สร้างแผน',
schemeName: 'ชื่อแผน',
templateLibrary: 'คลังเทมเพลต',
generateTemplate: 'สร้างเทมเพลต',
intervalTime: 'ช่วงเวลาห่าง',
triggerCondition: 'เงื่อนไขการกระตุ้น',
NoReplyYet: 'ยังไม่มีการตอบกลับ',
unread: 'ยังไม่ได้อ่าน',
translation: 'แปลเป็นภาษาเป้าหมาย',
description: 'คำอธิบาย',
save: 'บันทึก',
isGenerateRemplate: 'ต้องการสร้างเทมเพลตหรือไม่ ?',
addSuccessfully: 'เพิ่มสำเร็จ',
generateNewScheme: 'เมื่อเปิดใช้งานเนื้อหาที่แก้ไขจะทำให้เกิดแผนใหม่ ?',
mailTemplate: 'เทมเพลตอีเมล',
addTemplate: 'เพิ่มเทมเพลต',
updateTime: 'เวลาอัปเดต',
personalTemplate: 'เทมเพลตส่วนบุคคล',
commonTemplate: 'เทมเพลตทั่วไป',

    }
  }
