<template>
  <div>
    <el-input-number
      v-if="filterType !== 9"
      ref="numberInput"
      :value="inputcount"
      v-bind="$attrs"
      v-on="$listeners"
      :controls="false"
      @input="handle"
      controls-position="right"
      :placeholder="placeholderspan"
      size="mini"
    >
    </el-input-number>
    <div v-if="filterType === 9">
      <el-input-number
        ref="numberInput"
        v-model="inputcount1"
        v-bind="$attrs"
        v-on="$listeners"
        @input="handlecount"
        controls-position="right"
        :placeholder="placeholderspan"
        size="mini"
        :controls="false"
      >
      </el-input-number>

      - 到 -

      <el-input-number
        ref="numberInput"
        v-model="inputcount2"
        v-bind="$attrs"
        v-on="$listeners"
        @input="handlecount"
        controls-position="right"
        :placeholder="placeholderspan"
        size="mini"
        :controls="false"
      >
      </el-input-number>
    </div>
  </div>
</template>

<script>
export default {
  name: "Y-numberInput",
  props: {
    input: {
      type: [String, Array, Number],
      required: true
    },
    filterType: {
      type: Number,
    },
    placeholder: String,
  },
  data() {
    return {
      placeholderspan: "",
      inputcount: null,
      inputcount1: undefined,
      inputcount2: undefined
    };
  },
  computed: {},
  created() {
    // console.log(this.placeholder)
    if (this.placeholder === "" || this.placeholder === null || this.placeholder === undefined) {
      this.placeholderspan = this.$t('configMoudle.content')
    } else {
      this.placeholderspan = this.placeholder
    }
  },
  mounted() {
    // this.$refs.input.focus()
    this.$emit('mounted')
   },
  watch: {
    input: {
      handler (val) {
        this.inputcount = val;
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    handle(val) {
      this.$emit("update:input", val);
    },
    handlecount () {
      let count = []
      if (this.inputcount1 === "" || this.inputcount1 === null || this.inputcount1 === undefined) {
        count.push("0")
      } else {
        count.push(this.inputcount1 + "")
      }
      if (this.inputcount2 === "" || this.inputcount2 === null || this.inputcount2 === undefined) {
        count.push("0")
      } else {
        count.push(this.inputcount2 + "")
      }
      if ((count[0] === "" && count[1] === "") || count[0] == "0" && count[1] == "0") {
        count = ""
      }
      // count[0] = this.inputcount1 + ""
      // count[1] = this.inputcount2 + ""
      this.$emit("update:input", count);
    },
    suffixclick () {
      this.$emit("suffixclick");
    }
  },
  components: {},
};
</script>

<style scoped lang="scss">
/deep/ .el-input-number.is-controls-right[class*=mini] [class*=decrease], .el-input-number.is-controls-right[class*=mini] [class*=increase] {
  line-height: 16px;
}
/deep/ .el-input-number.is-without-controls .el-input__inner {
  padding: 0px;
}
</style>
