var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "shop_wrapper" },
    _vm._l(_vm.tableData, function(item, index) {
      return _c("div", { key: index, staticClass: "shop_item_bg" }, [
        item.img_ids
          ? _c("div", { staticClass: "shop_item" }, [
              _c("a", { staticClass: "shop_item_link", attrs: { href: "#" } }, [
                _c(
                  "div",
                  {
                    staticStyle: {
                      width: "100%",
                      height: "100%",
                      display: "flex",
                      "flex-direction": "column"
                    }
                  },
                  [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          flex: "0.9",
                          display: "flex",
                          "justify-content": "center",
                          "align-items": "center"
                        }
                      },
                      [
                        _c("img", {
                          staticClass: "rax-image",
                          staticStyle: { width: "50%", height: "auto" },
                          attrs: { src: item.img_ids[0].url },
                          on: {
                            dblclick: function($event) {
                              return _vm.imgDbClick(item.id)
                            }
                          }
                        })
                      ]
                    ),
                    _c("div", { staticClass: "shop_main-wrapper" }, [
                      _c("span", { staticClass: "shop__main-title" }, [
                        _vm._v(_vm._s(item.spname || item.sdesc || "暂无名称"))
                      ])
                    ])
                  ]
                )
              ])
            ])
          : _c(
              "div",
              {
                staticClass: "shop_item noimage",
                on: {
                  dblclick: function($event) {
                    return _vm.imgDbClick(item.id)
                  }
                }
              },
              [
                _vm._m(0, true),
                _c("div", { staticClass: "shop_main-wrapper" }, [
                  _c("span", { staticClass: "shop__main-title" }, [
                    _vm._v(_vm._s(item.spname || item.sdesc || "暂无名称"))
                  ])
                ])
              ]
            )
      ])
    }),
    0
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      {
        staticStyle: {
          width: "100%",
          height: "70%",
          "text-align": "center",
          "justify-content": "center",
          padding: "20px 0 0 0"
        }
      },
      [
        _c("i", {
          staticClass: "iconfont icon-tupianguanli",
          staticStyle: { "font-size": "80px" }
        })
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }