<template>
  <div v-if="showDeepCom(item)">
    <Y-tab
      v-show="item.showTabFlag"
      :ref="item.id"
      :key="item.id"
      :tab="item.children"
      :tabName="'id'"
      :tabLabel="'header'"
      :changeActive.sync="item.childrenActive"
      @handleTabClick="handleTabClick"
      :checkout.sync="checkout"
    >
      <template
        #default="{ tabItem, tabItem: { fieldsConfigVOs, tableData, itemsConfigVOs } }"
      >
        <el-collapse v-model="activeNames">
          <el-collapse-item title="" name="1">
            <div class="buttonbox">
              <Y-button
                v-if="
                  (handleType !== 'list' &&
                    !isView &&
                    !tabItem.isReadOnly &&
                    tabItem.header != '占用明细' &&
                    !tabItem.isCancelFilter &&
                    tabItem.header != '库存明细') ||
                  $route.path == '/cggl/zjjh_edit'
                "
                :button="button"
                @handleClickButton="
                  (val) => {
                    return handleClickHandle({ title: 'add', tabItem });
                  }
                "
              />
              <Y-button
                v-if="
                  handleType !== 'list' &&
                  !isView &&
                  !tabItem.isReadOnly &&
                  tabItem.header != '占用明细' &&
                  !tabItem.isCancelFilter &&
                  tabItem.header != '库存明细'
                "
                :button="buttonse"
                @handleClickButton="
                  (val) => {
                    return handleClickHandle({ title: 'excelCopy', tabItem });
                  }
                "
              />
              <Y-button
                v-if="
                  handleType !== 'list' &&
                  !isView &&
                  !tabItem.isReadOnly &&
                  tabItem.header != '占用明细' &&
                  tabItem.header != '库存明细'
                "
                :button="buttonedsa"
                @handleClickButton="
                  (val) => {
                    return handleClickHandle({ title: 'deleteall', tabItem });
                  }
                "
              />
              <Y-button
                v-if="
                  !isView &&
                  tabItem.header == '库存明细' &&
                  $route.path.indexOf('add') == -1
                "
                :button="[{ key: 'zhanyong', label: '占用' }]"
                @handleClickButton="
                  (val) => {
                    return handleClickHandle({ title: 'zhanyong', tabItem });
                  }
                "
              />

              <!-- 取消占用  -->
              <Y-button
                v-if="
                  !isView &&
                  tabItem.header == '占用主表' &&
                  $route.path.indexOf('add') == -1
                "
                :button="[{ key: 'cancelZhanYong', label: '取消占用' }]"
                @handleClickButton="
                  (val) => {
                    return handleClickHandle({ title: 'cancelZhanYong', tabItem });
                  }
                "
              />

              <Y-button
                v-if="tabItem.isCancelFilter"
                :button="[
                  { key: 'cancelFilter', label: $t('configMoudle.cancelFilter') },
                ]"
                @handleClickButton="
                  (val) => {
                    return handleClickHandle({ title: 'cancelFilter', tabItem });
                  }
                "
              />

              <!--显示小计相关功能-->
              <template v-if="tabItem.isShowSubtotal">
                <Y-button
                  :button="[
                    { key: 'subTotalSet', label: $t('configMoudle.subTotalSet') },
                  ]"
                  @handleClickButton="showSubTotalDialog(fieldsConfigVOs, tabItem.id)"
                />
                <Y-button
                  :button="[
                    {
                      key: 'openSubtotal',
                      label:
                        subtotalSwitchStatus == false
                          ? $t('configMoudle.openSubtotal')
                          : $t('configMoudle.closeSubtotal'),
                    },
                  ]"
                  @handleClickButton="
                    (key, value) => {
                      handleSwitchSubTotal(value, tableData, tabItem.id, fieldsConfigVOs);
                    }
                  "
                />
                <!-- <el-switch
                  v-model="subtotalSwitchStatus"
                  @change="(val)=>handleSwitchSubTotal(val,tableData,tabItem.id,fieldsConfigVOs)"
                  >
                </el-switch> -->
              </template>
            </div>
            <Y-table
              :row-class-name="({ row, rowIndex }) => (row.$index = rowIndex)"
              :ref="tabItem.id"
              :key="tabItem.id"
              :isColumnFilter="true"
              :alingitem="'alignType'"
              :column="fieldsConfigVOs"
              :itemsConfigVOs="itemsConfigVOs"
              :isExcelImport="tabItem.isExcelImport"
              :isExcelExport="tabItem.isExcelExport"
              :isBatchModify="tabItem.isBatchModify"
              :tabItemIsReadOnly="tabItem.peddingReadOnly"
              :tableData="
                tableData && tabItem.isPaged
                  ? tableData.slice(
                      (detailCurrentPage - 1) * detailpageSize,
                      detailCurrentPage * detailpageSize
                    )
                  : tableData
              "
              :columnKey="'fieldName'"
              :columnLabel="'showName'"
              :height="theights"
              :handleBtn="!tabItem.isReadOnly ? handleBtn : ['']"
              :cwqrisok="cwqrisok"
              :editCell="
                ((tabItem.isInlineEditable || isInlineEditable) &&
                  !isView &&
                  !tabItem.isReadOnly) ||
                $route.path == '/cggl/zjjh_edit'
              "
              :showSummary="showSummary"
              :selection="false"
              :headerbe="false"
              :handleDisable="tabItem.isReadOnly ? true : handleBtn.length ? false : true"
              @currentChange="(row) => currentChange(row, tabItem)"
              @rowClick="(row) => handleRowClick(row, tabItem)"
              @cellClick="cellClick"
              @selectionChage="handleSelectDtl"
              @handleClickHandle="(obj) => handleClickHandle({ ...obj, tabItem })"
              @handleExport="handleExport(tabItem)"
              @handleImport="handleImport(tabItem)"
              @handleHeaderEdit="(column) => handleHeaderEdit(column, tabItem)"
              @handleHeaderSearch="(column) => handleHeaderSearch(column, tabItem)"
              @sortChange="sortChange"
              v-bind="$attrs"
            >
              <!-- @rowClick="(row) => handleRowClick(row, tabItem)" -->
              <!-- :editCell="
                (tabItem.isInlineEditable || isInlineEditable) &&
                !isView &&
                !tabItem.isReadOnly
              " -->
              <template #myColumn="{ row, column }">
                <Y-input
                  v-if="editShowRowcom(column, 'editType', 'dataType') === 'text'"
                  :input.sync="row[column['fieldName']]"
                  :ref="`y-input-${column['fieldName']}`"
                  @hook:mounted="mountedRes(`y-input-${column['fieldName']}`, 'input')"
                  :fetchId="column.fetchId"
                  @blur="handleBlur"
                  @update:input="handleInput"
                  @suffixclick="suffixclick(itemsConfigVOs, column)"
                ></Y-input>
                <Y-numberInput
                  v-if="editShowRowcom(column, 'editType', 'dataType') === 'number'"
                  :input="
                    row[column['fieldName']]
                      ? row[column['fieldName']]
                      : defaultNumberInput
                  "
                  :type="'number'"
                  :ref="`y-numberInput-${column['fieldName']}`"
                  @input="
                    (val) => {
                      handleNumberInput(val, row, column, itemsConfigVOs);
                    }
                  "
                  @hook:mounted="
                    mountedRes(`y-numberInput-${column['fieldName']}`, 'numberInput')
                  "
                  @blur="handleBlur"
                ></Y-numberInput>
                <Y-date
                  v-if="editShowRowcom(column, 'editType', 'dataType') === 'date'"
                  :value="row[column['fieldName']]"
                  :type="'date'"
                  :ref="`y-date-${column['fieldName']}`"
                  format="timestamp"
                  @input="(val) => (row[column['fieldName']] = val)"
                  @blur="handleBlur"
                  @hook:mounted="
                    mountedRes(`y-date-${column['fieldName']}`, 'datePicker')
                  "
                ></Y-date>
                <el-select
                  size="mini"
                  v-model="row[column['fieldName']]"
                  v-if="editShowRowcom(column, 'editType', 'dataType') === 'select'"
                  :value-key="column.id"
                  @change="handleChange(row, $event)"
                  :ref="`el-select-${column['fieldName']}`"
                  @hook:mounted="mountedRes(`el-select-${column['fieldName']}`, 'select')"
                >
                  <el-option
                    v-for="val in column.dicDataItems"
                    :key="`${val}-select-option`"
                    :value="
                      column.optionValue ? val : val[column.optionValue || 'value'] || val
                    "
                    :label="val[column.optionLabel || 'label'] || val"
                  ></el-option>
                </el-select>
                <el-select
                  size="mini"
                  v-model="row[column['fieldName']]"
                  v-if="editShowRowcom(column, 'editType', 'dataType') === 'inputSelect'"
                  :value-key="column.id"
                  filterable
                  allow-create
                  default-first-option
                  @change="handleChange(row, $event)"
                  :ref="`el-inputSelect-${column['fieldName']}`"
                >
                  <el-option
                    v-for="val in column.dicDataItems"
                    :key="`${val}-inputSelect-option`"
                    :value="
                      column.optionValue ? val : val[column.optionValue || 'value'] || val
                    "
                    :label="val[column.optionLabel || 'label'] || val"
                  ></el-option>
                </el-select>
                <el-switch
                  v-if="editShowRowcom(column, 'editType', 'dataType') === 'switch'"
                  active-color="#13ce66"
                  inactive-color="#ff4949"
                  v-model="row[column['fieldName']]"
                  @change="handleChange(row, $event)"
                >
                </el-switch>
                <img
                  v-if="column.fieldName == 'img_ids'"
                  width="100px"
                  height="100px"
                  :src="row.img_ids[0].url"
                  alt=""
                />
              </template>
            </Y-table>
            <Y-page
              v-if="tabItem.isPaged"
              :isPaged="isPaged"
              :total="
                tabItem.tableData && tabItem.tableData instanceof Array
                  ? tabItem.tableData.length
                  : 0
              "
              :current-page="detailCurrentPage"
              @handleSizeChange="pageSizeChange"
              @handleCurrentChange="pageCurrentChange"
            />
            <el-upload
              class="excel_upload"
              id="uploadfile_controls"
              :action="baseUrl + '/custom/importExcel'"
              :limit="1"
              :data="uploadFormData"
              :headers="headers"
              v-show="false"
              :file-list="uploadFileList"
              :before-upload="beforeAvatarUpload"
              :on-success="avatarUploadSuccess"
              ref="uploadfile_controls"
            >
              <el-button ref="uploadButton" size="small" type="primary">{{
                $t("configMoudle.clickUpload")
              }}</el-button>
            </el-upload>
          </el-collapse-item>
        </el-collapse>
        <!--小计配置-->
        <Y-dialog
          :dialog.sync="subTotalSetOkEditDialog"
          :title="$t('configMoudle.subTotalSet')"
          :width="'60%'"
          :top="'30vh'"
          :destoryOnClone="false"
          @handleOk="handleSubTotalSetOkDialog(tableData)"
          v-if="tabItem.isShowSubtotal"
        >
          <el-form :inline="true" class="subTotalSet">
            <el-form-item
              :label="$t('configMoudle.groupCondition')"
              style="margin-bottom: 10px"
            >
              <el-select v-model="subtotalGroupFiled" placeholder="">
                <el-option
                  v-for="item in groupFileds"
                  :key="item.fieldName"
                  :label="item.showName"
                  :value="item.fieldName"
                >
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('configMoudle.subtotalField')">
              <!-- filterable
                :filter-method="subtotalFiledSetFilterMethod" -->
              <el-transfer
                :titles="[$t('configMoudle.notSelected'), $t('configMoudle.selected')]"
                :props="{
                  key: 'fieldName',
                  label: 'showName',
                }"
                v-model="subtotalFiled"
                :data="selectTotalfileds"
              >
                <span slot-scope="{ option }">{{ option.showName }}</span>
              </el-transfer>
            </el-form-item>
          </el-form>
        </Y-dialog>
      </template>
    </Y-tab>
    <Y-dialog
      :dialog.sync="dialog"
      :title="dialogTitle"
      :destoryOnClone="false"
      @handleOk="handleOk"
    >
      <Y-form
        :form="toForm(tabItem.itemsConfigVOs) || []"
        :disabledKey="'isReadOnly'"
        :formData="formData"
        :itemLabel="'itemShowName'"
        :itemProp="'itemName'"
        :itemTypeKey="'editType'"
        :firstTab="firstTab"
        :isImage="isImage"
        :imageList="imageList"
        :dataId="dataId"
        :disabledReadOnly="isReadOnlyform"
        @handleFocus="handleFo"
        @handleUpload="handleUpload"
        @handleRemove="handleRemove"
        inline
      />
    </Y-dialog>
    <Y-dialog
      v-if="transObj.fieldsVOs.column.length"
      :dialog.sync="transDialog"
      :title="transObj.value"
      :top="'1vh'"
      @handleOk="handleTrans(transObj.fieldsVOs.tableData, transObj.fieldsVOs.column)"
    >
      <div
        class="search-zone"
        v-if="transObj.fieldsVOs.searchInput && transObj.fieldsVOs.searchInput.length"
      >
        <div class="insBtn">
          <Y-inputs
            :inputs="transObj.fieldsVOs.searchInput"
            :keyEn="'fieldName'"
            :keyCh="'showName'"
            :itemTypeKey="'dataType'"
            ref="Yinput"
          />
          <Y-button
            :button="[
              { key: 'search', label: $t('configMoudle.search'), type: 'primary' },
            ]"
            @handleClickButton="handleClickButton"
            style="margin-bottom: 10px"
          />
        </div>
      </div>
      <div v-else></div>
      <!-- <Y-table
        :column="transObj.fieldsVOs.column"
        :tableData="transObj.fieldsVOs.tableData"
        :columnKey="'fieldName'"
        :columnLabel="'showName'"
        :handleDisable="true"
        ref="dialogTableMains"
        :max-height="tableHeight"
        @selectionChage="handleSelect"
      /> -->
      <Y-radioTable
        :column="transObj.fieldsVOs.column"
        :tableData="transObj.fieldsVOs.tableData"
        :max-height="tableHeight"
        @roadeChange="roadeChange"
      />
      <el-pagination
        v-if="transObj.isPaged"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[20, 50, 100, 200]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalData"
      >
      </el-pagination>
    </Y-dialog>
    <Y-dialog
      v-if="transObj.fieldsVOs.column.length"
      :dialog.sync="transDialogs"
      :title="transObj.value"
      :top="'1vh'"
      @handleOk="handleTranss(transObj.fieldsVOs.tableData, transObj.fieldsVOs.column)"
    >
      <Y-table
        :column="transObj.fieldsVOs.column"
        :tableData="transObj.fieldsVOs.tableData"
        :columnKey="'fieldName'"
        :columnLabel="'showName'"
        :handleDisable="true"
        ref="dialogTableMains"
        :max-height="tableHeight"
        @selectionChage="handleSelect"
      />
      <el-pagination
        v-if="transObj.isPaged"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[20, 50, 100, 200]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="totalData"
      >
      </el-pagination>
    </Y-dialog>
    <!-- 向上移动 -->
    <Y-dialog
      v-if="moveLine"
      :dialog.sync="moveLine"
      :title="$t('configMoudle.linenum')"
      :width="'30%'"
      :top="'30vh'"
      @handleOk="handleLine"
    >
      <Y-form :form="columns" :formData="lineData" @handleSelect="handleSelect" inline />
    </Y-dialog>
    <Y-dialog
      :dialog.sync="headEditDialog"
      :title="$t('configMoudle.bulkEdit')"
      :destoryOnClone="false"
      @handleOk="handleOkDialog"
    >
      <el-input
        v-if="columnField.dataType == 'text'"
        v-model="property"
        size="mini"
        :placeholder="$t('configMoudle.content')"
      ></el-input>
      <el-input
        v-if="columnField.dataType == 'decimal'"
        v-model="property"
        size="mini"
        :placeholder="$t('configMoudle.content')"
      ></el-input>
      <el-date-picker
        v-if="columnField.dataType == 'date'"
        v-model="property"
        size="mini"
        value-format="yyyy-MM-dd"
        type="date"
        :placeholder="$t('configMoudle.selectDate')"
      ></el-date-picker>
      <!-- 目前是字典的没有value和 label  如果其他的  到时候或运算就可以 -->

      <el-select
        v-if="columnField.dataType == 'select'"
        v-model="property"
        size="mini"
        :placeholder="$t('configMoudle.select')"
      >
        <el-option
          v-for="(item, index) in columnField.dicDataItems"
          :key="index"
          :label="item"
          :value="item"
        >
        </el-option>
      </el-select>
    </Y-dialog>
    <!-- excel复制数据弹框 -->
    <el-dialog
      title="excel复制"
      :visible.sync="exceldisabled"
      width="50%"
      :before-close="exceldisabledNo"
    >
      <el-input
        type="textarea"
        autosize
        placeholder="excel复制的数据"
        v-model="exceltextarea"
      >
      </el-input>
      <span slot="footer" class="dialog-footer">
        <el-button @click="exceldisabledNo">{{ $t("configMoudle.cancel") }}</el-button>
        <el-button type="primary" @click="exceldisabledYes">{{
          $t("configMoudle.confirm")
        }}</el-button>
      </span>
    </el-dialog>
    <!-- 递归组件，如果下面还有表格自动递归该组件 -->
    <template v-if="item.children">
      <Y-common
        v-for="(val, id) in item.children"
        :key="id"
        :item="val"
        :goods="goodsRow"
        :allData="allData"
        :formData="formData"
        :indexList="indexList"
        :handleBtn="handleBtns"
        theights="300"
        @countlistdata="(name, count) => countlistdata(name, count, rowIndex)"
        @handleImportLAist="(data) => handleImportLAist(data, newrowdata)"
        @updateQty="updateDetail"
      ></Y-common>
    </template>
    <!-- 库存占用 -->
    <el-dialog
      width="90%"
      title="库存占用"
      :visible.sync="occupyVisible"
      :close-on-click-modal="false"
      :show-close="true"
      class="dialog-occupy"
    >
      <el-descriptions
        v-if="occupyVisible"
        :labelStyle="{ width: '150px' }"
        :contentStyle="{ width: '200px' }"
        :column="3"
        class="margin-top"
        border
      >
        <el-descriptions-item :label="$t('addTips.sono')">{{
          item.row.sono
        }}</el-descriptions-item>
        <el-descriptions-item :label="$t('addTips.itemno')">{{
          item.row.itemno
        }}</el-descriptions-item>
        <el-descriptions-item :label="$t('addTips.productName')">{{
          item.row.sdesc
        }}</el-descriptions-item>
        <el-descriptions-item :label="$t('addTips.purchaseQuantity')">{{
          item.row.qty
        }}</el-descriptions-item>
        <el-descriptions-item :label="$t('addTips.zyqty')">{{
          item.row.zyqty
        }}</el-descriptions-item>
        <el-descriptions-item :label="$t('addTips.poqty')">{{
          item.row.poqty
        }}</el-descriptions-item>
      </el-descriptions>
      
      <el-table
        id="occTable"
        v-if="occupyVisible"
        style="margin-top: 10px"
        highlight-current-row
        :row-style="rowStyle"
        :row-class-name="({ row, rowIndex }) => (row.$index = rowIndex)"
        :ref="tabItem.id + 'zy'"
        :key="tabItem.id + 'zy'"
        :column="zyfieldsConfigVOs"
        :data="zyList"
        :height="520"
        :show-summary="true"
        :summary-method="getSummaries"
        @current-change="(row) => currentChangeZY(row, tabItem)"
      >
        <el-table-column
          v-for="(val, index) in zyfieldsConfigVOs"
          :key="index"
          :prop="val['fieldName']"
          :label="val['showName']"
          align="center"
          :fixed="val.fixed || val.isLocked"
          :min-width="val.width === 'auto' ? '' : val.width || val.colWidth || 120"
          :formatter="val.formatter"
          :show-overflow-tooltip="val.fieldName != 'img_ids'"
        >
          <template #header="{ column }">
            {{ column.label }}
            <el-popover placement="top" width="400" trigger="click">
              <el-input
                :placeholder="$t('configMoudle.content')"
                v-model="inputSearch"
                @input="zySearch(arguments[0], column)"
                size="mini"
                clearable
              ></el-input>
              <i style="cursor: pointer" class="el-icon-search" slot="reference"></i>
            </el-popover>
          </template>
          <template #default="scope" v-if="val['fieldName'] == 'occupyQty'">
            <el-input-number
              v-if="!scope.row.sono"
              v-model="scope.row.occupyQty"
              @change="zyChange($event, scope.row)"
              :min="0"
              :max="scope.row.qty"
              controls-position="right"
              style="width: 100%"
            />
            <span v-else>
              {{ scope.row.occupyQty }}
            </span>
          </template>
        </el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button
          size="mini"
          :loading="occupyLoading"
          @click="() => (occupyVisible = false)"
          >{{ $t("FetchSet.cancel") }}</el-button
        >
        <el-button
          size="mini"
          :loading="occupyLoading"
          @click="handlerOccupy"
          type="primary"
          >{{ $t("FetchSet.generateOrder") }}</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  renderData,
  exportData,
  employ,
  unEmploy,
  occupyBatch,
  cancelOccupy,
} from "@/api/list";
import { dataType } from "@/assets/js/table";
import FormatData from "@/mixin/undateData";
import { switchTime, getSession, subtotal, setSession, checkFont } from "@/utils/methods";
import { transConfigId, fetchList as fetchDataApi } from "@/api/detail";
import { isNum } from "@/utils/validate";
import clone from "clone";
const _dataType = dataType();
export default {
  mixins: [FormatData],
  name: "Y-common",
  inject: ["reload"],
  props: {
    item: {
      type: Object,
    },
    allData: {
      type: Object,
    },
    formData: {
      type: Object,
    },
    parentId: [String, Number],
    handleBtn: {
      type: Array,
      default: () => [],
    },
    checkout: {
      type: String,
    },
    indexList: {
      type: Array,
      default: () => [],
    },
    goods: {
      type: Object,
      default: () => {},
    },
    cwqrisok: {
      type: Boolean,
      default: false,
    },
    theights: {
      type: String,
      default: "400",
    },
  },
  data() {
    return {

      occupyLoading: false,
      occupyVisible: false,
      occupyForm: {},
      occupyRules: {
        occupyQty: [
          { type: "number", require: true, message: this.$t("addTips.occupyNum") },
        ],
      },
      recordRowCount: 0,
      recordRowCounttwo: 0,
      recordcolumn: "",
      exceldisabled: false,
      exceltextarea: "",
      subTotalSetOkEditDialog: false,
      subtotalSwitch: false,
      subtotalFiled: [],
      subtotalGroupFiled: "",
      subtotalSwitchStatus: false,
      groupFileds: [],
      selectTotalfileds: [],
      isReadOnlyform: false,
      newrowdata: {},
      isPaged: true,
      goodsRow: {},
      baseUrl: this.$baseUrl,
      uploadFileList: [],
      uploadFormData: {},
      headers: {
        Authorization: getSession("vue_admin_template_token"),
        AcceptLanguage: getSession("LanguageName") || "zh-cn",
      },
      activeNames: ["1"],
      property: "",
      headEditDialog: false,
      columnField: {},
      tableHeight: "500px",
      watchers: [],
      imageList: [],
      dataId: "",
      firstTab: false,
      isImage: false,
      dialog: false,
      showSummary: true,
      totalData: 0,
      pageSize: 20,
      currentPage: 1,
      detailCurrentPage: 1,
      detailpageSize: 20,
      // formData: {},
      tabItem: {},
      button: [{ key: "add", label: this.$t("configMoudle.add") }],
      buttonse: [{ key: "excelCopy", label: this.$t("configMoudle.excelCopy") }],
      buttonedsa: [{ key: "deleteall", label: this.$t("configMoudle.batchDelete") }],
      dialogTitle: "",
      isInlineEditable: false, // 是否行内编辑
      isView: false,
      transDialog: false,
      transDialogs: false,
      transObj: {
        fieldsVOs: {
          column: [],
          tableData: [],
        },
      },
      selectList: [],
      selectDtlList: [],
      rowIndex: 0,
      itemName: "",
      moveLine: false,
      columns: [{ prop: "line", label: this.$t("configMoudle.replaceLineNumber") }],
      lineData: {},
      currentChangecouint: 0,
      handleBtns: [
        // 表格的操作栏
        {
          class: "el-icon-edit",
          key: "edit",
          title: this.$t("configMoudle.edit"),
        },
        {
          class: "el-icon-delete",
          key: "delete",
          title: this.$t("configMoudle.delete"),
        },
        {
          class: "el-icon-document-copy",
          key: "copy",
          title: this.$t("configMoudle.copy"),
        },
        {
          class: "el-icon-circle-plus-outline",
          key: "add",
          title: this.$t("configMoudle.add"),
        },
        {
          class: "el-icon-arrow-up",
          key: "move",
          title: this.$t("configMoudle.moveRow"),
        },
      ],
      defaultNumberInput: undefined,
      zyList: [],
      copyZyList: [],
      inputSearch: '',
    };
  },
  computed: {
    // dialogTitle() {
    //   if (JSON.stringify(this.formData) === "{}") {
    //     return `${this.tabItem.header || ""}新增`;
    //   } else {
    //     return `${this.tabItem.header || ""}编辑`;
    //   }
    // },
    zytTotal() {
      return this.zyList.reduce((accumulator, currentValue) => {
        let res = accumulator + currentValue.occupyQty;
        if (res.toString().includes(".")) {
          // 若小数位超过4位，则只截取前4位
          let main = res.toString().split(".")[0],
            point = res.toString().split(".")[1];
          if (point.length > 4) return Number(main + "." + point.slice(0, 4));
          else return res;
        } else return res;
      }, 0);
    },

    handleType() {
      const query = this.$route.query;
      return !query.hasOwnProperty("id")
        ? !query.hasOwnProperty("configId")
          ? "list"
          : "create"
        : "edit";
    },
    pathFilter() {
      const path = this.$route.path;
      if (!path.includes("add")) return true;
    },
  },
  watch: {
    // allData: {
    //   deep: true,
    //   handler:function(newV,oldV){
    //   }
    // },
    occupyVisible(val) {
      if (!val) {
        this.occupyForm = {};
        this.zyList = [];
        this.zyfieldsConfigVOs = [];
      }
    },

    dialog(newVal) {
      if (!newVal) {
        this.formData = {};
        this.imageList = [];
        this.dataId = "";
      }
    },
    // 每个表格的是数据变化触发
    "tabItem.tableData": {
      handler(tableData) {
        if (!tableData?.length) {
          this.$set(this.tabItem, "row", null);
          return;
        }
        /* 先注释杨家豪的代码，影响明细表格中soamt字段的计算 */
        // for(let i in tableData){
        //   if(tableData[i].soamt){
        //     tableData[i].soamt=Math.floor(tableData[i].soamt*100)/100;
        //   }
        // }
        // 着重看透下面的代码，f的判断是决定传参的区别
        const obj = {
          formId: this.tabItem?.id,
          data: tableData,
        };
        let row = this.item.row;
        if (row) {
          let f = null;
          if (!row._children) {
            row._children = [];
          } else {
            this.$nextTick(() => {
              f = row._children?.findIndex((item) => {
                return item.formId == this.item.childrenActive;
              });
            });
          }
          this.$nextTick(() => {
            f === -1
              ? row._children.push(obj)
              : row._children.splice(f, 1, { ...obj, ...row._children[f] });
          });
        }
      },
      deep: true,
      immediate: true,
    },
    "tabItem.row"(row) {
      this.setTableCurrentRow(this.tabItem?.id, row);
    },
    "item.row"(row) {
      if (!row) return;
      const f = row?._children?.some(
        (item) =>
          (item.formId === this.item.childrenActive && item.data.length) ||
          item.delList?.length
      );
      if (row?.id && this.item.childrenActive && !f) this.initData(row);
      if (row._children) this.updateChildrenTable(row._children, this.item.children);
    },
  },
  created() {
    // 采购计划的占用明细表格先隐藏表格操作栏
    if (this.$route.path == "/ck/plan") this.handleBtns = [];
  },
  mounted() {
    if (this.$route.path.indexOf("edit") != -1 || this.$route.path.indexOf("add") != -1)
      this.isInlineEditable = true;
    //是否仅查看
    if (this.$route.path.indexOf("view") != -1) {
      this.isView = true;
    }
    let font = checkFont();
    this.fonts = font;
    let all = document.getElementsByTagName("*"),
      reg = new RegExp("[\\u4E00-\\u9FFF]+", "g");
    for (var i = 0; i < all.length; i++) {
      if (reg.test(all[i].innerText)) {
        all[i].style.fontSize = font;
      }
    }
    this.uploadtroute();
    this.$nextTick(function () {
      //处理点击报关触发单证
      this.$on("childmethods", function (cur, tab) {
        this.handleTabClick({ cur, tab });
      });
    });
    console.log(this.item);
  },
  updated() {
    if (this.$refs[this.tabItem.id]) {
      this.$refs[this.tabItem.id].$refs.Ytable.doLayout();
    }
    // this.$nextTick(() => {
    //   this.$refs['activityTable'].doLayout();
    // })
  },
  activated() {
    // this.init()
    //页面级别缓存刷新数据、刷新明细的数据
    if (this.$route.query && this.$route.query.refreshDetailData) {
      /* 先注掉  问宝哥会不会影响 */
      this.$root.$on("loadDetailItemData", (item) => {
        // this.item = item
        // this.initData(item.row)
      });
    }
    // let closeTagList = this.$store.state.global.tagCloseDetailRouteList
    // if (closeTagList && closeTagList.length > 0){
    //   if (closeTagList.indexOf(this.$route.path) != -1){
    //     // this.$store.commit("global/DEL_TAG_CLEAR_DETAIL_CATCH",this.$route.path)
    //     // this.pageFirstLoad = false
    //   }
    // }
  },
  methods: {
    rowStyle({row, rowIndex}) {
      return row.result=='不合格' ? {background:"#f56c6c"} : row.occupyQty>0 ?  {background:"yellow"}:{}
    },
    handleSwitchSubTotal(e, tableData, id, fieldsConfigVOs) {
      this.subtotalSwitchStatus = !this.subtotalSwitchStatus;
      if (e == this.$t("configMoudle.openSubtotal")) {
        if (!localStorage.getItem("subtotal_" + id)) {
          this.$message.error(this.$t("configMoudle.noConfigTotal"));
          this.subtotalSwitchStatus = false;
          return;
        }
        localStorage.setItem("subtotal_switch_" + id, true);
        let configObject = JSON.parse(localStorage.getItem("subtotal_" + id));
        subtotal(
          tableData,
          configObject.subtotalGroupFiled,
          configObject.subtotalFiled,
          fieldsConfigVOs
        );
      } else {
        localStorage.removeItem("subtotal_switch_" + id);
        for (let j = tableData.length - 1; j >= 0; j--) {
          if (tableData[j].subtotal) {
            tableData.splice(j, 1);
          }
        }
      }
    },
    showSubTotalDialog(field, id) {
      setSession("subtotal_modelid", id);
      if (localStorage.getItem("subtotal_" + id)) {
        try {
          let configObject = JSON.parse(localStorage.getItem("subtotal_" + id));
          this.subtotalFiled = configObject.subtotalFiled;
          this.subtotalGroupFiled = configObject.subtotalGroupFiled;
        } catch (error) {}
      }
      this.groupFileds = this.subtotalColumnList(field, 2);
      this.selectTotalfileds = this.subtotalColumnList(field, 1);
      this.subTotalSetOkEditDialog = true;
    },
    subtotalFiledSetFilterMethod(query, item) {
      return item.showName.indexOf(query) > -1;
    },
    handleSubTotalSetOkDialog() {
      if (!this.subtotalGroupFiled) {
        this.$message.error(this.$t("configMoudle.noGroup"));
        return;
      }
      if (!this.subtotalFiled || this.subtotalFiled.length <= 0) {
        this.$message.error("configMoudle.noTotalField");
        return;
      }
      localStorage.setItem(
        "subtotal_" + getSession("subtotal_modelid"),
        JSON.stringify({
          subtotalGroupFiled: this.subtotalGroupFiled,
          subtotalFiled: this.subtotalFiled,
        })
      );
      if (this.subtotalSwitchStatus) {
        this.subtotalSwitchStatus = false;
      }
      this.subTotalSetOkEditDialog = false;
    },
    subtotalColumnList(columns, type) {
      if (type == 1) {
        return columns.filter((item) => {
          return (
            (item.hasOwnProperty("isShow") &&
              item.dataType == "decimal" &&
              item.isShow &&
              item.showName !== "" &&
              item.showName !== null &&
              item.showName !== undefined) ||
            !item.hasOwnProperty("isShow")
          );
        });
      } else {
        return columns.filter((item) => {
          return (
            (item.hasOwnProperty("isShow") &&
              item.dataType == "text" &&
              item.isShow &&
              item.showName !== "" &&
              item.showName !== null &&
              item.showName !== undefined) ||
            !item.hasOwnProperty("isShow")
          );
        });
      }
    },

    countlistdata(name, count, index) {
      if (!this.tabItem?.tableData?.length) {
        this.initData(this.item.row);
      } else {
        this.$watch(() => {
          this.tabItem.tableData[index][name] = count;
        });
        this.$nextTick(() => {
          this.handleRowClick(this.tabItem.tableData[index], this.tabItem);
        });
      }
    },
    handleChange(val) {
      this.handleBlur();
    },
    // 切换数据行号
    handleLine(vnode = []) {
      const formVm = vnode[0].componentInstance;
      const formData = this._.cloneDeep(formVm.curData);
      !this.tabItem.tableData && this.$set(this.tabItem, "tableData", []);
      if (formData.line > this.tabItem.tableData.length) {
        this.$message({
          message: this.$t("configMoudle.tip19"),
          type: "warning",
        });
        return;
      }
      this.tabItem.tableData.splice(this.selectDtlList[0].$index, 1);
      // line更新到换行的位置
      this.tabItem.tableData.splice(formData.line - 1, 0, this.selectDtlList[0]);
      for (let i = 0; i < this.tabItem.tableData.length; i++) {
        this.$set(this.tabItem.tableData[i], "$index", i);
        this.$set(this.tabItem.tableData[i], "line", i + 1);
      }
      this.moveLine = false;
    },
    pageSizeChange(size) {
      this.detailpageSize = size;
      if (this.tabItem.isPaged) {
        let count = this.detailCurrentPage - 1;
        this.recordRowCounttwo =
          count * this.detailpageSize + Number(this.recordRowCount);
      } else {
        this.recordRowCounttwo = Number(this.recordRowCount);
      }
    },
    pageCurrentChange(page) {
      this.detailCurrentPage = page;
      if (this.tabItem.isPaged) {
        let count = this.detailCurrentPage - 1;
        this.recordRowCounttwo =
          count * this.detailpageSize + Number(this.recordRowCount);
      } else {
        this.recordRowCounttwo = Number(this.recordRowCount);
      }
    },
    handleSizeChange(val) {
      const searchData = this.$refs.Yinput.inputsData;
      for (let i in searchData) {
        if (!searchData[i].length) {
          delete searchData[i];
        }
      }
      this.pageSize = val;
      fetchDataApi({
        params: { fetchConfigId: this.transObj.key, pageNum: 1, pageSize: this.pageSize },
        data: {
          filter: searchData || {},
        },
      }).then((res) => {
        if (!res.result.length) return;
        this.$set(this.transObj.fieldsVOs, "tableData", res.result);
        this.pageSize = res.pageSize;
        this.pageNum = res.pageNum;
        this.totalData = res.totalRecord;
        this.transObj.fieldsVOs.row = res.result[0];
        // this.transDialogs = true;
        // this.itemName = item.itemName;
      });
    },
    handleCurrentChange(val) {
      const searchData = this.$refs.Yinput.inputsData;
      for (let i in searchData) {
        if (!searchData[i].length) {
          delete searchData[i];
        }
      }
      this.currentChangecouint = val;
      fetchDataApi({
        params: {
          fetchConfigId: this.transObj.key,
          pageNum: this.currentChangecouint,
          pageSize: this.pageSize,
        },
        data: {
          filter: searchData || {},
        },
      }).then((res) => {
        if (!res.result.length) return;
        this.$set(this.transObj.fieldsVOs, "tableData", res.result);
        this.pageSize = res.pageSize;
        this.pageNum = res.pageNum;
        this.totalData = res.totalRecord;
        this.transObj.fieldsVOs.row = res.result[0];
        // this.transDialogs = true;
        // this.itemName = item.itemName;
      });
    },
    handleInput(val) {},
    recursion(arr, span, spanIndex) {
      for (let i = 0; i < arr.length; i++) {
        if (arr[i].mainTableName == span[spanIndex]) {
          if (Number(spanIndex + 1) >= Number(span.length - 1)) {
            let obj = {
              data: arr[i].tableData,
              index: spanIndex,
            };
            return obj;
          } else {
            let spanx = Number(spanIndex + 1);
            this.recursion(arr[i].children, span, spanx);
          }
        }
      }
    },
    isNumber(val) {
      var regPos = /^\d+(\.\d+)?$/; //非负浮点数
      var regNeg = /^(-(([0-9]+\.[0-9]*[1-9][0-9]*)|([0-9]*[1-9][0-9]*\.[0-9]+)|([0-9]*[1-9][0-9]*)))$/; //负浮点数
      if (regPos.test(val) || regNeg.test(val)) {
        return true;
      } else {
        return false;
      }
    },
    // 单击单元格事件
    cellClick(row, column) {
      this.recordRowCount = row.$index;
      this.recordcolumn = column.property;
      if (this.tabItem.isPaged) {
        let count = this.detailCurrentPage - 1;
        this.recordRowCounttwo = count * this.detailpageSize + Number(row.$index);
      } else {
        this.recordRowCounttwo = Number(row.$index);
      }
    },
    handleRowClick(row, tabItem) {
      this.$refs[tabItem.id].$children[0].setCurrentRow(row);
      const _that = this;
      if (tabItem.header != "库存明细" || tabItem.header != "占用明细") {
      }
      if (tabItem.header == "商品明细") {
        this.goodsRow = row;
        Object.assign(this.goodsRow, row);
      }
      this.rowIndex = row.$index;
      if (tabItem?.level) {
        if (!this.indexList.length) this.indexList.push(row.$index);
        else if (this.indexList.length < tabItem.level) this.indexList.push(row.$index);
        else if ((this.indexList.length = tabItem.level))
          this.$set(this.indexList, tabItem.level - 1, row.$index);
        else this.indexList.splice(tabItem.level - 1, this.indexList.length - 1);
      }
      // 行内编辑自定义公式  1111
      for (let i = 0; i < tabItem.itemsConfigVOs.length; i++) {
        if (tabItem.itemsConfigVOs[i].label == tabItem.header) {
          for (let j = 0; j < tabItem.itemsConfigVOs[i].items.length; j++) {
            if (tabItem.itemsConfigVOs[i].items[j].expression) {
              // const c = tabItem.itemsConfigVOs[i].items[j].expression.replace(
              //   /([\w]+)/g,
              //   (match) => {
              //     return `parseFloat(tabItem.tableData[row.$index].${match})`;
              //   }
              // );
              const c = tabItem.itemsConfigVOs[i].items[j].expression.replace(
                /([\w]|\.)+/g,
                (match) => {
                  if (match.indexOf(".") != -1) {
                    let span = match.split(".");
                    if (span[0] == "form") {
                      // match = this.allData.formData[span[1]]
                      match = this.formData[span[1]];
                      return `parseFloat(${match})`;
                    } else {
                      //
                      // match = this.allData.formData[span[1]]
                      // 需要上级表格而不是表单，所以是至少是从从表
                      let { data, index } = this.recursion(
                        this.allData.children,
                        span,
                        0
                      );
                      match = data[this.indexList[index]][span[span.length - 1]];
                      return `parseFloat(${match})`;
                    }
                  } else {
                    // 判断是常数还是数字类型
                    if (this.isNumber(match)) {
                      // 是数字
                      return `parseFloat(${match})`;
                    } else {
                      // 是字符串
                      return `parseFloat(tabItem.tableData[row.$index].${match})`;
                    }
                    //  return `parseFloat(tabItem.tableData[row.$index].${match})`
                  }
                  // return `parseFloat(tabItem.tableData[row.$index].${match})`;
                }
              );
              setTimeout(() => {
                // 新加的一行，可能下面this.$watch当结果计算为0 的时候不知道为啥不触法增加的一行
                tabItem.itemsConfigVOs[i].items[j].expressionDecimalDigit ||
                tabItem.itemsConfigVOs[i].items[j].expressionDecimalDigit == 0
                  ? this.$set(
                      tabItem.tableData[row.$index],
                      tabItem.itemsConfigVOs[i].items[j].itemName,
                      isNaN(eval(c))
                        ? 0
                        : eval(c).toFixed(
                            tabItem.itemsConfigVOs[i].items[j].expressionDecimalDigit
                          )
                    )
                  : this.$set(
                      tabItem.tableData[row.$index],
                      tabItem.itemsConfigVOs[i].items[j].itemName,
                      isNaN(eval(c)) ? 0 : eval(c)
                    );
                this.watchers.push(
                  this.$watch(
                    () => eval(c),
                    (a) => {
                      if (isNaN(a)) {
                      } else {
                        // this.$set(
                        //   tabItem.tableData[row.$index],
                        //   tabItem.itemsConfigVOs[i].items[j].itemName,
                        //   a
                        // );
                        tabItem.itemsConfigVOs[i].items[j].expressionDecimalDigit ||
                        tabItem.itemsConfigVOs[i].items[j].expressionDecimalDigit == 0
                          ? this.$set(
                              tabItem.tableData[row.$index],
                              tabItem.itemsConfigVOs[i].items[j].itemName,
                              isNaN(eval(c))
                                ? 0
                                : eval(c).toFixed(
                                    tabItem.itemsConfigVOs[i].items[j]
                                      .expressionDecimalDigit
                                  )
                            )
                          : this.$set(
                              tabItem.tableData[row.$index],
                              tabItem.itemsConfigVOs[i].items[j].itemName,
                              isNaN(eval(c)) ? 0 : eval(c)
                            );
                      }
                    }
                  )
                );
              }, 100);
            }

            if (
              tabItem.itemsConfigVOs[i].items[j].combinedField != "" &&
              tabItem.itemsConfigVOs[i].items[j].combinedField != null &&
              tabItem.itemsConfigVOs[i].items[j].combinedField != undefined
            ) {
              let list = tabItem.itemsConfigVOs[i].items[j].combinedField;
              let newlist = list.split("#");
              let countditel = 0;
              if (newlist[0] == "form") {
                let count = 0;
                let span = tabItem.itemsConfigVOs[i].items[j].itemName;
                let newaddlist = JSON.parse(JSON.stringify(this.tabItem.tableData));
                for (let i = 0; i < newaddlist.length; i++) {
                  if (
                    newaddlist[i][span] == "" ||
                    newaddlist[i][span] == null ||
                    newaddlist[i][span] == undefined
                  ) {
                    count += 0;
                  } else {
                    count += Number(newaddlist[i][span]);
                  }
                }
                if (newlist[1] == "sum") {
                  this.$emit("countlist", newlist[2], count);
                } else if (newlist[1] == "min") {
                  let min = newaddlist.sort(this.compare(span));
                  this.$emit("countlist", newlist[2], min[0][span]);
                } else if (newlist[1] == "max") {
                  let max = newaddlist.sort(this.compare(span));
                  this.$emit("countlist", newlist[2], max[newaddlist.length - 1][span]);
                } else if (newlist[1] == "avg") {
                  let avg = Number(count) / Number(newaddlist.length);
                  this.$emit("countlist", newlist[2], avg);
                }
              } else if (newlist[0] == "list") {
                let spaname = tabItem.itemsConfigVOs[i].items[j].itemName;
                let addlist = JSON.parse(JSON.stringify(this.tabItem.tableData));

                for (let i = 0; i < addlist.length; i++) {
                  if (
                    addlist[i][spaname] == "" ||
                    addlist[i][spaname] == null ||
                    addlist[i][spaname] == undefined
                  ) {
                    countditel += 0;
                  } else {
                    countditel += Number(addlist[i][spaname]);
                  }
                }
                if (newlist[1] == "sum") {
                  this.$emit("countlistdata", newlist[2], countditel);
                } else if (newlist[1] == "min") {
                  let min = addlist.sort(this.compare(spaname));
                  this.$emit("countlistdata", newlist[2], min[0][spaname]);
                } else if (newlist[1] == "max") {
                  let max = addlist.sort(this.compare(spaname));
                  this.$emit(
                    "countlistdata",
                    newlist[2],
                    max[addlist.length - 1][spaname]
                  );
                } else if (newlist[1] == "avg") {
                  let avg = Number(countditel) / Number(addlist.length);
                  this.$emit("countlistdata", newlist[2], avg);
                }
              } else {
              }
            }
          }
        }
      }
    },
    compare(property) {
      return function (a, b) {
        //value1 - value2升序
        //value2 - value1降序
        var value1 = a[property];
        var value2 = b[property];
        return value1 - value2; //升序
      };
    },
    async currentChange(row, tabItem) {
      setTimeout(() => {
        this.tabItem = tabItem;
        this.newrowdata = clone(row);
      });
      this.dataId = row?.id;
      let newObj = JSON.parse(JSON.stringify(tabItem));
      const formItem = tabItem.itemsConfigVOs.reduce((a, b) => a.concat(b.items), []);
      let fetchIdData = tabItem.itemsConfigVOs[0].items.filter(function (item) {
        return item.isShow && item.fetchId;
      });
      tabItem.fieldsConfigVOs = tabItem.fieldsConfigVOs
        .filter((item) => item.isShow)
        .map((column) => {
          const formType = formItem.find((f) => column.fieldName === f.itemName)
            ?.editType;
          let t = _dataType.find(
            (l) => l.value === column.dataType || l.label === column.dataType
          )?.label;
          if (t === "string") t = "text";
          if (column.dicDataItems) {
            if (column.editType == 12) t = "inputSelect";
            else t = "select";
            column.options = column.dicDataItems;
          }
          return {
            ...column,
            dataType: t,
            editType: this._formType[formType] || "",
          };
        });
      for (let i = 0; i < fetchIdData.length; i++) {
        for (let j = 0; j < tabItem.fieldsConfigVOs.length; j++) {
          if (fetchIdData[i].itemName === tabItem.fieldsConfigVOs[j].fieldName) {
            tabItem.fieldsConfigVOs[j].fetchId = fetchIdData[i].fetchId;
            break;
          }
        }
      }
      if (newObj.isImage) {
        for (let i = 0; i < newObj.fieldsConfigVOs.length; i++) {
          //  这里是无关报错
          if (newObj.fieldsConfigVOs[i]?.fieldName == "img_ids") {
            if (tabItem.fieldsConfigVOs[i]?.fieldName == "img_ids") {
            } else
              tabItem.fieldsConfigVOs.splice(i, 0, {
                fieldName: "img_ids",
                isLocked: newObj.fieldsConfigVOs[i].isLocked,
                showName: newObj.fieldsConfigVOs[i].showName,
                isShow: true,
              });
            this.isImage = true;
            this.firstTab = true;
            break;
          }
        }
      }
      let t = row?.img_ids;
      if (t) row.img_ids = [];
      try {
        t?.forEach((item) => {
          if (item.url) {
            row.img_ids.push(item);
          } else {
            row.img_ids.push({ url: item });
          }
        });
      } catch (err) {}
      this.$set(tabItem, "row", row);
      if (!row?._children) {
        tabItem.children?.forEach((item) => this.$set(item, "tableData", []));
      } else {
        tabItem.children?.forEach((item) => {
          const child = row._children.find((v) => item?.id === v.formId);
          if (child) {
            item.tableData = child.data;
            item.row = child.data[0];
          } else {
            item.tableData = [];
            item.row = null;
          }
        });
      }
    },
    // 占用明细切换时
    currentChangeZY(row, tabItem) {
      this.occupyForm = row;
      this.occupyForm.poqty = this.item.totalNeed;
    },
    zyChange(val, row) {
      if (!val) val = 0;
      row.occupyQty = val;
      this.occupyForm = row;
    },
    handleBlur(e) {
      let field = this.tabItem.fieldsConfigVOs
        .map((item) => {
          if (item.dataType == "date") return item.fieldName;
        })
        .filter((item) => {
          if (item) return item;
        });
      let row = this.tabItem.row;

      /* 筛选修改  */
      if (this.tabItem.indexArr && this.tabItem.tableArr) {
        let indexI = null;
        this.item.row._children.forEach((item, index) => {
          if (item.formId == this.tabItem.id) {
            //
            indexI = index;
          }
        });
        for (let i = 0; i < this.tabItem.indexArr.length; i++) {
          let index = this.tabItem.indexArr[i];
          this.$set(
            this.item.row._children[indexI].data,
            index,
            this.tabItem.tableData[i]
          );
        }
      }
      field?.forEach((item) => {
        this.tabItem.row[item] = switchTime(this.tabItem.row[item], "{y}-{m}-{d}");
      });
      let data = {
        formId: this.tabItem?.id,
        data: this.formatData([
          {
            ...row,
            _children: this._.cloneDeep(row?._children),
          },
        ]),
      };
      // 采购计划下可以编辑后失焦保存明细
      if (this.$route.path == "/ck/plan") {
        this.$emit("updateQty", data);
      }
    },
    updateDetail(data) {
      this.$emit("updateDetail", data);
    },
    updateChildrenTable(a, b) {
      a?.forEach((item) => {
        const child = b.find((v) => item.formId === v?.id);
        if (child) {
          child.tableData = item.data;
          child.row = item.data[0];
        } else {
          if (child?.tableData) child.tableData = [];
          if (child?.row) child.row = null;
        }
      });
    },
    async handleTabClick({ cur, tab }) {
      if (localStorage.getItem("subtotal_switch_" + cur?.id)) {
        this.subtotalSwitchStatus = true;
      } else {
        this.subtotalSwitchStatus = false;
      }
      this.$set(this.activeNames, 0, "1");
      this.tabItem = cur;
      const f = this.item.row?._children?.some(
        (item) => item.formId === cur?.id && item.delList
      );
      tab?.forEach((item) => {
        item.showTabFlag = item?.id === cur?.id;
      });
      if (
        (cur.tableData?.length || f || this.handleType === "create") &&
        cur.header != "占用明细" &&
        cur.header != "库存明细"
      )
        return;
      this.$nextTick((_) => {
        // this.item.childrenActive = cur.id
        this.initData(this.item.row);
      });
    },
    toForm(fields) {
      if (!fields) return;
      const ob = fields[0].items
        ?.filter((item) => item.isShow)
        .map((item) => {
          if (
            this.dialogTitle.includes(this.$t("configMoudle.add")) &&
            item.defaultValue
          ) {
            this.formData[item.itemName] = item.defaultValue;
          }

          // 明细添加小数
          if (item?.expressionDecimalDigit && this.dialog) {
            this.$nextTick(() => {
              if (this.formData.hasOwnProperty(item.itemName)) {
                this.$set(
                  this.formData,
                  item.itemName,
                  parseFloat(this.formData[item.itemName])?.toFixed(
                    item?.expressionDecimalDigit
                  )
                );
              }
            });
          }

          if (item?.dicDataItems?.length) item.list = item.dicDataItems;
          return {
            ...item,
            editType: this._formType[item.editType],
          };
        });
      return ob;
    },
    handleExport(tabItem) {
      let newselectDtlList = [];
      if (this.selectDtlList.length === 0) {
        newselectDtlList = this.tabItem.tableData;
      } else {
        newselectDtlList = this.selectDtlList;
      }
      exportData(tabItem?.id, { list: newselectDtlList }).then((res) => {
        this.download(res.data, "detailList", "xlsx");
      });
    },
    uploadtroute() {
      if (this.$route.query.leadingspan) {
        this.$nextTick(() => {
          this.handleImport(this.item);
        });
      }
    },
    handleImportLAist(data, newrowdata) {
      if (newrowdata !== null && newrowdata !== undefined) {
        let promis = JSON.stringify(newrowdata);
        this.uploadFormData = {
          configId: data.id,
          tableName: data.mainTableName,
          params_json: promis,
        };
        this.$refs?.uploadfile_controls.$el.children[0].children[0].click();
      } else {
        this.$message({
          message: "上一层没有数据无法导入execl",
          type: "warning",
        });
      }
    },
    handleImport(item) {
      if (this.$route.query.id) {
        let tableName = item.mainTableName;
        let configId = item.id;
        if (item.newlevel == 1) {
          let promis = JSON.stringify(this.formData);
          this.uploadFormData = {
            configId: configId,
            tableName: tableName,
            params_json: promis,
          };
          this.$refs.uploadfile_controls.$el.children[0].children[0].click();
        } else {
          this.$nextTick(() => {
            this.$emit("handleImportLAist", item);
          });
        }
      } else {
        this.$emit("leadingIn");
      }
      // this.uploadFormData = { configId: configId, tableName: tableName };
      // this.$refs.uploadfile_controls.$el.children[0].children[0].click();
      // let rids = ""
      // if (item.tableData && item.tableData.length > 0) {
      //   if (item.tableData[0]?.rid) {
      // rids = item.tableData[0].rid
      // , rid: rids
      // this.uploadFormData = { configId: configId, tableName: tableName };
      // this.$refs.uploadfile_controls.$el.children[0].children[0].click();
      //   } else {
      //     this.$message({
      //       message: '保存后在进行文件上传。',
      //       type: 'warning'
      //     })
      //   }
      // } else {
      //   this.$message({
      //     message: '请新增并保存一条明细后，在进行文件上传。',
      //     type: 'warning'
      //   })
      // }
    },
    beforeAvatarUpload(file) {
      const isExcel =
        file.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        file.type === "application/vnd.ms-excel";
      if (!isExcel) {
        this.$message.error(this.$t("configMoudle.tip7"));
        this.uploadFileList = [];
      }
      return isExcel;
    },
    avatarUploadSuccess(res) {
      if (res.code == 200) {
        this.$message.success(res.message);
        this.initData(this.item.row);
      } else {
        this.$message.error(res.message);
      }
      this.uploadFileList = [];
    },
    handleHeaderEdit(column, tabItem) {
      // 批量修改
      if (!this.selectDtlList.length) {
        this.$message({
          message: this.$t("configMoudle.tip10"),
          type: "warning",
        });
        return;
      }
      // 审批中不能修改
      if (
        this.item.row.placed != 0 &&
        this.item.row.placed != -1 &&
        this.item.row.placed != undefined
      ) {
        this.$message({
          message: this.$t("configMoudle.tip20"),
          type: "warning",
        });
        return;
      }
      let x = tabItem.fieldsConfigVOs
        .map((item) => {
          if (item.fieldName == column.property) {
            return item;
          }
        })
        .filter((i) => i);
      this.property = "";
      this.headEditDialog = true;
      this.selectDtlList.sort(this.compare("$index"));
      this.columnField = x[0];
    },
    handleHeaderSearch(column, tabItem) {
      if (tabItem.indexArr == undefined) {
        tabItem.tableArr = JSON.parse(JSON.stringify(tabItem?.tableData));
      }
      tabItem.indexArr = []; //用来存数组下标
      // for(let i = 0; i < tabItem.tableData.length; i++) {
      //   tabItem.indexArr.push(i)  // 最原始的下标
      // }
      tabItem.isCancelFilter = true; // 取消筛选按钮
      let obj = [
        {
          class: "el-icon-edit",
          key: "edit",
          title: this.$t("configMoudle.edit"),
        },
        {
          class: "el-icon-delete",
          key: "delete",
          title: this.$t("configMoudle.delete"),
        },
      ];
      this.$emit("handleHandleBtn", obj);
      /* 第二种方法 */
      column = column.filter((item) => item.inputSearch);
      const { jilulist, indexArr } = this.initA(
        tabItem.tableArr,
        column,
        tabItem.indexArr
      );
      tabItem.tableData = jilulist;
      tabItem.indexArr = indexArr;
    },
    sortChange({ column, prop, order }, tableData) {
      this.$nextTick(() => {
        tableData.sort(function (a, b) {
          var value1 = a[prop];
          var value2 = b[prop];
          if (order == "ascending") {
            return (value1 + "").localeCompare(value2);
          } else if (order == "descending") {
            return (value2 + "").localeCompare(value1);
          }
        });
      });
    },
    // data1是原数组
    //data2是筛选数组
    //indexArr空数组
    initA(data1, data2, indexArr) {
      let jilulist = [];
      for (let i = 0; i < data1.length; i++) {
        let count = 0;
        let add = data1[i];
        for (let j = 0; j < data2.length; j++) {
          for (let k in add) {
            if (k == data2[j].fieldName) {
              if (data2[j].editType === "text") {
                //如果是文本框需要模糊筛选
                if (add[k].indexOf(data2[j].inputSearch) > -1) {
                  count += 1;
                  break;
                }
              } else {
                if (add[k] == data2[j].inputSearch) {
                  count += 1;
                  break;
                }
              }
            }
          }
        }
        if (count == data2.length) {
          jilulist.push(data1[i]);
          indexArr.push(i);
        }
      }
      let obj = {
        jilulist,
        indexArr,
      };
      return obj;
    },

    handleSelectDtl(selection) {
      // console.log(selection);
      this.selectDtlList = clone(selection);
    },
    handleOkDialog(vnode = []) {
      let formVm = "";
      if (this.columnField.dataType == "text") formVm = vnode[0].componentInstance.value;
      if (this.columnField.dataType == "decimal")
        formVm = vnode[1].componentInstance.value;
      if (this.columnField.dataType == "date") formVm = vnode[2].componentInstance.value;
      if (this.columnField.dataType == "select")
        formVm = vnode[3].componentInstance.value;
      // if (this.columnField.dataType == "number" || this.columnField.dataType == "number")
      //   formVm = vnode[3].componentInstance.value;
      this.$nextTick(() => {
        for (let i = 0; i < this.tabItem.tableData.length; i++) {
          for (let j = 0; j < this.selectDtlList.length; j++) {
            if (this.tabItem.tableData[i].$index == this.selectDtlList[j].$index) {
              this.tabItem.tableData[i][this.columnField.fieldName] = formVm;
            }
          }
        }
        this.headEditDialog = false;
      });
    },
    compare(property) {
      return function (a, b) {
        var value1 = a[property];
        var value2 = b[property];
        return value1 - value2;
      };
    },
    download(data, id, type) {
      if (!data) {
        return;
      }
      let url = window.URL.createObjectURL(
        new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        })
      );
      let link = document.createElement("a");
      link.style.display = "none";
      link.href = url;
      link.target = "_blank";
      link.setAttribute("download", this.item.row?.id + "." + type);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    /* excel复制数据弹框确认按钮事件 */
    exceldisabledYes() {
      // // this.exceldisabled = false
      // let rows = this.exceltextarea.split("\n");
      // let newrows = rows.map(function (item) {
      //   if (item != "" && item !== null && item !== undefined) {
      //     return item.split("\t")
      //   } else {
      //     return ""
      //   }
      // })
      // let newrowslist = newrows.filter(function (item) {
      //   return item != "" && item !== null && item !== undefined
      // })
      // for (let i = 0; i < newrowslist.length; i ++) {
      //   let data = {}
      //   for (let j = 0; j < newrowslist[i].length; j ++) {
      //     data[this.tabItem.fieldsConfigVOs[j].fieldName] = newrowslist[i][j]
      //   }
      //   this.tabItem.tableData.push(data)
      // }
      // this.exceldisabled = false
    },
    exceldisabledNo() {
      this.exceldisabled = false;
    },
    handleClickHandle({ title, scope, tabItem }) {
      this.tabItem = tabItem;
      switch (title) {
        /* excel复制按钮事件 */
        case "excelCopy":
          // this.exceldisabled = true
          if (this.recordcolumn != "" && this.tabItem.tableData?.length) {
            navigator.clipboard
              .readText()
              .then((v) => {
                /* 复制的excel数据根据\n分割初始数据 */
                let rows = v.split("\n");
                /* 根据\n分割初始数据在根据\t分割内部数据 */
                let newrows = rows.map(function (item) {
                  if (item != "" && item !== null && item !== undefined) {
                    return item.split("\t");
                  } else {
                    return "";
                  }
                });
                /* 利用.filter过滤出不需要的数据 */
                let newrowslist = newrows.filter(function (item) {
                  return item != "" && item !== null && item !== undefined;
                });
                /* 部分情况会出现带有\r的换行数据，需要去掉\r */
                for (let i = 0; i < newrowslist.length; i++) {
                  for (let j = 0; j < newrowslist[i].length; j++) {
                    if (newrowslist[i][j].indexOf("\r")) {
                      let addd = newrowslist[i][j].split("\r");
                      newrowslist[i][j] = addd[0];
                    }
                  }
                }
                /* 获取对应开始字段的key值 */
                let columskey = 0;
                for (let i = 0; i < this.tabItem.fieldsConfigVOs.length; i++) {
                  if (this.tabItem.fieldsConfigVOs[i].fieldName === this.recordcolumn) {
                    columskey = i;
                    break;
                  }
                }
                /* 根据获取的字段开始key值和开始行的行key值开始对数据进行替换或者新增 */
                let rowkey = clone(this.recordRowCounttwo);
                for (let i = 0; i < newrowslist.length; i++) {
                  let data = {};
                  for (
                    let j = 0, k = columskey;
                    j < newrowslist[i].length, k < this.tabItem.fieldsConfigVOs.length;
                    j++, k++
                  ) {
                    if (j < newrowslist[i].length) {
                      data[this.tabItem.fieldsConfigVOs[k].fieldName] = newrowslist[i][j];
                    }
                  }
                  if (rowkey >= this.tabItem.tableData.length) {
                    this.tabItem.tableData.push(data);
                  } else {
                    Object.assign(this.tabItem.tableData[rowkey], data);
                    this.tabItem.tableData.splice(1, 0);
                  }
                  rowkey += 1;
                }
              })
              .catch((v) => {
                this.$message.error("获取剪贴板内容失败");
              });
          }
          break;
        case "view":
          this.isReadOnlyform = true;
          this.dialog = true;
          this.isImage = true;
          this.formData = tabItem.row = scope.row;
          this.dialogTitle = "明细查看";
          if (this.formData?.img_ids) {
            this.formData.img_ids?.forEach((item, index) => {
              if (!item.url) {
                this.formData.img_ids.splice(index, 1);
              }
            });
            this.imageList = this.formData.img_ids;
          }
          break;
        case "edit":
          this.isReadOnlyform = false;
          this.dialogTitle =
            `${this.tabItem.header || ""}` + this.$t("configMoudle.edit");
          tabItem.rowIdx = scope.$index;
          this.dialog = true;
          this.isImage = true;
          this.formData = tabItem.row = scope.row;
          this.dataId = this.formData?.id;
          // let t = this.formData.img_ids
          // this.formData.img_ids = []
          // t?.forEach(item => {
          //   this.formData.img_ids.push({url: item})
          // })
          if (this.formData?.img_ids) {
            this.formData.img_ids?.forEach((item, index) => {
              if (!item.url) {
                this.formData.img_ids.splice(index, 1);
              }
            });
            this.imageList = this.formData.img_ids;
          }
          // this.formData.imageList = this.formData.imageList.map(item => {
          //   return item.accessUrl
          // })
          break;
        case "delete":
          if (scope.row?.inc > 0) {
            this.$message.error("明细数据已被调用,无法删除");
            return;
          }
          if (scope.row?.id) {
            const child = this.item.row._children.find((v) => v.formId === tabItem?.id);
            const { id } = scope.row;
            scope.row.isDel = true;
            !child.delList && (child.delList = []);
            child.delList.push(scope.row);
          }
          tabItem.tableData.splice(scope.$index, 1);
          const nextId = scope.$index <= 0 ? 0 : scope.$index - 1;
          this.setTableCurrentRow(this.tabItem.id, tabItem.tableData[nextId]);
          this.countfunction(this.tabItem);
          break;
        case "copy":
          let newObj = clone(scope.row);
          delete newObj.create_by;
          delete newObj.update_time,
            delete newObj.create_time,
            delete newObj?.id,
            delete newObj.update_by,
            delete newObj?.rid;
          delete newObj.$index;
          if (newObj?.line) {
            newObj.line = Number(scope.row.line) + 1;
            for (let i = 0; i < tabItem.tableData.length; i++) {
              if (tabItem.tableData[i].line >= newObj.line) {
                tabItem.tableData[i].line += 1;
              }
            }
            tabItem.tableData.push(newObj);
            tabItem.tableData.sort(compare("line"));
            function compare(property) {
              return function (a, b) {
                var value1 = a[property];
                var value2 = b[property];
                return value1 - value2;
              };
            }
          } else {
            newObj.line = 1;
            for (let i = 0; i < tabItem.tableData.length; i++) {
              if (tabItem.tableData[i].line >= newObj.line) {
                tabItem.tableData[i].line += 1;
              }
            }
            tabItem.tableData.push(newObj);
            tabItem.tableData.sort(compare("line"));
            function compare(property) {
              return function (a, b) {
                var value1 = a[property];
                var value2 = b[property];
                return value1 - value2;
              };
            }
          }
          // tabItem.tableData.push(newObj);
          // let sortLine = []
          // for (let i = 0; i < tabItem.tableData.length; i ++) {
          //   if (tabItem.tableData[i].line !== "" && tabItem.tableData[i].line !== null && tabItem.tableData[i].line !== undefined) {
          //     sortLine.push(this.tabItem.tableData[i].line)
          //   } else {
          //     sortLine.push(0)
          //   }
          // }
          // let newlines = 0
          // let newsortLine = sortLine.sort(
          //   function (a, b) {
          //     return a - b
          //   }
          // )
          // if (newsortLine[newsortLine.length - 1] === null || newsortLine[newsortLine.length - 1] === undefined || newsortLine[newsortLine.length - 1] == "") {
          //   newlines = 0
          // } else {
          //   newlines = newsortLine[newsortLine.length - 1]
          // }
          // newObj.line = newlines + 1
          // tabItem.tableData.push(newObj);
          break;
        case "add":
          !this.tabItem.tableData && this.$set(this.tabItem, "tableData", []);
          let lineadd = [];
          for (let i = 0; i < this.tabItem.tableData.length; i++) {
            if (
              this.tabItem.tableData[i].line !== "" &&
              this.tabItem.tableData[i].line !== null &&
              this.tabItem.tableData[i].line !== undefined
            ) {
              lineadd.push(this.tabItem.tableData[i].line);
            } else {
              lineadd.push(0);
            }
          }
          let linspan = 0;
          // if (lineadd.length === 0) {
          //   linspan = 1
          // } else {
          //   // lineadd.sort((a, b) => a - b)
          //   linspan = this.tabItem.tableData.length + 1
          // }
          let newlineadd = lineadd.sort(function (a, b) {
            return a - b;
          });
          linspan = newlineadd[newlineadd.length - 1] + 1;
          let addFormObj = this.tabItem.itemsConfigVOs.find((item) => {
            return item.label == this.tabItem.header;
          });
          if (isNaN(linspan)) linspan = 1;
          let addObj = { line: linspan };
          if (addFormObj && addFormObj.item) {
            addFormObj.items.forEach((item) => {
              if (item.defaultValue) {
                addObj[item.itemName] = item.defaultValue;
              }
            });
          }
          tabItem.tableData.push(addObj);
          // tabItem.tableData.push({
          //   line: linspan,
          // });
          break;
        case "deleteall":
          !this.tabItem.tableData && this.$set(this.tabItem, "tableData", []);
          if (this.selectDtlList.length === 0) {
            this.$message({
              message: this.$t("configMoudle.tip21"),
              type: "warning",
            });
          } else {
            for (let i = 0; i < this.tabItem.tableData.length; i++) {
              if (!this.tabItem.tableData[i].indexed) {
                this.tabItem.tableData[i].indexed = this.tabItem.tableData[i].$index;
              }
            }
            for (let i = 0; i < this.tabItem.tableData.length; i++) {
              for (let j = 0; j < this.selectDtlList.length; j++) {
                if (this.selectDtlList[j]?.inc > 0) {
                  this.$message.error("第" + (j + 1) + "行已被调用过");
                  continue;
                }
                if (this.selectDtlList[j].$index === this.tabItem.tableData[i].indexed) {
                  console.log(i);
                  if (this.tabItem.tableData[i].id) {
                    const child = this.item.row._children.find(
                      (v) => v.formId === tabItem?.id
                    );
                    this.tabItem.tableData[i].isDel = true;
                    !child.delList && (child.delList = []);
                    child.delList.push(this.tabItem.tableData[i]);
                  }
                  this.tabItem.tableData.splice(i, 1);
                  if (i != 0) {
                    i--;
                  }
                }
              }
            }
            this.selectDtlList = [];
            for (let i = 0; i < this.tabItem.tableData.length; i++) {
              delete this.tabItem.tableData[i].indexed;
            }
            tabItem.tableData = this.tabItem.tableData;
          }
          break;
        case "move":
          if (this.selectDtlList.length == 1) {
            this.moveLine = true;
            return;
          }
          this.$message({
            message: this.$t("configMoudle.tip22"),
            type: "warning",
          });
          break;
        case "zhanyong":
          // if (this.selectDtlList.length !== 1) {
          //   this.$message({ message: this.$t("addTips.addoccupy"), type: "warning" });
          // }
          // let arr = clone(this.selectDtlList);
          // if (arr.length > 1) {
          //   this.$message.warning(this.$t("addTips.onlyOneoccupy"));
          //   return;
          // }
          // const row = arr[0];
          // row.sourceId = this.item.row.id;
          // delete row?.$index;
          // const qty = (this.item.row.qty || 0) - (this.item.row.zyqty || 0);
          // this.occupyForm = row;
          // this.occupyForm.poqty = qty;
          // this.occupyForm.occupyQty = qty;
          // this.occupyVisible = true;
          let zyfieldsConfigVOs = JSON.parse(
            JSON.stringify(this.tabItem.fieldsConfigVOs)
          );
          const obj = {
            fieldName: "occupyQty",
            showName: this.$t("addTips.occupationQuantity"),
            isShow: true,
            isMustFill: false,
            isReadOnly: false,
            editType: "number",
            fixed: "right",
            width: 160,
            isEditableUnderApproval: null,
          };
          const it = zyfieldsConfigVOs.findIndex((it) => it.fieldName == "occupyQty");

          if (it) zyfieldsConfigVOs.splice(it, 1, obj);
          else zyfieldsConfigVOs.push(obj);
          this.zyfieldsConfigVOs = zyfieldsConfigVOs;

          let dateProps = this.zyfieldsConfigVOs
            .filter((f) => f.dataType == "date")
            .map((m) => m.fieldName);
          let arr = clone(this.$refs[this.tabItem.id].$refs.Ytable.tableData);
          this.zyList = this.handleZYFunc(arr);
          this.occupyVisible = true;
          this.currentChangeZY(this.zyList[0]);
          if (dateProps.length) {
            this.zyList.forEach((a) => {
              dateProps.forEach((d) => {
                if (a[d]) {
                  if (typeof a[d] !== "string") {
                    a[d] = switchTime(Number(a[d]), "{y}-{m}-{d}");
                    // string类型时，值可能是带着h-m-s的，需要过滤一下
                  } else a[d] = a[d].split(" ")[0];
                } else delete a[d];
                // 将null值的数据删除
              });
            });
          }
          this.copyZyList = clone(this.zyList);

          break;
        case "cancelZhanYong":
          if (!this.selectDtlList.length) {
            this.$message.warning(this.$t("addTips.addoccupy"));
            return;
          }
          let arrs = [];
          if (this.selectDtlList[0]._children?.length) {
            this.selectDtlList[0]._children[0].data.forEach((item) => arrs.push(item.id));
          }
          cancelOccupy(arrs).then((res) => {
            if (this.$jsons.data.status) {
              this.$message.success(this.$jsons.data.message);
              this.selectDtlList = [];
              this.$parent.init();
              this.$refs[tabItem.id].$refs.Ytable.clearSelection();
              this.initData(this.item.row);
              this.$emit("clearChild");
            }
          });
          break;
        case "cancelFilter":
          this.tabItem.fieldsConfigVOs.forEach((item) => {
            if (item.inputSearch) item.inputSearch = "";
          });
          // this.tabItem.tableData
          tabItem.tableData = tabItem.tableArr;
          tabItem.isCancelFilter = false; // 筛选按钮取消
          // tabItem.indexArr = tabindexArr
          break;
        default:
          break;
      }
    },
    // 库存占用表头搜索
    zySearch(str, col) {
      if (!str) this.zyList = clone(this.copyZyList);
      else
        this.zyList = clone(this.copyZyList).filter((f) => f[col.property].includes(str));
    },
    // 表尾合计列
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];
      columns.forEach((column, index) => {
        if (index == 0) {
          sums[index] = "合计";
          return;
        }
        const values = data.map((item) => Number(item[column.property]));
        if (!values.every((value) => isNaN(value))) {
          // 占用数量合计计算
          if (column.property == "occupyQty") {
            sums[index] = values.reduce((prev, curr) => {
              const value = Number(curr);
              if (!isNaN(value)) {
                // 处理合计时的浮点数计算问题
                const add = (preNum, curNum) => {
                  let fir, sec, mes, ces;
                  try {
                    fir = preNum.toString().split(".")[1].length;
                  } catch (e) {
                    fir = 0;
                  }
                  try {
                    sec = curNum.toString().split(".")[1].length;
                  } catch (e) {
                    sec = 0;
                  }
                  ces = Math.abs(fir - sec);
                  mes = Math.pow(10, Math.max(fir, sec));
                  if (ces > 0) {
                    let cm = Math.pow(10, ces);
                    if (fir > sec) {
                      preNum = Number(preNum.toString().replace(".", ""));
                      curNum = Number(curNum.toString().replace(".", "")) * cm;
                    } else {
                      preNum = Number(preNum.toString().replace(".", "")) * cm;
                      curNum = Number(curNum.toString().replace(".", ""));
                    }
                  } else {
                    preNum = Number(preNum.toString().replace(".", ""));
                    curNum = Number(curNum.toString().replace(".", ""));
                  }
                  let results = (preNum + curNum) / mes;
                  if (results.toString().includes(".")) {
                    // 若值小数位超过4位，则只截取前4位
                    let main = results.toString().split(".")[0],
                      point = results.toString().split(".")[1];
                    if (point.length > 4)
                      results = Number(main + "." + point.slice(0, 4));
                  }
                  return results;
                };
                return add(prev, curr);
              } else return prev;
            }, 0);
          } else sums[index] = "";
        } else sums[index] = "";
      });
      return sums;
    },

    inputNative() {
      const key = this.$refs.refNumber.displayValue;
      if (Number(key) > this.occupyForm.qty) {
        this.$message.error("超出库存数量");
      }
    },
    handleZYFunc(arr) {
      let qty = (this.item.row.qty || 0) - (this.item.row.zyqty || 0);
      this.item.totalNeed = qty;
      arr.map((occupyForm) => {
        occupyForm.sourceId = this.item.row.id;
        // occupyForm.occupyQty = occupyForm.qty >= qty ? qty : occupyForm.qty;
        // if (qty == 0 ||occupyForm.sono ) occupyForm.occupyQty = 0
        // qty = qty - occupyForm.occupyQty
        // if (qty <= 0) qty = 0
        occupyForm.occupyQty = 0;
        delete occupyForm?.$index;
      });
      return arr;
    },

    async handlerOccupy() {
      try {
        let params = { sono: this.item.row.sono },
            list = clone(this.zyList);
        // 过滤掉占用数量为0的数据
        list = list.filter(f => f.occupyQty>0)
        if(!list?.length) {
          this.$message.warning('暂无占用数量大于0的明细')
          return
        }
        if(Number(this.item.totalNeed.toFixed(3))  < this.zytTotal || this.item.totalNeed==0) {
          throw ('占用数量合计超过采购数量，请调整')
        }
        await Promise.all(list.map(it => {
          it.sono = this.item.row.sono
          if(it.occupyQty > it.qty) throw (`${it.itemno}超出库存数量`)
        }))
        this.occupyLoading = true
        const res = await occupyBatch(params, list)
        if (this.$jsons.data.status) {
          this.$message.success(res);
          this.selectDtlList = [];
          this.$parent.init()
          this.$refs[this.tabItem.id].$refs.Ytable.clearSelection();
          this.initData(this.item.row);
          this.$emit('updateMain', this.item.row.id);
          this.occupyLoading = false
          this.occupyVisible = false
        }
      } catch (e) {
        if(e){
          this.$message.error(e)
        }
      }
    },

    // 递归
    handleReduce(children, goods) {
      // for(let i = 0 ; i < children.length; i++) {
      //   if(children[i].header == "占用明细") {
      //     let arr = JSON.parse(JSON.stringify(this.selectDtlList))
      //     for(let j = 0; j < arr.length; j++) {
      //       if(arr[j].kcqty <= goods) {
      //         arr[j].qty = arr[j].kcqty
      //         goods = goods - arr[j].qty
      //         this.item.children[i].tableData.splice(this.item.children[i].tableData.length -1,0, arr[j])
      //       }else {
      //         arr[j].qty = goods
      //         goods = 0
      //         this.item.children[i].tableData.splice(this.item.children[i].tableData.length -1,0, arr[j])
      //         break
      //       }
      //     }
      //     break;

      //   }
      //   if(children[i]?.children?.length) {
      //     this.handleReduce(children[i],children, goods)
      //   }
      // }
      for (let i = 0; i < children.length; i++) {
        if (children[i].header == "占用明细") {
          let arr = clone(this.selectDtlList);
          for (let j = 0; j < arr.length; j++) {
            // arr[j].kcqty 是库存数量   arr[j].qty 是可售库存数量, goods是商品明细里的占用数量
            if (arr[j].qty == 0) return;
            // if(arr[j].kcqty <= goods) { // 库存 <= 占用

            //     // goods = arr[j].qty
            //     // this.tabItem.tableData[j].qty = 0
            //     // arr[j].qty = arr[j].kcqty // 占用就等于库存
            //     goods = goods - arr[j].qty // 占用 = 需要占用 - 可售
            //     this.tabItem.tableData[j].qty = this.tabItem.tableData[j].qty - goods
            //     // this.selectList[j].qty = this.selectDtlList[j].kcqty - goods
            //   this.item.children[i].tableData.splice(this.item.children[i].tableData.length -1,0, arr[j])
            // }else { // 库存 > 占用
            if (arr[j].qty < goods) {
              this.tabItem.tableData[j].qty = "0";
            } else {
              // goods = 0  // 占用完不能占用了吗？ 先注释
              arr[j].qty = goods; // 占用明细的数量就可以完全占用
              this.tabItem.tableData[j].qty = this.tabItem.tableData[j].qty - goods;
            }
            this.item.children[i].tableData.splice(
              this.item.children[i].tableData.length - 1,
              0,
              arr[j]
            );
            break;
            // }
          }
          break;
        }
        if (children[i]?.children?.length) {
          this.handleReduce(children[i], children, goods);
        }
      }
    },

    countfunction(tabItem) {
      for (let i = 0; i < tabItem.itemsConfigVOs.length; i++) {
        if (tabItem.itemsConfigVOs[i].label == tabItem.header) {
          for (let j = 0; j < tabItem.itemsConfigVOs[i].items.length; j++) {
            if (
              tabItem.itemsConfigVOs[i].items[j].combinedField != "" &&
              tabItem.itemsConfigVOs[i].items[j].combinedField != null &&
              tabItem.itemsConfigVOs[i].items[j].combinedField != undefined
            ) {
              let list = tabItem.itemsConfigVOs[i].items[j].combinedField;
              let newlist = list.split("#");
              let countditel = 0;
              if (newlist[0] == "form") {
                let count = 0;
                let span = tabItem.itemsConfigVOs[i].items[j].itemName;
                let newaddlist = JSON.parse(JSON.stringify(this.tabItem.tableData));
                for (let i = 0; i < newaddlist.length; i++) {
                  if (
                    newaddlist[i][span] == "" ||
                    newaddlist[i][span] == null ||
                    newaddlist[i][span] == undefined
                  ) {
                    count += 0;
                  } else {
                    count += Number(newaddlist[i][span]);
                  }
                }
                if (newlist[1] == "sum") {
                  this.$emit("countlist", newlist[2], count);
                } else if (newlist[1] == "min") {
                  let min = newaddlist.sort(this.compare(span));
                  this.$emit("countlist", newlist[2], min[0][span]);
                } else if (newlist[1] == "max") {
                  let max = newaddlist.sort(this.compare(span));
                  this.$emit("countlist", newlist[2], max[newaddlist.length - 1][span]);
                } else if (newlist[1] == "avg") {
                  let avg = Number(count) / Number(newaddlist.length);
                  this.$emit("countlist", newlist[2], avg);
                }
              } else if (newlist[0] == "list") {
                let spaname = tabItem.itemsConfigVOs[i].items[j].itemName;
                let addlist = JSON.parse(JSON.stringify(this.tabItem.tableData));

                for (let i = 0; i < addlist.length; i++) {
                  if (
                    addlist[i][spaname] == "" ||
                    addlist[i][spaname] == null ||
                    addlist[i][spaname] == undefined
                  ) {
                    countditel += 0;
                  } else {
                    countditel += Number(addlist[i][spaname]);
                  }
                }
                if (newlist[1] == "sum") {
                  this.$emit("countlistdata", newlist[2], countditel);
                } else if (newlist[1] == "min") {
                  let min = addlist.sort(this.compare(spaname));
                  this.$emit("countlistdata", newlist[2], min[0][spaname]);
                } else if (newlist[1] == "max") {
                  let max = addlist.sort(this.compare(spaname));
                  this.$emit(
                    "countlistdata",
                    newlist[2],
                    max[addlist.length - 1][spaname]
                  );
                } else if (newlist[1] == "avg") {
                  let avg = Number(countditel) / Number(addlist.length);
                  this.$emit("countlistdata", newlist[2], avg);
                }
              } else {
              }
            }
          }
        }
      }
    },
    handleClickHandledeletd(title) {
      !this.tabItem.tableData && this.$set(this.tabItem, "tableData", []);
      if (title == "deleteall") {
        if (this.selectDtlList.length === 0) {
          this.$message({
            message: this.$t("configMoudle.tip21"),
            type: "warning",
          });
        } else {
          for (let i = 0; i < this.tabItem.tableData.length; i++) {
            this.tabItem.tableData[i].indexed = this.tabItem.tableData[i].$index;
          }
          for (let i = 0; i < this.tabItem.tableData.length; i++) {
            for (let j = 0; j < this.selectDtlList.length; j++) {
              if (this.selectDtlList[j].$index === this.tabItem.tableData[i].indexed) {
                this.tabItem.tableData.splice(i, 1);
              }
            }
          }
          this.selectDtlList = [];
        }
      }
    },
    handleClickButton(val, tabItem) {
      if (typeof tabItem == "object") this.tabItem = tabItem;
      switch (val) {
        case "add":
          this.dialogTitle = `${this.tabItem.header || ""}` + this.$t("configMoudle.add");
          this.isImage = false;
          this.dialog = true;
          break;
        case "search":
          const searchData = this.$refs.Yinput.inputsData;
          for (let i in searchData) {
            if (!searchData[i].length) {
              delete searchData[i];
            }
          }
          if (!searchData) break;
          let objs = {
            params: {
              fetchConfigId: this.transObj.key,
              pageSize: this.pageSize,
              pageNum: 1,
            },
            data: {
              filter: searchData,
            },
          };
          fetchDataApi(objs).then((rel) => {
            this.$nextTick(() => {
              this.$set(this.transObj.fieldsVOs, "tableData", rel.result);
              this.totalData = rel.totalRecord;
              this.pageSize = rel.pageSize;
              this.currentPage = rel.pageNum;
              this.transObj.fieldsVOs.row = rel.result[0];
            });
          });
          break;
        default:
          break;
      }
    },
    handleOk(vnode = []) {
      // 1111
      const formVm = vnode[0].componentInstance;
      const formData = this._.cloneDeep(formVm.curData);
      !this.tabItem.tableData && this.$set(this.tabItem, "tableData", []);
      if (this.dialogTitle.includes(this.$t("configMoudle.add"))) {
        this.tabItem.tableData.push(formData);
        const obj = {
          formId: this.tabItem?.id,
          data: this.tabItem.tableData,
        };
        const itemRow = this.item.row;
        !itemRow._children && (itemRow._children = []);
        const f = itemRow._children.findIndex((v) => v.formId === obj.formId);
        f === -1
          ? itemRow._children.push(obj)
          : itemRow._children.splice(f, 1, { ...obj, ...itemRow._children[f] });
        this.setTableCurrentRow(this.tabItem?.id, formData);
      }
      if (this.dialogTitle.includes(this.$t("configMoudle.edit"))) {
        this.$set(this.tabItem.tableData, this.tabItem.rowIdx, {
          ...this.tabItem.row,
          ...formData,
        });

        if (this.tabItem.indexArr && this.tabItem.tableArr) {
          let indexI = null;
          this.item.row._children.forEach((item, index) => {
            if (item.formId == this.tabItem.id) {
              //
              indexI = index;
            }
          });
          for (let i = 0; i < this.tabItem.indexArr.length; i++) {
            let index = this.tabItem.indexArr[i];
            this.$set(
              this.item.row._children[indexI].data,
              index,
              this.tabItem.tableData[i]
            );
          }
        }
      }

      //
      let tabItem = this.tabItem;
      for (let i = 0; i < tabItem.itemsConfigVOs.length; i++) {
        if (tabItem.itemsConfigVOs[i].label == tabItem.header) {
          for (let j = 0; j < tabItem.itemsConfigVOs[i].items.length; j++) {
            if (tabItem.itemsConfigVOs[i].items[j].expression) {
              const c = tabItem.itemsConfigVOs[i].items[j].expression.replace(
                /([\w]|\.)+/g,
                (match) => {
                  if (match.indexOf(".") != -1) {
                    let span = match.split(".");
                    if (span[0] == "form") {
                      // match = this.allData.formData[span[1]]
                      match = this.formData[span[1]];
                      return `parseFloat(${match})`;
                    } else {
                      //
                      // match = this.allData.formData[span[1]]
                      // 需要上级表格而不是表单，所以是至少是从从表
                      let { data, index } = this.recursion(
                        this.allData.children,
                        span,
                        0
                      );
                      match = data[this.indexList[index]][span[span.length - 1]];
                      return `parseFloat(${match})`;
                    }
                  } else {
                    // 判断是常数还是数字类型
                    if (this.isNumber(match)) {
                      // 是数字
                      return `parseFloat(${match})`;
                    } else {
                      // 是字符串
                      return `parseFloat(tabItem.tableData[row.$index].${match})`;
                    }
                    //  return `parseFloat(tabItem.tableData[row.$index].${match})`
                  }
                  // return `parseFloat(tabItem.tableData[row.$index].${match})`;
                }
              );
              setTimeout(() => {
                // 新加的一行，可能下面this.$watch当结果计算为0 的时候不知道为啥不触法增加的一行
                this.$set(
                  tabItem.tableData[row.$index],
                  tabItem.itemsConfigVOs[i].items[j].itemName,
                  eval(c)
                );

                this.watchers.push(
                  this.$watch(
                    () => eval(c),
                    (a) => {
                      if (isNaN(a)) {
                      } else {
                        this.$set(
                          tabItem.tableData[row.$index],
                          tabItem.itemsConfigVOs[i].items[j].itemName,
                          a
                        );
                      }
                    }
                  )
                );
              }, 100);
            }
            if (
              tabItem.itemsConfigVOs[i].items[j].combinedField != "" &&
              tabItem.itemsConfigVOs[i].items[j].combinedField != null &&
              tabItem.itemsConfigVOs[i].items[j].combinedField != undefined
            ) {
              let list = tabItem.itemsConfigVOs[i].items[j].combinedField;
              let newlist = list.split("#");
              let countditel = 0;
              if (newlist[0] == "form") {
                let count = 0;
                let span = tabItem.itemsConfigVOs[i].items[j].itemName;
                let newaddlist = JSON.parse(JSON.stringify(this.tabItem.tableData));
                for (let i = 0; i < newaddlist.length; i++) {
                  if (
                    newaddlist[i][span] == "" ||
                    newaddlist[i][span] == null ||
                    newaddlist[i][span] == undefined
                  ) {
                    count += 0;
                  } else {
                    count += Number(newaddlist[i][span]);
                  }
                }
                if (newlist[1] == "sum") {
                  this.$emit("countlist", newlist[2], count);
                } else if (newlist[1] == "min") {
                  let min = newaddlist.sort(this.compare(span));
                  this.$emit("countlist", newlist[2], min[0][span]);
                } else if (newlist[1] == "max") {
                  let max = newaddlist.sort(this.compare(span));
                  this.$emit("countlist", newlist[2], max[newaddlist.length - 1][span]);
                } else if (newlist[1] == "avg") {
                  let avg = Number(count) / Number(newaddlist.length);
                  this.$emit("countlist", newlist[2], avg);
                }
              } else if (newlist[0] == "list") {
                let spaname = tabItem.itemsConfigVOs[i].items[j].itemName;
                let addlist = JSON.parse(JSON.stringify(this.tabItem.tableData));

                for (let i = 0; i < addlist.length; i++) {
                  if (
                    addlist[i][spaname] == "" ||
                    addlist[i][spaname] == null ||
                    addlist[i][spaname] == undefined
                  ) {
                    countditel += 0;
                  } else {
                    countditel += Number(addlist[i][spaname]);
                  }
                }
                if (newlist[1] == "sum") {
                  this.$emit("countlistdata", newlist[2], countditel);
                } else if (newlist[1] == "min") {
                  let min = addlist.sort(this.compare(spaname));
                  this.$emit("countlistdata", newlist[2], min[0][spaname]);
                } else if (newlist[1] == "max") {
                  let max = addlist.sort(this.compare(spaname));
                  this.$emit(
                    "countlistdata",
                    newlist[2],
                    max[addlist.length - 1][spaname]
                  );
                } else if (newlist[1] == "avg") {
                  let avg = Number(countditel) / Number(addlist.length);
                  this.$emit("countlistdata", newlist[2], avg);
                }
              } else {
              }
            }
          }
        }
      }

      this.dialog = false;
    },
    setTableCurrentRow(refId, row) {
      this.$refs[refId].$refs.Ytable.setCurrentRow(row);
    },
    async initData(row) {
      // childrenActive 为0时，表示children为null，无需再获取数据
      if (this.item.childrenActive && this.item.childrenActive !== "0") {
        const obj = {
          data: row,
          params: { configId: this.item.childrenActive },
        };
        let child = this.item.children.find((v) => v?.id === this.item.childrenActive);
        if (!obj.params.configId) return;
        const res = await renderData(obj).then((res) => res);
        res.result?.forEach((item) => {
          //至少两个中间会有逗号
          if (item.img_ids && item.img_ids.indexOf(",") != -1) {
            item.img_ids = item.img_ids.split(",");
          }
          // 如果没有逗号，中间是一个情况下
          if (
            typeof item.img_ids == "string" &&
            item.img_ids.indexOf(",") == -1 &&
            item.img_ids != ""
          ) {
            let t = item.img_ids;
            item.img_ids = [];
            item.img_ids.push({ url: t });
          }
        });
        //分组小计
        if (child.isShowSubtotal) {
          if (localStorage.getItem("subtotal_switch_" + child.id)) {
            this.subtotalSwitchStatus = true;
            try {
              if (localStorage.getItem("subtotal_" + child.id)) {
                let subtotalConfig = JSON.parse(
                  localStorage.getItem("subtotal_" + child.id)
                );
                subtotal(
                  res.result,
                  subtotalConfig.subtotalGroupFiled,
                  subtotalConfig.subtotalFiled,
                  child.fieldsConfigVOs
                );
              }
            } catch (error) {
              console.log(error);
            }
          }
        }
        this.$set(child, "tableData", res.result);
        if (!res.result.length) return;
        this.setTableCurrentRow(this.item.childrenActive, res.result[0]);
      }
    },
    showDeepCom(item) {
      if (item.children?.length || item.id == "1654427488727732225") return true;
      return false;
    },
    handleTransForm() {
      if (this.selectList.length) {
        const obj = this.selectList[0];
      }
    },
    //行编辑弹出来的调取
    handleTranss(tableData, column) {
      if (this.selectList.length) {
        const obj = this.selectList[0];
        this.transObj.fieldsVOs.column?.forEach((item) => {
          //isFetched 为true的是需要调取的字段，字段名称targetField
          if (item.isFetched && item.targetField) {
            for (let k = 0; k < this.tabItem.fieldsConfigVOs.length; k++) {
              if (
                (this.formData,
                item.targetField == this.tabItem.fieldsConfigVOs[k].fieldName)
              ) {
                this.$set(this.formData, item.targetField, obj[item.fieldName]);
              }
            }
          }
        });
        this.transDialogs = false;
      }
    },
    // 行调取
    handleTrans(tableData, column) {
      if (this.selectList.length) {
        const obj = this.selectList[0];
        for (let i = 0; i < this.transObj.fieldsVOs.column.length; i++) {
          //isFetched 为true的是需要调取的字段，字段名称targetField
          if (
            this.transObj.fieldsVOs.column[i].targetField !== null &&
            this.transObj.fieldsVOs.column[i].targetField !== undefined &&
            this.transObj.fieldsVOs.column[i].targetField != "" &&
            this.transObj.fieldsVOs.column[i].isFetched
          ) {
            for (let k = 0; k < this.tabItem.fieldsConfigVOs.length; k++) {
              if (
                this.transObj.fieldsVOs.column[i].targetField ==
                this.tabItem.fieldsConfigVOs[k].fieldName
              ) {
                this.tabItem.tableData[this.rowIndex][
                  this.transObj.fieldsVOs.column[i].targetField
                ] = obj[this.transObj.fieldsVOs.column[i].fieldName];
              }
            }
          }
        }
        this.transDialog = false;
      } else {
        this.$message({
          showClose: true,
          type: "warning",
          message: this.$t("configMoudle.tip4"),
        });
        return;
      }
    },
    handleSelect(selection) {
      if (selection.length > 1) {
        return;
      }
      this.selectList = selection;
    },
    roadeChange(radiolist) {
      console.log(radiolist);
      let data = [];
      data.push(radiolist);
      this.selectList = data;
    },
    handleRemove({ file, fileList }) {
      // this.formData.img_ids = this.formData.img_ids.split(',')
      // this.imageList.forEach((item, i) => {
      //   if(item.id == file.id) {
      //     this.imageList.splice(i, 1)
      //   }
      // })
      this.formData.img_ids?.forEach((item, index) => {
        if (item.uid == file.uid) this.formData.img_ids.splice(index, 1);
      });

      // this.formData.img_ids = this.formData.img_ids.join()
    },
    handleUpload(val) {
      if (!this.formData.img_ids) {
        this.formData.img_ids = [];
        this.formData.img_ids.push({ url: val });
      } else {
        this.formData.img_ids.push({ url: val });
      }

      // if(!this.formData.img_ids) this.formData.img_ids = val
      // else this.formData.img_ids = this.formData.img_ids + ',' + val
    },

    handleFo(item) {
      if (item.fetchId) {
        transConfigId(item.fetchId).then((res) => {
          let obj = {
            ...res,
            fieldsVOs: {
              column: res.fieldsVOs.filter((k) => k.isShow),
              allColumn: res.fieldsVOs,
              children: res.children,
              searchInput: res.fieldsVOs
                .filter((v) => v.isFilter)
                .map((j) => {
                  // let t = DataType.find((k) => k.value === j.dataType)?.label;
                  let t = _dataType.find((k) => k.value === j.dataType)?.label;
                  if (t === "string") {
                    t = "text";
                  }
                  return {
                    ...j,
                    dataType: t,
                  };
                }),
            },
          };
          delete obj.children;
          this.transObj = obj;
          fetchDataApi({ params: { fetchConfigId: obj.key } }).then((rel) => {
            if (!rel.result.length) return;
            this.$set(this.transObj.fieldsVOs, "tableData", rel.result);
            this.totalData = rel.totalRecord;
            this.pageSize = rel.pageSize;
            this.currentPage = rel.pageNum;
            this.transObj.fieldsVOs.row = rel.result[0];
            this.transDialogs = true;
            this.itemName = item.itemName;
          });
        });
      }
    },
    /**
     * 获取主表单上的实时数据
     */
    getForm() {
      let formData = {};
      Object.assign(formData, this.formData, this.$store.state.global.moduleMainFormData);
      return formData;
    },
    suffixclick(config, column) {
      config = config[0].items.filter((i) => {
        if (i.isShow && i.fetchId) {
          return i;
        }
      });
      config?.forEach((item) => {
        if (item.itemName == column.fieldName) {
          this.transDialog = true;
          transConfigId(item.fetchId).then((res) => {
            let obj = {
              ...res,
              fieldsVOs: {
                column: res.fieldsVOs.filter((k) => k.isShow),
                allColumn: res.fieldsVOs,
                children: res.children,
                searchInput: res.fieldsVOs
                  .filter((v) => v.isFilter)
                  .map((j) => {
                    // let t = DataType?.find((k) => k.value === j.dataType)?.label;
                    let t = _dataType?.find((k) => k.value === j.dataType)?.label;
                    if (t === "string") {
                      t = "text";
                    }
                    return {
                      ...j,
                      dataType: t,
                    };
                  }),
              },
            };
            delete obj.children;
            this.transObj = obj;
            //console.log('asdfasdfdasfasdf',this.tabItem.row)
            //明细调取，这里需要传递主表上的参数
            fetchDataApi({
              params: { fetchConfigId: obj.key },
              data: this.getForm() || {},
            }).then((rel) => {
              if (!rel.result.length) return;
              this.$set(this.transObj.fieldsVOs, "tableData", rel.result);
              this.pageSize = rel.pageSize;
              this.currentPage = rel.pageNum;
              this.totalData = rel.totalRecord;
              this.transObj.fieldsVOs.row = rel.result[0];
              // this.$refs.dialogTableMains.setCurrentRow(res[0]);
              // if (val.fieldsVOs.children) {
              //   this.rowClick(data[0]);
              // }
            });
          });
        }
      });
    },
    // toDecimal2(x) {
    //    var f = parseFloat(x);
    //     if (isNaN(f)) {
    //         return false;
    //     }
    //     var f = Math.round(x*100)/100;
    //     var s = f.toString();
    //     var rs = s.indexOf('.');
    //     if (rs < 0) {
    //         rs = s.length;
    //         s += '.';
    //     }
    //     while (s.length <= rs + 2) {
    //         s += '0';
    //     }
    //     return s;
    //   },
    handleNumberInput(val, row, column, itemsConfigVOs) {
      // console.log("数字框父组件更改时", val)
      // let count = Number(val) + ""
      let a = itemsConfigVOs[0].items.find((item) => {
        if (item.isShow && item.itemName == column["fieldName"]) {
          return item;
        }
      });
      if (val === "" || val === null || val === undefined) {
        val = "";
      }
      //expressionDecimalDigit
      if (val || val == 0) {
        if (a.expressionDecimalDigit) {
          row[column["fieldName"]] = Number(val.toFixed(a.expressionDecimalDigit));
        } else {
          row[column["fieldName"]] = val.toString();
        }
      }
    },
    handleFocus(config, column) {
      config = config[0].items.filter((i) => {
        if (i.isShow && i.fetchId) {
          return i;
        }
      });
      config?.forEach((item) => {
        if (item.itemName == column.fieldName) {
          this.transDialog = true;
          transConfigId(item.fetchId).then((res) => {
            let obj = {
              ...res,
              fieldsVOs: {
                column: res.fieldsVOs.filter((k) => k.isShow),
                allColumn: res.fieldsVOs,
                children: res.children,
                searchInput: res.fieldsVOs
                  .filter((v) => v.isFilter)
                  .map((j) => {
                    // let t = DataType?.find((k) => k.value === j.dataType)?.label;
                    let t = _dataType?.find((k) => k.value === j.dataType)?.label;
                    if (t === "string") {
                      t = "text";
                    }
                    return {
                      ...j,
                      dataType: t,
                    };
                  }),
              },
            };
            delete obj.children;
            this.transObj = obj;
            fetchDataApi({ params: { fetchConfigId: obj.key } }).then((res) => {
              if (!res.length) return;
              this.$set(this.transObj.fieldsVOs, "tableData", res);
              this.transObj.fieldsVOs.row = res[0];
              // this.$refs.dialogTableMains.setCurrentRow(res[0]);
              // if (val.fieldsVOs.children) {
              //   this.rowClick(data[0]);
              // }
            });
          });
        }
      });
    },
  },
  destroyed() {
    this.watchers?.forEach((item) => item?.unwatchFn());
  },
};
</script>
<style lang="scss" scoped>
/deep/.dialog-occupy {
  .el-dialog {
    margin-top: 8vh !important;
  }
}
/deep/.el-tabs {
  height: initial !important;
}
.search-zone {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-bottom: 10px;
  justify-content: space-between;
}
.buttonbox {
  display: flex;
  margin-top: 10px;
  div {
    margin: 0 10px 10px 0;
  }
}
/deep/.el-tabs__content {
  .el-collapse {
    position: relative;
    top: -60px;
    border: 0;
  }
  .el-tab-pane {
    height: 100%;
  }
}
/deep/.el-collapse-item__header {
  border: 0;
}
/deep/.el-collapse-item__wrap {
  border: 0;
}
/deep/ .el-table .el-table__row {
  background: #ffffff;
}
// /deep/ .el-table th > .cell {
//   text-indent: 0px;
// }
.subTotalSet {
  /deep/ .el-transfer {
    display: flex;
    .el-transfer__buttons {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .el-transfer__button {
        margin-left: 0;
      }
    }
  }
}
/deep/ .occupy .el-input-number .el-input__inner {
  text-align: left !important;
}
/deep/ .occupy .el-input-number__decrease {
  display: none !important;
}
/deep/ .occupy .el-input-number__increase {
  display: none !important;
}
/deep/.el-table .notSubmit {
  background: #ffffff;
}
/deep/.el-table .submit {
  background: #f8f8de;
}
/deep/.el-table .success {
  background: rgb(176, 255, 186);
}
/deep/.el-table .reject {
  background: rgb(253, 176, 176);
}
/deep/.el-table__body tr.current-row > td {
  background-color: #73e3f7 !important;
}
/deep/.el-table .el-table__cell > .cell {
  height: 100%;
  line-height: 33px;
  text-align: center !important; // border-bottom: 0.5px solid black;
}
/deep/ .el-dialog__body .el-table .el-table__cell > .cell {
  height: 100%;
  line-height: 33px;
  display: flex;
  justify-content: center;
}
/deep/#occTable {
  th,
  td {
    padding: 4px 0 !important;
  }
}
/deep/ .el-descriptions .is-bordered {
    table-layout: auto;
}
/deep/ .el-descriptions__body .el-descriptions__table {
    border-collapse: collapse;
    width: 100%;
    table-layout: fixed;
}
/deep/ .el-descriptions .is-bordered .el-descriptions-item__cell {
    border: 1px solid #EBEEF5;
    padding: 12px 10px;
}
/deep/ .el-descriptions-item__label.is-bordered-label {
    font-weight: 700;
    color: #909399;
    background: #fafafa;
}
@import "@/styles/table.scss";
</style>
