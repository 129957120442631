var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("el-tree", {
        ref: "tree",
        attrs: {
          data: _vm.data,
          "show-checkbox": "",
          "node-key": _vm.path,
          "default-checked-keys": _vm.checkKeys,
          "expand-on-click-node": false,
          "render-content": _vm.renderContent
        },
        scopedSlots: _vm._u(
          [
            {
              key: "default",
              fn: function(ref) {
                var node = ref.node
                var data = ref.data
                return _vm.type && _vm.type === "roleAdmin"
                  ? _c("span", { staticClass: "custom-tree-node" }, [
                      _c("span", [_vm._v(_vm._s(node.label))]),
                      data.hasChildren &&
                      node.label != "邮件系统" &&
                      node.label != "邮件营销"
                        ? _c(
                            "div",
                            {
                              staticStyle: {
                                width: "80%",
                                "text-align": "right"
                              }
                            },
                            [
                              _c("span", [_vm._v("查看范围")]),
                              _c(
                                "el-select",
                                {
                                  staticStyle: { width: "30%" },
                                  style:
                                    data.parentId !== "0"
                                      ? "margin-right: 15px;"
                                      : "",
                                  attrs: { size: "mini" },
                                  on: {
                                    change: function(e) {
                                      return _vm.handleChooseCheck(e, data)
                                    }
                                  },
                                  model: {
                                    value: data.scope,
                                    callback: function($$v) {
                                      _vm.$set(data, "scope", $$v)
                                    },
                                    expression: "data.scope"
                                  }
                                },
                                [
                                  _c("el-option", {
                                    attrs: { value: 0, label: "所有数据" }
                                  }),
                                  _c("el-option", {
                                    attrs: { value: 1, label: "自有数据" }
                                  }),
                                  _c("el-option", {
                                    attrs: { value: 2, label: "部门数据" }
                                  })
                                ],
                                1
                              ),
                              data.parentId !== "0"
                                ? _c(
                                    "span",
                                    { staticStyle: { margin: "0 5px 0 0" } },
                                    [_vm._v("拓展范围")]
                                  )
                                : _vm._e(),
                              data.parentId !== "0"
                                ? _c(
                                    "el-input",
                                    {
                                      staticStyle: { width: "30%" },
                                      attrs: {
                                        size: "mini",
                                        disabled: true,
                                        placeholder: "请选择范围"
                                      },
                                      model: {
                                        value: data.expend,
                                        callback: function($$v) {
                                          _vm.$set(data, "expend", $$v)
                                        },
                                        expression: "data.expend"
                                      }
                                    },
                                    [
                                      _c("template", { slot: "append" }, [
                                        _c(
                                          "div",
                                          {
                                            on: {
                                              click: function($event) {
                                                return _vm.handleSetExpand(data)
                                              }
                                            }
                                          },
                                          [_vm._v("...")]
                                        )
                                      ])
                                    ],
                                    2
                                  )
                                : _vm._e()
                            ],
                            1
                          )
                        : _vm._e()
                    ])
                  : _vm._e()
              }
            }
          ],
          null,
          true
        )
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.dialog,
            title: "扩展范围设置",
            width: "55%",
            destoryOnClone: false
          },
          on: {
            "update:visible": function($event) {
              _vm.dialog = $event
            }
          }
        },
        [
          _c(
            "div",
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: { click: _vm.handleAddExpand }
                },
                [_vm._v("新增")]
              ),
              _vm._l(_vm.expandList, function(item, index) {
                return _c(
                  "div",
                  { key: index, staticStyle: { margin: "10px 0 0 0" } },
                  [
                    _c(
                      "el-select",
                      {
                        staticStyle: { margin: "0 8px 0 0", width: "100px" },
                        attrs: { size: "mini", placeholder: "请选择前缀" },
                        model: {
                          value: item.prefix,
                          callback: function($$v) {
                            _vm.$set(item, "prefix", $$v)
                          },
                          expression: "item.prefix"
                        }
                      },
                      [
                        _c("el-option", { attrs: { value: "(", label: "(" } }),
                        _c("el-option", {
                          attrs: { value: "((", label: "((" }
                        }),
                        _c("el-option", {
                          attrs: { value: "(((", label: "(((" }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-select",
                      {
                        staticStyle: { margin: "0 8px 0 0", width: "120px" },
                        attrs: { size: "mini", placeholder: "请选择类型" },
                        on: {
                          change: function(e) {
                            return _vm.handleChooseType(e, item)
                          }
                        },
                        model: {
                          value: item.type,
                          callback: function($$v) {
                            _vm.$set(item, "type", $$v)
                          },
                          expression: "item.type"
                        }
                      },
                      [
                        _c("el-option", { attrs: { value: 0, label: "人员" } }),
                        _c("el-option", { attrs: { value: 1, label: "部门" } }),
                        _c("el-option", {
                          attrs: { value: 2, label: "单据字段" }
                        })
                      ],
                      1
                    ),
                    item.type == 2
                      ? _c(
                          "el-select",
                          {
                            staticStyle: {
                              margin: "0 8px 0 0",
                              width: "120px"
                            },
                            attrs: { size: "mini", placeholder: "请选择字段" },
                            model: {
                              value: item.fieldName,
                              callback: function($$v) {
                                _vm.$set(item, "fieldName", $$v)
                              },
                              expression: "item.fieldName"
                            }
                          },
                          [
                            _vm._l(_vm.fieldList, function(item) {
                              return [
                                _c("el-option", {
                                  key: item.id,
                                  attrs: {
                                    value: item.fieldName,
                                    label: item.showName
                                  }
                                })
                              ]
                            })
                          ],
                          2
                        )
                      : _vm._e(),
                    _c(
                      "el-select",
                      {
                        staticStyle: { margin: "0 8px 0 0", width: "120px" },
                        attrs: { size: "mini" },
                        model: {
                          value: item.symbol,
                          callback: function($$v) {
                            _vm.$set(item, "symbol", $$v)
                          },
                          expression: "item.symbol"
                        }
                      },
                      [
                        _c("el-option", { attrs: { value: 0, label: "等于" } }),
                        _c("el-option", { attrs: { value: 1, label: "包含" } }),
                        _c("el-option", { attrs: { value: 2, label: "比较" } }),
                        _c("el-option", { attrs: { value: 3, label: "大于" } }),
                        _c("el-option", {
                          attrs: { value: 4, label: "大于等于" }
                        }),
                        _c("el-option", { attrs: { value: 5, label: "小于" } }),
                        _c("el-option", {
                          attrs: { value: 6, label: "小于等于" }
                        })
                      ],
                      1
                    ),
                    item.type == 0
                      ? _c(
                          "el-select",
                          {
                            staticStyle: {
                              margin: "0 8px 0 0",
                              width: "180px"
                            },
                            attrs: {
                              size: "mini",
                              multiple: "",
                              "collapse-tags": "",
                              placeholder: "请选择人员"
                            },
                            model: {
                              value: item.value,
                              callback: function($$v) {
                                _vm.$set(item, "value", $$v)
                              },
                              expression: "item.value"
                            }
                          },
                          [
                            _vm._l(_vm.userOptions, function(item) {
                              return [
                                _c("el-option", {
                                  key: item.id,
                                  attrs: {
                                    value: item.username,
                                    label: item.nickName
                                  }
                                })
                              ]
                            })
                          ],
                          2
                        )
                      : _vm._e(),
                    item.type == 1
                      ? _c(
                          "el-select",
                          {
                            staticStyle: {
                              margin: "0 8px 0 0",
                              width: "150px"
                            },
                            attrs: { size: "mini", placeholder: "请选择部门" },
                            model: {
                              value: item.value,
                              callback: function($$v) {
                                _vm.$set(item, "value", $$v)
                              },
                              expression: "item.value"
                            }
                          },
                          [
                            _vm._l(_vm.deptOptions, function(item) {
                              return [
                                _c("el-option", {
                                  key: item.id,
                                  attrs: {
                                    value: item.id,
                                    label: item.deptName
                                  }
                                })
                              ]
                            })
                          ],
                          2
                        )
                      : _vm._e(),
                    item.type == 2
                      ? _c("el-input", {
                          staticStyle: { margin: "0 8px 0 0", width: "100px" },
                          attrs: { placeholder: "请填写字段", size: "mini" },
                          model: {
                            value: item.value,
                            callback: function($$v) {
                              _vm.$set(item, "value", $$v)
                            },
                            expression: "item.value"
                          }
                        })
                      : _vm._e(),
                    _c(
                      "el-select",
                      {
                        staticStyle: { margin: "0 8px 0 0", width: "100px" },
                        attrs: { size: "mini" },
                        model: {
                          value: item.suffix,
                          callback: function($$v) {
                            _vm.$set(item, "suffix", $$v)
                          },
                          expression: "item.suffix"
                        }
                      },
                      [
                        _c("el-option", { attrs: { value: ")", label: ")" } }),
                        _c("el-option", {
                          attrs: { value: "))", label: "))" }
                        }),
                        _c("el-option", {
                          attrs: { value: ")))", label: ")))" }
                        })
                      ],
                      1
                    ),
                    _c(
                      "el-select",
                      {
                        staticStyle: { margin: "0 8px 0 0", width: "120px" },
                        attrs: { size: "mini", placeholder: "请选择条件" },
                        model: {
                          value: item.condition,
                          callback: function($$v) {
                            _vm.$set(item, "condition", $$v)
                          },
                          expression: "item.condition"
                        }
                      },
                      [
                        _c("el-option", { attrs: { value: 0, label: "且" } }),
                        _c("el-option", { attrs: { value: 1, label: "或" } })
                      ],
                      1
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { size: "mini", type: "danger" },
                        on: {
                          click: function($event) {
                            return _vm.handleDelExpand(item, index)
                          }
                        }
                      },
                      [_vm._v("删除")]
                    )
                  ],
                  1
                )
              }),
              _c(
                "span",
                {
                  staticClass: "dialog-footer",
                  staticStyle: { margin: "15px 0 0 0" },
                  attrs: { slot: "footer" },
                  slot: "footer"
                },
                [
                  _c(
                    "el-button",
                    { attrs: { size: "mini" }, on: { click: _vm.handleClose } },
                    [_vm._v("取 消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: {
                        type: "primary",
                        loading: _vm.saveLoading,
                        size: "mini"
                      },
                      on: { click: _vm.handleOk }
                    },
                    [_vm._v("确 定")]
                  )
                ],
                1
              )
            ],
            2
          )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }