import request from "@/axios/index";
export function findUserList(params) {
  return request({
    url: "/sys/user",
    method: "get",
    params
  })
}

// 附件下载
export const batchDownloadFile = (data)=>{
  return request({
    url: "/common/batchDownloadFile",
    method: "post",
    responseType:'blob',
    data
  })
}

// 采购人员列表（根据角色名获取用户）
export function getUserByRoleName(params) {
  return request({
    url: "/sys/user/getUserByRoleName",
    method: "get",
    params
  })
}
// 获取树结构数据
export function findtreeList(params) {
  return request({
    url: "/sys/user/dept_tree",
    method: "get",
    params
  })
}
// 部门人员树结构
export function getDeptAndUser() {
  return request({
    url: "/shate_group/getDeptAndUser",
    method: "get",
  })
}
// 添加用户
export function addUser(data) {
  return request({
    url: "/sys/user",
    method: "post",
    data
  })
}
// 批量设置用户查看权限
export function batchSetDept(data) {
  return request({
    url: "/sys/user/batchSetDept",
    method: "post",
    data
  })
}
// 批量删除用户
export function deleteUser(data) {
  return request({
    url: "/sys/user/deleteAll",
    method: "delete",
    data
  })
}
// 获取用户信息
export function getUser(id) {
  return request({
    url: `/sys/user/${id}`,
    method: "get",
  })
}
// 修改用户
export function editUser(id, data) {
  return request({
    url: `/sys/user/${id}`,
    method: "put",
    data
  })
}
// 删除用户
export function deleteUserOne(id) {
  return request({
    url: `/sys/user/${id}`,
    method: "delete",
  })
}