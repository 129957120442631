import {EditorComponent} from '@/g6-canvas/editor/index'
import {externalComponents} from "@/g6-canvas/components/index.ts";
import VueFlowEditor from '@/g6-canvas/editor/vue-flow-editor'
import VueFLowEditMenu from '@/g6-canvas/editor/vue-flow-edit-menu.vue'
import VueFLowEditMenuGroup from '@/g6-canvas/editor/vue-flow-edit-menu-group.vue'
import {formatPos} from "@/g6-canvas/utils/utils";

export default {
    VueFlowEditor,
    VueFLowEditMenu,
    VueFLowEditMenuGroup,

    formatPos,
    install(Vue: any) {
        // console.log('install', Component)
        [...externalComponents, ...EditorComponent].forEach(Component => {
            Vue.component(Component.name, Component)
        })
    }
}