/*
const color = '#EF8B1F'
const deepColor = '#8e4f00'

export const GraphStyle = {
    default: {
        defaultEdge: {
            shape: 'flow-polyline-round',
            style: {
                stroke: color,
                lineWidth: 2,
                lineAppendWidth: 10,
                cursor: 'pointer',
            },
        },
        defaultNode: {
            shape: 'rect',
            size: [120, 40],
            anchorPoints: [
                /!*[0, 0],
                [0, 1],
                [1, 0],
                [1, 1],*!/

                [0.5, 0],
                [0, 0.5],
                [0.5, 1],
                [1, 0.5],
            ],
            style: {
                fill: color,
                lineWidth: 1,
                stroke: color,
                cursor: 'pointer',
            },
            labelCfg: {
                style: {
                    fill: '#ffffff',
                    cursor: 'pointer',
                }
            },
        },
        nodeStateStyles: {
            selected: {
                fill: deepColor,
                stroke: deepColor,
            },
            hover: {
                fill: deepColor,
                stroke: deepColor,
            },
        },
        edgeStateStyles: {
            selected: {
                stroke: deepColor,
                lineWidth: 3,
            },
            hover: {
                stroke: deepColor,
                lineWidth: 2,
            },
        },
    },
    anchorStyle: {stroke: color, radius: 3.5, fill: '#fff', lineAppendWidth: 12},
    anchorHotsoptStyle: {radius: 12, fill: color, fillOpacity: .25},
    anchorHotsoptActivedStyle: {radius: 14},
    anchorPointHoverStyle: {radius: 4, fill: color, fillOpacity: 1, stroke: deepColor},

    edgeDelegationStyle: {stroke: color, lineDash: [4, 4], lineWidth: 1},
}*/

export const BASE_COLOR = '#1F74FF'
export const BASE_COLOR_DEEP = '#324aff'

export const GraphStyle = {
    default: {
        defaultEdge: {
            shape: 'flow-polyline-round',
            style: {
                stroke: '#BFC5D2',
                lineWidth: 1,
                lineAppendWidth: 10,
                cursor: 'pointer',
            },
        },
        defaultNode: {
            shape: 'rect',
            size: [120, 40],
            anchorPoints: [
                /*[0, 0],
                [0, 1],
                [1, 0],
                [1, 1],*/

                [0.5, 0],
                [0, 0.5],
                [0.5, 1],
                [1, 0.5],
            ],
            style: {
                fill: '#E8F1FF',
                lineWidth: 0,
                stroke: BASE_COLOR,
                cursor: 'pointer',
            },
            labelCfg: {
                style: {
                    fontSize: 14,
                    fill: BASE_COLOR,
                    cursor: 'pointer',
                }
            },
        },
        nodeStateStyles: {
            selected: {
                lineWidth: 2,
                stroke: BASE_COLOR,
            },
            hover: {
                stroke: BASE_COLOR,
            },
        },
        edgeStateStyles: {
            selected: {
                stroke: BASE_COLOR,
                lineWidth: 1,
            },
        },
    },
    anchorStyle: {stroke: BASE_COLOR, radius: 3.5, fill: '#fff', lineAppendWidth: 12},
    anchorHotsoptStyle: {radius: 12, fill: BASE_COLOR, fillOpacity: .25},
    anchorHotsoptActivedStyle: {radius: 14},
    anchorPointHoverStyle: {radius: 4, fill: BASE_COLOR, fillOpacity: 1, stroke: BASE_COLOR_DEEP},

    edgeDelegationStyle: {stroke: BASE_COLOR, lineDash: [4, 4], lineWidth: 1},
}