<template>
  <div class="annexesList">
    <el-table :data="tableData" border max-height="450px" style="width: 100%">
      <el-table-column type="index" label="序号" width="80" align="center"></el-table-column>
      <el-table-column prop="realAttachName" label="附件名称"></el-table-column>
      <el-table-column prop="attachPath" label="附件路径"></el-table-column>
      <el-table-column prop="attachType" label="附件类型" width="120"></el-table-column>
      <el-table-column prop="type" label="类型" width="220">
        <template slot-scope="scope">
          <el-select v-model="scope.row.type" :placeholder="$t('placeHolder.pleaseSelect')" @change="change(scope.row)" style="width: 100%">
            <el-option label="普通附件" :value="1"></el-option>
            <el-option label="采购合同" :value="2"></el-option>
          </el-select>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="200" align="center">
        <template slot-scope="scope">
          <i class="el-icon-download" @click="handleEdit(scope.$index, scope.row)"></i>
          <i class="el-icon-delete-solid" @click="handleDeletea(scope.$index, scope.row)"></i>
          <i class="el-icon-view" @click="handleView(scope.$index, scope.row)"></i>
        </template>
      </el-table-column>
    </el-table>
    <!-- <el-pagination
      style="margin-top: 10px;"
      background
      layout="total, prev, pager, next, sizes"
      :total="total"
      :page-sizes="[20, 50, 100, 200]"
      :current-page.sync="pageNum"
      @size-change="sizeChange"
      @current-change="currentChange"
      @prev-click="currentChange"
      @next-click="currentChange">
    </el-pagination> -->
    <el-dialog
      :title="$t('configMoudle.tip')"
      :visible.sync="dialogVisible"
      width="30%">
      <span>是否删除当前附件</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">{{$t('configMoudle.cancel')}}</el-button>
        <el-button type="primary" @click="dialogVisibleok">{{$t('configMoudle.confirm')}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {saveEnclosure} from '@/api/detail'
export default {
  name: "Y-annexesList",
  data() {
    return {
      dialogVisible: false,
      rowList: {}
    };
  },
  props: {
    tableData: {
      type: Array
    },
    pageNum: {
      type: Number
    },
    pageSize: {
      type: Number
    },
    total: {
      type: Number
    }
  },
  created() {},
  mounted() {},
  methods: {
    dialogVisibleok () {
      this.$emit("dialogVisibleok", this.rowList)
      this.dialogVisible = false
    },
    change(row){
      console.log(row);
      saveEnclosure(row.id,row).then(res => {
        this.$message.success(this.$t('configMoudle.tip2'))
      })
    },
    sizeChange (val) {
      this.$emit("sizeChange", val)
    },
    currentChange (val) {
      this.$emit("currentChange", val)
    },
    handleEdit (index, row) {
      this.$emit("handleEdit", row)
    },
    handleDeletea (index, row) {
      this.rowList = row
      this.dialogVisible = true
    },
    handleView(index, row) {
      this.$emit('handleView', index, row)
    }
  },
};
</script>

<style lang="scss" scoped>
[class*=" el-icon-"], [class^=el-icon-] {
  width: 30px;
  height: 3px;
  font-size: 22px;
}
/deep/ .el-table td, .el-table th {
  padding: 8px 4px;
}
.el-icon-delete-solid {
  color: red;
}
.el-icon-download {
  color: cornflowerblue;
}
</style>