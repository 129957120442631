// 处理主题样式
export function handleThemeStyle(name,theme,level) {
	const index = level || 9
	for (let i = 1; i <=index; i++) {
		document.documentElement.style.setProperty(`--${name}-light-${i}`, `${getLightColor(theme, i / 10)}`)
	}
	for (let i = 1; i <= index; i++) {
		document.documentElement.style.setProperty(`--${name}-dark-${i}`, `${getDarkColor(theme, i / 10)}`)
	}
	document.documentElement.style.setProperty(`--${name}`, theme)

}
export function setStyleCommon(name,theme) {
	document.documentElement.style.setProperty(name, theme)
}

export function getStyleCommon(name){
	return document.documentElement.style['--primary']

}

// 变浅颜色值
export function getLightColor(color, level) {
	let rgb = hexToRgb(color)
	for (let i = 0; i < 3; i++) {
		rgb[i] = Math.floor((255 - rgb[i]) * level + rgb[i])
	}
	return rgbToHex(rgb[0], rgb[1], rgb[2])
}

// 变深颜色值
export function getDarkColor(color, level) {
	let rgb = hexToRgb(color)
	for (let i = 0; i < 3; i++) {
		rgb[i] = Math.floor(rgb[i] * (1 - level))
	}
	return rgbToHex(rgb[0], rgb[1], rgb[2])
}

// 将颜色从十六进制转换为 RGB 格式
export function hexToRgb(hex) {
	const bigint = parseInt(hex.slice(1), 16);
	const r = (bigint >> 16) & 255;
	const g = (bigint >> 8) & 255;
	const b = bigint & 255;
	return [r, g, b];
}

// 将 RGB 转换回十六进制
export function rgbToHex(r, g, b) {
	return (
		"#" +
		((1 << 24) + (r << 16) + (g << 8) + b)
			.toString(16)
			.slice(1)
			.toUpperCase()
	);
}

// 调整亮度的函数，factor > 1 是变亮，factor < 1 是变暗
export function adjustBrightness([r, g, b], factor) {
	return [
		Math.min(255, Math.max(0, Math.floor(r * factor))),
		Math.min(255, Math.max(0, Math.floor(g * factor))),
		Math.min(255, Math.max(0, Math.floor(b * factor))),
	];
}

// 获取高对比度文本颜色（例如黑色或白色）
export function getContrastingTextColor([r, g, b]) {
	// 使用 YIQ 配方来判断颜色的亮度，从而决定白色或黑色字体
	const yiq = (r * 299 + g * 587 + b * 114) / 1000;
	return yiq >= 128 ? "#000000" : "#FFFFFF";
}

// 动态设置 activeBackgroundColor 和 activeTextColor
/**
 * 根据背景颜色生成激活状态的背景和文本颜色样式。
 * @param {string} backgroundColor - 背景颜色的十六进制值或 RGB 值。
 * @param textColor - 文本颜色的十六进制值或 RGB 值。
 * @returns {object} - 包含激活状态下的背景颜色和文本颜色的对象。
 */
export function getActiveStyles(backgroundColor, textColor) {
	const bgRgb = hexToRgb(backgroundColor);
	const textRgb = hexToRgb(textColor);
	// 根据亮度变化规则，调暗背景色
	const activeBgRgb = adjustBrightness(bgRgb, 0.9); // 使背景稍微变暗
	// 如果 textColor 是白色，保持不变；如果不是白色，选择对比度高的颜色
	const activeTextColor =
		textColor.toUpperCase() === "#FFFFFF"
			? "#FFFFFF"
			: getContrastingTextColor(textRgb);
	// 返回新颜色
	return {
		activeBackgroundColor: rgbToHex(...activeBgRgb),
		activeTextColor: activeTextColor,
	};
}

// 变浅颜色：将颜色向白色靠拢
export function lightenColor([r, g, b], factor) {
	return [
		Math.min(255, Math.floor(r + (255 - r) * factor)),
		Math.min(255, Math.floor(g + (255 - g) * factor)),
		Math.min(255, Math.floor(b + (255 - b) * factor)),
	];
}

export function getLightenBackgroundColor(backgroundColor, lightenFactor) {
	const bgRgb = hexToRgb(backgroundColor);
	const lightenBgRgb = lightenColor(bgRgb, lightenFactor);
	return rgbToHex(...lightenBgRgb);
}

// 变深颜色：将颜色向黑色靠拢
export function darkenColor([r, g, b], factor) {
	return [
		Math.max(0, Math.floor(r * (1 - factor))),
		Math.max(0, Math.floor(g * (1 - factor))),
		Math.max(0, Math.floor(b * (1 - factor))),
	];
}

// 动态设置变深背景颜色
export function getDarkenBackgroundColor(backgroundColor, darkenFactor) {
	const bgRgb = hexToRgb(backgroundColor);
	const darkenBgRgb = darkenColor(bgRgb, darkenFactor);
	return rgbToHex(...darkenBgRgb);
}