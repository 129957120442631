// @ts-ignore
const G6Module = window.G6 as any

const G6 = G6Module.default
const Item = G6Module.Item
const Grid = G6Module.Grid
const Minimap = G6Module.Minimap
const SingleShapeMixin = G6Module.SingleShapeMixin
export {
    G6,
    Item,
    Grid,
    Minimap,
    SingleShapeMixin,
}


