import {BASE_COLOR} from "@/g6-canvas/utils/styles";

export function registerActivity(G6) {
    G6.registerNode('activity', {
        options: {
            style: {},
            stateStyles: {},
        },
        setState() {},
        drawShape(cfg, group) {

            let {text, desc, img, color} = cfg
            color = color || BASE_COLOR
            desc = desc || '无描述'

            const [width, height] = (cfg.size || [200, 80]) as [number, number]

            const shapes = {
                keyShape: {
                    type: 'rect',
                    attrs: {fill: 'white', x: -width / 2, y: -height / 2, width, height, shadowColor: '#BFC5D2', shadowBlur: 50},
                },
                sideRect: {
                    type: 'rect',
                    attrs: {x: -width / 2, y: -height / 2, width: 6, height, fill: color},
                },
                img: {
                    type: 'image',
                    attrs: {x: height / 4 - width / 2, y: height / 4 - height / 2, width: height / 2, height: height / 2, img},
                },
                label: {
                    type: 'text',
                    attrs: {text, x: height - width / 2, y: height * (3 / 8) - height / 2, fontSize: 16, textAlign: 'left', textBaseline: 'middle', fill: 'black'},
                },
                desc: {
                    type: 'text',
                    attrs: {text: desc, x: height - width / 2, y: height * (5 / 8) - height / 2, fontSize: 12, textAlign: 'left', textBaseline: 'middle', fill: '#999'},
                }
            }

            const addShapes = {}

            let keyShape;
            Object.keys(shapes).forEach((shapeName) => {
                const {type, attrs} = shapes[shapeName]
                addShapes[shapeName] = group.addShape(type, {
                    attrs,
                    name: shapeName,
                    draggable: true,
                })

                if (shapeName === 'keyShape') {
                    keyShape = addShapes[shapeName]
                }
            })

            group.shapes = addShapes

            return keyShape
        },
        update(cfg, group) {
            group = group.getContainer()
            group.shapes.sideRect.attr({fill: cfg.color})
            group.shapes.img.attr({img: cfg.img})
            group.shapes.label.attr({text: cfg.text})
            group.shapes.desc.attr({text: cfg.desc})
        },
    }, 'single-shape')
}