<template>
  <div :class="{ 'show': show }" class="header-search">
    <i class="el-icon-search search-icon" @click.stop="handleFocusSearch" v-show="!show"
       :style="{color: navFontColor }"></i>
    <el-select :size="size || 'medium'" ref="headerSearchSelectRef" v-model="search"
               :remote-method="querySearch" filterable default-first-option remote :placeholder="$t('user.searchMenu')"
               class="header-search-select" @change="change" :loading="loading">
      <el-option v-for="option in options" :key="option.path" :value="option.path"
                 :label="option.title.join(' > ')"/>
    </el-select>
  </div>
</template>
<script>
import {isHttp, getNormalPath} from "@/utils/validate"
import {addNewClickMenuRecord} from "@/api/sysTem/menuAdmin";
import {mapState} from "vuex";

export default {
  name: "Y-HeaderSearch",
  data() {
    return {
      show: false,
      options: [],
      list: [],
      search: '',
      loading: false
    }
  },
  props: {
    routes: Array,
    size: {},
  },
  mounted() {
    this.list = this.generateRoutes(this.routes)
  },
  watch: {
    show(newVal, oldVal) {
      if (newVal) {
        document.body.addEventListener('click', this.handleCloseSearch)
      } else {
        document.body.removeEventListener('click', this.handleCloseSearch)
      }
      this.$store.commit('global/setSearch', newVal)
    }
  },
  computed: {
    ...mapState({
      navFontColor: (state) => state.global.navFontColor,
      navPosition: (state) => state.global.navPosition,
    })
  },
  methods: {
    // 路由遍历
    generateRoutes(routes, basePath = '', prefixTitle = []) {
      let res = []
      for (const r of routes) {
        if (r.hidden) {
          continue
        }
        const p = r.path.length > 0 && r.path[0] === '/' ? r.path : '/' + r.path;
        const data = {
          path: !isHttp(r.path) ? getNormalPath(basePath + p) : r.path,
          title: [...prefixTitle]
        }
        if (r.meta && r.meta.title) {
          data.title = [...data.title, r.meta.title]
          if (r.redirect !== 'noRedirect') {
            res.push(data)
          }
        }
        if (r.children) {
          const tempRoutes = this.generateRoutes(r.children, data.path, data.title)
          if (tempRoutes.length >= 1) {
            res = [...res, ...tempRoutes]
          }
        }
      }
      return res
    },
    //  搜索框失去焦点
    handleCloseSearch() {
      this.$refs.headerSearchSelectRef.blur()
      this.options = []
      this.show = false
    },
    // 搜索框聚焦
    handleFocusSearch() {
      this.show = !this.show
      if (this.show) {
        this.$refs.headerSearchSelectRef.focus()
      }
    },
    // 检索菜单路由
    querySearch(query) {
      if (query !== '') {
        this.loading = true;
        this.options = this.list.filter(item => {
          return item.title.join().search(query) > -1
        })
        this.loading = false;
      } else {
        this.options = []
      }
    },
    // 选择跳转
    change(val) {
      if (isHttp(val)) {
        const pindex = val.indexOf("http");
        window.open(val.substr(pindex, val.length), "_blank");
      } else {
        this.$router.push({path: val})
      }
      this.search = ''
      this.options = []
      this.show = false
    },
  }
}

</script>

<style lang="scss" scoped>
.header-search {
  font-size: 0 !important;

  .search-icon {
    cursor: pointer;
    font-size: 18px;
    padding: 6px;
    vertical-align: middle;
    border-radius: 4px;

    &:hover {
      background-color: var(--barColor-dark-2);
      color: var(--barColor-font-active);
    }
  }

  .header-search-select {
    font-size: 18px;
    transition: width 0.2s;
    width: 0;
    overflow: hidden;
    background: transparent;
    border-radius: 0;
    display: inline-block;
    vertical-align: middle;

    /deep/ .el-input__inner {
      border: 0;
    }
  }

  &.show {
    .header-search-select {
      width: 210px;
      margin-left: 10px;
    }
  }
}
</style>