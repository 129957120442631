export const HOVER_STATE_NAME = 'hover'

export function hoverItemActive(G6) {
    G6.registerBehavior('hover-item-active', {
        getEvents() {
            return {
                'node:mouseenter': 'onEnter',
                'node:mouseleave': 'onLeave',
                'edge:mouseenter': 'onEnter',
                'edge:mouseleave': 'onLeave',
            }
        },
        onEnter(e) {
            const self = this;
            const item = e.item;
            const graph = self.graph;
            graph.setItemState(item, HOVER_STATE_NAME, true);
        },
        onLeave(e) {
            const self = this;
            const item = e.item;
            if (!item) {
                return
            }
            const graph = self.graph;
            graph.setItemState(item, HOVER_STATE_NAME, false);
        },
    })
}