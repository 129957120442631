import routers from '../router/routers';
import _ from 'lodash';
import path from 'path';
import { isString, isArray } from '@/utils/validate';
import { getUser } from "_api/sysTem/userAdmin";
import language from "./checkLang"
export const getSession = name => {
	if(sessionStorage.vue_admin_template_token) {
		if(sessionStorage.vue_admin_template_token!=='undefined') {
			return JSON.parse(sessionStorage.getItem(name));
		}
	}
	if(name.indexOf('LanguageName')>-1){

		// alert(JSON.parse(sessionStorage.getItem(name)))
		return JSON.parse(sessionStorage.getItem(name));

	}
};
export const setSession = (name = '', data) => {
	if (sessionStorage) {
		let o = JSON.stringify(data);
		sessionStorage.setItem(name, o);
	}
};
export const removeSession = (name = '') => {
	if (sessionStorage) {
		sessionStorage.removeItem(name);
	}
};

/* 加载用户字体大小 */
export const checkFont = () => {
	let updatetime = getSession('updateTime'),
  info = getSession('users');
  if(updatetime || info) {
		let fonts = info?.fontSize
    let fontf = info?.fontFamily
		switch(fontf) {
			case "默认":
				document.body.style.fontFamily = "Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif";
				break;
			case "宋体":
				document.body.style.fontFamily = "宋体";
				break;
			case "楷体":
				document.body.style.fontFamily = "楷体";
				break;
			case "圆体":
				document.body.style.fontFamily = "圆体";
				break;
			case "诗简体":
				document.body.style.fontFamily = "诗简体";
			default:break;
		}
		switch(fonts) {
			case "default":
				fonts = "14px";
				break;
			case "middle":
				fonts = "16px";
				break;
			case "large":
				fonts = "18px";
				break;
			default:break;
		}

			return fonts

	}
  else {
		setTimeout(() =>{
			let userinfo = getSession('userInfo');
			const id = userinfo.user.id;
			getUser(id).then(res=>{
				let fonts = res?.fontSize,
						fontf = res.fontFamily;
				if(!getSession('users')){
					setSession('users', res);
				}

				switch(fontf) {
					case "默认":
						document.body.style.fontFamily = "Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB, Microsoft YaHei, Arial, sans-serif";
						break;
					case "宋体":
						document.body.style.fontFamily = "宋体";
						break;
					case "楷体":
						document.body.style.fontFamily = "楷体";
						break;
					case "圆体":
						document.body.style.fontFamily = "圆体";
						break;
					case "诗简体":
						document.body.style.fontFamily = "诗简体";
					default:break;
				}
				switch(fonts) {
					case "default":
						fonts = "14px";
						break;
					case "middle":
						fonts = "16px";
						break;
					case "large":
						fonts = "18px";
						break;
					default:break;
				}
					return fonts

				// this.reload();
			})
		},150)
	}

}
export const sortAny = (arr, key, sort = 'asc', objFn) => {
	if (!arr || !key) return;
	let formatterObjFn = (a, b) => {
		let key = 'formatter';
		if (!b[key] && b.prop === 'date') {
			b[key] = objFn[key];
		}
		if (!a[key] && a.prop === 'date') {
			a[key] = objFn[key];
		}
	};
	let t = _.cloneDeep(arr);
	t.sort((a, b) => {
		if (sort === 'asc') {
			if (objFn) formatterObjFn(a, b);
			return a[key] - b[key];
		}
		if (sort === 'desc') {
			if (objFn) formatterObjFn(a, b);
			return b[key] - a[key];
		}
	});

	return t;
};
export const switchTime = (time, format) => {
	if (!time) return null;
	const _format = format || '{y}-{m}-{d} {h}:{i}:{s}';
	let date;
	if (typeof time == 'string') {
		if (/^[0-9]+$/.test(time)) {
			time = parseInt(time);
		} else {
			time = time.replace(new RegExp(/-/gm), '/');
		}
	}
	date = new Date(time);
	const formatObj = {
		y: date.getFullYear(),
		m: date.getMonth() + 1,
		d: date.getDate(),
		h: date.getHours(),
		i: date.getMinutes(),
		s: date.getSeconds(),
		a: date.getDay(),
	};
	const time_str = _format.replace(/{([ymdhisa])+}/g, (result, key) => {
		const value = formatObj[key];
		if (key === 'a') {
			return ['日', '一', '二', '三', '四', '五', '六'][value];
		}
		return value.toString().padStart(2, '0');
	});
	return time_str;
};

export const initRoutes = (route = []) => {
	let routes = JSON.parse(JSON.stringify(route));

	let a = (arr = []) => {

		return arr.reduce((cur, next) => {

			if (cur && routers[next.component]) {
				// console.log('next', next)
				// next.meta = 'true'
				if(next.meta) {
					next.meta.keepAlive = true
				}
				next.component = routers[next.component];
				cur.push(next);
				if (next.children?.length) {
					next.children = a(next.children);
				} else {
					if(next.meta) {
						next.meta.keepAlive = !!next.meta.isSystem;
					}
					if (next.name) {
						let detailEdisable = true
						if (next.name == "sdrl") {
							detailEdisable = false
						} else {
							detailEdisable = true
						}
						const detailEdit = {
							//component: routers['DetailEdit'],
							path: `${next.name}_edit`,
							name: `${next.name}_detailEdit`,
							meta: {
								title: next?.meta?.title +' '+ language.configMoudle.details,
								keepAlive: detailEdisable,
							},
						};
						const detailAdd = {
							//component: routers['DetailAdd'],
							path: `${next.name}_add`,
							name: `${next.name}_detailAdd`,
							meta: {
								title: next?.meta?.title +' '+ language.configMoudle.add,
								keepAlive: true,
							},
						};
						const detailView = {
							component: routers['Detail'],
							path: `${next.name}_view`,
							name: `${next.name}_detailView`,
							meta: {
								title: next?.meta?.title +' '+ language.configMoudle.view,
							},
						};
						//没有该模块可访问的路由处理
						const noAuthDetailEdit = {
							component: routers['Detail'],
							path: `noauth_view`,
							name: `noauth_detailView`,
							meta: {
								title:language.configMoudle.portDetails,
							},
						};
						cur.push(detailEdit, detailAdd, detailView,noAuthDetailEdit);
					}
				}

				return cur;
			}
		}, []);
	};
	routes.forEach(item =>{
	})
	routes = a(routes);
	return routes;
};

export const deepFindOne = (arr = [], key, val) => {
	let box;
	let ac = (arr, key, val) => {
		for (let i = 0, len = arr.length; i < len; i++) {
			if (isArray(val)) {
				let len = val.length;
				while (len--) {
					if (b[key] === val[len]) {
						b.resolvePath = path.resolve(box.path, b.path);
						a.push(b);
					}
				}
			}
			if (isString(val)) {
				if (arr[i][key] === val || (box && path.resolve(box.path, arr[i].path) === val)) {
					arr[i].resolvePath = path.resolve(box.path, arr[i].path);
					return arr[i];
				}
			}
			if (arr[i].children && arr[i].children.length) {
				box = arr[i];
				let v = ac(arr[i].children, key, val);
				if (v) {
					return v;
				}
			}
		}
	};

	return ac(arr, key, val);
};

export const sqlFn = (sql = '', data) => {
	if (!sql || !data) return;
	const fn = match => {
		let _match = match.replace('@', '');
		if (data[_match]) {
			return `'${data[_match]}'`;
		}
		return match;
	};
	const str = sql.replace(/@[a-z0-9][a-z0-9]+/g, fn);

	return str;
};

export const deppZip = (arr = [], key = '') => {
	arr.reduce((a, b) => {
		(a[b[key]] = a[b[key]] || []).push(b);
		return a;
	}, []);
};
export const deepFindOneObj = function(obj = {}, key = '', value = '') {
	if (obj[key] === value) {
		return obj;
	}
	if (obj.children) {
		for (let index = 0; index < obj.children.length; index++) {
			const t = deepFindOneObj(obj.children[index], key, value);
			if (t) return t;
		}
	}
};
  /**
   * 分组小计
   * @param {*} tableData  表格数据
   * @param {*} subtotalGroupFiled  分组字段
   * @param {*} subtotalFiled  小计字段
   * @param {*} fieldsConfigVOs  字段列表
   * @returns
   */
  export const subtotal = function(tableData,subtotalGroupFiled,subtotalFiled,fieldsConfigVOs){
	if (!tableData){
		return
	}
	const groupBy = (array, key) => {
			return array.reduce((result, currentValue) => {
			  (result[currentValue[key]] = result[currentValue[key]] || []).push(
				currentValue
			  );
			  return result;
			}, {});
		  };

		  const sortArray = (prop)=>{
			return function(a, b) {
				if (a[prop] > b[prop]) {
					return 1;
				} else if (a[prop] < b[prop]) {
					return -1;
				}
				return 0;
			}
		  }
		  for(let j = tableData.length-1;j >= 0;j--){
			if (tableData[j].subtotal){
				tableData.splice(j,1)
			 }

		  }
		  tableData.sort(sortArray(subtotalGroupFiled));
		  let listGroupedBy = groupBy(tableData, subtotalGroupFiled);
		  let index = 0
		  for(let key in listGroupedBy){
			 index = index + listGroupedBy[key].length
			 if (listGroupedBy[key].length > 1){
			   let fields = subtotalFiled
			   let obj = {[subtotalGroupFiled]:'分组小计',subtotal:true}
			   let a = tableData.filter((item)=>{ return item[subtotalGroupFiled] == key})
			   for(let f = 0 ; f < fields.length;f++){
				  let total = 0
				  a.forEach(function(val, idx, arr) {
					  total += parseFloat(val[fields[f]] || 0);
				  }, 0);
				  let filterA = fieldsConfigVOs.filter((a)=>{
					  return a.fieldName.toLowerCase() == fields[f].toLowerCase()
				  })
				  if (filterA && filterA.length > 0){
					obj[fields[f]] = total.toFixed(filterA[0].expressionDecimalDigit || 2)
				  }else{
					obj[fields[f]] = total.toFixed(2)
				  }
			   }
			   tableData.splice(index, 0, obj);
			   index++
			 }


		  }
  }

/*函数防抖处理*/
export const debounce = function(fn, interval) {
	var timer;
	var gapTime = interval || 1000;//间隔时间
	return function() {
	  clearTimeout(timer);
	  var context = this;
	  var args = arguments;//保存此处的arguments，因为setTimeout是全局的，arguments不是防抖函数需要的。
	  timer = setTimeout(function() {
		fn.call(context,args);
	  }, gapTime);
	};
}

/*浏览器判断*/
export const AgentType = {
	Opera: 'Opera',
	FireFox: 'FireFox',
	Chrome: 'Chrome',
	Safari: 'Safari',
	IE: 'IE',
}

export const getUserAgent = () => {
	const { userAgent } = navigator; // 取得浏览器的userAgent字符串
	// console.log(userAgent)
	if (userAgent.indexOf('Opera') > -1) {
	  // 判断是否Opera浏览器
	  return AgentType.Opera;
	}
	if (userAgent.indexOf('Firefox') > -1) {
	  // 判断是否Firefox浏览器
	  return AgentType.FireFox;
	}
	if (userAgent.indexOf('Chrome') > -1) {
	  // 判断是否为谷歌浏览器
	  return AgentType.Chrome;
	}
	if (userAgent.indexOf('Safari') > -1) {
	  // 判断是否Safari浏览器
	  return AgentType.Safari;
	}
	if ((userAgent.indexOf('Trident') > -1 && userAgent.indexOf("rv:11.0") > -1) || (userAgent.indexOf("compatible") > -1 && userAgent.indexOf("MSIE") > -1)) {
	  // 判断是否IE浏览器
	  return AgentType.IE;
	}
	return '';
};

/* 富文本配置参数 */
export const emotion = [
	[
		{
			title: "默认表情", // tab 的标题
			type: "image", // 'emoji' 或 'image' ，即 emoji 形式或者图片形式
			content: [
				{
				alt: "微笑",
				src: "https://rescdn.qqmail.com/zh_CN/images/mo/DEFAULT2/0.gif",
				},
				{
				alt: "撇嘴",
				src: "https://rescdn.qqmail.com/zh_CN/images/mo/DEFAULT2/1.gif",
				},
				{
				alt: "色",
				src: "https://rescdn.qqmail.com/zh_CN/images/mo/DEFAULT2/2.gif",
				},
				{
				alt: "发呆",
				src: "https://rescdn.qqmail.com/zh_CN/images/mo/DEFAULT2/3.gif",
				},
				{
				alt: "得意",
				src: "https://rescdn.qqmail.com/zh_CN/images/mo/DEFAULT2/4.gif",
				},
				{
				alt: "流泪",
				src: "https://rescdn.qqmail.com/zh_CN/images/mo/DEFAULT2/5.gif",
				},
				{
				alt: "害羞",
				src: "https://rescdn.qqmail.com/zh_CN/images/mo/DEFAULT2/6.gif",
				},
				{
				alt: "闭嘴",
				src: "https://rescdn.qqmail.com/zh_CN/images/mo/DEFAULT2/7.gif",
				},
				{
				alt: "睡",
				src: "https://rescdn.qqmail.com/zh_CN/images/mo/DEFAULT2/8.gif",
				},
				{
				alt: "大哭",
				src: "https://rescdn.qqmail.com/zh_CN/images/mo/DEFAULT2/9.gif",
				},
				{
				alt: "尴尬",
				src: "https://rescdn.qqmail.com/zh_CN/images/mo/DEFAULT2/10.gif",
				},
				{
				alt: "发怒",
				src: "https://rescdn.qqmail.com/zh_CN/images/mo/DEFAULT2/11.gif",
				},
				{
				alt: "调皮",
				src: "https://rescdn.qqmail.com/zh_CN/images/mo/DEFAULT2/12.gif",
				},
				{
				alt: "呲牙",
				src: "https://rescdn.qqmail.com/zh_CN/images/mo/DEFAULT2/13.gif",
				},
				{
				alt: "惊讶",
				src: "https://rescdn.qqmail.com/zh_CN/images/mo/DEFAULT2/14.gif",
				},
				{
				alt: "难过",
				src: "https://rescdn.qqmail.com/zh_CN/images/mo/DEFAULT2/15.gif",
				},
				{
				alt: "酷",
				src: "https://rescdn.qqmail.com/zh_CN/images/mo/DEFAULT2/16.gif",
				},
				{
				alt: "冷汗",
				src: "https://rescdn.qqmail.com/zh_CN/images/mo/DEFAULT2/17.gif",
				},
				{
				alt: "抓狂",
				src: "https://rescdn.qqmail.com/zh_CN/images/mo/DEFAULT2/18.gif",
				},
				{
				alt: "吐",
				src: "https://rescdn.qqmail.com/zh_CN/images/mo/DEFAULT2/19.gif",
				},
				{
				alt: "偷笑",
				src: "https://rescdn.qqmail.com/zh_CN/images/mo/DEFAULT2/20.gif",
				},
				{
				alt: "可爱",
				src: "https://rescdn.qqmail.com/zh_CN/images/mo/DEFAULT2/21.gif",
				},
				{
				alt: "白眼",
				src: "https://rescdn.qqmail.com/zh_CN/images/mo/DEFAULT2/22.gif",
				},
				{
				alt: "傲慢",
				src: "https://rescdn.qqmail.com/zh_CN/images/mo/DEFAULT2/23.gif",
				},
				{
				alt: "饥饿",
				src: "https://rescdn.qqmail.com/zh_CN/images/mo/DEFAULT2/24.gif",
				},
				{
				alt: "困",
				src: "https://rescdn.qqmail.com/zh_CN/images/mo/DEFAULT2/25.gif",
				},
				{
				alt: "惊恐",
				src: "https://rescdn.qqmail.com/zh_CN/images/mo/DEFAULT2/26.gif",
				},
				{
				alt: "流汗",
				src: "https://rescdn.qqmail.com/zh_CN/images/mo/DEFAULT2/27.gif",
				},
				{
				alt: "憨笑",
				src: "https://rescdn.qqmail.com/zh_CN/images/mo/DEFAULT2/28.gif",
				},
				{
				alt: "大兵",
				src: "https://rescdn.qqmail.com/zh_CN/images/mo/DEFAULT2/29.gif",
				},
				{
				alt: "奋斗",
				src: "https://rescdn.qqmail.com/zh_CN/images/mo/DEFAULT2/30.gif",
				},
				{
				alt: "咒骂",
				src: "https://rescdn.qqmail.com/zh_CN/images/mo/DEFAULT2/31.gif",
				},
				{
				alt: "疑问",
				src: "https://rescdn.qqmail.com/zh_CN/images/mo/DEFAULT2/32.gif",
				},
				{
				alt: "嘘...",
				src: "https://rescdn.qqmail.com/zh_CN/images/mo/DEFAULT2/33.gif",
				},
				{
				alt: "晕",
				src: "https://rescdn.qqmail.com/zh_CN/images/mo/DEFAULT2/34.gif",
				},
				{
				alt: "折磨",
				src: "https://rescdn.qqmail.com/zh_CN/images/mo/DEFAULT2/35.gif",
				},
				{
				alt: "衰",
				src: "https://rescdn.qqmail.com/zh_CN/images/mo/DEFAULT2/36.gif",
				},
				{
				alt: "骷髅",
				src: "https://rescdn.qqmail.com/zh_CN/images/mo/DEFAULT2/37.gif",
				},
				{
				alt: "敲打",
				src: "https://rescdn.qqmail.com/zh_CN/images/mo/DEFAULT2/38.gif",
				},
				{
				alt: "再见",
				src: "https://rescdn.qqmail.com/zh_CN/images/mo/DEFAULT2/39.gif",
				},
				{
				alt: "擦汗",
				src: "https://rescdn.qqmail.com/zh_CN/images/mo/DEFAULT2/40.gif",
				},
				{
				alt: "抠鼻",
				src: "https://rescdn.qqmail.com/zh_CN/images/mo/DEFAULT2/41.gif",
				},
				{
				alt: "鼓掌",
				src: "https://rescdn.qqmail.com/zh_CN/images/mo/DEFAULT2/42.gif",
				},
				{
				alt: "糗大了",
				src: "https://rescdn.qqmail.com/zh_CN/images/mo/DEFAULT2/43.gif",
				},
				{
				alt: "坏笑",
				src: "https://rescdn.qqmail.com/zh_CN/images/mo/DEFAULT2/44.gif",
				},
				{
				alt: "左哼哼",
				src: "https://rescdn.qqmail.com/zh_CN/images/mo/DEFAULT2/45.gif",
				},
				{
				alt: "右哼哼",
				src: "https://rescdn.qqmail.com/zh_CN/images/mo/DEFAULT2/46.gif",
				},
				{
				alt: "哈欠",
				src: "https://rescdn.qqmail.com/zh_CN/images/mo/DEFAULT2/47.gif",
				},
				{
				alt: "鄙视",
				src: "https://rescdn.qqmail.com/zh_CN/images/mo/DEFAULT2/48.gif",
				},
				{
				alt: "委屈",
				src: "https://rescdn.qqmail.com/zh_CN/images/mo/DEFAULT2/49.gif",
				},
				{
				alt: "快哭了",
				src: "https://rescdn.qqmail.com/zh_CN/images/mo/DEFAULT2/50.gif",
				},
				{
				alt: "阴险",
				src: "https://rescdn.qqmail.com/zh_CN/images/mo/DEFAULT2/51.gif",
				},
				{
				alt: "亲亲",
				src: "https://rescdn.qqmail.com/zh_CN/images/mo/DEFAULT2/52.gif",
				},
				{
				alt: "吓",
				src: "https://rescdn.qqmail.com/zh_CN/images/mo/DEFAULT2/53.gif",
				},
				{
				alt: "可怜",
				src: "https://rescdn.qqmail.com/zh_CN/images/mo/DEFAULT2/54.gif",
				},
				{
				alt: "菜刀",
				src: "https://rescdn.qqmail.com/zh_CN/images/mo/DEFAULT2/55.gif",
				},
				{
				alt: "西瓜",
				src: "https://rescdn.qqmail.com/zh_CN/images/mo/DEFAULT2/56.gif",
				},
				{
				alt: "啤酒",
				src: "https://rescdn.qqmail.com/zh_CN/images/mo/DEFAULT2/57.gif",
				},
				{
				alt: "篮球",
				src: "https://rescdn.qqmail.com/zh_CN/images/mo/DEFAULT2/58.gif",
				},
				{
				alt: "乒乓",
				src: "https://rescdn.qqmail.com/zh_CN/images/mo/DEFAULT2/59.gif",
				},
				{
				alt: "咖啡",
				src: "https://rescdn.qqmail.com/zh_CN/images/mo/DEFAULT2/60.gif",
				},
				{
				alt: "饭",
				src: "https://rescdn.qqmail.com/zh_CN/images/mo/DEFAULT2/61.gif",
				},
				{
				alt: "猪头",
				src: "https://rescdn.qqmail.com/zh_CN/images/mo/DEFAULT2/62.gif",
				},
				{
				alt: "玫瑰",
				src: "https://rescdn.qqmail.com/zh_CN/images/mo/DEFAULT2/63.gif",
				},
				{
				alt: "凋谢",
				src: "https://rescdn.qqmail.com/zh_CN/images/mo/DEFAULT2/64.gif",
				},
				{
				alt: "示爱",
				src: "https://rescdn.qqmail.com/zh_CN/images/mo/DEFAULT2/65.gif",
				},
				{
				alt: "爱心",
				src: "https://rescdn.qqmail.com/zh_CN/images/mo/DEFAULT2/66.gif",
				},
				{
				alt: "心碎",
				src: "https://rescdn.qqmail.com/zh_CN/images/mo/DEFAULT2/67.gif",
				},
				{
				alt: "蛋糕",
				src: "https://rescdn.qqmail.com/zh_CN/images/mo/DEFAULT2/68.gif",
				},
				{
				alt: "闪电",
				src: "https://rescdn.qqmail.com/zh_CN/images/mo/DEFAULT2/69.gif",
				},
				{
				alt: "炸弹",
				src: "https://rescdn.qqmail.com/zh_CN/images/mo/DEFAULT2/70.gif",
				},
				{
				alt: "刀",
				src: "https://rescdn.qqmail.com/zh_CN/images/mo/DEFAULT2/71.gif",
				},
				{
				alt: "足球",
				src: "https://rescdn.qqmail.com/zh_CN/images/mo/DEFAULT2/72.gif",
				},
				{
				alt: "瓢虫",
				src: "https://rescdn.qqmail.com/zh_CN/images/mo/DEFAULT2/73.gif",
				},
				{
				alt: "便便",
				src: "https://rescdn.qqmail.com/zh_CN/images/mo/DEFAULT2/74.gif",
				},
				{
				alt: "月亮",
				src: "https://rescdn.qqmail.com/zh_CN/images/mo/DEFAULT2/75.gif",
				},
				{
				alt: "太阳",
				src: "https://rescdn.qqmail.com/zh_CN/images/mo/DEFAULT2/76.gif",
				},
				{
				alt: "礼物",
				src: "https://rescdn.qqmail.com/zh_CN/images/mo/DEFAULT2/77.gif",
				},
				{
				alt: "拥抱",
				src: "https://rescdn.qqmail.com/zh_CN/images/mo/DEFAULT2/78.gif",
				},
				{
				alt: "强",
				src: "https://rescdn.qqmail.com/zh_CN/images/mo/DEFAULT2/79.gif",
				},
				{
				alt: "弱",
				src: "https://rescdn.qqmail.com/zh_CN/images/mo/DEFAULT2/80.gif",
				},
				{
				alt: "握手",
				src: "https://rescdn.qqmail.com/zh_CN/images/mo/DEFAULT2/81.gif",
				},
				{
				alt: "胜利",
				src: "https://rescdn.qqmail.com/zh_CN/images/mo/DEFAULT2/82.gif",
				},
				{
				alt: "抱拳",
				src: "https://rescdn.qqmail.com/zh_CN/images/mo/DEFAULT2/83.gif",
				},
				{
				alt: "勾引",
				src: "https://rescdn.qqmail.com/zh_CN/images/mo/DEFAULT2/84.gif",
				},
				{
				alt: "拳头",
				src: "https://rescdn.qqmail.com/zh_CN/images/mo/DEFAULT2/85.gif",
				},
				{
				alt: "差劲",
				src: "https://rescdn.qqmail.com/zh_CN/images/mo/DEFAULT2/86.gif",
				},
				{
				alt: "爱你",
				src: "https://rescdn.qqmail.com/zh_CN/images/mo/DEFAULT2/87.gif",
				},
				{
				alt: "NO",
				src: "https://rescdn.qqmail.com/zh_CN/images/mo/DEFAULT2/88.gif",
				},
				{
				alt: "OK",
				src: "https://rescdn.qqmail.com/zh_CN/images/mo/DEFAULT2/89.gif",
				},
				{
				alt: "爱情",
				src: "https://rescdn.qqmail.com/zh_CN/images/mo/DEFAULT2/90.gif",
				},
				{
				alt: "飞吻",
				src: "https://rescdn.qqmail.com/zh_CN/images/mo/DEFAULT2/91.gif",
				},
				{
				alt: "跳跳",
				src: "https://rescdn.qqmail.com/zh_CN/images/mo/DEFAULT2/92.gif",
				},
				{
				alt: "发抖",
				src: "https://rescdn.qqmail.com/zh_CN/images/mo/DEFAULT2/93.gif",
				},
				{
				alt: "怄火",
				src: "https://rescdn.qqmail.com/zh_CN/images/mo/DEFAULT2/94.gif",
				},
				{
				alt: "转圈",
				src: "https://rescdn.qqmail.com/zh_CN/images/mo/DEFAULT2/95.gif",
				},
				{
				alt: "磕头",
				src: "https://rescdn.qqmail.com/zh_CN/images/mo/DEFAULT2/96.gif",
				},
				{
				alt: "回头",
				src: "https://rescdn.qqmail.com/zh_CN/images/mo/DEFAULT2/97.gif",
				},
				{
				alt: "跳绳",
				src: "https://rescdn.qqmail.com/zh_CN/images/mo/DEFAULT2/98.gif",
				},
				{
				alt: "挥手",
				src: "https://rescdn.qqmail.com/zh_CN/images/mo/DEFAULT2/99.gif",
				},
				{
				alt: "激动",
				src: "https://rescdn.qqmail.com/zh_CN/images/mo/DEFAULT2/100.gif",
				},
				{
				alt: "街舞",
				src: "https://rescdn.qqmail.com/zh_CN/images/mo/DEFAULT2/101.gif",
				},
				{
				alt: "献吻",
				src: "https://rescdn.qqmail.com/zh_CN/images/mo/DEFAULT2/102.gif",
				},
				{
				alt: "左太极",
				src: "https://rescdn.qqmail.com/zh_CN/images/mo/DEFAULT2/103.gif",
				},
				{
				alt: "右太极",
				src: "https://rescdn.qqmail.com/zh_CN/images/mo/DEFAULT2/104.gif",
				},
			],
		},
		{
			title: "emoji",
			type: "image",
			content: [
				{ src: "https://rescdn.qqmail.com/zh_CN/images/mo/EMOJI/001.png" },
				{ src: "https://rescdn.qqmail.com/zh_CN/images/mo/EMOJI/002.png" },
				{ src: "https://rescdn.qqmail.com/zh_CN/images/mo/EMOJI/003.png" },
				{ src: "https://rescdn.qqmail.com/zh_CN/images/mo/EMOJI/004.png" },
				{ src: "https://rescdn.qqmail.com/zh_CN/images/mo/EMOJI/005.png" },
				{ src: "https://rescdn.qqmail.com/zh_CN/images/mo/EMOJI/006.png" },
				{ src: "https://rescdn.qqmail.com/zh_CN/images/mo/EMOJI/007.png" },
				{ src: "https://rescdn.qqmail.com/zh_CN/images/mo/EMOJI/008.png" },
				{ src: "https://rescdn.qqmail.com/zh_CN/images/mo/EMOJI/009.png" },
				{ src: "https://rescdn.qqmail.com/zh_CN/images/mo/EMOJI/010.png" },
				{ src: "https://rescdn.qqmail.com/zh_CN/images/mo/EMOJI/011.png" },
				{ src: "https://rescdn.qqmail.com/zh_CN/images/mo/EMOJI/012.png" },
				{ src: "https://rescdn.qqmail.com/zh_CN/images/mo/EMOJI/013.png" },
				{ src: "https://rescdn.qqmail.com/zh_CN/images/mo/EMOJI/014.png" },
				{ src: "https://rescdn.qqmail.com/zh_CN/images/mo/EMOJI/015.png" },
				{ src: "https://rescdn.qqmail.com/zh_CN/images/mo/EMOJI/016.png" },
				{ src: "https://rescdn.qqmail.com/zh_CN/images/mo/EMOJI/017.png" },
				{ src: "https://rescdn.qqmail.com/zh_CN/images/mo/EMOJI/018.png" },
				{ src: "https://rescdn.qqmail.com/zh_CN/images/mo/EMOJI/019.png" },
				{ src: "https://rescdn.qqmail.com/zh_CN/images/mo/EMOJI/020.png" },
				{ src: "https://rescdn.qqmail.com/zh_CN/images/mo/EMOJI/021.png" },
				{ src: "https://rescdn.qqmail.com/zh_CN/images/mo/EMOJI/022.png" },
				{ src: "https://rescdn.qqmail.com/zh_CN/images/mo/EMOJI/023.png" },
				{ src: "https://rescdn.qqmail.com/zh_CN/images/mo/EMOJI/024.png" },
				{ src: "https://rescdn.qqmail.com/zh_CN/images/mo/EMOJI/025.png" },
				{ src: "https://rescdn.qqmail.com/zh_CN/images/mo/EMOJI/026.png" },
				{ src: "https://rescdn.qqmail.com/zh_CN/images/mo/EMOJI/027.png" },
				{ src: "https://rescdn.qqmail.com/zh_CN/images/mo/EMOJI/028.png" },
				{ src: "https://rescdn.qqmail.com/zh_CN/images/mo/EMOJI/029.png" },
				{ src: "https://rescdn.qqmail.com/zh_CN/images/mo/EMOJI/030.png" },
				{ src: "https://rescdn.qqmail.com/zh_CN/images/mo/EMOJI/031.png" },
				{ src: "https://rescdn.qqmail.com/zh_CN/images/mo/EMOJI/032.png" },
				{ src: "https://rescdn.qqmail.com/zh_CN/images/mo/EMOJI/033.png" },
				{ src: "https://rescdn.qqmail.com/zh_CN/images/mo/EMOJI/034.png" },
				{ src: "https://rescdn.qqmail.com/zh_CN/images/mo/EMOJI/035.png" },
				{ src: "https://rescdn.qqmail.com/zh_CN/images/mo/EMOJI/036.png" },
				{ src: "https://rescdn.qqmail.com/zh_CN/images/mo/EMOJI/037.png" },
				{ src: "https://rescdn.qqmail.com/zh_CN/images/mo/EMOJI/038.png" },
				{ src: "https://rescdn.qqmail.com/zh_CN/images/mo/EMOJI/039.png" },
				{ src: "https://rescdn.qqmail.com/zh_CN/images/mo/EMOJI/040.png" },
				{ src: "https://rescdn.qqmail.com/zh_CN/images/mo/EMOJI/041.png" },
				{ src: "https://rescdn.qqmail.com/zh_CN/images/mo/EMOJI/042.png" },
				{ src: "https://rescdn.qqmail.com/zh_CN/images/mo/EMOJI/043.png" },
				{ src: "https://rescdn.qqmail.com/zh_CN/images/mo/EMOJI/044.png" },
				{ src: "https://rescdn.qqmail.com/zh_CN/images/mo/EMOJI/045.png" },
				{ src: "https://rescdn.qqmail.com/zh_CN/images/mo/EMOJI/046.png" },
				{ src: "https://rescdn.qqmail.com/zh_CN/images/mo/EMOJI/047.png" },
				{ src: "https://rescdn.qqmail.com/zh_CN/images/mo/EMOJI/048.png" },
				{ src: "https://rescdn.qqmail.com/zh_CN/images/mo/EMOJI/049.png" },
				{ src: "https://rescdn.qqmail.com/zh_CN/images/mo/EMOJI/050.png" },
				{ src: "https://rescdn.qqmail.com/zh_CN/images/mo/EMOJI/051.png" },
				{ src: "https://rescdn.qqmail.com/zh_CN/images/mo/EMOJI/052.png" },
				{ src: "https://rescdn.qqmail.com/zh_CN/images/mo/EMOJI/053.png" },
				{ src: "https://rescdn.qqmail.com/zh_CN/images/mo/EMOJI/054.png" },
				{ src: "https://rescdn.qqmail.com/zh_CN/images/mo/EMOJI/055.png" },
				{ src: "https://rescdn.qqmail.com/zh_CN/images/mo/EMOJI/056.png" },
				{ src: "https://rescdn.qqmail.com/zh_CN/images/mo/EMOJI/057.png" },
				{ src: "https://rescdn.qqmail.com/zh_CN/images/mo/EMOJI/058.png" },
				{ src: "https://rescdn.qqmail.com/zh_CN/images/mo/EMOJI/059.png" },
				{ src: "https://rescdn.qqmail.com/zh_CN/images/mo/EMOJI/060.png" },
				{ src: "https://rescdn.qqmail.com/zh_CN/images/mo/EMOJI/061.png" },
				{ src: "https://rescdn.qqmail.com/zh_CN/images/mo/EMOJI/062.png" },
				{ src: "https://rescdn.qqmail.com/zh_CN/images/mo/EMOJI/063.png" },
				{ src: "https://rescdn.qqmail.com/zh_CN/images/mo/EMOJI/064.png" },
				{ src: "https://rescdn.qqmail.com/zh_CN/images/mo/EMOJI/065.png" },
				{ src: "https://rescdn.qqmail.com/zh_CN/images/mo/EMOJI/066.png" },
				{ src: "https://rescdn.qqmail.com/zh_CN/images/mo/EMOJI/067.png" },
				{ src: "https://rescdn.qqmail.com/zh_CN/images/mo/EMOJI/068.png" },
				{ src: "https://rescdn.qqmail.com/zh_CN/images/mo/EMOJI/069.png" },
				{ src: "https://rescdn.qqmail.com/zh_CN/images/mo/EMOJI/070.png" },
				{ src: "https://rescdn.qqmail.com/zh_CN/images/mo/EMOJI/071.png" },
				{ src: "https://rescdn.qqmail.com/zh_CN/images/mo/EMOJI/072.png" },
				{ src: "https://rescdn.qqmail.com/zh_CN/images/mo/EMOJI/073.png" },
				{ src: "https://rescdn.qqmail.com/zh_CN/images/mo/EMOJI/074.png" },
				{ src: "https://rescdn.qqmail.com/zh_CN/images/mo/EMOJI/075.png" },
				{ src: "https://rescdn.qqmail.com/zh_CN/images/mo/EMOJI/076.png" },
				{ src: "https://rescdn.qqmail.com/zh_CN/images/mo/EMOJI/077.png" },
				{ src: "https://rescdn.qqmail.com/zh_CN/images/mo/EMOJI/078.png" },
				{ src: "https://rescdn.qqmail.com/zh_CN/images/mo/EMOJI/079.png" },
				{ src: "https://rescdn.qqmail.com/zh_CN/images/mo/EMOJI/080.png" },
				{ src: "https://rescdn.qqmail.com/zh_CN/images/mo/EMOJI/081.png" },
				{ src: "https://rescdn.qqmail.com/zh_CN/images/mo/EMOJI/082.png" },
				{ src: "https://rescdn.qqmail.com/zh_CN/images/mo/EMOJI/083.png" },
				{ src: "https://rescdn.qqmail.com/zh_CN/images/mo/EMOJI/084.png" },
				{ src: "https://rescdn.qqmail.com/zh_CN/images/mo/EMOJI/085.png" },
				{ src: "https://rescdn.qqmail.com/zh_CN/images/mo/EMOJI/086.png" },
				{ src: "https://rescdn.qqmail.com/zh_CN/images/mo/EMOJI/087.png" },
				{ src: "https://rescdn.qqmail.com/zh_CN/images/mo/EMOJI/088.png" },
				{ src: "https://rescdn.qqmail.com/zh_CN/images/mo/EMOJI/089.png" },
				{ src: "https://rescdn.qqmail.com/zh_CN/images/mo/EMOJI/090.png" },
			],
		}
	]
]

/* 时间戳转换 */
export const transDate = function(dates) {
	let date = new Date(dates);
	let month =
	  date.getMonth() + 1 < 10
		? "0" + (date.getMonth() + 1)
		: date.getMonth() + 1;
	let day = date.getDate() < 10 ? "0" + date.getDate() : date.getDate();
	let hours =
	  date.getHours() < 10 ? "0" + date.getHours() : date.getHours();
	let minutes =
	  date.getMinutes() < 10 ? "0" + date.getMinutes() : date.getMinutes();
	let seconds =
	  date.getSeconds() < 10 ? "0" + date.getSeconds() : date.getSeconds();
	return (
	  date.getFullYear() +
	  "-" +
	  month +
	  "-" +
	  "-" +
	  day +
	  " " +
	  hours +
	  ":" +
	  minutes +
	  ":" +
	  seconds
	);
  }
