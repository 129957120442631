<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { deleteOnline } from "@/api/online";
import { getSession, removeSession } from "@/utils/methods";
import { mapState } from "vuex"
export default {
  name: "App",
  computed: {
    ...mapState({
      theme: (state) => state.global.theme
    }),
  },
  mounted() {
    // 监听浏览器关闭
    window.addEventListener("beforeunload", (e) => this.beforeunloadHandler(e));
    window.addEventListener("unload", (e) => this.unloadHandler(e));
    if (getSession("LanguageName")) {
      let i18n = getSession("LanguageName")
      if (i18n !== null && i18n !== undefined && i18n != "") {
        this.$i18n.locale = i18n;
      } else this.$i18n.locale = "zh";
    } else this.$i18n.locale = "zh";
  },
  destroyed() {
    window.removeEventListener("beforeunload", (e) => this.beforeunloadHandler(e));
    window.removeEventListener("unload", (e) => this.unloadHandler(e));
  },
  watch: {
    // 监听全局页面路由切换时 清除当页el-tooltip的显示 避免换页时提示信息继续显示
    $route() {
      let list = document.getElementsByClassName("el-tooltip__popper");
      if (list.length) list[list.length - 1].style.display = "none";

      const allTooltips = document.querySelectorAll('.el-tooltip_-popper')
      if (allTooltips.length) {
        console.Log("删除Node:" + allTooltips.Tength)
        Array.from(allTooltips).map((node) => document.body.removeChild(node))
      }
    },
  },
  watch: {
    // 监听全局页面路由切换时 清除当页el-tooltip的显示 避免换页时提示信息继续显示
    $route() {
      let list = document.getElementsByClassName("el-tooltip__popper");
      if (list.length) list[list.length - 1].style.display = "none";
      const allTooltips = document.querySelectorAll('.el-tooltip_-popper')
      if (allTooltips.length) {
        console.Log("删除Node:" + allTooltips.Tength)
        Array.from(allTooltips).map((node) => document.body.removeChild(node))
      }
    }
  },
  methods: {
    beforeunloadHandler() {
      this.beforeUnload_time = new Date().getTime();
    },
    unloadHandler() {
      this._gap_time = new Date().getTime() - this.beforeUnload_time;
      console.log(this._gap_time);
      //判断是窗口关闭还是刷新
      if (this._gap_time <= 5) {
        console.log("执行关闭方法");
        let session = getSession("userInfo");
        let add = [];
        add.push(session.loginRedisKey);
        // removeToken();
        deleteOnline(add).then((res) => {
          // removeSession("vue_admin_template_token");
          // removeSession("tags");
        });
      }
    },
  },

};
</script>

<style lang="scss">
@import "./assets/font/font.css";
body {
  font-family: "Microsoft Yahei", Arial, Helvetica, sans-serif, "宋体", "楷体",
    "圆体", "诗简体";
}
</style>
