<template>
  <el-form :model="curData" :rules="rules" label-width="150px" :inline="inline" :size="size" ref="form"
    class="info-form">
    <el-row class="rowBox">
      <el-col v-for="(item, colIndex) in form" :key="`form-row-${colIndex}`" :span="spanSet(item, reactive)" 
        :style="item.stretchTile == '1' ? 'width: 100%' : item.stretchTile == '2' ? 'width: 264px' 
              : item.stretchTile == '3' ? 'width: 50%' : item.stretchTile == '4' ? 'width: 30%' 
              : item.stretchTile == '5' ? 'width: 48%'
              : item.stretchTile == '6' ? 'width: 20%' : 'width: 264px'">
        <el-form-item v-if="showOnlyInfo" :label="item[itemLabel]" :prop="item[itemProp]"
          :key="`form-item-${item[itemProp]}`" :class="[`y-${item[itemTypeKey] || 'input'}`]">
          <div class="hhh">{{ curData[item[itemProp]] }}</div>
        </el-form-item>
        
        <el-form-item v-else :label="item[itemLabel] + ':'" :prop="item[itemProp]" :key="`form-item-${item[itemProp]}`"
          :class="[`y-${item[itemTypeKey] || 'input'}`]">
          <template slot="label">
            <span style="position: relative">
              <span :style="{ fontSize: fonts }">
                <span style="color: red;" v-if="item.required">*</span>
                {{ item[itemLabel] }}
                <el-popover v-show="item['extAttr'] && item['extAttr'] == 'formAddDefaultValue'" placement="top-start"
                  :title="$t('configMoudle.tip')" width="300" trigger="click">
                  <div>
                    默认值支持常量和变量<br />支持的系统变量如下:<br />
                    sys_user_code&nbsp;&nbsp;登陆用户账号<br />
                    sys_user_name &nbsp;&nbsp;登陆用户名<br />
                    sys_date &nbsp;&nbsp; 系统当前日期(yyyy-MM-dd)
                  </div>
                  <i class="el-icon-question" slot="reference"></i>
                </el-popover>
              </span>
              <el-tooltip class="item" effect="dark" placement="top" :tabindex="tabindex">
                <div slot="content"><p>{{ item[itemLabel] }}</p></div>
                <i style=" position: absolute; left: 0; display: inline-block; width: 100%; height: 28px;"></i>
              </el-tooltip>
            </span>
          </template>
          
          <el-popover v-if="item.isShows" placement="bottom-start" trigger="click">
            <IconSelect ref="iconSelect" @selected="selected" :fieldname="item[itemProp]" />
            <el-input slot="reference" v-model="curData[item[itemProp]]" placeholder="点击选择图标" readonly>
              <svg-icon v-if="curData[item[itemProp]]" slot="prefix" :icon-class="curData[item[itemProp]]"
                class="el-input__icon" />
              <i v-else slot="prefix" class="el-icon-search el-input__icon" />
            </el-input>
          </el-popover>

          <el-input v-model="curData[item[itemProp]]"
            v-if="inputType[item[itemTypeKey]] || (!item[itemTypeKey] && !item.isShows)"
            :type="item[itemTypeKey] ? item[itemTypeKey] : 'text'" :autosize="{ minRows: 4, maxRows: 4 }" resize="none"
            class="formStyle" :disabled="(item[disabledKey] || disabledReadOnly) &&
              !($route.query.peddingApproval && item.isEditableUnderApproval)
              " @dblclick.native="Enlarge(item, curData[item[itemProp]], colIndex)" ref="contents"
            @input="changeValue($event, item[itemProp], curData)">
            <i v-if="item.fetchId" class="el-icon-edit el-input__icon coin" style="cursor: pointer" slot="suffix"
              @click="handleFocus(item)"></i>
          </el-input>

          <el-date-picker v-if="item[itemTypeKey] == 'date'" :disabled="item[disabledKey] &&
            !($route.query.peddingApproval && item.isEditableUnderApproval)
            " v-model="curData[item[itemProp]]" type="date" value-format="timestamp"
            @change="handlePicker($event, item[itemProp], curData)"></el-date-picker>

          <el-date-picker v-if="item[itemTypeKey] == 'datetimerange'" v-model="datetimerange" type="datetimerange"
            :range-separator="$t('placeHolder.to')" :start-placeholder="$t('placeHolder.startDate')"
            :end-placeholder="$t('placeHolder.endDate')" @change="datechange"></el-date-picker>

          <el-select v-if="
            item[itemTypeKey] == 'select' ||
            item[itemTypeKey] == 'multipleSelect' ||
            item[itemTypeKey] == 'person' ||
            item[itemTypeKey] == 'selectroles'" 
            :clearable="!item.multiple ? true : false" filterable v-model="curData[item[itemProp]]" 
            :disabled="item[disabledKey] && !($route.query.peddingApproval && item.isEditableUnderApproval)" 
            :multiple="item.multiple ||
              item[itemTypeKey] == 'multipleSelect' ||
              item[itemTypeKey] == 'person' ||
              item[itemTypeKey] == 'selectroles'
              " :collapse-tags="item.collapseTags ||
              item[itemTypeKey] == 'multipleSelect' ||
              item[itemTypeKey] == 'person'
              " :value-key="item.valueKey || item.itemName" @change="
              handleSelect(curData[item[itemProp]], item[itemProp], item.list, curData)">
            <el-option v-if="item.multiple" :label="$t('Emails.getAll')" value="all"></el-option>
            <el-option v-if="item.multiple" :label="$t('Emails.clearAll')" value="empty"></el-option>
            <el-option v-for="(val, index) in item.list"
              :key="`${val[item.optionValue || 'value'] || val}-select-option${index}`"
              :value="item.valueKey ? val : val[item.optionValue || 'value'] || val"
              :label="val[item.optionLabel || 'label'] || val">
              <span v-if="item.titleValue" style="float: left">{{ val.label }}</span>
              <span v-if="item.titleValue" style="float: right; color: #8492a6; font-size: 13px">{{ val.value }}</span>
            </el-option>
          </el-select>

          <el-switch v-if="item[itemTypeKey] == 'switch'" v-model="curData[item[itemProp]]"
            :active-text="item.activeText" :disabled="(item[disabledKey] &&
              !($route.query.peddingApproval && item.isEditableUnderApproval)) || (!filterProp(curData.fieldName))
              "></el-switch>
          <el-radio-group v-model="curData[item[itemProp]]" v-if="item[itemTypeKey] == 'radioGroupButton'" size="small"
            :disabled="item[disabledKey] &&
              !($route.query.peddingApproval && item.isEditableUnderApproval)
              " @change.native="chaneRadioButton">
            <el-radio-button v-for="items in item.GroupList" :label="items.label" :key="items.label"></el-radio-button>
          </el-radio-group>

          <el-radio-group v-if="item[itemTypeKey] == 'radioGroup'" v-model="curData[item[itemProp]]" size="small"
            :disabled="item[disabledKey] &&
              !($route.query.peddingApproval && item.isEditableUnderApproval)
              ">
            <el-radio v-for="items in item.GroupList" :label="items.label" :key="items.label"></el-radio>
          </el-radio-group>

          <Y-treeSelect v-if="item[itemTypeKey] === 'treeselect'" :input.sync="curData[item[itemProp]]"
            :options.sync="item.options" :multiple="item.multiple || false" :normalizerFormat="item.normalizerFormat"
            :size="size" :appendToBody="item.appendToBody" :loadOptions="item.loadOptions"
            :auto-load-root-options="item.autoLoadRootOptions" :flat="item.flat"
            @selectDepart="(val) => selectDepart(val, item[itemProp])" />
          <Y-citySelect v-if="item[itemTypeKey] == 'citySelect'" :modellist="curData[item[itemProp]]"
            @changecasca="(val) => changecasca(val, item, curData)" />
        </el-form-item>
      </el-col>
    </el-row>

    <el-row v-if="isImage && firstTab" @contextmenu.prevent.native="" @dragstart.prevent.native="">
      <el-col>
        <el-form-item label="上传图片:">
          <el-upload :action="baseUrl + '/common/upload'" list-type="picture-card" :file-list="imageList"
            :headers="headers" :data="resData" :auto-upload="true" :show-file-list="true" multiple name="file"
            :disabled="(isView || isReadOnly || isUploads) &&
              !($route.query.peddingApproval && item.isEditableUnderApproval)
              " accept=".png, .jpeg, .jpg" :on-success="handleSuccess" :on-preview="handlePictureCardPreview"
            :before-upload="handleBefore" :on-remove="handleRemove">
            <i v-if="isUpload" class="el-icon-plus" />
            <i v-else class="el-icon-remove-outline" />
            <div slot="file" slot-scope="{ file }" style="
                height: 100%;
                display: flex;
                justify-content: center;
                align-items: center;
              ">
              <img style="width: auto; height: auto; max-width: 100%; max-height: 100%"
                class="el-upload-list__item-thumbnail" :src="file.url" alt="" />
              <span class="el-upload-list__item-actions">
                <span class="el-upload-list__item-preview">
                  <i class="el-icon-zoom-in" @click="previewImgClick(file)"></i>
                </span>
                <span class="el-upload-list__item-delete">
                  <i class="el-icon-delete" @click="deleteImgClick(file)"></i>
                </span>
              </span>
            </div>
          </el-upload>

          <el-dialog style="width: 100%" :visible.sync="dialogVisible" v-dialogDrag>
            <img height="auto" width="90%" style="margin-top: 10px" :src="dialogImageUrl" alt="" />
          </el-dialog>
        </el-form-item>
      </el-col>
    </el-row>
    <el-dialog :modal="false" :title="EnlargeTitle" :visible.sync="dialogV">
      <el-input type="textarea" resize="none" class="tArea" @input="textareaChange" :value="curData[textareaVal]"
        ref="dialog"></el-input>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="EnSubmit">{{
          $t("configMoudle.confirm")
        }}</el-button>
      </span>
    </el-dialog>
  </el-form>
</template>

<script>
import { isFunction, isNull, isObject } from "@/utils/validate";
import IconSelect from "@/components/IconSelect";
// import { getToken } from "@/utils/auth";
import { getSession, checkFont } from "@/utils/methods";
import { deleteImage } from "@/api/detail";
import { compressAccurately } from "image-conversion";
export default {
  name: "Y-form",
  props: {
    form: {
      type: Array,
      default: () => [],
    },
    itemsConfigVOs: {
      type: Array,
      default: () => [],
    },
    inline: {
      type: Boolean,
      default: false,
    },
    formData: {
      type: Object | Boolean,
      default: () => { },
    },
    oldData: {
      type: Object | Boolean,
      default: () => { },
    },
    size: {
      type: String,
      default: "mini",
    },
    rowColSize: {
      type: [String, Number],
      default: 2,
    },
    itemLabel: {
      type: String,
      default: "label",
    },
    itemProp: {
      type: String,
      default: "prop",
    },
    itemTypeKey: {
      type: String,
      default: "type",
    },
    rulesKey: String,
    showOnlyInfo: {
      type: Boolean,
      default: false,
    },
    disabledKey: {
      type: String,
      default: "disabled",
    },
    disabledReadOnly: {
      type: Boolean,
      default: false,
    },
    dataId: {
      type: String,
      default: "",
    },
    isImage: {
      type: Boolean,
      default: false,
    },
    isView: {
      type: Boolean,
      default: false,
    },
    isReadOnly: {
      type: Boolean,
      default: false,
    },
    firstTab: {
      type: Boolean,
      default: false,
    },
    imageList: {
      type: Array,
      default: () => [],
    },
    appendToBody: {
      type: Boolean,
      default: false,
    },
    // tableLength: {},
  },
  data() {
    return {
      tabindex: Number(-1),
      textareaVal: "",
      isUploads: false,
      curData: {},
      EnlargeTitle: "",
      EnlargeText: "",
      forms: {
        icon: "",
      },
      watchers: [],
      headers: {
        Authorization: getSession("vue_admin_template_token"),
        AcceptLanguage: getSession("LanguageName") || "zh-cn",
      },
      resData: {
        type: "general",
        attachType: 10,
        id: "",
      },
      datetimerange: [],
      fileLists: [],
      isUpload: true,
      dialogVisible: false,
      dialogV: false,
      dialogImageUrl: "",
      defaultOption: {
        roleId: 0,
      },
      baseUrl: this.$baseUrl,
      fonts: null,
      cancelOccupy: false,
    };
  },
  mounted() {
    this.fonts = checkFont();
    // 仅在整个视图都被渲染之后才会运行的代码
    this.$nextTick(function () {
      //如果后台有设置默认值给控件赋默认值
      if (this.form) {
        this.form.forEach((item) => {
          if (item.defaultValue) {
            if (item.type == "date") {
              let date = new Date(item.defaultValue).getTime();
              this.curData[item[this.itemProp]] = date;
            } else {
              this.curData[item[this.itemProp]] = item.defaultValue;
            }
          }
        });
      }
    });
  },
  computed: {
    reactive() {
      this.form.map(async (item) => {
        item.list = isFunction(item.list) ? await item.list() : item.list;
        if (item.expression) {
          const c = item.expression.replace(/([\w]+)/g, (match) => {
            return `parseFloat(this.curData.${match})`;
          });
          setTimeout(() => {
            this.watchers.push(
              this.$watch(
                () => {
                  return eval(c);
                },
                (a) => {
                  if (isNaN(a)) {
                  } else {
                    this.curData[item[this.itemProp]] = a;
                  }
                }
              )
            );
          }, 100);
        }
      });
      return this._.chunk(this.form, this.rowColSize);
    },
    rules() {
      let rules = {};
      this.form.map((item) => {
        if (item.isMustFill) {
          if (item.type && (item.type === "treeselect" || item.type === "select")) {
            rules[item[this.itemProp]] = [
              {
                required: true,
                message: `请选择${item[this.itemLabel]}`,
                trigger: "change",
              },
            ];
          } else {
            let ruleList = [
              {
                required: true,
                message: `请输入${item[this.itemLabel]}`,
                trigger: "blur",
              },
            ];

            if (item.type === "password") {
              let validatePassword = (rule, value, callback) => {
                var r1 = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,}$/; //密码必须是6位以上、必须含有字母、数字
                if (this.oldData) {
                  const passwordTxt = this.oldData["password"];
                  if (passwordTxt !== value && !r1.test(value)) {
                    callback(new Error("六位数，含字母和数字"));
                  } else {
                    callback();
                  }
                } else {
                  if (!r1.test(value)) {
                    callback(new Error("六位数，含字母和数字"));
                  } else {
                    callback();
                  }
                }
              };
              ruleList.push({
                required: true,
                trigger: "blur",
                validator: validatePassword,
              });
            }
            rules[item[this.itemProp]] = ruleList;
          }
        }
      });
      return rules;
    },
    propFormData() {
      let v = this._.cloneDeep(this.formData);
      if (!v) return v;
      delete v.children;
      return v;
    },
    inputType() {
      return {
        text: true,
        password: true,
        textarea: true,
        url: true,
        email: true,
        date: false,
        number: true,
        tel: true,
      };
    },
  },
  created() {
    this.setResponse();
  },
  updated() { },
  watch: {
    curData: {
      deep: true,
      handler: function (newval, oldVal) {
        this.$store.commit("global/saveModuleMainFormData", newval);
        this.$refs["form"].clearValidate();
      },
    },
    firstTab: {
      handler: function (newval, oldVal) {
        this.firstTab = newval;
      },
      immediate: true,
    },
    dataId: {
      handler: function (newval, oldVal) {
        if (newval == "") {
          this.isUploads = true;
        } else {
          this.isUploads = false;
        }
        this.resData.id = newval;
      },
      immediate: true,
    },
    propFormData: {
      handler(newVal) {
        if (!newVal) return;
        this.$nextTick((_) => {
          this.form?.forEach((item) => {
            this.$set(this.curData, item[this.itemProp], newVal[item[this.itemProp]]);
            if (item.type == "multipleSelect") {
              let a = newVal[item[this.itemProp]]?.substring(
                1,
                newVal[item[this.itemProp]].length - 1
              );
              a = a?.replace(/,\s/g, ",");
              a = a?.split(",");
              this.$set(this.curData, item[this.itemProp], a);
            }

            if (item[this.itemProp] == "roleCode") {
              this.$set(this.curData, item[this.itemProp], Date.parse(new Date()));
            }
            if (newVal.id) {
              this.curData.id = newVal.id;
            }
          });
        });
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    filterProp(prop) {
      const arr = [ "fmodalid", "rid", "del_flag", "placed", "process_instance_id", "sys_template_name", "fsaved"];
      const FilterTip = (field) => field !== prop;
      return arr.every(FilterTip);
    },
    previewImgClick(val) {
      this.dialogVisible = true;
      this.dialogImageUrl = val.url;
    },
    deleteImgClick(val) {
      for (let i = 0; i < this.imageList.length; i++) {
        if (val.uid === this.imageList[i].uid) {
          this.imageList.splice(i, 1);
        }
      }
      this.$emit("deleteImgClick", this.imageList);
    },
    datechange() {
      if (
        this.datetimerange === "" ||
        this.datetimerange === null ||
        this.datetimerange === undefined
      ) {
        this.curData.beginTime = "";
        this.curData.endTime = "";
      } else {
        this.curData.beginTime = date(this.datetimerange[0]);
        this.curData.endTime = date(this.datetimerange[1]);
      }
      function date(data) {
        if (data === "" || data === null || data === undefined) {
          return "";
        } else {
          let date = data;
          let year = date.getFullYear();
          let month = date.getMonth() + 1; //js从0开始取
          let date1 = date.getDate();
          let h = date.getHours();
          let m = date.getMinutes();
          let s = date.getSeconds();
          let newmonth = "";
          let newdate1 = "";
          let newh = "";
          let newm = "";
          let news = "";
          if (month < 10) {
            newmonth = "0" + month;
          } else {
            newmonth = month;
          }
          if (date1 < 10) {
            newdate1 = "0" + date1;
          } else {
            newdate1 = date1;
          }
          if (h < 10) {
            newh = "0" + h;
          } else {
            newh = h;
          }
          if (m < 10) {
            newm = "0" + m;
          } else {
            newm = m;
          }
          if (s < 10) {
            news = "0" + s;
          } else {
            news = s;
          }
          let datetime = "";
          datetime =
            year + "-" + newmonth + "-" + newdate1 + " " + newh + ":" + newm + ":" + news;
          return datetime;
        }
      }
    },
    isNumber(val) {
      var regPos = /^\d+(\.\d+)?$/; //非负浮点数
      var regNeg = /^(-(([0-9]+\.[0-9]*[1-9][0-9]*)|([0-9]*[1-9][0-9]*\.[0-9]+)|([0-9]*[1-9][0-9]*)))$/; //负浮点数
      if (regPos.test(val) || regNeg.test(val)) {
        return true;
      } else {
        return false;
      }
    },
    handlePicker(ev, id, expressionDecimalDigit) {
      this.formData[id] = ev;
    },
    changeValue(ev, id, expressionDecimalDigit) {
      // ev是值，id是字段名
      if (expressionDecimalDigit && typeof expressionDecimalDigit == "number") {
        this.curData[id] = ev.toFixed(expressionDecimalDigit);
      } else this.curData[id] = ev;
      console.log(id, ev, 'sdasdsad')
      // this.formData[id] = ev;
      if (this.itemsConfigVOs.length) {
        this.itemsConfigVOs?.forEach((iv) => {
          iv.items?.forEach((item) => {
            if (item?.expression) {
              let c = item.expression?.replace(/([\w]|\.)+/g, (match) => {
                if (match.indexOf(".") != -1) {
                  let span = match?.split(".");
                  if (span[0] == "form") {
                    // match = this.allData.formData[span[1]]
                    match = this.formData[span[1]];
                    return `parseFloat(${match})`;
                  } else {
                    // match = this.allData.formData[span[1]]
                    // 需要上级表格而不是表单，所以是至少是从从表
                    let { data, index } = this.recursion(this.allData.children, span, 0);
                    match = data[this.indexList[index]][span[span.length - 1]];
                    return `parseFloat(${match})`;
                  }
                } else {
                  // 判断是常数还是数字类型
                  if (this.isNumber(match)) {
                    // 是数字
                    return `parseFloat(${match})`;
                  } else {
                    // 是字符串
                    if (match == id) {
                      return parseFloat(ev);
                    } else {
                      return parseFloat(this.formData[match]);
                    }
                  }
                }
              });
              setTimeout(() => {
                if (item.expressionDecimalDigit) {
                  this.$set(
                    this.formData,
                    item.itemName,
                    eval(c).toFixed(item.expressionDecimalDigit)
                  );
                  this.$set(
                    this.curData,
                    item.itemName,
                    eval(c).toFixed(item.expressionDecimalDigit)
                  );
                } else {
                  this.$set(this.formData, item.itemName, eval(c));
                  this.$set(this.curData, item.itemName, eval(c));
                }
              }, 500);
            }
          });
        });
      } else {
        this.form?.forEach((item) => {
          if (item?.expression) {
            const c = item.expression?.replace(/([\w]|\.)+/g, (match) => {
              if (match.indexOf(".") != -1) {
                let span = match?.split(".");
                if (span[0] == "form") {
                  // match = this.allData.formData[span[1]]
                  match = this.formData[span[1]];
                  return `parseFloat(${match})`;
                } else {
                  //
                  // match = this.allData.formData[span[1]]
                  // 需要上级表格而不是表单，所以是至少是从从表
                  let { data, index } = this.recursion(this.allData.children, span, 0);
                  match = data[this.indexList[index]][span[span.length - 1]];
                  return `parseFloat(${match})`;
                }
              } else {
                // 判断是常数还是数字类型
                if (this.isNumber(match)) {
                  // 是数字
                  return `parseFloat(${match})`;
                } else {
                  // 是字符串
                  if (match == id) {
                    return `parseFloat(this.formData.${match})`;
                  } else {
                    return `parseFloat(this.formData.${match})`;
                  }
                }
              }
            });
            setTimeout(() => {
              if (item.expressionDecimalDigit) {
                this.$set(
                  this.formData,
                  item.itemName,
                  eval(c).toFixed(item.expressionDecimalDigit)
                );
                this.$set(
                  this.curData,
                  item.itemName,
                  eval(c).toFixed(item.expressionDecimalDigit)
                );
              } else {
                this.$set(this.formData, item.itemName, eval(c));
                this.$set(this.curData, item.itemName, eval(c));
              }
            }, 100);
          }
        });
      }
    },
    textareaChange(ev) {
      if (this.curData[this.textareaVal] === undefined) {
        this.curData[this.textareaVal] = "";
      }
      this.curData[this.textareaVal] = ev;
    },
    spanSet(item, reactive) {
      if (item[this.itemTypeKey] === "textarea") {
        return 24;
      }
      return 24 / this.rowColSize;
    },
    setResponse() {
      this.form?.forEach((item) => {
        this.$set(this.curData, item[this.itemProp], null);
      });
    },
    selectDepart(val, itemCloum) {
      this.$emit("selectDepart", val, itemCloum);
    },
    changecasca(val, data, curData) {
      this.$emit("changecasca", val, data.itemName);
    },
    chaneRadioButton(val) {
      console.log(val, "22222222");
      this.$store.state.statusVal = val.target.value;
      this.$emit("changeRadioButton", val);
    },
    selected(obj) {
      this.curData[obj.fieldname] = obj.name;
    },
    handleSelect(value, prop, list, it) {
      this.$store.state.roleList = list;
      if (Array.isArray(value)) {
        value?.forEach((item) => {
          if (item === "all") {
            let role = [];
            for (let i of list) {
              if (i.value != "all") {
                role.push(i.value);
              }
              if (i.roleId) role.push(i);
            }
            // 排掉undefined以免出现空对象
            role = role.filter((res) => {
              return res !== undefined;
            });
            this.curData[prop] = role;
            this.$store.state.roleIds = this.curData.roleIds;
          }
          if (item === "empty") {
            this.curData[prop] = [];
          }
        });
      }
      this.$emit("handleSelect", value, prop, it);
    },
    handleFocus(item) {
      this.$emit("handleFocus", item);
    },
    handleSuccess(response, file, fileList) {
      this.$emit("handleUpload", response.message);
    },
    beforeUploadys(file) {
      // 注意这里需要用Promise函数来阻止图片没有压缩好,就直接将文件上传
      return new Promise(async (resolve, reject) => {
        const isJPG = file.type === "image/jpeg";
        const isPng = file.type === "image/png";
        // 每个限制返回错误信息   都需要用到reject()
        if (!isJPG && !isPng) {
          this.$message.warning(this.$t("configMoudle.uploadTypeTip"));
          return reject(false); //注意这里需要用reject来返回错误的信息,防止图片自动上传
        }
        let compress = 10; // 假设图片限制不能大于2M
        let sizeOver = file.size / 1024 > compress; //文件大小 是否大于指定大小
        if (sizeOver && compress) {
          //大于2M进行压缩
          const res = await compressAccurately(file, {
            size: compress, //需要压缩的大小
            accuracy: 0.9, //精度 0.8-0.99之间 默认值0.95
            type: file.type,
            // width: 105,
            // height: 105,
          });
          // this.upload.YSfileList.push(new File([res],file.name))
          file = res; //把得到的新的图片文件赋值给原文件,然后进行上传
        }
        resolve(file); // 通过resolve将Promise函数返回成功回调,进行后面操作
      });
    },
    handleBefore(file) {
      return new Promise(async (resolve, reject) => {
        if (this.dataId) {
          return resolve(true);
        } else {
          this.$message({
            type: "warning",
            message: this.$t("configMoudle.saveFirst"),
          });
          return reject(new Error(false));
        }
      });
    },
    handlePictureCardPreview(file) {
      this.$emit("handlePictureCardPreview", file.url);
    },
    handleRemove(file, fileList) {
      this.$emit("handleRemove", { file, fileList });
    },
    Enlarge(item, cur, index) {
      this.textareaVal = item.itemName || item.prop;
      if (item.type === "textarea" || item?.editType == "textarea") {
        this.EnlargeTitle = item.itemShowName;
        this.EnlargeText = cur;
        this.dialogV = true;
        if (this.formData.placed == "2") {
          this.dialogV = false;
        }
      }
    },
    EnSubmit() {
      this.dialogV = false;
    },
  },
  components: { IconSelect },
  destroyed() {
    this.watchers?.forEach((item) => item?.unwatchFn());
  },
};
</script>

<style scoped lang="scss">
.hhh {
  border-bottom: 1px solid #606266;
  min-height: 28px;
}

/deep/.el-row {
  margin-bottom: 0;
}

/deep/.el-form-item__label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  /deep/.el-input:hover {
    color: red;
  }
}

/deep/ .el-input.is-disabled .el-input__inner {
  cursor: default !important;
}

.coin {
  cursor: pointer;
}

/deep/.tArea {
  font-size: 18px;

  textarea {
    height: 200px;
    overflow: scroll;
    white-space: nowrap;
  }
}

/deep/.el-col {
  margin-right: 20px;
  margin-bottom: 15px;
}

/deep/.el-form-item {
  display: flex;
  align-items: center;

  .el-form-item__content {
    margin-left: 0 !important;
  }
}

.rowBox {
  display: flex;
  flex-wrap: wrap;
}

/* 去掉number类型自带的加减按钮 */
::v-deep input::-webkit-outer-spin-button,
::v-deep input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
}

::v-deep input[type="number"] {
  -moz-appearance: textfield !important;
}

/deep/.is-required span {
  color: red !important;
}

/deep/.is-required i {
  color: rgb(177, 177, 177) !important;
}

/deep/.el-dialog__body {
  overflow: auto;
  max-height: 700px;
}
</style>
