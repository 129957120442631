var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "svg",
    {
      staticClass: "vue-flow-editor-icon",
      attrs: { "aria-hidden": "true" },
      on: {
        click: function(e) {
          return _vm.$emit("click", e)
        }
      }
    },
    [_c("use", { attrs: { "xlink:href": "#" + _vm.icon } })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }