<template>
  <div class="shop_wrapper">
    <div v-for="(item, index) in tableData" :key="index" class="shop_item_bg">
      <div class="shop_item" v-if="item.img_ids">
        <a href="#" class="shop_item_link">
          <div style="width: 100%; height: 100%; display: flex; flex-direction: column">
            <div
              style="flex: 0.9; display: flex; justify-content: center; align-items: center"
            >
              <img
                style="width: 50%; height: auto"
                class="rax-image"
                :src="item.img_ids[0].url"
                @dblclick="imgDbClick(item.id)"
              />
            </div>
            <div class="shop_main-wrapper">
              <span class="shop__main-title">{{ item.spname || item.sdesc || '暂无名称'}}</span>
            </div>
          </div>
        </a>
      </div>
      <div class="shop_item noimage" v-else @dblclick="imgDbClick(item.id)">
        <div style="width:100%;height:70%;text-align: center;justify-content: center;padding:20px 0 0 0">
          <i class="iconfont icon-tupianguanli" style="font-size:80px;"></i>
        </div>
        <div class="shop_main-wrapper">
          <span class="shop__main-title">{{ item.spname || item.sdesc || '暂无名称'}}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { deepFindOne } from "@/utils/methods";
export default {
  name: "Y-productView",
  data() {
    return {
      formConfigId: "",
    };
  },
  props: {
    tableData: [Array],
  },
  computed: {
    routes() {
      return this.$store.state.global.routes;
    },
    path() {
      return this.$route.path;
    },
  },
  mounted() {},
  created() {
    const { formConfigId } = deepFindOne(this.routes, "formConfigId", this.path);
    this.formConfigId = formConfigId;
  },
  methods: {
    async imgDbClick(itemId) {
      this.$router.push({
        path: "spk_edit",
        query: { configId: this.formConfigId, id: itemId, refreshDetailData: true },
      });
    },
  },
};
</script>
<style scoped lang="scss">
.shop_wrapper {
  border: 0px solid black;
  box-sizing: border-box;
  display: flex;
  flex-flow: row wrap;
  align-content: flex-start;
  flex-shrink: 0;
  width: 1000px;
  overflow-y: auto;
  margin: 0vw auto;
}
.shop_item_bg {
  
  background-color: #faf6f6;
  margin: 0 10px 10px 0;
}
.shop_item {
  border: 0px solid black;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  flex-shrink: 0;
  width: 160px;
  float: left;
  margin: 0;
  overflow: hidden;
  position: relative;
  border-radius: 8px;
  height: 160px;


}
.shop_item_link {
  display: block;
  position: relative;
  text-decoration: none;
  height: 334px;
}
.rax-image {
  
  display: block;
}
.shop_main-wrapper {
 
  border: 0px solid black;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  flex-shrink: 0;
  overflow: hidden;
  width: 100%;
  text-align: center;
  margin: 0vw auto;
}
.shop__main-title {
  padding: 0 5px; 
  border-width: 0px;
  border-style: solid;
  border-color: black;
  position: relative;
  box-sizing: border-box;
  display: block;
  flex-direction: column;
  align-content: flex-start;
  flex-shrink: 0;
  font-size: 14px;
  font-weight: 600;
  white-space: nowrap;
  color: rgb(51, 51, 51);
  text-align: center;
  overflow: hidden;
  text-overflow: ellipsis;
}
.noimage {
  justify-content: center;
  align-items: center;
  
  background-image: linear-gradient(to top, #e14fad 0%, #f9d423 100%);
  background-clip: text;
  color: transparent;
  i {
    margin-bottom: 15px;
  }
}
</style>
