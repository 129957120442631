import request from "@/axios/index";
// 查询角色
export function findRoleList(params) {
  return request({
    url: "/sys/role",
    method: "get",
    params
  })
}
// 新增角色
export function addRoleList(data) {
  return request({
    url: "/sys/role",
    method: "post",
    data
  })
}
//批量删除
export function deleteAllRoleList(data) {
  return request({
    url: "/sys/role/deleteAll",
    method: "delete",
    data
  })
}
//获取角色信息
export function getRoleInfo(id) {
  return request({
    url: `/sys/role/${id}`,
    method: "get"
  })
}
// 修改角色信息
export function editRoleInfo(id, data) {
  return request({
    url: `/sys/role/${id}`,
    method: "put",
    data
  })
}
// 删除角色信息表
export function deleteRoleInfo(id) {
  return request({
    url: `/sys/role/${id}`,
    method: "delete"
  })
}

//查看菜单项
export function findRoleMenu(roleId) {
  return request({
    url: `/sys/menu/role_menu_tree/${roleId}`,
    method: "get"
  })
}

// 扩展权限保存
export function saveExtand(data) {
  return request({
    url: '/data-permission/extend/save',
    method: 'post',
    data
  })
}

// 扩展权限列表
export function getExtandList(roleId, menuId) {
  return request({
    url: `/data-permission/extend/${roleId}/${menuId}`,
    method: "get"
  })
}

export function copyRole(roleId, name) {
  return request({
    url: `/sys/role/copy/${roleId}/${name}`,
    method: "post"
  })
}
