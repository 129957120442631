import en from '../lang/en'
import zh from '../lang/zh'
import th from '../lang/th'
import id from '../lang/id'
import ru from '../lang/ru'
import vie from '../lang/vie'
import { getSession } from "@/utils/methods"
let language = null

if(getSession('LanguageName') == 'en') {
	language = en
}else if(getSession('LanguageName') == 'th') {
	language = th
}else if(getSession('LanguageName') == 'id') {
	language = id
}else if(getSession('LanguageName') == 'ru') {
	language = ru
}else if(getSession('LanguageName') == 'vie') {
	language = vie
}else if(getSession('LanguageName') == 'zh-cn') {
	language = zh
}else {
	let lang = navigator.language; //浏览器语言判断
  lang = lang.substr(0, 2);
	switch (lang) {
		case 'zh': //中文
		language = zh;
				break;
		case 'en': //英文
		language = en;
				break;
		case 'th':
		language = th;
				break;
		case 'id':
		language = id;
				break;
				case 'ru':
		language = id;
				break;
				case 'vie':
				language = id;
						break;
		default:
			language = en;
			break;
	}
}
const baseRoutes = [
	{
		path: '/',
		redirect: '/home',
		component: 'Loyout',
		hidden: true,
		children: [
			{
				path: 'home',
				component: 'Home',
				name: 'home',
				meta: { title: language.Menu.home },
			},
		],
	},
	{
		path: '/login',
		component: 'Login',
		hidden: true,
	},
	{
		path: '/system',
		component: 'Loyout',
		meta: { title: language.Menu.SystemAdmin, icon: 'icon-chuangjianxitong' },
		children: [
			{
				//列表设置
				path: 'listSet',
				component: 'ListSet',
				name: 'listSet',
				meta: {title: language.Menu.ListSet, icon: 'icon-fuwuliebiao', colorIcon: 'icon-gouzhijihua', isSystem: true},
			}, {
				path: 'homeEditor',
				component: 'HomeEditor',
				name: 'HomeEditor',
				meta: {title: language.Menu.HomeEditor, icon: 'icon-shezhi', colorIcon: 'icon-shebeijiaojie', isSystem: true},
			}, {
				//调取设置
				path: 'fetchSet',
				component: 'FetchSet',
				name: 'fetchSet',
				meta: { title: language.Menu.FetchSet, icon: 'icon-luquguanli', colorIcon: 'icon-tiaoshijilu', isSystem: true },
			}, {
				//首页设置
				path: 'homeSet',
				component: 'HomeSet',
				name: 'homeSet',
				meta: { title: language.Menu.HomeSet, icon: 'icon-shezhi', colorIcon: 'icon-shebeijiaojie', isSystem: true },
			}, {
				//报表权限设置
				path: 'jimuReportSet',
				component: 'JimuReportSet',
				name: 'jimuReportSet',
				hidden: true,
				meta: {title: language.Menu.ReportPermissSet, icon: 'icon-shezhi', colorIcon: 'icon-shebeijiaojie'},
			},
			// {
			// 	path: 'dict',
			// 	component: 'Dict',
			// 	name: 'dict',
			// 	meta: { title: language.Menu.dataDict, icon: 'icon-dashuju', colorIcon: 'icon-xitongshu' },
			// },
			// {
			// 	//分类字典
			// 	path: 'category',
			// 	component: 'Category',
			// 	name: 'category',
			// 	meta: { title: language.Menu.category, icon: 'icon-dashuju', colorIcon: 'icon-xitongshu' },
			// },
			{
				//场地设置
				path: 'keySet',
				component: 'KeySet',
				name: 'keySet',
				hidden: true,
				meta: {title: language.Menu.fieldSet, icon: 'icon-zidingyishezhi', colorIcon: 'icon-xitongshu'},
			}, {
				path: 'dictItemSet',
				component: 'DictItemSet',
				name: 'dictItemSet',
				hidden: true,
				meta: { title: language.Menu.dataDictSet, icon: 'el-icon-location' },
			}, {
				path: 'dictItemSet/:id',
				component: 'DictItem',
				name: 'dictItem',
				hidden: true,
				meta: { title: language.Menu.dataDictSet, icon: 'el-icon-location' },
			}, {
				//调取场地设置
				path: 'fetchKeySet',
				component: 'FetchKeySet',
				name: 'fetchKeySet',
				hidden: true,
				meta: { title: language.Menu.fetchFieldSet, icon: 'el-icon-location' },
			}, {
				//工作表页设置
				path: 'sheetSet',
				component: 'SheetSet',
				name: 'sheetSet',
				hidden: true,
				meta: { title: language.Menu.sheetSet, icon: 'el-icon-location',colorIcon: 'icon-gouzhijihua' },
			}, {
				//表单设置
				path: 'formSet',
				component: 'FormSet',
				name: 'formSet',
				hidden: true,
				meta: { title:language.Menu.formSet , icon: 'el-icon-location',colorIcon: 'icon-gouzhijihua' },
			}, {
				//Logo设置
				path: 'logoSet',
				component: 'LogoSet',
				name: 'logoSet',
				meta: { title:language.Menu.logoSet , icon: 'icon-jichushezhi',colorIcon: 'icon-gouzhijihua', isSystem: true },
			}, {
				// loginBack设置
				path: 'loginSet',
				component: 'LoginSetBack',
				name: 'loginSet',
				meta: { title:language.Menu.loginSet , icon: 'icon-biaozhunmobanshezhi',colorIcon: 'icon-gouzhijihua', isSystem: true },
			}, {
				//编码设置
				path: 'codingSet',
				component: 'codingSet',
				name: 'codingSet',
				hidden: true,
				meta: { title: language.Menu.codeSet, icon: 'el-icon-location' },
			}, {
				//菜单管理
				path: 'menuAdmin',
				component: 'menuAdmin',
				name: 'menuAdmin',
				meta: {title: language.Menu.menuAdmin, icon: 'icon-caidanguanli', colorIcon: 'icon-weihuguanli', isSystem: true },
			}, {
				//用户管理
				path: 'userAdmin',
				component: 'userAdmin',
				name: 'userAdmin',
				meta: {title: language.Menu.userAdmin, icon: 'icon-yonghushezhi', colorIcon: 'icon-anzhuangguanli', isSystem: true },
			}, {
				path: 'editUserAdmin',
				component: 'editUserAdmin',
				name: 'editUserAdmin',
				hidden: true,
				meta: { title: language.Menu.editUser, icon: 'user' },
			}, {
				path: 'LanguageSet/:id/:type',
				component: 'LanguageSet',
				name: 'LanguageSet',
				hidden: true,
				meta: { title: language.Menu.languageSet, icon: 'user' },
			}, {
				path: 'versionView/:fid/:listid',
				component: 'versionView',
				name: 'versionView',
				hidden: true,
				meta: { title: language.Menu.versionView, icon: 'user' },
			}, {
				//在线用户
				path: 'userOnline',
				component: 'userOnline',
				name: 'userOnline',
				meta: {title: language.Menu.userOnline, icon: 'icon-yonghuliebiao', colorIcon: 'icon-anzhuangguanli',isSystem: true },
			}, {
				// 消息中心
				path: 'messageCenter',
				component: 'messageCenter',
				name: 'messageCenter',
				hidden: true,
				meta: {title: language.Menu.messageCenter, icon: 'icon-yonghuliebiao', colorIcon: 'icon-anzhuangguanli' }
			}, {
				//节点设定
				path: 'nodeSettings',
				component: 'nodeSettings',
				name: 'nodeSettings',
				hidden: true,
				meta: {title: language.Menu.nodeSettings, icon: 'icon-yonghuliebiao', colorIcon: 'icon-anzhuangguanli' },
			},
			{
				//业务流跟踪
				path: 'businessFlowTracking',
				component: 'businessFlowTracking',
				name: 'businessFlowTracking',
				hidden: true,
				meta: {title: language.Menu.businessFlowTracking, icon: 'icon-yonghuliebiao', colorIcon: 'icon-anzhuangguanli' },
			},
			{
				//角色管理
				path: 'roleAdmin',
				component: 'roleAdmin',
				name: 'roleAdmin',
				meta: {title: language.Menu.roleAdmin, icon: 'icon-renshishezhi', colorIcon: 'icon-ancheguanli-copy', isSystem: true },
			},
			{
				//部门管理
				path: 'deptAdmin',
				component: 'deptAdmin',
				name: 'deptAdmin',
				meta: {title: language.Menu.deptAdmin, icon: 'icon-waibukejian', colorIcon: 'icon-ancheguanli-copy', isSystem: true },
			},
			{
				//打印管理
				path: 'printAdmin',
				component: 'printAdmin',
				name: 'printAdmin',
				meta: {title: language.Menu.printAdmin, icon: 'icon-wangluobanji', colorIcon: 'icon-yunhangguanli', isSystem: true },
			},
			// {
			// 	path: 'ReportModule',
			// 	component: 'ReportModule',
			// 	name: 'ReportModule',
			// 	meta: {
			// 		title: language.Menu.termsTemplete,
			// 		icon: 'icon-rizhiguanli',
			// 		colorIcon: 'icon-yunhangguanli',
			// 	},
			// },
			{
				path: 'personalCenter',
				hidden: true,
				name: 'personalCenter',
				component: 'personalCenter',
				meta: { title: language.Menu.persionCenter },
			},
			// {
			// 	path: '/statisticalManagement',
			// 	component: 'Loyout',
			// 	name: 'statisticalManagement',
			// 	children: [
					// 统计管理
					{
						path: 'statisticalManagement',
						component: 'statisticalManagement',
						name: 'statisticalManagement',
						hidden: false,
						meta: {title: language.StatisticalManagement.StatisticalManagement, icon: 'icon-icon-email', colorIcon: 'icon-anzhuangguanli', isSystem: true }
					},
			// 	]
			// },
			{
				//业务建模
				path: 'businessModel',
				component: 'businessModel',
				name: 'businessModel',
				meta: { title: language.Menu.businessModel, icon: 'icon-shezhi', isSystem: true },
			},
			{
				path: 'businessProcess',
				component: 'businessProcess',
				name: 'businessProcess',
				meta: { title: '业务流程' },
				hidden: true,
			},
			{
				// 代码事件配置
				path: 'codeEventSet',
				component: 'codeEventSet',
				name: 'codeEventSet',
				hidden: true,
				meta: {  title: language.Menu.codeEventSet, icon: 'icon-shezhi'}
			},
			{
				//系统设置模块
				path: 'settings',
				component: 'settings',
				name: 'settings',
				meta: {title: language.Menu.systemSet, icon: 'icon-rizhiguanli', colorIcon: 'icon-yunhangguanli', isSystem: true },
			},
		

		],
	},
	{
		path: '/monitor',
		component: 'Loyout',
		hidden: true,
		meta: { title: language.Menu.monitor, icon: 'icon-xiaochengxu' },
		children: [
			// {
			// 	//系统日志
			// 	path: 'systemLog',
			// 	component: 'systemLog',
			// 	name: 'systemLog',
			// 	meta: {title: language.Menu.systemLog, icon: 'icon-rizhiguanli', colorIcon: 'icon-yunhangguanli', isSystem: true },
			// },
			// {
			// 	//预警设置
			// 	path: 'earlyWarningSetting',
			// 	component: 'earlyWarningSetting',
			// 	name: 'earlyWarningSetting',
			// 	meta: {title: language.Menu.warningSet, icon: 'icon-yonghuliebiao', colorIcon: 'icon-anzhuangguanli', isSystem: true },
			// },
			{
				//预警权限设置
				path: 'earlyWarningPermissionSetting',
				component: 'earlyWarningPermissionSetting',
				name: 'earlyWarningPermissionSetting',
				hidden: true,
				meta: {title: language.Menu.warnPermissSet, icon: 'icon-yonghuliebiao', colorIcon: 'icon-anzhuangguanli' },
			},
			{
				//预警
				path: 'earlyWarning',
				component: 'earlyWarning',
				name: 'earlyWarning',
				hidden: true,
				meta: {title: language.Menu.warning, icon: 'icon-yonghuliebiao', colorIcon: 'icon-anzhuangguanli' },
			},
			{
				path: 'earlyWarningFieldSetting/:id',
				component: 'earlyWarningFieldSetting',
				name: 'earlyWarningFieldSetting',
				hidden: true,
				meta: {title: language.Menu.warningFieldSet, icon: 'icon-yonghuliebiao', colorIcon: 'icon-anzhuangguanli' },
			},
		]
	},
	{
		path: '/flow',
		component: 'Loyout',
		meta: { title: language.Menu.flowMenu, icon: 'icon-xinxiliu' },
		children: [
			{
				path: 'model',
				component: 'modelSet',
				name: 'modelSet',
				meta: {
					title: language.Menu.flowModel,
					icon: 'icon-mobanguanli',
					colorIcon: 'icon-biaoqianguanli',
				},
			},
			{
				path: 'createModel',
				component: 'createModel',
				name: 'createModel',
				hidden: true,
				meta: { title: language.Menu.modelOnlineDesign, icon: '' },
			},
			{
				path: 'definition',
				component: 'definitionSet',
				name: 'definitionSet',
				meta: {
					title: language.Menu.flowDefinition,
					icon: 'icon-mokuaishezhi',
					colorIcon: 'icon-biaoqianguanli',
				},
			},
			{
				path: 'programme',
				component: 'programmeSet',
				name: 'programmeSet',
				meta: {
					title: language.Menu.programmeSet,
					icon: 'icon-mokuaishezhi',
					colorIcon: 'icon-biaoqianguanli',
				},
			},
			{
				path: 'createProgramme',
				component: 'createProgramme',
				name: 'createProgramme',
				hidden: true,
				meta: { title: language.Menu.programmeSetAdd, icon: 'config' },
			},
			{
				path: 'editProgramme/:id',
				component: 'editProgramme',
				name: 'editProgramme',
				hidden: true,
				meta: { title: language.Menu.programmeSetEdit, icon: 'config' },
			},
			{
				path: 'flowConfiger',
				component: 'flowConfiger',
				name: 'flowConfiger',
				meta: {
					title: language.Menu.flowConfig,
					icon: 'icon-biaozhunmobanshezhi',
					colorIcon: 'icon-xitongshu',
				},
			},
			{
				path: 'flowConfigerDtl',
				component: 'flowConfigerDtl',
				name: 'flowConfigerDtl',
				hidden: true,
				meta: { title: language.Menu.addFlowConfig, icon: 'config' },
			},
			{
				path: 'flowConfigerEdit/:id',
				component: 'flowConfigerEdit',
				name: 'flowConfigerEdit',
				hidden: true,
				meta: { title: language.Menu.editFlowConfig, icon: 'config' },
			},
		],
	},
	{
		path: '/approval',
		component: 'Loyout',
		name: 'approval',
		meta: { title: language.Menu.approvalAdmin, icon: 'icon-shenpishezhi1' },
		children: [
			{
				path: 'peddingApproval',
				component: 'peddingApproval',
				name: 'peddingApproval',
				meta: {
					title: language.Menu.peddingApproval,
					icon: 'icon-shenpishezhi',
					colorIcon: 'icon-shebeijiaojie',
				},
			},
			{
				path: 'successApproval',
				component: 'successApproval',
				name: 'successApproval',
				meta: {
					title: language.Menu.approval,
					icon: 'icon-wodeshenpi',
					colorIcon: 'icon-jungongyanshou',
				},
			},
			{
				path: 'successInitiated',
				component: 'successInitiated',
				name: 'successInitiated',
				meta: {
					title: language.Menu.Initiated,
					icon: 'icon-fuwuliebiao',
					colorIcon: 'icon-jungongyanshou',
				},
			},
			{
				path: 'successApprovalCopy',
				component: 'successApprovalCopy',
				name: 'successApprovalCopy',
				meta: {
					title: language.Menu.CCRecord,
					icon: 'icon-wodeshenpi',
					colorIcon: 'icon-jungongyanshou',
				},
			},
		],
	},
	{
		path: '/Report',
		component: 'Loyout',
		hidden: true,
		children: [
			{
				path: 'statistical',
				component: 'statistical',
				name: 'statistical',
				meta: { title: language.Menu.statisticsReport },
			},
		],
	},
];
export default baseRoutes;
