var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "header-search", class: { show: _vm.show } },
    [
      _c("i", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: !_vm.show,
            expression: "!show"
          }
        ],
        staticClass: "el-icon-search search-icon",
        style: { color: _vm.navFontColor },
        on: {
          click: function($event) {
            $event.stopPropagation()
            return _vm.handleFocusSearch($event)
          }
        }
      }),
      _c(
        "el-select",
        {
          ref: "headerSearchSelectRef",
          staticClass: "header-search-select",
          attrs: {
            size: _vm.size || "medium",
            "remote-method": _vm.querySearch,
            filterable: "",
            "default-first-option": "",
            remote: "",
            placeholder: _vm.$t("user.searchMenu"),
            loading: _vm.loading
          },
          on: { change: _vm.change },
          model: {
            value: _vm.search,
            callback: function($$v) {
              _vm.search = $$v
            },
            expression: "search"
          }
        },
        _vm._l(_vm.options, function(option) {
          return _c("el-option", {
            key: option.path,
            attrs: { value: option.path, label: option.title.join(" > ") }
          })
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }