import {defineComponent, getCurrentInstance} from "@vue/composition-api";
import {getDesignInstance} from "@/g6-canvas/composition/getDesignInstance";

export const designComponent: typeof defineComponent = (sourceOption) => {
    const targetOption = {
        ...sourceOption,
        setup(props, context) {
            const ctx = getDesignInstance()
            if (!ctx) {return {}}
            const referOrRender = sourceOption.setup.apply(ctx, [props, context])
            const {refer, referRender} = typeof referOrRender === "function" ? {refer: {}, referRender: referOrRender} : {refer: referOrRender, referRender: () => null}
            refer.referRender = referRender
            return refer
        },
        render() {
            console.log( this.referRender())
           
            return this.referRender()
        },
    }
    return defineComponent(targetOption)
}
