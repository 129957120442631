import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

//导入语言包
import Zh from '../lang/zh.js'; // 中文语言包
import En from '../lang/en.js'; // 英文语言包
import Th from '../lang/th.js'; // 泰国语言包
import Id from '../lang/id.js'; 
import Ru from '../lang/ru.js'; 
import Vie from '../lang/vie.js'; 
// vxeTable 的语言包
import zhCN from 'vxe-table/lib/locale/lang/zh-CN'
import enUS from 'vxe-table/lib/locale/lang/en-US'


const messages = {
         zh: {...Zh,...zhCN}, // 中文语言包
         en: {...En,...enUS}, // 英文语言包
         th: {...Th}, //泰国语
         id: {...Id} ,
         ru: {...Ru} ,
         vie: {...Vie} ,
}

export default new VueI18n({
    locale: 'zh', // set locale 默认显示中文
    fallbackLocale: 'en', //如果语言包没有，则默认从英语中抽取
    silentTranslationWarn: true,
    messages: messages // set locale messages
});