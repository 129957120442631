import Theme from "theme-vue"
import store from '@/store';
import {
    handleThemeStyle, setStyleCommon,
    getActiveStyles, getLightenBackgroundColor, getDarkenBackgroundColor
} from '@/utils/theme'


const Th = new Theme(); // 创建修改主题对象

Th.changeTheme(store.state.global.theme) // 修改element颜色（hex/rgb/rgba）
window.Theme = store.state.global.theme
window.handleThemeStyle = handleThemeStyle
const {
    activeBackgroundColor,
    activeTextColor
} = getActiveStyles(store.state.global.navTheme, store.state.global.navFontColor);
const lightenedBg = getLightenBackgroundColor(store.state.global.navTheme, 0.8);
const darkenedBg = getDarkenBackgroundColor(store.state.global.navTheme, 1);
const darkenedOneBg = getDarkenBackgroundColor(store.state.global.navTheme, 0.5);
// 处理导航栏的颜色
handleThemeStyle('barColor', store.state.global.navTheme, 6)
setStyleCommon('--barColor-font', store.state.global.navFontColor)
setStyleCommon('--barColor-font-active', activeTextColor)
setStyleCommon('--barColor-dark-1', darkenedOneBg)
setStyleCommon('--barColor-dark-2', activeBackgroundColor)
setStyleCommon('--barColor-light-2', lightenedBg)
setStyleCommon('--barColor-dark-3', darkenedBg)


