import {G6} from "@/g6-canvas/g6/g6.ts";
import {clickSelect} from "@/g6-canvas/behavior/click-select.ts";
import {dragNode} from "@/g6-canvas/behavior/drag-node";
import {hoverItemActive} from "@/g6-canvas/behavior/hover-item-active";
import {hoverAnchorActive} from "@/g6-canvas/behavior/hover-anchor-active";
import {dragEdge} from "@/g6-canvas/behavior/drag-edge";
import {dragCanvas} from "@/g6-canvas/behavior/drag-canvas";
import {brushSelecct} from "@/g6-canvas/behavior/brush-select";

interface OptionType {
    multipleSelect: boolean,
    dragEdge: {
        disabled: boolean,
        beforeAdd: (model: object, type: string) => Promise<any> | undefined,
        afterAdd: (model: object, type: string) => Promise<any> | undefined,
    },
}

export function useBehavior(option: OptionType) {

    clickSelect(G6)
    dragNode(G6)
    hoverItemActive(G6)
    hoverAnchorActive(G6)
    dragEdge(G6, {beforeAdd: option.dragEdge.beforeAdd, afterAdd: option.dragEdge.afterAdd})
    dragCanvas(G6)
    brushSelecct(G6)

    const registryBehaviors: Array<string | object> = [
        'drag-canvas',
        // 'zoom-canvas',
        {
            type: 'drag-node',
        },
        {
            type: 'click-select',
            multiple: option.multipleSelect,
        },

        'hover-item-active',
    ]

    if (!option.dragEdge.disabled) {
        registryBehaviors.push(...[
            'hover-anchor-active',
            'drag-edge',
        ])
    }

    if (option.multipleSelect) {
        registryBehaviors.push({
            type: 'brush-select',
            selectedState: 'selected',
            includeEdges: true,
            onSelect() {
                // @ts-ignore
                const graph = this.graph;
                const nodes = graph.findAllByState('node', 'selected')
                const edges = graph.findAllByState('edge', 'selected')
                graph.emit('select-change', {nodes, edges})
            }
        },)
    }

    return registryBehaviors
}