import { initLanguage } from "@/api/sysTem";

export default {
  // 代码事件
  codeEvent: {
    eventSet:"事件配置",
    placeSetName:'请输入事件名称',
    eventName:"事件名称",
    eventId:"事件ID",
    placeSet:"请输入",
    fieldChange:"字段变动",
    field:"字段",
    status:'状态',
    eventContent:'事件内容',
    sqlConfig:"sql配置",
    name:'名称',
    remark:'备注'
  },

  // 报表配置
  formSet: {
    guageSet:"切换语言",
    languageSet: '语言设置',
    hyperlink: '超链接',
    cascadeSelection: '联级选择框',
    longRangeSelection: '远程选择框',
    stretch: '拉伸',
    tile: '平铺',
    half: '半拉伸',
    third: '占用三分之一',
    leftJustify: '左对齐',
    rightJustify: '右对齐',
    center: "居中对齐",
    equal: '等于',
    less: '小于',
    lessOrequal: '小于等于',
    greater: '大于',
    greatorequal: "大于等于",
    like: '全like',
    left: '左left',
    right: '右left',
    between: 'BETWEEN',
    database: '数据库',
    expression: '自定义表达式',
    year: '年',
    years: '年份选择框',
    month: '月份选择框',
    day: '日',
    hour: '时',
    minute: '分',
    second: '秒',
    input: '输入框',
    DateRange: '日期范围选择框',
    dateInput: '日期选择框',
    selectOption: '选择框',
    switch: '开关',
    textarea: '文本域',
    numberInput: '数字框',
    richText: '富文本',
    checkBox: '选择复选框',
    people: '人员',
    citySelect: '城市选择框',
    selectInput: '选择输入框',
    permiAll: '所有数据权限',
    permiCustom: '自定义数据权限',
    department: '本部门数据权限',
    deptBelow: "本部门及以下数据权限",
    selfOnly:'仅本人数据权限'
  },

  // 版本管理
  version: {
    record: '变更记录',
    times: "变更次数",
    updateDate: "变更日期",
    updatePerson: "变更人",
    mainTableData: '主表变更数据',
    detailsOldData: '明细原数据',
    detailsData: '明细变更数据',
    oldData:"原数据",
    newData:"变更后数据"
  },

  // 新增的确认对话框 一个个改实在是太困难了
  $confirm: {
    message:{
    修改为已读吗:'修改为已读吗',
    确定撤回该数据吗:'确定撤回该数据吗?',
    '是否要变更该合同？':"是否要变更该合同？",
    '是否要取消变更该合同？':'是否要取消变更该合同？'
    },
    title:{
      "提示":"提示",
    },
    cancelButtonText:{},
    confirmButtonText:{},
  },

  // 新增的提示弹框 绑定到vue 中文作为建明 修改太困难了
  $message:{
    '请选择数据':"请选择数据",
    '请选择人员进行数据转交':'请选择人员进行数据转交！',
    '部署成功':'部署成功 ',
    '请先添加计划明细':"请先添加计划明细",
    '该文件暂不支持预览':'该文件暂不支持预览',
    '已取消关闭':'已取消关闭',
    '成功清除缓存':'成功清除缓存',
    '模型名称重复，请使用新名称创建模型。':'模型名称重复，请使用新名称创建模型',
    '用户名或密码不能为空！':'用户名或密码不能为空！',
    "提交成功":"提交成功",
    "提交失败":"提交失败",
    "已变更":'已变更',
    "已取消变更":'已取消变更',
    "待采明细有采购不能重新生成":'待采明细有采购不能重新生成',
    "生成待采明细成功":"生成待采明细成功",
    "生成待采明细失败":"生成待采明细失败",
    "此调取未包含目标模块的字段，请切换到正确的页签":'此调取未包含目标模块的字段，请切换到正确的页签',
    "明细中供应商存在多个，请检查！":"明细中供应商存在多个，请检查！",
    "认领金额不能大于收款金额，请重新填写！":'认领金额不能大于收款金额，请重新填写！',
    "请选择要赋值的人员":"请选择要赋值的人员",
    "请选择模板人员!":"请选择模板人员",
    "赋值成功":"赋值成功",
    "赋值失败":'赋值失败'
  },

  // 个性化
  individuation:{
    fieldName:"列名称",
    visible:"是否显示",
    total:'是否合计',
    locktopic:"是否锁定",
    width:"当前宽度",
    enable:"启用",
    disabled:'禁用'
  },

  // 登录
  loginPage: {
    login: "登  录",
    welCome: '欢迎登录',
    password: "密码",
    account: '账号'
  },

  // 消息中心
  messageCenter: {
    addNewMessage: '新增消息',
    editNewMessage: '编辑消息',
    messageTitle: '消息标题',
    modelName: '模块名称',
    messageContent: '正文内容',
    remark: '备注',
    receiveBy: '接收人',
    sendBy: '发起人',
    createTime: '创建时间',
    isRead: '是否已读',
  },

  placeHolder: {
    expression:'计算公式表达式',
    selectLanguage: '请选择语言',
    forTrackPlaceHolder: '请输入所属单号',
    start: "起",
    end: "终",
    to: '至',
    startDate: '开始日期',
    endDate: '结束日期',
    selectDate: '日期选择',
    startTime: "开始时间",
    endTime: '结束时间',
    pleaseFill: "请输入",
    pleaseSelect: "请选择",
    Financialrecognition: '请选择财务确认',
    onWeek: "最近一周",
    onMonth: '最近一个月',
    threeMonth: '最近三个月',
    contractNumber: '外销合同号',
  },

  // 导航栏上方的操作栏
  user: {
    quit: '退出登录',
    noticeCenter: "消息中心",
    preWarning: '预警',
    statistical: "统计报表",
    noticeAlert: "消息提醒",
    searchMenu: '消息菜单',
    businessProcess: '业务流程',
    accountSetting: '账户设置',
    about: '关于'
  },

  // 首页设置
  Home: {
    ToBeapproved: '待审批单据',
    realTimeData: '实时数据', // 实时数据
    recents: '最近访问', // 最近访问
    commUsedFun: '常用功能模块',
    messageCenter: '消息中心',
    more: '查看更多',
    schedule: '日程安排',
    scheduleTip: '暂无安排',
    menuCollapse: '折叠菜单'
  },


  /* 左侧菜单 */
  Menu: {
    HomeEditor:'首页流程编辑',
    messageCenter: '消息中心',
    nodeSettings: '节点设定',
    businessFlowTracking: '业务流跟踪',
    SystemAdmin: '系统管理',
    ListSet: '列表设置',
    FetchSet: '调取设置',
    HomeSet: '首页设置',
    home: '首页',
    monitor: '系统监控',
    ReportPermissSet: '报表权限设置',
    dataDict: '数据字典',
    category: '分类字典',
    menuAdmin: '菜单管理',
    userAdmin: '用户管理',
    userOnline: '在线用户',
    roleAdmin: '角色管理',
    deptAdmin: '部门管理',
    printAdmin: '打印管理',
    systemLog: '操作日志',
    termsTemplete: '条款模板',
    warningSet: '预警设置',
    fieldSet: '字段设置',
    dataDictSet: '数据字典项设置',
    fetchFieldSet: '调取字段设置',
    sheetSet: 'sheet页设置',
    formSet: '表单设置',
    codeSet: '编码设置',
    editUser: '编辑用户',
    languageSet: '语言设置',
    versionView: '版本查看',
    persionCenter: '个人中心',
    warnPermissSet: '预警权限设置',
    warning: '预警',
    warningFieldSet: '预警字段设置',
    businessModel: '业务建模',
    systemSet: '系统设置',
    logoSet: 'Logo设置',
    loginSet: '登录背景设置',
    // 流程菜单
    flowMenu: '流程菜单',
    flowDefinition: '流程定义',
    flowConfig: '流程配置',
    flowModel: '流程模型',
    modelOnlineDesign: '流程设计',
    addFlowConfig: '新增流程配置',
    editFlowConfig: '编辑流程配置',
    programmeSet: '方案设置',
    programmeSetAdd: '新增方案',
    programmeSetEdit: '编辑方案',
    // 审批管理
    approvalAdmin: '审批管理',
    peddingApproval: '待审批',
    approval: '已审批',
    Initiated: '已发起',
    CCRecord: '抄送记录',
    // 订单进度管理
    orderProgressAdmin: '订单进度管理',
    orderProgressList: '订单进度列表',
    orderNodes: '订单节点库',
    orderProgressDetail: '订单进度详情',
    statisticsReport: '统计报表',
    // 代码事件配置
    codeEventSet: '代码事件配置'
  },

  // 列表设置
  listOfSet: {
    operation: '操作',
    batchExport: '批量导出',
    ponoFieldExist: 'PONO字段不存在',
    attachmentManagement: '进口附件管理',
    enableOcr: 'OCR识别',
    add: '新增',
    refresh: '刷新',
    clearCatch: '清除缓存',
    loadField: '加载字段',
    copyModule: '复制模块',
    search: "搜索",
    reset: "重置",
    edit: '编辑',
    delete: '删除',
    colWidth: '列宽',
    moduleName: '模块名称',
    mainTable: '主表',
    foreignKey: '外键',
    viewSwitch: '查看开关',
    approvalHistorySwitch: '审批历史开关',
    isAgreeRequired: '是否审批必填',
    listSql: '列表sql',
    isImage: '图片',
    imageColumn: '图片第几列',
    storedProcedure: '表单存储过程',
    isReadOnly: '仅查看',
    isInlineEditable: '行内编辑',
    isUpdateKeyOnImport: '导入更新',
    isCheckFno: '校验fno唯一',
    parentModule: '上级模块',
    isPage: '分页',
    contactSql: '联系人sql',
    listStoredProcedure: '列表存储过程',
    checkStoredProcedure: '校验存储过程',
    isShowList: '列表展示',
    tableImgShow: '表格图片展示',
    formImgShow: '表单图片展示',
    isImport: '导入',
    isExport: '导出',
    batchExport: '批量下载',
    isSendEmail: '发送邮件',
    isUploadFile: '上传附件',
    isBatchModify: '批量修改',
    isAboutTypography: '左右排版',
    isGlobalRetrieval: '全局检索',
    tableFieldSet: '表格字段设置',
    formFieldSet: '表单字段设置',
    codeEventSet: '代码事件设置',
    languageSet: '语言设置',
    sheetSet: 'sheet页设置',
    isDbClkJump: '双击进入详情',
    isShowSubtotal: '分组小计',
    showTheApprovalTab: '显示审批页签',
    passProcName: '通过存储过程',
    rejectProcName: '驳回存储过程',
    pageSize: '默认每页显示数量',
    addSuccess: '新增成功',
    editSuccss: '更新成功',
    /* 字段设置 */
    fieldShowOrder: '字段显示顺序',
    databaseFieldName: '数据库字段名',
    showName: '前端显示名称',
    dataType: '数据类型',
    alignType: "对齐方式",
    queryOperator: "查询操作符",
    isSelect: '查询',
    isLocked: "锁定",
    isGlobalQuery: '全局检索',
    isSum: '合计',
    isShow: '可见',
    dataDict: '数据字典',
    datePrecision: "日期精度",
    language: '语言设置',
    databaseFields: '数据库字段名',
    configFormula: '公式编辑器',
    initialization: '初始化模块',
    initLanguage: '初始化语言',
    batchSetField: '批量设置字段',
    //编码设置
    save: '保存',
    year: '年份(2位)',
    years: '年份(4位)',
    month: '月份',
    date: '日期',
    fixedContent1: '固定内容1',
    fixedContent2: '固定内容2',
    serialNumber: '流水号',
    loginId: '登录人ID',
    serialNoDigit: '流水号位数',
    serialNoUt: '单号单位',
    pattern: '格式',
    formField1: '表单字段1',
    formField2: '表单字段2',
    formField3: '表单字段3',
    formField4: '表单字段4',
    formField5: '表单字段5',
    smallSerialStart: '小流水起始编号',
    bigSerial: '大流水号',
    resetSuccess: '已重置',
    soon: '等',
    strip: '条',
    filePermission: '单据提交后附件权限'
  },

  FormOfSet: {
    uploadDrag: " 将文件拖到此处，或",
    clickUpload: '点击上传',
    uploadSize: '文件大小不超过',
    add: '新增',
    fieldOrder: '字段显示顺序',
    name: '名称',
    showName: '显示名称',
    defaultValue: '新增默认值',
    expressionDecimalDigit: '保留位数',
    dictLevel: '数据字典层级',
    dictRelationParentId: '数据字典上级关联字段id',
    dataType: '数据类型',
    editType: '编辑类型',
    formConfigId: '表单配置Id',
    isMustFill: '保存时必填',
    isMustFillAgree: '提交时必填',
    approveParam: '提交字段',
    approveChange: '审批更改字段',
    combinedField: '合计字段',
    majorKey: '主键',
    isShow: '可见',
    isReadOnly: '只读',
    dataDict: '数据字典',
    remark: '备注',
    sheet: '所属sheet页面',
    expression: '自定义表达式',
    clauseTemplete: '条款模板模型',
    widthType: '宽度类型',
    matchingCondition: '请选择匹配条件',
    noFileSelected: '未选择任何文件'
  },

  // 列表设置-----语言设置
  LanguageSet: {
    contentRequire: "语言不可以为空！",
    contentRequire: "内容不可以为空",
    add: "新增",
    edit: '修改',
    language: '语言',
    content: '内容',
    operation: '操作',
    simplifiedChinese: "中文简体",
    confirm: "确定",
    cancel: "取消"
  },

  //列表设置-- sheet页设置
  SheetSetting: {
    name: '名称',
    AccordingToTheOrder: '显示排序',
    add: '新增',
    show: '是否显示',
    hasFold: '默认折叠',
    hasTabs: '子表标签页'
  },

  // 调取设置
  FetchSet: {
    sequence: '顺序',
    add: '新增',
    name: '名称',
    targetModuleId: '目标模块Id',
    parentModuleId: '上级模块Id',
    targetField: '目标字段',
    colWidth: '字段列宽',
    isPaged: '分页',
    fetchSql: '调取语句',
    isShowImage: '显示图片',
    showColumns: '显示在第几列',
    isRetrieval: '启用',
    containsCalledSQL: '含已调取的Sql',
    edit: '编辑',
    delete: '删除',
    languageSetting: '语言设置',
    tableFiledSetting: '表格字段设置',
    copySetting: '复制',
    fetchWarinning: '请填写调取语句!',
    cancel: '取消',
    confirm: '确定',
    spidTips: '暂无数据！',
    fourTips: '暂无数据！',
    generateOrder: '生成库存调拨单',
    sidebar: '侧边栏分类'
  },

  // 调取设置 ---- 调取字段设置
  FetchKeySet: {
    add: '新增',
    syncField: '显示字段同步',
    fieldShowOrder: '字段显示顺序',
    databaseFieldName: '数据库字段名',
    showName: '前端显示名称',
    dataType: '数据类型',
    alignType: "对齐方式",
    isSelect: '查询',
    queryOperator: "查询操作符",
    isSortable: '排序',
    isFetched: "调取",
    isLocked: "锁定",
    targetField: "目标字段",
    isShow: "可见",
    datePrecision: "日期精度",
  },

  reportSet: {
    reportName:"统计名称",
    viewRange: "查看范围",
    isEnabled:"是否使用",
    template: '报表名称',
    set: '赋值'
  },
  // 报表权限设置
  ReportSet: {
    jimuReportPermissionSetting: '积木报表权限设置',
    roleNamePlaceHolder: '请输入角色名称',
    save: '保存',
    code: '编号',
    reportName: '报表名称',
    createTime: '创建时间',
    warningTip: '请至少选择一项',
    saveTip: '保存成功',
    add: '新增',
    edit: '编辑',
    template:"模板人员",
    username :"人员",
    set:'赋值',
    empty:"数据为空",
    isEnabled:"是否使用",
  },

  // 字典设置
  DictSet: {
    template: '下载模板',
    searchButton: '搜索',
    resetButton: '重置',
    batchUpload: '导入',
    add: '新增',
    edit: '编辑',
    delete: '删除',
    dictName: '字典名称',
    isForbidden: '禁用',
    enabled: '正常',
    enable: '启用',
    disAbled: "禁用",
    remark: '备注',
    dataItemSetting: '数据项设置',
    name: '名称',
    alias: '别名',
    sortOrder: '排列顺序',
    desc: '描述',
    dataPermission: '数据权限',
    importSuccess: '导入成功',
    importError: '导入失败',
  },

  // 菜单管理
  menuSet: {
    shangpinku:'调取商品库',
    kucun:'调取库存',
    yushou:"预收款分摊",
    permisVies: "查看权限",
    menuName: '菜单名称',
    search: '搜索',
    reset: '重置',
    add: '新增',
    edit: '编辑',
    delete: '删除',
    icon: '图标',
    type: '类型',
    orderNum: '显示顺序',
    permsCode: '权限标识',
    remark: '备注',
    routingPath: '路由地址',
    menuStatus: '菜单状态',
    addMenu: '新增菜单',
    menuType: '菜单类型',
    menuIcon: '菜单图标(单色)',
    menuIconColor: '菜单图标多色',
    menuVisable: '菜单可见',
    menuOrder: '菜单顺序',
    parentCatalogue: '上级目录',
    associateModule: '关联模块',
    buttonName: '按钮名称',
    language: '语言设置',
    deleteTip: '确认删除?',
    tip: '提示',
    confirm: '确定',
    cancel: '取消',
    firstSelectDelete: '请先选择再删除',
    deleteSuccess: '删除成功！',
    action: '操作',
    onExpanded: '展开',
    onUnExpanded: '收缩'
  },

  // 用户管理
  userAdmin: {
    deptPlace: '请输入部门名称',
    userName: '用户名称',
    password: '密码',
    loginAccount: '登录账号',
    phoneNumber: '手机号码',
    status: '状态',
    createTime: '创建时间',
    search: '搜索',
    reset: '重置',
    add: '新增',
    passOn: '转交',
    passOnSuccess: '转交成功',
    passOnPerson: '转单目标人员',
    passOnMoudle: '转单模块',
    deptName: '部门名称',
    nickName: '用户昵称',
    englishName: '英文名称',
    phone: '手机号码',
    email: '用户邮箱',
    roleName: '角色',
    sex: '性别',
    postId: '岗位Id',
    avatar: '头像',
    deptId: '部门Id',
    passoword: '密码',
    remark: '备注',
    dept: '部门',
    edit: '编辑',
    delete: '删除',
    tip1: '请选择一项进行修改',
    loadingtip: '加载中，请稍等...',
    tip2: '请选择一项进行删除',
    tip3: '确认删除吗',
    tip: '提示',
    confirm: '确定',
    cancel: '取消',
    deleteSuccess: '删除成功！',
    editSuccess: '修改成功',
    noEmpty: '不能为空',
    limitOfAuthority: '权限',
    synchronousAssignmentGroup: '同步赋值组别',
  },

  // 用户管理 --- 编辑用户
  editUserAdmin: {
    save: '保存',
    sync: '同步',
    add: '新增',
    userInfo: '用户信息',
    nickName: '用户昵称',
    englishName: '英文名称',
    password: '密码',
    phoneNumber: '手机号码',
    remark: '备注',
    loginAccount: '登录账号',
    userEmail: '用户邮箱',
    postId: '岗位Id',
    dept: '部门',
    roleName: '角色',
    superiorLeaders: '上级领导',
    sex: '性别',
    status: '状态',
    emailConfig: '邮件配置',
    dataPermission: '数据权限',
    modelName: '模块名称',
    groupName: '组名称',
    groupCode: '组代码',
    edit: '编辑',
    delete: '删除',
    loadingtip: '加载中，请稍等...',
    saveSuccess: '保存成功',
    emailUserName: '发件人姓名',
    emailPassword: '邮箱密码',
    smtpHost: 'SMTP地址',
    smtpPort: 'SMTP端口',
    signature: '签名',
    ownPhone: '私人手机号',
    qq: 'QQ',
    weChat: '微信',
    platformName: '平台名称',
    idcard: '身份证号',
    isEmailManage: '是否邮箱管理员',
    noPicture: '暂无图片'
  },

  // 在线用户
  userOnline: {
    userName: '用户名',
    name: '中文名',
    search: '搜索',
    reset: '重置',
    kickOut: '踢出',
    email: '邮箱',
    phone: '电话',
    sex: '性别',
    loginConputerIp: '登录计算机Ip',
    loginTime: '登录时间',
    nowTime: '登录时长',
    status: '状态',
    remark: '备注',
    tip1: '确认踢出此用户吗？',
    tip: '提示',
    confirm: '确定',
    cancel: '取消',
    tip2: '请先选择再踢出！',
    tip3: '已踢出用户'
  },

  // 角色管理
  roleAdmin: {
    selectedOnly: '请选择一条数据',
    copy: '复制',
    addRole: '增加权限',
    cancleRole: "取消权限",
    tip1: '确定删除勾选的角色？',
    menuAllot: '菜单分配',
    roleName: '角色名称',
    status: '状态',
    search: '搜索',
    reset: '重置',
    add: '新增',
    save: '保存',
    showSort: '显示顺序',
    roleStatus: '角色状态',
    permissionChar: '权限字符',
    permissionScope: '权限范围',
    dataPermission: '数据权限',
    dataScope: '数据范围',
    remark: '备注',
    edit: '编辑',
    delete: '删除',
    tip2: '不能为空',
    tip3: '请先选择再删除',
    tip4: '请选择要修改分配的菜单',
    tip5: '保存成功',
    requiredName: '名称不能为空'
  },

  // 部门管理
  deptAdmin: {
    deptName: '部门名称',
    status: '状态',
    search: '搜索',
    reset: '重置',
    remark: '备注',
    add: '新增',
    deptCode: '部门代码',
    departmentHead: '部门负责人',
    contactWay: '联系方式(手机号)',
    showSort: '显示顺序',
    createTime: '创建时间',
    addDept: '新增部门',
    superiorDepartment: '上级部门',
    edit: '编辑',
    delete: '删除',
    tip1: '确认删除勾选部门吗？',
    tip: '提示',
    confirm: '确定',
    cancel: '取消',
    tip2: '请先选择再删除！',
    tip3: '删除成功'
  },

  //打印管理
  printAdmin: {
    add: '新增',
    line: '顺序',
    modelNumber: '模块序号',
    modelCode: '模块代码',
    modelId: '模块Id',
    mainTableName: '主表名称',
    modelName: '模块名称',
    reportName: '报表名称',
    classify: '分类',
    idField: 'id字段',
    remark: '备注',
    edit: '编辑',
    delete: '删除',
    tip1: '确认删除勾选模块吗？',
    tip: '提示',
    confirm: '确定',
    cancel: '取消',
    tip2: '请先选择再删除',
    tip3: '删除成功',
    classify: '分类',
    total: '下载次数',
    printer: 'printer',
    closeConfirm: '关闭前保存了吗?'
  },

  // 系统日志
  Systemlog: {
    tip: '提示',
    requestParams: '请求参数',
    returnParams: '返回参数',
    module: '模块',
    status: '状态',
    message: '消息',
    remark: '备注',
    requestMethodName: '请求方法名称',
    requestIp: '请求Ip',
    createByName: '用户',
    createTime: '时间',
    execTime: '执行秒数',
    requestWay: '请求方式',
    bussinessType: '业务类型',
    cancel: '取消',
    confirm: '确定',
    search: '搜索',
    reset: '重置',
    add: '新增',
    delete: '删除',
    tip1: '确认删除勾选参数吗？',
    tip2: '请先选择再删除',
    tip3: '删除成功！'
  },

  // 模板类型
  termsTemplete: {
    add: '新增',
    templeteName: '模板名称',
    templeteContent: '模板内容',
    content: '请输入内容',
    templeteType: '模板类型',
    createBy: '创建人',
    createTime: '创建时间',
    updateBy: '更新人',
    updateTime: '更新时间',
    selectDate: '选择日期',
    cancel: '取消',
    confirm: '确定',
    search: '搜索',
    reset: '重置',
    delete: '删除',
  },

  // 预警设置
  warning: {
    content: '请输入内容',
    select: '请选择',
    warningTitle: '预警标题',
    isPage: '分页',
    warningSql: '预警sql',
    CustomMenuName: '自定义菜单名称',
    remark: '备注',
    showOrder: '显示顺序',
    isShow: '可见',
    cancel: '关闭',
    confirm: '确定',
    add: '新增',
    edit: "编辑",
    batchDeletes: '批量删除',
    delete: '删除',
    languageSet: '语言设置',
    warningFieldSet: '预警字段设置',
    tip1: '请选择要删除的数据',
    tip2: 'addSuccess',
    warningName: '预警名称',
    emptyContent: '请在左侧点击报表名称选择报表',
  },

  // 预警设置---预警字段设置
  warnFieldSet: {
    databaseName: '数据库字段名',
    search: '查找',
    fieldShowOrder: '字段显示顺序',
    datebaseField: '数据库字段名',
    showName: '前端显示名称',
    dataType: '数据类型',
    select: '请选择',
    alignType: '对齐方式',
    isSelect: '查询',
    QueryOperator: '查询操作符',
    isLock: '锁定',
    datePrecision: '日期精度',
    isShow: '可见',
    cancel: '取消',
    confirm: '确定',
    add: '新增',
    edit: '编辑',
    languageSet: '语言设置',
    tip1: '请选择要删除的数据',
    tip2: '新增成功',
  },

  // 流程模型
  flowModel: {
    name: '名称',
    search: '搜索',
    reset: '重置',
    add: '新增',
    flowKey: '流程Key',
    version: '版本',
    createTime: '创建时间',
    updateTime: '更新时间',
    metadata: '元数据',
    edit: '编辑',
    delete: '删除',
    upload: '部署',
    download: '导出',
    downLoad: '下载',
    addFlowModel: '添加流程模型',
    category: '分类',
    desc: '描述',
  },

  // 流程定义
  flowDefinition: {
    operation: '操作',
    moduleId: '模块id',
    moduleName: '模块名称',
    programmeId: '方案id',
    programmeName: '方案名称',
    processDefinitionKey: '流程实例Key',
    remark: '备注',
    tip4: '确认删除勾选模块吗？',
    tip5: '请先选择再删除',
    tip6: '保存成功',
    save: '保存',
    active: '激活',
    hangUp: '挂起',
    transferModel: '转模型',
    delete: '删除',
    upload: '部署',
    category: '分类',
    select: '请选择',
    selectFile: '选择文件',
    clickUpload: '点击上传',
    cancel: '取消',
    confirm: '确定',
    flowChart: '流程图',
    flowKey: '流程Key',
    flowName: '流程名称',
    search: '搜索',
    reset: '重置',
    flowId: '流程ID',
    version: '版本',
    flowDesc: '流程描述',
    uploadTime: '部署时间',
    flowDefinition: '流程定义',
    deploymentProcessDefinition: '部署流程定义',
    flowDefinitionStatus: '流程定义状态',
    edit: '编辑',
    delete: '删除',
    actived: '已激活',
    tip1: '确认删除此流程吗？',
    tip: '提示',
    confirm: '确定',
    cancel: '取消',
    tip2: '删除成功',
    tip3: '请选择分类再提交',
    add: '新增',
    selectModule: '先选择模块名称',
    selectProgramme: '先选择方案名称'
  },

  // 审批方案
  programmeSet: {
    name: '名称',
    search: '搜索',
    reset: '重置',
    jointTrial: "是否会审",
    add: '新增',
    groups: '审批组',
    Appoint: '指定审批人',
    nodeSetTip: '请选择审批节点',
    nodeSet: '审批节点设置',
    programmeSetAdd: '新增方案',
    programmeSetEdit: '编辑方案',
    programmeSetName: '方案名称',
    programmeSetCode: '方案编号',
    approvalNode: '审批节点',
    version: '版本',
    createTime: '创建时间',
    updateTime: '更新时间',
    metadata: '元数据',
    edit: '编辑',
    delete: '删除',
    upload: '部署',
    download: '导出',
    addFlowModel: '添加',
    category: '分类',
    desc: '描述',
  },

  // 审批
  Approval: {
    normal: '正常',
    remove: '删除',
    auditor:'审核人',
    backTo: '返回节点',
    tipNode: '不选默认退回提交人!',
    operation: '操作',
    agree: '同意',
    reject: '驳回',
    history: '历史',
    schedule: '进度',
    view: '查看',
    confirm: '确定',
    cancel: '取消',
    approvalOption: '审批意见',
    approvalProcess: '审批流程',
    approvalHistory: '审批历史',
    approveParam: '审批参数',
    scheduleChart: '进度图',
    initiator: '发起人',
    moduleName: '模块名称',
    search: '搜索',
    reset: '重置',
    refresh: '刷新',
    approvalAgree: '审批通过',
    approvalReject: '审批驳回',
    approvalTime: '审批时间',
    // assignee: '分配给',
    approvalParam: '审批参数',
    taskName: '任务名称',
    assignee: '审批人',
    startTime: '开始时间',
    endTime: '结束时间',
    durationTime: '耗时',
    refreshSuccess: '刷新成功',
    refressFail: '刷新失败',
    tip1: '请勾选后再进行同意操作！',
    tip2: '请勾选后再进行驳回操作！',
    viewInvoices: '查看',
    print: '打印',
    approvalStatus: '审批状态',
    tip44: '请填写审批意见',
    initiationDate: '发起日期',
    fno: '单据编号',
    save: '保存',
    status: '状态',
    billStatus: '状态',
  },

  // 订单进度管理
  Order: {
    orderProgressTable: '订单进度表',
    progress: '进度',
    operation: '操作',
    viewDetail: '查看详情',
    orderProgressChart: '订单进度图',
    contractNumber: '合同号',
    contractDate: '合同日期',
    customerName: '客户名称',
    search: '搜索',
    reset: '重置',
    refresh: '刷新',
    deliveryDate: '交货日期',
    destinationPort: '目的港',
    shipmentPort: '装运港',
    createBy: '监理人',
    progress: '进度',
    addNode: '新增节点',
    confirmNode: '确认节点',
    linkName: '环节名称',
    computeMode: '计算方式',
    basisNode: '依据节点',
    Days: '所需天数',
    createDate: '创建日期',
    chargePersion: '责任人',
    status: '状态',
    edit: '编辑',
    delete: '删除',
    selectNode: '选择节点',
    confirm: '确认',
    cancel: '取消',
    sendSuccess: '发送成功',
    addNodes: '新增节点库',
    nodeName: '节点名称',
    nodeEndCondition: '节点结束条件',
    nodeEndConditionSql: '节点结束条件语句',
    nodesName: '节点库名称',
    createByName: '创建人名称',
    createTime: '创建时间',
    tip1: '确认删除此节点吗？',
    tip: '提示',
    saveSuccess: '保存成功',
  },

  // 嵌套BI系统
  Bisystem: {
    BiName: "统计查询"
  },

  StatisticalManagement: {
    StatisticalManagement: "统计管理",
  },

  // 邮件模块
  Emails: {
    edit: '编辑',
    sign: '签名设置',
    folders: '文件夹设置',
    eName: '邮件系统',
    write: '写邮件',
    contact: '通讯录',
    inbox: '收件箱',
    outbox: '发件箱',
    drafts: '草稿箱',
    trash: '垃圾箱',
    folders: '文件夹',
    searchFolder: "搜索文件夹",
    label: '邮件标签',
    settings: '邮件设置',
    please: '请登录',
    login: '登录',
    loginsuccess: '登录成功',
    loginfailed: '登录失败',
    selectAccount: '选择账号',
    signContent: '签名内容',
    account: '账号',
    password: '密码',
    notnull: '账号或密码不能为空',
    cancel: '取消',
    go: '确定',
    register: '注册',
    regSuccess: '注册成功',
    khname: '单位名称',
    copy: '抄送',
    secret: '密送',
    addcopy: '添加抄送',
    addsecret: '添加密送',
    delcopy: '删除抄送',
    delsecret: '删除密送',
    addressee: '收件人',
    sendName: '发件人姓名',
    copyName: '抄送人',
    linkTips: '暂无联系人请前往通讯录添加或直接输入',
    theme: '主题',
    upload: '上传附件',
    clearFiles: '清空列表',
    numsTips: '目前已选中附件数：',
    uploadTips: '总列表大小:',
    sizeTips: '附件列表总大小为：',
    readReceipt: '已读回执',
    Sender: '发件人',
    pickSender: '请选择发件人',
    send: '发送',
    save: '存草稿',
    reset: '重置',
    taskAddBut: '新增',
    entrustAddBut: '新增',
    taskRemoveBut: '删除',
    entrustRemoveBut: '删除',
    addresseeEmpty: '收件人不能为空',
    themeEmpty: '主题不能为空',
    senderEmpty: '发件人不能为空',
    senderNull: '还没有发件人，请前往邮箱设置添加',
    textEmpty: '发件内容不能为空',
    sendSuccess: '发送成功',
    sendFail: '发送失败',
    saveSuccess: '保存草稿成功',
    saveFail: '保存草稿失败',
    refresh: '刷新',
    themeKeyword: '主题关键字',
    contentKeyword: '内容关键字',
    senderKeyword: '选择发送人',
    getAll: '全选',
    clearAll: '全不选',
    oneByone: '群发单显',
    generatedBy: "AI写信",
    languageSet: '目标语言',
    transLate: '翻译',
    promotional: '开发信',
    exampleContent: '示例：请为我生成一份面向零售行业客户的外贸开发信，我叫张三，来自xxx公司，我们的主要产品服务是外贸软件，目标市场是东南亚。',
    addMails: "插入到邮件",
    readStatus: '阅读状态',
    all: '全部',
    theLastDay: '最近一天',
    lastThreeDays: '最近三天',
    lastSevenDays: '最近七天',
    lastThirtyDays: '最近30天',
    lastNinetyDays: '最近90天',
    lastOneHundredEightyDays: '最近180天',
    unread: '未读',
    read: '已读',
    removed: "已删除",
    receive: '接收',
    delete: '删除',
    emailNums: '邮件总数',
    emailUnread: '未读邮件',
    receiving: '接收中...',
    receiveFail: '接收失败',
    receiveSuccess: '接收成功',
    emailDetails: '点击查看邮件详情',
    delTips: '请选择后再删除',
    delSuccess: '删除成功',
    delFail: '删除失败',
    contactsList: '联系人列表',
    contactsManage: '联系人管理',
    categoryManage: '类别管理',
    categoryName: '类别名称',
    customerName: '客户名称',
    name: '姓名',
    customerCode: '客户代码',
    email: '邮箱',
    operation: '操作',
    categoryParent: '上级分类',
    phone: '电话',
    reply: '回复',
    replyAll: '回复全部',
    date: '时间',
    saving: '保存',
    pickCategory: '选择类别',
    edit: '修改',
    cateTips: '请选择一项',
    chooseAnItem: '只能选择一项',
    writeCategoryName: '请填写类别名称',
    nameMailTips: '请将姓名和邮箱补充完整',
    recoverySuccess: '恢复成功',
    recoveryFail: '恢复失败',
    newFolder: '新增文件夹',
    editFolder: '编辑文件夹',
    addFolderTips: '请输入文件夹名称',
    folderName: '文件夹名称',
    addSuccess: '新增成功',
    addFail: '新增失败',
    emailSetting: '邮箱设置',
    signSetting: '签名设置',
    folderSetting: '文件夹设置',
    displayName: '显示名称',
    senderName: '发件人姓名',
    emailAddress: '邮箱地址',
    required: '请填写',
    addSetting: '新增设置',
    requiredSelect: '请选择',
    selectMoveWarning: '请选择要移动的邮件',
    moveSuccess: "移动成功",
    maveFailed: '移动失败',
    sendingServer: '发送服务器',
    Authorization: "授权密码",
    sendserverType: "服务器类型(收)",
    serverAddress: "服务器地址",
    sendserverAddress: "服务器地址(发)",
    isDefault: '是否默认',
    enable: '是否启用',
    port: '端口',
    sendTime: '发送时间',
    originalMail: '原始邮件',
    receiveAll: '收取所有账号',
    receiveMailPick: '请选择要收取的账号',
    receiveMailTime: '请选择要收取的时间段',
    receiveEmail: '收取选项',
    emailNums: '邮件数量',
    relatedModules: '相关模块',
    moduleName: '模块名',
    fno: '单据号',
    selectReportFile: '选择报表附件',
    uploaded: '已上传的附件',
    uploadSuccess: '上传报表附件成功',
    uploadFailed: '上传报表附件失败',
    selectModelName: '请选择模块名',
    selectPrintFormat: '请选择打印格式',
    readTime: '阅读时间',
    readAddress: '所在地区',
    readCount: '阅读次数',
    readCount: '阅读次数',
    markAllAsRead: '全部标记为已读',
    markSelectedAsRead: '选中标记为已读',
    markAllAsReadTip: '确认要将所有邮件标记为已读吗',
    markSelectedAsReadtip: '确认要将选中的邮件标记为已读吗',
    markedSuccess: '标记成功',
    markedFail: '标记失败',
    moveto: '移至',
    emailForward: '转发',
    emailTemplate: '邮件模板',
    addTemplate: '新增模板',
    EditTemplate: '编辑模板',
    restore: '确认要恢复此邮件吗',
    emailSet: '邮箱配置',
    noAccount: '您还未配置邮箱账号',
    goToSet: "去设置",
    eMarketingName:'电子邮件营销',
    eMarketingTemplate: "电子邮件模板",
    eMarketingTask: "任务",
    eMarketingEntrust: '委托销售',
    eMarketingStatistics: '统计数据',
    singleForwarding: '仅支持单项转发',
    attachments: '附件',
    download: '下载',
    cancel: '关闭',
    selectTheMailTemplate: '选择邮件模板',
    name: '名称',
    defaultExpression: '默认表情',
    receivingAccount: '请选择接收账号',
    pleaseSelectAnAccount: '请选择账号',
    pleaseEnterYourSignature: '请输入签名的内容',
    pleaseFillInTheName: '请填写名称',
    pleaseFillInTheContent: '请填写内容',
    targetLanguage: '请选择目标语言',
    english: '英语',
    french: '法语',
    spanish: '西班牙语',
    japanese: '日语',
    korean: '韩语',
    burmese: '缅甸语',
    thai: '泰语',
    russian: '俄语',
    servicesProvidedByOpenai: '由openai提供服务',
  },

  // 补充的提示语
  addTips: {
    approve:'审批中',
    reject:'驳回',
    pass:'通过',
    noUse:'模块未启用',
    hasPigeonhole:'已归档',
    noPigeonhole:'未归档',
    all:'全部',
    cancel:'取消',
    confirm:'确认',
    noSupportpreview:'该文件暂不支持预览',
    fileServiceIsNotDeployed:'文件预览服务未部署',
    isAssign:'是否指定',
    programmeNameRequire:'请填写方案名称',
    programmeCodeRequire:"请填写方案编号",
    addPerson:'请选择人员',
    number:'第',
    groupEmpty:"组名不能为空",
    approveEmpty:'请添加审批节点',
    nodeEmpty:"节点数据不能为空",
    tempNameRequire:'请输入模板名称',
    tableNameRequire:'请输入表名',
    refreshDatabase:'请稍后，正在刷库',
    nowusebbs:'子表名称不可重复',
    tableNameNorepeat:"表名不可重复",
    moduleNameNorepeat:'模板名称不可重复',
    done:'操作成功',
    doneFailed:'操作失败',
    successZhengshi:'成功转为正式客户',
    selectZhengshi:'请选择需要转正式的客户',
    successGonghai:'成功转为公海客户',
    selectGonghai:'请选择需要转公海的客户',
    selectGiveData:'请选择需要转单的数据',
    selectClassIs:'您选中的是',
    ifContinue:'确认继续吗',
    addTopClass:"请先选择大类",
    cantclaimed:'当前水单不能认领',
    treeBelow:'请选择树结构的最底层进行',
    goodCanNotDelete:'此节点还有商品，不能删除',
    orderNo:"该采购订单不存在",
    addoccupy:'请选择需要占用的明细',
    onlyOneoccupy:'只能选择一条记录',
    occupyNum: '请输入占用数量',
    warehouseLocation: '库位',
    productName: '产品名称',
    currentInventory: '当前库存',
    purchaseQuantity: '采购数量',
    occupationQuantity: '占用数量',
    onlyOneTop:'只能勾选一条顶级主表',
    comfirmDelet: "确认要删除吗？",
    selectRow: "请选择要移动的行",
    onlyOneRowMove: "只能选择一行进行移动",
    noEffectRow: '目的行号无效，请重新输入',
    noMaxLine: '不能超出最大行号',
    fetchNoMove: '明细数据已被调用,无法删除',
    checkError: '校验明细异常',
    nodeNoGood: '此节点暂时还没有商品哦',
    languageNoRepeat: '语言已添加，请勿重复添加！',
    printMessage: 'IE浏览器下无法预览打印，请使用其他浏览器打开',
    tableNoData:'未查询到该模块的流程设置,请设置后再试！',
    subassembly: '子配件：',
    sono: '销售发票号',
    itemno: '产品编号',
    sdesc: '中文品名',
    zyqty: '已占用数量',
    poqty: '未占用数量'

  },

  GlobalConfig: {
    duplicationCheck: '设置',
    CustomerSwitch: '客户开关',
    CustomerSwitchDesc: '客户查重功能，查重规则：客户编号（fno），客户简称（cpal），客户名称（cpny），联系电话（tel） ，公司网址（http）',
    SupplierSwitch: '供应商开关',
    SupplierSwitchDesc: '供应商查重功能，查重规则：供应商编号（fno），供应商简称（cpal），供应商名称（cpny），电话（tel） ，公司网址（http）',
    GoodsSwitch: '商品开关',
    GoodsSwitchDesc: '商品查重功能，商品编号（itemno）',
    FeedbackConfiguration: '基本配置',
    projectName: '项目名称',
    projectContact: '项目合同号',
    version: '版本号',
    systemCallbackAddress: '系统回调地址',
    wincareAddress: 'wincare地址',
    projectNamePlace: '请填写项目名称',
    projectContactPlace: '请填写项目合同号',
    wincareAddressPlace: '请填写系统回调地址'
  },

  // 配置模块
  configMoudle: {
    setting: '设置',
    actions: '同步',
    settingSyncTip: '是否同步？',
    success: '成功',
    fail: '失败',
    splitLine: '数据拆分',
    splitLineNum: '数量',
    splitAdd: '新增',
    splitOperation: '操作',
    splitQtyError: '拆分数量的和必须等于总数量',
    filesManage: '附件管理',
    tip: '提示',
    ocr: 'OCR识别',
    tableExportName: '表格数据',
    timedifference:'时间差',
    resetTheSelection: '重置选择',
    nodeFinish:'节点完成时间字段',
    analyticResult: '分析结果',
    nodeFlow:'节点流程',
    associatedFormField: '关联表单字段',
    waterKey:'小流水key',
    associateSubtableFields: '关联子表字段',
    topLeval:'顶级',
    operator:'选择运算符',
    function:"选择函数",
    selectfieldName:'选择字段',
    clear:'清空',
    hierarchyTree: '层次结构树',
    fieldType:'字段类型',
    field: '字段',
    resultField:'结果字段',
    formulaEditor:'公式编辑器',
    fieldDigit:'保留位数',
    fieldLength:'字段长度',
    characteristic:'字段特性',
    dbname:'数据库名',
    fieldName:'字段名称',
    catalogue:'目录',
    menu:'菜单',
    button:'按钮',
    male:'男',
    female:'女',
    normal:'正常',
    diabled:'停用',
    signOrNot:'是否会签',
    tableName:'表名',
    bulkEdit:'批量编辑',
    portDetails: '单据详情',
    totalAll: '总计',
    noData: '暂无数据',
    multipleChoice: '单选',
    saveFirst: '请先保存',
    uploadTypeTip: "上传图片只能是 JPG 或者 PNG 格式!",
    uploadSuccess: '上传成功',
    uploadFail: '上传失败',
    ForTrackRemark: '备注：所属模块及所属单号必填，输入完成后请点击查询',
    searchForTrack: '查询所属单号',
    addFile: '新增附件',
    updateFile: '变更附件',
    moveLineNum: '目标行号',
    destLineNum: '目的行号',
    selctRowTip: '请选择要编辑的行',
    selctRowTipDelet: ' 请选择要删除的行',
    getTemplate: '获取模板',
    details: '详情',
    forModel: '所属模块',
    forTrack: '所属单号',
    filesManage: '附件管理',
    serialNumber: '序号',
    thumbnail: '缩略图',
    filesLoading: '附件加载',
    noConfigTotal: '您还未配置小计',
    noGroup:'请选择分组条件',
    noTotalField:'请选择小计字段',
    Financialrecognition: '财务确认',
    FinancialCancle: '取消财务确认',
    noRepeat: '不能重复',
    confirmRecall: '是否撤回当前数据',
    recallSucces: '撤回成功',
    CategoryNum: '大类编号',
    shareSuccess: '共享成功',
    imageUpload: '多图片上传',
    selectFillter: '输入关键字进行过滤',
    inventoryNumber: '备货单号',
    selectYes: "是",
    selectNo: '否',
    confirmDelete: '是否删除当前数据？',
    transferOrder: '转单',
    selectTransfer: '请选择要转单的数据',
    selectPrint: '请选择需要打印的数据',
    saveDefaultTemplate: '保存默认模板',
    highSeasCustomers: '公海客户',
    customerMemorabilia: '客户大事记',
    formalCustomers: '正式客户',
    sendEmail: '发送邮件',
    excelCopy: 'Excel 复制',
    claim: '认领',
    add: '新增',
    copyTokey: '复制到表格',
    copyToform: '复制到表单',
    initializationSequence: '初始化顺序',
    edit: '编辑',
    delete: '删除',
    bulkEdit: '批量修改',
    content: '请输入内容',
    selectDate: '选择日期',
    select: '请选择',
    commodityTypeAdmin: '商品类别管理',
    typeTitleAdd: '商品类型新增',
    typeTitleEdit: '商品类型编辑',
    parentType: '上级类别',
    TypeName: '类别名称',
    clickUpload: '点击上传',
    fileView: '文件预览',
    Viewflie: '预览',
    save: '保存',
    printFormat: '打印格式',
    headSeal: '抬头章',
    cancel: '取消',
    print: '打印',
    batchPrinting: '批量打印',
    more: "更多",
    share: '共享',
    placeOnFile: '归档',
    toggleArchiving: '归档/取消归档',
    bulkSubmission: '批量提交',
    personnel: '人员选择',
    personName: '人员',
    classChange: '类别变更',
    uploadPicture: "上传图片",
    billsPrint:"单据打印",
    uploadPictures: "批量上传图片",
    globalQuery: '全局检索',
    modelName: '模板名',
    search: '查找',
    img: '图片',
    tip1: '请输入类别名称',
    tip2: '修改成功',
    tip3: '添加成功',
    tip4: '请选择一项',
    tip5: '确认要删除当前类别以及子类别吗',
    tip: '提示',
    confirm: '确定',
    cancel: '取消',
    tip6: '删除成功',
    refresh: '刷新',
    close: '关闭',
    closeLeft: '关闭左侧',
    closeRight: '关闭右侧',
    closeOther: '关闭其他',
    closeAll: '关闭全部',
    view: '查看',
    versionView: '版本查看',
    tip7: '只能上传Excel格式',
    tip8: '您不是该单据的创建人!',
    tip9: '该单据正在审批中或已通过',
    tip10: '您没有选择行',
    tip11: '请不要勾选已审批或正在审批的行',
    tip12: '请先选择一行再修改',
    tip13: '请先选择一行再打印',
    tip14: '请选择报表输出格式！',
    tip15: '请选择模块名！',
    tip16: '打印成功',
    tip17: '下载成功',
    approvalHistory: '审批历史',
    purchaseContract: '采购合同号',
    supplierName: '供应商名称',
    fileName: '附件名称',
    filePath: '附件路径',
    fileType: '附件类型',
    compilingAndbrushingLibraries: '编译刷库',
    moduleName: '模块名称',
    mainTable: '主表表名',
    subTableName: '子表名称',
    subTable: '子表表名',
    aboutMainTable: '对应主表',
    createBy: '创建人',
    // 详情
    fetch: '调取',
    approval: '审批同意',
    reject: '审批驳回',
    approvalOption: '审批意见',
    sendEmail: '发送邮件',
    Examination: '审批',
    ExaminationSave: '审批保存',
    AnnextopurchaseContract: '采购合同附件',
    Appendixtoexportcontract: '外销合同附件',
    uploadFile: '上传附件',
    report: '报表',
    includeFetchSql: '含已调取',
    includeSqlFinish: '含已调取完毕数据',
    viewLargeImg: '查看大图',
    save: '保存',
    status: '状态',
    copy: '复制',
    topcopy: '上移',
    bottomcopy: '下移',
    moveRow: '移动行号',
    update: '保存',
    submit: '提交',
    recall: '撤回',
    list: '列表',
    copyInvoice: '复制单据',
    // tip17: '请选择内容再点击确定',
    tip18: '页面已刷新，请重新进入此页再执行该操作！',
    subTotalSet: '小计配置',
    openSubtotal: '开启小计',
    closeSubtotal: '关闭小计',
    groupCondition: '分组条件',
    subtotalField: '小计字段',
    notSelected: '未选择',
    selected: '已选择',
    goLeft: '到左边',
    goRight: '到右边',
    noFno: '未找到此合同号，无法跳转',
    // Y-common
    linenum: '行号',
    batchDelete: '批量删除',
    replaceLineNumber: '目的行号',
    tip19: '您输入得行号不合法',
    tip20: '审批状态中...',
    tip21: '请选择要删除的数据',
    tip22: '只能选择一条进行换行',
    tip23: '请选择要共享的数据',
    tip24: '请选择要归档或者取消归档的数据',
    tip25: '请选择要共享的人员',
    tip26: '是否取消归档',
    tip27: "取消归档成功",
    unfiling: '取消归档',
    tip28: "归档成功",
    tipQuit: '确定退出吗?',
    sureToSave: '您若已经保存数据点击确定!',
    cancelFilter: '取消筛选',
    SalesContract: '外销合同',
    PurchaseContract: '采购合同',
    alteration: '变更',
    cancelAlteration: '取消变更',
  },

  // 首页设置
  homeSet: {
    homeSet: "首页设置",
    roleNamePlaceHolder: '请输入角色名称',
    warningSet: '预警设置',
    reportSet: '报表设置',
    earlyWarning: '预警',
    title: '标题',
    router: '路由',
    sql: 'sql',
    languageSetting: '语言设置',
    save: '保存',
    select: '请选择',
    inputPlaceHolder: '请输入内容',
    report: '报表',
    save: '保存',
    add: '新增',
    edit: '编辑',
    submitTip: '提交数据不能为空',
    saveTip: '保存成功',
    completeTip: '请完善数据并保存之后在设置语言',
    saveFailed: '保存失败',
    qrSetting: '二维码设置',
    qrUpload: 'APP二维码上传',
    qrEdit: '二维码信息编辑',
    qrPlaceholder: '请输入要编辑的信息',
    qrTips: '请先上传图片',
    basicInfor: "基本资料",
    fontConfig: "字体设置",
    passWordConfig: '修改密码',
    languageConfig: "语言设置",
    fontSize: "字体页面大小设置",
    fontFamily: '页面字体类型设置',
    oldPassword: '旧密码',
    newPassword: '新密码',
    languageSet: '语言设置',
    defaultFont: '默认',
    smallFont: '中号',
    largeFont: '大号',
    defultFont: '默认',
    song: '宋体',
    kai: "楷体",
    yuan: '圆体',
    shijian: '诗简体',
    logoSetting: 'Logo图片上传',
    companySetting: '名称',
    loginBackgroundSetting: '登录背景图',
    top:"顶部",
    side:"侧边",
    themeConfig:"主题配置",
    navSet:"导航栏位置",
    navTheme:"导航栏主题色",
    navFontColor:"字体颜色",
    pageTheme: "页面主题色",
    moreMenu: "更多",
  },

  // 邮件营销
  Marketing: {
    explosibility: '可发总量',
    alreadySent: '已发总量',
    remaining: '剩余可发',
    screening: '筛选',
    reset: "重置",
    sendEmail: '发送邮件',
    pleaseEnterSendEmail: '请输入发送邮件',
    missionDataOverview: '任务数据总览',
    marketingNumber: '营销人数',
    sendingNumber: '发送人数',
    deliveryNumber: '送达人数',
    unpackNumber: '打开人数',
    recoverNumber: '回复人数',
    taskName: '任务名称',
    add: '新增',
    remove: '删除',
    selected: '已选',
    PCS: '个',
    underWay: '进行中',
    complete: '完成',
    creationTime: '创建时间',
    startSending: '开始发送',
    sendComplete: '发送完成',
    lastUpdated: '最后更新',
    taskDeletion: '请选择要删除的任务 !',
    isDeleteCheck: '是否删除选中数据 ?',
    tip: '提示',
    senderSettings: '发件设置',
    contentEditing: '内容编辑',
    sendSetting: '发送设置',
    taskType: '任务类型',
    developmentLetter: '开发信',
    companyIntroduction: '公司介绍',
    productIntroduction: '产品介绍',
    longTermConnection: '长期联系',
    seasonsGreetings: '节日问候',
    other: '其他',
    senderNickname: '发件人昵称',
    sendingAddress: '发送地址',
    sendingType: '发送类型',
    none: '无',
    sendImmediately: '立即发送',
    automatedMarketing: '自动营销',
    replyEmail: '回复邮件',
    correctEmail: '正确的邮箱',
    type: '类型',
    addressee: '收件人',
    replyStopSending: '回复停止发送',
    readStoppedSending: '已读停止发送',
    pleaseEnter: '请输入',
    pleaseSelect: '请选择',
    select: '选择',
    optional: '选填',
    confirm: '确 认',
    cancel: '取 消',
    lastStep: '上一步',
    nextStep: '下一步',
    immediatelyEnable: '立即启用',
    emailSubject: '邮件主题',
    outgoingInterval: '发送间隔',
    subjectName: '主题名称',
    theme: '主题',
    controls: '操作',
    editor: '编辑',
    successfulOperation: '操作成功',
    successfulReplication: '复制成功',
    operationFailure: '操作失败',
    confirmToDelete: '确定删除吗?',
    mailSubjectName: '邮件主题名称',
    insertVariable: '插入变量',
    emailContent: '邮件内容',
    uploadAttachment: '上传附件',
    commodityList: '商品列表',
    templateSelection: '模板选择',
    AILetterWriting: 'AI信件',
    AIWrite: 'AI写信',
    variable: '变量',
    toggle: '切换',
    for: '为',
    language: '语言',
    english: '英语',
    french: '法语',
    spanish: '西班牙语',
    japanese: '日语',
    korean: '韩语',
    burmese: '缅甸语',
    thai: '泰语',
    russian: '俄语',
    day: '天',
    remark: '备注',
    isUnsubscribe: '是否带退订结束语',
    companyName: '公司名称',
    languageUsed: '输出邮件所使用的语言',
    productName: '产品名称',
    toneOfMail: '邮件语气',
    business: '商务',
    moreFormal: '较为正式',
    politeness: '礼貌',
    formal: '正式',
    festivalName: '节日名称',
    wordLimit: '字数限制',
    unlimitedWordCount: '不限字数',
    intraWord: '字以内',
    generalDescription: '总体描述',
    send: '发送',
    insertIntoAMessage: '插入到消息',
    copyContent: '复制内容',
    examples: '示例：请为我生成一份面向零售行业客户的外贸开发信，我叫张三，来自xxx公司，我们的主要产品服务是外贸软件，目标市场是东南亚。',
    product: '商品',
    templateName: '模板名称',
    hostedDataOverview: '托管数据总览',
    mailUserGrouping: '邮件用户分组',
    addGroup: '新建分组',
    deleteGroup: '删除分组',
    serialNumber: '序号',
    userGroup: '用户组',
    creationDate: '创建日期',
    instructions: '说明',
    name: '名称',
    mailbox: '邮箱',
    groupName: '分组名称',
    userMailbox: '用户邮箱',
    userGroupName: '用户组名称',
    refresh: '刷新',
    deletingUserGroup: '请选择需要删除的用户组 !',
    isCheckDeleteUserGroup: '确定删除选中的用户组吗 ?',
    successfullyDeleted: '删除成功 !',
    saveSuccessfully: '保存成功 !',
    success: '成功',
    search: '搜索',
    personalTemplate: '个人模板',
    mailTemplateName: '邮件模板名称',
    privatelyOwned: '私有',
    publicity: '公开',
    isPublicTemplate: '是否公开模板',
    templateTopic: '模板主题',
    templateContent: '模板内容',
    templateRemarks: '模板备注',
    saveTemplate: '保存模板',
    NOTranslationPicture: '无法翻译包含图片的内容 !',
    marketingTask: '营销任务',
    setbasicInformation: '设置基本信息',
    submitScheme: '提交并确认方案',
    trusteeshipScheme: '需要托管的方案',
    view: '查看',
    edit: '修改',
    addCustomScheme: '新建自定义方案',
    outgoingMailbox: '发件邮箱',
    replyEmail: '回复邮箱',
    recipientEmailAddress: '收件人邮箱地址',
    industry: '所属行业',
    textile: '纺织',
    homeFurnishings: '家居',
    mailLanguage: '邮件使用语言',
    confirmationMarketingLetter: '请确认营销信',
    tip1001: '通过多轮营销进行客户开发，每轮围绕不同侧重点，针对未回复收件人进行自动定时发信',
    startCircularMarketing: '开始循环营销',
    change: '换一换',
    addScheme: '新建方案',
    schemeName: '方案名称',
    templateLibrary: '模板库',
    generateTemplate: '生成模板',
    intervalTime: '间隔时间',
    triggerCondition: '触发条件',
    NoReplyYet: '未回复',
    unread: '未读',
    translation: '翻译为目标语言',
    description: '描述',
    save: '保存',
    isGenerateRemplate: '是否生成模板 ?',
    addSuccessfully: '添加成功',
    generateNewScheme: '修改内容启用后将生成新的方案 ?',
    mailTemplate: '邮件模板',
    addTemplate: '新增模板',
    updateTime: '更新时间',
    personalTemplate: '个人模板',
    commonTemplate: '公共模板',
  }
}
