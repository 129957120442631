export function suffixSize(val: string | number | undefined): string {

    if (typeof val === 'number') {
        return `${val}px`
    } else {
        val = String(val)

        if (/^[\d]+$/.test(val)) {
            return `${val}px`
        }

        if (val.indexOf('px') > 0) {
            return val
        }

        return val
    }
}

export function flatten(array) {
    let flattend = [];
    (function flat(array) {
        array.forEach(function (el) {
            if (Array.isArray(el)) flat(el);
            else flattend.push(el);
        });
    })(array);
    return flattend;
}

export const DEFAULT_SIZE = {
    rect: [130, 40],
    ellipse: [100, 50],
    diamond: [140, 50],
    circle: [80, 80],
    triangle: [80, 80],
    star: [80, 80],
    activity: [200, 80],
}

export function formatPos(option: { x: number, y: number, size: [number, number], shape: string }): { x: number, y: number, size: [number, number], shape } {

    const {x, y} = option
    option = {...option}
    option.size = option.size || DEFAULT_SIZE[option.shape]
    let [width, height] = option.size

    let originPos = {
        x: width / 2,
        y: height / 2,
    }
    let targetPos = {
        x, y
    }

    const grid = 5
    let durx = targetPos.x - originPos.x
    let dury = targetPos.y - originPos.y

    if (durx % grid > grid / 2) {
        durx = Math.ceil(durx / grid) * grid
    } else {
        durx = Math.floor(durx / grid) * grid
    }
    if (dury % grid > grid / 2) {
        dury = Math.ceil(dury / grid) * grid
    } else {
        dury = Math.floor(dury / grid) * grid
    }

    targetPos.x = durx + originPos.x
    targetPos.y = dury + originPos.y

    return {
        ...targetPos,
        size: option.size,
        shape: option.shape,
    }
}

export function formatNodeModel(model, activityConfig) {
    let {shape, size, activity} = model
          
    if (activity) {
        model.shape = 'activity'
    }

    model.size = size || DEFAULT_SIZE[shape]

    if (!!activity && !!activityConfig[activity]) {
        model.shape = 'activity'
        model.img = activityConfig[activity].img
        model.color = activityConfig[activity].color
    }else{
     
    }
}

export function staticPath(path) {
    return joinPath('/vue-flow-editor', path)
}

export function joinPath(source: string, target: string, separator = '/') {
    if (source.indexOf('/') === source.length - 1) {
        source = source.slice(0, -1)
    }
    if (target.charAt(0) === '/') {
        target = target.slice(1)
    }
    return `${source}${separator}${target}`
}