var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "transition",
    { attrs: { name: "vue-flow-editor-preview-transition" } },
    [
      _vm.p_value
        ? _c("div", { staticClass: "vue-flow-editor-preview" }, [
            _c(
              "div",
              {
                ref: "body",
                staticClass: "vue-flow-editor-preview-body",
                style: !_vm.ableClosed ? { height: "99%", width: "100%" } : {}
              },
              [
                _c("div", {
                  ref: "target",
                  staticClass: "vue-flow-editor-preview-target"
                }),
                _vm.ableClosed
                  ? _c(
                      "div",
                      {
                        staticClass: "vue-flow-editor-preview-close",
                        on: { click: _vm.close }
                      },
                      [_c("i", { staticClass: "el-icon-close" })]
                    )
                  : _vm._e()
              ]
            )
          ])
        : _vm._e()
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }