export function hoverAnchorActive(G6) {
    G6.registerBehavior('hover-anchor-active', {
        getEvents() {
            return {
                'anchor:mouseenter': 'onEnter',
                'anchor:mouseleave': 'onLeave',
                'node:mouseenter': 'onEnterNode',
                'node:mouseleave': 'onLeaveNode',
            }
        },
        onEnter(e) {
            const anchor = e.item;
            const group = anchor.getContainer()._cfg.parent
            if (!!group) {
                group.keyShape.showAnchor(this.graph)
                anchor.setState('active-anchor', true)
            }
        },
        onLeave(e) {
            const anchor = e.item;
            const group = anchor.getContainer()._cfg.parent
            if (!!group) {
                anchor.getContainer()._cfg.parent.keyShape.hideAnchor(this.graph)
                anchor.setState('active-anchor', false)
            }
        },
        onEnterNode(e) {
            const item = e.item;
            item.showAnchor(this.graph)
        },
        onLeaveNode(e) {
            try {
                const item = e.item;
                item.hideAnchor(this.graph)
            } catch (e) {
            }
        },
    })
}