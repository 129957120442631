import { setSession } from "@/utils/methods";
import language from "@/utils/checkLang";
import router from "@/router";
import { isArray } from "@/utils/validate";

export default {
  NAV_SET(state,payload){
    Object.keys(payload).map(it=>{
      state[it] =  payload[it] || ''
    })
  },
  setSearch(state,payload){
    state.showSearch = payload
  },
  // TAG_ADD(state, view) {
  //   if (view.meta && view.meta.title) {
  //     view.meta.title = view.meta.title
  //         .replace("新增", language.configMoudle.add)
  //         .replace("修改", language.configMoudle.edit)
  //         .replace("详情", language.configMoudle.details);
  //   }
  //   const substringId = (id) => {
  //     if (typeof id !== 'string') return '';
  //     return id.length > 6 ? id.substring(id.length - 6) : id;
  //   };
  //   const substringFno = (fno, id) => {
  //     if (fno == '' || fno == undefined || fno == null) return substringId(id);
  //   };
  //   const appendIdToTitle = (title, id, fno) => id ? `${title} (${substringFno(fno, id) || substringId(id)})` : title;
  //   const appendIdToName = (name, id) => id ? `${name}-${id}` : name;
  //   const isArrayView = Array.isArray(view);
  //   if (isArrayView) {
  //     state.tags = view;
  //   } else {
  //     const { query = {}, name, path, fullPath, meta = {} } = view;
  //     const hasQueryId = !!query.id;
  //     const hasConfigId = !!query.configId;
  //     const isDetailEdit = name.includes("_detailEdit");
  //     const isDetailView = name.includes("_detailView");
  //     if (!hasConfigId) {
  //       const existingIndex = state.tags.findIndex(item => item.name === name);
  //       if (existingIndex !== -1) {
  //         if (state.tags[existingIndex].path !== path || state.tags[existingIndex].fullPath !== fullPath) {
  //           state.tags.splice(existingIndex, 1, {
  //             ...view,
  //             title: meta.title || "没有名字",
  //           });
  //         }
  //       } else if (meta.title) {
  //         state.tags.push({ ...view, title: meta.title });
  //       }
  //     } else {
  //       if (isDetailEdit) {
  //         const existingEditTagIndex = state.tags.findIndex(item => item.path === path);
  //         if (existingEditTagIndex !== -1) {
  //           state.tags.splice(existingEditTagIndex, 1, {
  //             ...view,
  //             name: appendIdToName(name, query.id),
  //             title: appendIdToTitle(meta.title || "没有名字", query.id, query.fno),
  //           });
  //         } else {
  //           state.tags.push({
  //             ...view,
  //             name: appendIdToName(name, query.id),
  //             title: appendIdToTitle(meta.title || "没有名字", query.id, query.fno),
  //           });
  //         }
  //       } else if (isDetailView) {
  //         const existingTagIndex = state.tags.findIndex(item => hasQueryId ? item.query.id === query.id : item.name === name);
  //         if (existingTagIndex !== -1) {
  //           state.tags.splice(existingTagIndex, 1, {
  //             ...view,
  //             name: appendIdToName(name, query.id),
  //             title: appendIdToTitle(meta.title || "没有名字", query.id, query.fno),
  //           });
  //         } else {
  //           state.tags.push({
  //             ...view,
  //             name: appendIdToName(name, query.id),
  //             title: appendIdToTitle(meta.title || "没有名字", query.id, query.fno),
  //           });
  //         }
  //       } else {
  //         const existingTagIndex = state.tags.findIndex(item => hasQueryId ? item.query.id === query.id : item.name === name);
  //         if (existingTagIndex !== -1) {
  //           const existingTag = state.tags[existingTagIndex];
  //           if (hasQueryId && existingTag.query && existingTag.query.id !== query.id) {
  //             state.tags.push({
  //               ...view,
  //               title: appendIdToTitle(meta.title || "没有名字", query.id, query.fno),
  //             });
  //           } else {
  //             state.tags.splice(existingTagIndex, 1, {
  //               ...view,
  //               name: appendIdToName(name, query.id),
  //               title: appendIdToTitle(meta.title || "没有名字", query.id, query.fno),
  //             });
  //           }
  //         } else {
  //           if (view.meta.title && view.name !== 'home') {
  //             state.tags.push({
  //               ...view,
  //               title: appendIdToTitle(meta.title || "没有名字", query.id, query.fno),
  //             });
  //           }
  //         }
  //       }
  //     }
  //   }
  //   setSession("tags", state.tags);
  // },
  TAG_ADD(state, view) {
    if(view.meta && view.meta.title){
      view.meta.title = view.meta.title.replace("新增",language.configMoudle.add )
      view.meta.title=view.meta.title.replace("修改", language.configMoudle.edit)
      view.meta.title= view.meta.title.replace("详情", language.configMoudle.details)}
    const t = isArray(view);
    const { query = {}, name, path, fullPath, meta = {} } = view;
    if (t) {
      state.tags = view;
    } else {
      const isDetailView = name && name.includes("_detailView");
      if (isDetailView) {
        const hasQueryId = !!query.id;
        const substringId = (id) => {
          if (typeof id !== 'string') return '';
          return id.length > 6 ? id.substring(id.length - 6) : id;
        };
      const substringFno = (fno, id) => {
        if (fno == '' || fno == undefined || fno == null) return substringId(id);
      };
      const appendIdToTitle = (title, id, fno) => id ? `${title} (${substringFno(fno, id) || substringId(id)})` : title;
      const appendIdToName = (name, id) => id ? `${name}-${id}` : name;
        const existingTagIndex = state.tags.findIndex(item => hasQueryId ? item.query.id === query.id : item.name === name);
        if (existingTagIndex !== -1) {
          state.tags.splice(existingTagIndex, 1, {
            ...view,
            name: appendIdToName(name, query.id),
            title: appendIdToTitle(meta.title || "没有名字", query.id, query.fno),
          });
        } else {
          state.tags.push({
            ...view,
            name: appendIdToName(name, query.id),
            title: appendIdToTitle(meta.title || "没有名字", query.id, query.fno),
          });
        }
      } else {
        if (
            state.tags.some((item, index) => {
              if (item.name == view.name) {
                if (item.path != view.path || item.fullPath != view.fullPath) {
                  if(!view.meta.title) return
                  state.tags.splice(index, 1, {
                    ...view,
                    title: view.meta.title || "没有名字",
                  });
                }
                return true;
              }
            })
        )
          return;
        if(!view.meta.title) return
        state.tags.push(
            Object.assign({}, view, {
              title: view.meta.title || "没有名字",
            })
        );
      }
      setSession("tags", state.tags);
    }
  },
  TAG_DEL(state, obj) {
    const { handle, routePath, query } = obj;
    const isSameTag = (tag1, tag2) => {
      const extractRelevantFields = (query) => {
        if (!query) return {};
        return { configId: query.configId, id: query.id };
      };
      return tag1.path === tag2.path &&
          JSON.stringify(extractRelevantFields(tag1.query)) === JSON.stringify(extractRelevantFields(tag2.query));
    };
    const curId = state.tags.findIndex((item) => isSameTag(item, { path: routePath, query }));
    if (curId !== -1) {
      switch (handle) {
        case "closeLeft":
          if (curId > 0) {
            state.tags.splice(1, curId - 1);
          }
          break;
        case "closeRight":
          state.tags.splice(curId + 1);
          break;
        case "closeOther":
          if (curId === 0) {
            state.tags.splice(curId + 1);
          } else {
            state.tags = [state.tags[0], state.tags[curId]];
          }
          break;
        case "closeAll":
          state.tags = state.tags.filter((item, index) => index === 0);
          if (routePath !== "/home") router.push("/");
          break;
        default:
          if (routePath !== "/home") {
            state.tags.splice(curId, 1);
          }
          break;
      }
    } else if (!query || !query.id) {
      const noQueryTagIndex = state.tags.findIndex((item) => item.path === routePath);
      if (noQueryTagIndex !== -1) {
        state.tags.splice(noQueryTagIndex, 1);
      }
    }
    setSession("tags", state.tags);
  },

  ROUTES_SET(state, router) {
    state.routes = router;
  },
  GET_USERINFO(state, userInfo) {
    state.userInfo = userInfo
    setSession("userInfo", userInfo);
  },
  saveModuleMainFormData(state, info){
    state.moduleMainFormData = info
  },
  /**
   * 添加缓存的关闭标签页面 (列表页的标签删除)
   */
  ADD_TAG_CLEAR_CATCH(state,info){
    let data = state.tagCloseRouteList
    if (info){
      data.push(info)
    }
    state.tagCloseRouteList = data
  },
  /**
   * 删除缓存的关闭标签页面 (列表页的标签删除)
   */
  DEL_TAG_CLEAR_CATCH(state,info){
    let data = state.tagCloseRouteList
    if (info){
      data.splice(data.indexOf(info),1)
    }
    state.tagCloseRouteList = data
  },
  /**
   * 添加缓存的关闭标签页面 (详情页的标签删除)
   */
  ADD_TAG_CLEAR_DETAIL_CATCH(state,info){
    let data = state.tagCloseDetailRouteList
    if (info){
      data.push(info)
    }
    state.tagCloseDetailRouteList = data
  },
  /**
   * 删除缓存的关闭标签页面 (详情页的标签删除)
   */
  DEL_TAG_CLEAR_DETAIL_CATCH(state,info){
    let data = state.tagCloseDetailRouteList
    if (info){
      data.splice(data.indexOf(info),1)
    }
    state.tagCloseDetailRouteList = data
  },

  CLICK_TAG(state, isClick) {
    state.isClickTag = isClick
  },

  IS_SHOW_CLOSE_TAG(state, boo) {
    state.isShowClose = boo
  }
};
